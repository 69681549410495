/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, options_countries, options_banner_type, BASE_URL} from "../global";
  import DoneIcon from '@mui/icons-material/Done';

  import CloseIcon from '@mui/icons-material/Close';

  
  import "bootstrap/dist/css/bootstrap.min.css";

  import Box from "@mui/material/Box";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

const Banner = () => {



  const [records, setRecords] = useState([]);

  
  const [data, setItems] = useState([]);
  
  
  const [loading, setLoading] = useState(true);

  const [option_centre, setOptions] = useState([]);



  //Api call start


  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
const [data_delete, setDeleteLabelData] = useState([]);
const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


const handleDeleteClick = (row) => {
   
 
    const updatedData = { ...row};
    setDeleteLabelData(updatedData);
 
    setDeleteShow(true);
 };



 const [showVisible, setVisibleShow] = useState(false);
 const [data_visible, setVisibleLabelData] = useState([]);
 const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
 
 
 const handleVisibleClick = (row) => {
    
     const updatedData = { ...row};
     setVisibleLabelData(updatedData);
  
     setVisibleShow(true);
  };
  

    
  useEffect(() => {
    fetchData();
}, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllBanner"
      });
     
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

   
        try {
            const response_centre = await axios.post(BASE_URL+'API/api_index.php', {
              security_key: security_key,
              mode: 'getAllCenterGroupByCentre'
            });
            
            if(response_centre.data.success===true){
                const formattedOptions = response_centre.data.data.map(option => ({
                    value: option.pk_cent_key,
                    label: option.cent_name
                  }));
                 
                  setOptions(formattedOptions);
            }else{
              //alert message provide
              setLoading(false);
            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    
  };

 

  // Sample columns configuration
  const columns = [

    {
      headerName: 'Banner Name',
      field: "ban_name",
      headerClassName: "super-app-theme--header",
      flex: 1
    },
    {
      headerName: 'Banner Type',
      field: "Banner Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ban_type=='1' ? 'All countries' : params.row.ban_type=='2' ? 'Countries' : params.row.ban_type=='3' ? 'Centre' : '-'}`;
      }
      
    },
    {
      headerName: 'Centre Name',
      field: "Centre Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.cent_name == null ||  params.row.cent_name == '' ? '-' : params.row.cent_name}`;
      }
      
    },{
      headerName: 'Countries',
      field: "Countries",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.country_name == null ||  params.row.country_name == '' ? '-' : params.row.country_name}`;
      }
      },{
        headerName: 'Banner Link',
        field: "Banner Link",
        headerClassName: "super-app-theme--header",
        flex: 1,
        valueGetter: (params) => {
          return `${params.row.ban_link == null ||  params.row.ban_link == '' ? '-' : params.row.ban_link}`;
        },
        renderCell: (params) => {
          const row = params.row;
          if (row.ban_link != null && row.ban_link != '') { // Replace this condition with your actual condition
            return (
             // <img src={BASE_URL + 'API/upload/image/'+row.f_image} alt='image' width="50" height="50" />
             <NavLink underline="hover" color="inherit"  target="_blank" to={row.ban_link} class="my-2" >{row.ban_link}</NavLink>
            );
          }
          else {
            return (
             <p>-</p>
            );
          }
        }
       
      },
      
    {
      headerName: 'Banner',
      field: "Banner",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ban_image == null ||  params.row.ban_image == '' ? '-' : params.row.ban_image}`;
      },
      renderCell: (params) => {
        const row = params.row;

        if (row.ban_image != null && row.ban_image != '') { // Replace this condition with your actual condition
          return (
            <img src={row.ban_image} alt='image' width="50" height="50" />
          );
        }
        else {
          return (
           <p>-</p>
          );
        }
      }
    }, {
      headerName: "Action",
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      width: 200,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.ban_visibility)==1? 'Active' : 'Deactive'}`;
      },
      renderCell: (params) => {
            const row = params.row;

        if(parseInt(row.ban_visibility)==1){
          return (
            <div className='d-flex justify-content-center'>
               <span className='cursor-pointer active-btn' onClick={() => handleDeleteClick(row)} >
                <DoneIcon className="icon-blue  btn-icon me-1" />
                Active
               </span>
              {/* <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="primary-blue  btnmr-2 my-2" >Visible</Buttonmui> */}
            </div>
          );
        }else{
          return (
            <div className='d-flex justify-content-center'>
              <span className=' cursor-pointer deactive-btn'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className=" btn-icon me-1" />
                Deactive
              </span>
              {/* <Buttonmui variant="outlined-red" onClick={() => handleVisibleClick(row)}  className="filled-danger" >Not Visible</Buttonmui> */}
            </div>
          );
        }
          
            
          
      }
    },
    //{ name: "id", selector: (row) => row.pk_banner_key, omit: true }
   
  ];



 
 


  const [modalShowAdd, setModalShowAdd] = useState(false);



  const basicSchema = yup.object().shape({

    ban_name: yup.string().required("Banner name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    ban_type: yup.mixed().required("Banner type is required."),  
    ban_image: yup.mixed().required("Banner image is required."),  
    ban_link: yup.string().matches(
      /((https?):\/\/)?(www.)?([a-z0-9-]+\.)+[a-z]{2,}(\/[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct link.'
    ).required("Banner link is required."),
     


  //   ban_image: yup.mixed().test('at-least-one', 'Banner image or Banner link is required..', function (value) {
  //     const { ban_link } = this.parent;
  //     if (!value && !ban_link) {
  //         return this.createError({
  //             path: 'ban_image',
  //             message: 'Banner image or Banner link is required..',
  //         });
  //     }
  //     return true;
  // }),

  // ban_link: yup.mixed().test('at-least-one', 'Banner image or Banner link is required..', function (value) {
  //     const { ban_image } = this.parent;
  //     if (!value && !ban_image) {
  //         return this.createError({
  //             path: 'ban_link',
  //             message: 'Banner image or Banner link  is required..',
  //         });
  //     }
  //     return true;
  // }),

    country_id: yup.array().when('ban_type', {
      is: (banType) => parseInt(banType) === 2,
      then: (schema) => schema.test({
        test: (value) => value.length > 0, // Check if the array is not empty
        message: 'Countries is required.', // Display this message if the array is not empty
      }),
    }),
    
    fk_cent_key: yup.array().when('ban_type', {
      is: (banType) => parseInt(banType) === 3,
      then: (schema) => schema.test({
        test: (value) => value.length > 0, // Check if the array is not empty
        message: 'Centres is required.', // Display this message if the array is not empty
      }),
    }),

  });

  const initialValues = {
    /*tbl_year_level*/

    ban_name: '',
    ban_image: '',
    ban_link: '',
    ban_type: '',
    country_id: [],
    fk_cent_key: [],

    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "CreateBanner",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData();
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //handleClose();
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create banner. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create banner. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        toast.error("Failure! Unable to create banner. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;
  const fileInputRef = useRef(null);
  
  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };


  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteBanner",
      pk_banner_key: data_delete.pk_banner_key
    }).then((responseuserdelete) => {

        if(responseuserdelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! Banner has been deactivated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to delete banner. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete banner. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }


  
  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "VisibleBanner",
      pk_banner_key: data_visible.pk_banner_key
    }).then((responseVisible) => {

        if(responseVisible.data.data == 1){

          setVisibleShow(false)
            // Handle the successful response here
            toast.success("Success! Banner has been activated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

           setIsVisibleSubmitting(false);

        }else{
            toast.error("Failure! Unable to change banner status. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsVisibleSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to change banner status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }

  return (
    <>
       <ToastContainer position="top-center" />

      
      <Modal show={showDelete} variant="danger">

<Modal.Header>Banner</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to deactivate this banner? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

  <Modal show={showVisible} variant="danger">

<Modal.Header>Banner</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to activate this banner? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Banner </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Banner Name</h6>
                        <Form.Control
                            type="text"
                            value={values.ban_name}
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            id="ban_name"
                            className={errors.ban_name && touched.ban_name ? "input-error" : ""}
                        />
                        {errors.ban_name && touched.ban_name && <p className="error">{errors.ban_name}</p>}
                    </Col>
                     {/* --------------Student phone--------------- */}
                     <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Banner Image(png, jpeg, jpg, jfif)</h6>
                        {/* {!imageUrl && ( */}
                        <Form.Control type="file"
                            name="ban_image"
                            id="ban_image"
                            accept=".png, .jpeg, .jpg, .jfif"
                            onChange={(event) => {
                                setTouched({ ...touched, ban_image: true });
                                if (event.target.files.length > 0) {
                                    const fileName = event.target.files[0];
                                    async function handleFileUpload(fileName) {
                                        try {
                                            // Create a FormData object to send the form data, including the image file
                                            const formData = new FormData();
                                            formData.append("mode", "file_upload_banner_image");
                                            formData.append("image", fileName); // Append the image file

                                            // Make an API request here with the FormData object
                                            const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                                            // Handle the API response here
                                            if (response_file.data.success === true) {
                                               
                                                handleChange({ target: { name: "ban_image", value: response_file.data.data } });
                                            } else {
                                                handleChange({ target: { name: "ban_image", value: null } }); // Clear the input value
                                                fileInputRef.current.value = null;
                                              }

                                            // You can also perform further actions based on the API response
                                            // For example, show a success message or redirect to another page.
                                        } catch (error) {
                                           
                                            handleChange({ target: { name: "ban_image", value: null } }); // Clear the input value
                                            fileInputRef.current.value = null;
                                          }
                                    }

                                    // Call the async function when needed, passing the fileName as an argument
                                    handleFileUpload(fileName);


                                } else {
                                    // handleChange(event);
                                   
                                    handleChange({ target: { name: "ban_image", value: null } }); // Clear the input value
                                    fileInputRef.current.value = null;
                                  }
                            }}
                            ref={fileInputRef}
                            className={errors.ban_image && touched.ban_image ? "input-error" : ""}
                        />

                        {/* // )}
                        // {imageUrl && (
                        //     <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                        //         <img src={imageUrl} alt='image' width="50" height="50" />
                        //         <div className="text-end">
                        //             <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                        //         </div>
                        //     </Col>
                        // )} */}
                        {errors.ban_image && touched.ban_image && <p className="error">{errors.ban_image}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Banner Link</h6>
                        <Form.Control type="text"
                            name="ban_link"
                            id="ban_link"
                            //readOnly
                            value={values.ban_link}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.ban_link && touched.ban_link ? "input-error mb-0" : "mb-0"}

                        />
                        {errors.ban_link && touched.ban_link && <span className="error">{errors.ban_link}</span>}

                    </Col>

                    <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Banner Type</h6>
                 <Select menuPlacement="auto" 
                    options={options_banner_type}
                    name="ban_type"
                    value={values.ban_type['value']}
                    id="ban_type"
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "ban_type",
                                value: selectedOption["value"],
                            },
                        };

                        if (selectedOption["value"] === 1) {
                            const allCountries = options_countries.map(country => country.value);
                            const countryEvent = {
                                target: {
                                    name: "country_id",
                                    value: allCountries,
                                },
                            };
                            handleChange(countryEvent);
                        }
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, ban_type: true });
                    }}
                    className={errors.ban_type && touched.ban_type ? "input-error" : ""}
                />
                {errors.ban_type && touched.ban_type && <span className="error">{errors.ban_type}</span>}
                </Col>
                {parseInt(values.ban_type) === 2 && (
                  <> <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Countries</h6>
                 <Select menuPlacement="auto" 
                    options={options_countries}
                    name="country_id"
                    value={values.country_id}
                    id="country_id"
                    isMulti
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "country_id",
                                value: selectedOption,
                            },
                        };
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, country_id: true });
                    }}
                    className={errors.country_id && touched.country_id ? "input-error" : ""}
                />
                {errors.country_id && touched.country_id && <span className="error">{errors.country_id}</span>}
                </Col>
                </> )}
                {parseInt(values.ban_type) === 3 && (
                    <>
                <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Centres</h6>
                 <Select menuPlacement="auto" 
                    options={option_centre}
                    name="fk_cent_key"
                    value={values.fk_cent_key}
                    id="fk_cent_key"
                    isMulti
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "fk_cent_key",
                                value: selectedOption,
                            },
                        };
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, fk_cent_key: true });
                    }}
                    className={errors.fk_cent_key && touched.fk_cent_key ? "input-error" : ""}
                />
                {errors.fk_cent_key && touched.fk_cent_key && <span className="error">{errors.fk_cent_key}</span>}
                </Col>
                </> )}
                


               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>




        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Banners</h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                <Typography color="text.primary">Banners</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

                <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                <AddIcon className="btn-icon me-2"/> 
                Add Banner
                </Button>
                {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
            
            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap  justify-content-end">
                    
                    <div className='d-flex gap-2 '>
                     
                        {/* <div class="form-group has-search my-auto mb-2">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                        </div> */}
                     

                      
                    </div>

                  </div>
                  
                </Col>

                <Col sm={12}>


                        <Box
                          sx={{
                            height: "fit-content",
                            width: "100%",
                            "& .actions": {
                                color: "text.secondary",
                            },
                            "& .textPrimary": {
                                color: "text.primary",
                            },
                            "& .super-app-theme--header": {
                                backgroundColor: "#f4f4f4",
                            },
                        }}
                        style={{ height: '100%', width: "100%" }}
                    >
                        <DataGrid
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                          }
                        }}
                            //sx={{ border: "none", m: 2 }}
                            autoHeight
                            autoWidth
                            loading={loading}
                            slots={{
                                loadingOverlay: LinearProgress,
                                //noRowsOverlay: CustomNoRowsOverlay,
                                toolbar: GridToolbar,
                                
                                // toolbar: EditToolbar,
                                
                            }}
                            slotProps={{
                                toolbar: {
                                    csvOptions: { fileName: "BannerList" },
                                    printOptions: { disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "BannerList", pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }` },
                                    records,
                                    showQuickFilter: true

                                },
                            }}
                          //   initialState={{
                          //     filter: {
                          //         filterModel: {
                          //             items: [ ...yourCurrentFilterItems ],
                          //             quickFilterValues: ['value 1', 'value 2'],
                          //         },
                          //     },
                          // }}
                          
                            initialState={{
                              ...records.initialState,
                              pagination: { paginationModel: { pageSize: 10 } },
                              
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                            columns={columns}
                            rowHeight={60} density="compact"
                            //onRowClick={(params) => handleRowClick(params.row)}
                            disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                        />
                    </Box>
        
                </Col>
              </Row>
            

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default Banner;
