/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';

import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import { Button as Buttonmui } from '@mui/material';

import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, BASE_URL} from "../global";
  
  import ReactQuill from 'react-quill';
  import 'react-quill/dist/quill.snow.css'; 
  import Box from "@mui/material/Box";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import LinearProgress from "@mui/material/LinearProgress";
  
const ListContent = () => {



  const [records, setRecords] = useState([]);

  
  const [data, setItems] = useState([]);
  
  
  const [loading, setLoading] = useState(true);



  //Api call start


  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);

const [data_edit, setEditLabelData] = useState([]);
const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
const handleEditClick = (row) => {
 // console.log(row);

  const updatedEditData = { ...row};
  setEditLabelData(updatedEditData);
  setModalShowEdit(true);
};


 const [showVisible, setVisibleShow] = useState(false);
 const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
 
 
    
  useEffect(() => {
    fetchData();
}, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllContent"
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

   
    
  };

 

  // Sample columns configuration
  const columns = [

    {
      headerName: 'Content Title',
      field: "Content Title",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.content_title == null ||  params.row.content_title == '' ? '-' : params.row.content_title}`;
      }
    },
    {
      headerName: 'Content Subject',
      field: "Content Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.content_subject == null ||  params.row.content_subject == '' ? '-' : params.row.content_subject}`;
      }
    },
    // {
    //   name: 'Content Details',
    //   selector: row =>row.content_details == null ||  row.content_details == '' ? '-' : row.content_details,
    //   sortable: true
    // },
  //   {
  //     name: 'Content Details',cell: (row) => {
  //     if(row.content_details!=''){
  //       return (
  //         <div className='d-flex justify-content-center'>
  //          <div dangerouslySetInnerHTML={{ __html: row.content_details }} />
  //           {/* <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="primary-blue  btnmr-2 my-2" >Visible</Buttonmui> */}
  //         </div>
  //       );
  //     }else{
  //       return (
  //         <div className='d-flex justify-content-center'>
  //          -
  //           {/* <Buttonmui variant="outlined-red" onClick={() => handleVisibleClick(row)}  className="filled-danger" >Not Visible</Buttonmui> */}
  //         </div>
  //       );
  //     }
        
        
  //   }
  // },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      width: 200,
    //   disableExport: false,
    // disableColumnFilter: false,
    // disableColumnMenu: false,
    // filterable: true,
    // sortable: true,
    headerAlign:"left",
    valueGetter: (params) => {
      return `${parseInt(params.row.visibility)==1? 'Active' : 'Deactive'}`;
    },
      renderCell: (params) => {
            const row = params.row;
        if(parseInt(row.visibility)==1){
          return (
            <div className='d-flex justify-content-center'> <Buttonmui variant="outlined-red"  onClick={() => handleEditClick(row)}  className="primary-blue  btnmr-2 my-auto" >
            <VisibilityIcon className="btn-icon me-1"/> View/Edit Content
           </Buttonmui>
              {/* <span className='active-btn' onClick={() => handleDeleteClick(row)} >
               <DoneIcon className="icon-blue cursor-pointer btn-icon me-1" />
               Active
               </span> */}
               
            </div>
          );
        }else{
          return (
            <div className='d-flex justify-content-center'> <Buttonmui variant="outlined-red"  onClick={() => handleEditClick(row)}  className="primary-blue  btnmr-2 my-auto" >
            <VisibilityIcon className="btn-icon me-1"/> View/Edit Content
           </Buttonmui>
              {/* <span className='deactive-btn'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className="cursor-pointer btn-icon me-1" />
                Deactive
              </span> */}
             
            </div>
          );
        }
          
            // return (
            //   <div className='d-flex flex-wrap pad-12'>
            //     <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="filled-danger my-2" >Delete </Buttonmui>
            //   </div>
            // );
          
      }
    },
   // { name: "id", selector: (row) => row.pk_content_key, omit: true }
   
  ];


  const [modalShowAdd, setModalShowAdd] = useState(false);



  const basicSchema = yup.object().shape({

    content_title: yup.string().required("Content Title is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    content_subject: yup.string().required("Content Subject is required.").min(2, "Minimum 2 characters is required.").max(150, "Maximum 150 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),  
    content_details: yup.string().required("Content Details is required."),  
    //f_link: yup.mixed().required("Form link is required."),  


  //   ban_image: yup.mixed().test('at-least-one', 'Form image or Banner link is required..', function (value) {
  //     const { ban_link } = this.parent;
  //     if (!value && !ban_link) {
  //         return this.createError({
  //             path: 'ban_image',
  //             message: 'Banner image or Banner link is required..',
  //         });
  //     }
  //     return true;
  // }),

  // ban_link: yup.mixed().test('at-least-one', 'Banner image or Banner link is required..', function (value) {
  //     const { ban_image } = this.parent;
  //     if (!value && !ban_image) {
  //         return this.createError({
  //             path: 'ban_link',
  //             message: 'Banner image or Banner link  is required..',
  //         });
  //     }
  //     return true;
  // }),

   

  });

  const initialValues = {
    /*tbl_year_level*/

    content_title: '',
    content_subject: '',
    //f_link: '',
    content_details: '',

    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "CreateContent",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData();
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //handleClose();
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create content. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create content. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create content. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;


  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };


  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteContent",
      pk_content_key: ''
    }).then((responseuserdelete) => {

        if(responseuserdelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! Content has been deactivated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to delete content. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete content. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }



  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "VisibleContent",
      pk_content_key: ''
    }).then((responseVisible) => {

        if(responseVisible.data.data == 1){

          setVisibleShow(false)
            // Handle the successful response here
            toast.success("Success! Content has been activated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

           setIsVisibleSubmitting(false);

        }else{
            toast.error("Failure! Unable to change content status. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsVisibleSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to change content status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }

  
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const validationSchema1 = yup.object().shape({
    e_content_title: yup.string().required("Content Title is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    e_content_subject: yup.string().required("Content Subject is required.").min(2, "Minimum 2 characters is required.").max(150, "Maximum 150 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),  
  //  e_content_details: yup.string().required("Content Details is required.").min(2, "Minimum 2 characters is required."), 


  });


  const initialValues1 = {
       pk_content_key: data_edit ? data_edit.pk_content_key!=null && data_edit.pk_content_key!='' ? data_edit.pk_content_key : '' : '',
        
        e_content_title: data_edit ? data_edit.content_title!=null && data_edit.content_title!='' ? data_edit.content_title : '' : '',
        e_content_subject: data_edit ? data_edit.content_subject!=null && data_edit.content_subject!='' ? data_edit.content_subject : '' : '',
        //f_link: '',
        e_content_details: data_edit ? data_edit.content_details!=null && data_edit.content_details!='' ? data_edit.content_details : '' : '',

        updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingEdit(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "EditContent",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
        
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            fetchData();
            //resetForm();
            setModalShowEdit(false);


            setIsSubmittingEdit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update content. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingEdit(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to update content. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingEdit(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update content. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingEdit(false); // Re-enable the submit button

      } finally {
        setIsSubmittingEdit(false); // Re-enable the submit button

      }

    },
  });




  return (
    <>
       <ToastContainer position="top-center" />

      
      <Modal show={showDelete} variant="danger">

<Modal.Header>Content</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to deactivate this Content? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>


  <Modal show={showVisible} variant="danger">

<Modal.Header>Content</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to activate this Content? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> 
                
                Add Content </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Content Title</h6>
                        <Form.Control
                            type="text"
                            value={values.content_title}
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            id="content_title"
                            className={errors.content_title && touched.content_title ? "input-error" : ""}
                        />
                        {errors.content_title && touched.content_title && <p className="error">{errors.content_title}</p>}
                    </Col>
                     {/* --------------Student phone--------------- */}
                     <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Content Subject</h6>
                        <Form.Control
                            type="text"
                            value={values.content_subject}
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            id="content_subject"
                            className={errors.content_subject && touched.content_subject ? "input-error" : ""}
                        />
                        {errors.content_subject && touched.content_subject && <p className="error">{errors.content_subject}</p>}
                    </Col>

                    <Col lg="12" md="12" sm="12" className='position-relative'>
                        <h6 className="form-lable">Content Details</h6>
                        <ReactQuill
                        
                         modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['link', 'image'],
                            ['clean'],
                          ],
                        }}
                        formats={[
                          'header',
                          'bold', 'italic', 'underline', 'strike', 'blockquote',
                          'list', 'bullet',
                          'link', 'image',
                        ]}
                        value={formik.values.content_details}
                        onChange={(value) => formik.setFieldValue('content_details', value)}
                       // onBlur={formik.handleBlur('content_details')}
                         
                          />
                        {/* <Form.Control as="textarea" rows={3}
                            value={values.content_details}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="content_details"
                            className={errors.content_details && touched.content_details ? "input-error control_textarea" : "control_textarea mb-0"}
                        /> */} 
                        {/* {errors.content_details && touched.content_details && <p className="error">{errors.content_details}</p>} */}
                    </Col>
                   
               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>

        <Modal
          show={modalShowEdit} onHide={() => setModalShowEdit(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={formik1.handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> 
                
                Edit Content </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Content Title</h6>
                        <Form.Control
                            type="text"
                            value={formik1.values.e_content_title}
                            onChange={formik1.handleChange}
                            
                            onBlur={formik1.handleBlur}
                            id="e_content_title"
                            className={formik1.errors.e_content_title && formik1.touched.e_content_title ? "input-error" : ""}
                        />
                        {formik1.errors.e_content_title && formik1.touched.e_content_title && <p className="error">{formik1.errors.e_content_title}</p>}
                    </Col>
                     {/* --------------Student phone--------------- */}
                     <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Content Subject</h6>
                        <Form.Control
                            type="text"
                            value={formik1.values.e_content_subject}
                            onChange={formik1.handleChange}
                            
                            onBlur={formik1.handleBlur}
                            id="e_content_subject"
                            className={formik1.errors.e_content_subject && formik1.touched.e_content_subject ? "input-error" : ""}
                        />
                        {formik1.errors.e_content_subject && formik1.touched.e_content_subject && <p className="error">{formik1.errors.e_content_subject}</p>}
                    </Col>
                    
                       
                      
                    <Col lg="12" md="12" sm="12" className='position-relative'>
                        <h6 className="form-lable">Content Details</h6>
                        <ReactQuill
                        
                        modules={{
                         toolbar: [
                           [{ header: [1, 2, false] }],
                           ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                           [{ list: 'ordered' }, { list: 'bullet' }],
                           ['link', 'image'],
                           ['clean'],
                         ],
                       }}
                       formats={[
                         'header',
                         'bold', 'italic', 'underline', 'strike', 'blockquote',
                         'list', 'bullet',
                         'link', 'image',
                       ]}
                       value={formik1.values.e_content_details}
                       onChange={(value) => formik1.setFieldValue('e_content_details', value)}
                      // onBlur={formik.handleBlur('content_details')}
                        
                         />
                        {/* <Form.Control as="textarea" rows={3}
                            value={formik1.values.e_content_details}
                            onChange={formik1.handleChange}
                            onBlur={formik1.handleBlur}
                            id="e_content_details"
                            className={formik1.errors.e_content_details && formik1.touched.e_content_details ? "input-error control_textarea" : "control_textarea mb-0"}
                        /> */}
                        {formik1.errors.e_content_details && formik1.touched.e_content_details && <p className="error">{formik1.errors.e_content_details}</p>}
                    </Col>
                   
               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowEdit(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingEdit}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>


        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Content Management System</h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                <Typography color="text.primary">Content Management System</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

                <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                  <AddIcon className="btn-icon me-2"/> 
                Add Content
                </Button>
                {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
            
            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap  justify-content-end">
                    
                    <div className='d-flex gap-2 '>
                     
                        {/* <div class="form-group has-search my-auto mb-2">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                        </div> */}
                     

                      
                    </div>

                  </div>
                  
                </Col>

                <Col sm={12}>

                <Box
                                      sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#f4f4f4",
                                        },
                                    }}
                                    style={{ height: '100%', width: "100%" }}
                                >
                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        loading={loading}
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: { fileName: "CMSList" },
                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CMSList" },
                                                records,
                                                showQuickFilter: true
                                            },
                                        }}
                                        initialState={{
                                          ...records.initialState,
                                          pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        //onRowClick={(params) => handleRowClick(params.row)}
                                        disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                                    />
                                </Box>
                   
                </Col>
              </Row>
            

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default ListContent;
