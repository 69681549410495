import React, { useState, useCallback, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate } from 'react-router-dom';



function AdminReport() {
    const navigate = useNavigate();

    const location = useLocation();
    const [activeKey, setActiveKey] = useState('first');

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey);
        // You can perform additional actions when a Nav.Link is selected
      };
    

        useEffect(() => {

            const previousPageAdminPathname = sessionStorage.getItem('previousPageAdminPathname');

            if (previousPageAdminPathname) {
                //console.log(previousPagePathname || 'No previous page information available');
            
                if (['/admin/AdminFinanceNetworkRevenue','/admin/AdminFinanceNettRevenue','/admin/AdminFinanceRefunds','/admin/AdminFinanceRegistrationFees','/admin/AdminFinanceDeposits'].includes(previousPageAdminPathname)) {
                   // console.log('Student');
                    setActiveKey('second');
                    sessionStorage.setItem('previousPageAdminPathname', location.pathname);
                
                }else if (['/admin/AdminPGCommission','/admin/AdminPaymentFlows','/admin/AdminFailedPayments','/admin/AdminDisputes'].includes(previousPageAdminPathname)) {
                    // console.log('Student');
                     setActiveKey('third');
                     sessionStorage.setItem('previousPageAdminPathname', location.pathname);
                 
                 }else{
                   // console.log('Other');
                    setActiveKey('first');
                }

            } else {
                    console.log('No referrer information available.');
            }

        }, [location.pathname]);


    const changeContainer = useCallback((fileName) => {
        navigate(fileName);
    }, [navigate]);


    return (
        <Container  fluid className='mx-0 px-0'>

            {/* --------------Page Start--------------- */}

            {/* --------------Tabs Section-1 Start--------------- */}
            <Row>
                <Col xs="12">
                    <div className="mx-auto">

                        <Tab.Container defaultActiveKey="first"   activeKey={activeKey} onSelect={handleSelect}>

                            <Row className="justify-content-center">

                                {/* --------------Toggle Selector Start--------------- */}
                                <Col sm={12}  className='border-bottom mb-2'>
                                    <Nav variant="pills" className="text-darks gap-3 " >
                                        <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="first" className="d-flex rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-people-roof me-2 my-auto"></i>
                                                <p className="mb-0">Network Reports</p>
                                            </Nav.Link>
                                        </Nav.Item>
                                       <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="second" className="rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-file-invoice-dollar me-2 my-auto"></i>
                                                Finance Reports
                                            </Nav.Link>
                                        </Nav.Item>
                                         <Nav.Item className="report-nav-container">
                                            <Nav.Link eventKey="third" className="rounded-2 px-auto f-18">
                                                <i class="fa-solid fa-graduation-cap me-2 my-auto"></i>
                                                Payment Gateway Reports
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                {/* --------------Toggle Selector End--------------- */}

                                {/* --------------Toggle Content Start--------------- */}
                                <Col sm={12}>
                                    <Tab.Content>

                                        {/* --------------Toggle One Start--------------- */}
                                        <Tab.Pane eventKey="first">
                                            <Row>
                                     
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportEnrolmentStatistics")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Enrolment Statistics
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportInitialAssessments")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Initial Assessments
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportUnenrollment")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Unenrolment
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportStudentAppUsage")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Student App Usage
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportParentAppUsage")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Parent App Usage
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminReportStudentSession")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Student/Session
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            
                                        </Tab.Pane>
                                        {/* --------------Toggle One End--------------- */}
                                        {/* --------------Toggle Two Start--------------- */}
                                        <Tab.Pane eventKey="second">
                                            <Row>
                                     
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFinanceNetworkRevenue")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Network Revenue
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFinanceNettRevenue")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Nett Revenue
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFinanceRefunds")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Refunds
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFinanceDeposits")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Deposits
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFinanceRegistrationFees")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Registration Fees
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                               
                                            </Row>

                                            
                                        </Tab.Pane>
                                        {/* --------------Toggle Two End--------------- */}

                                        {/* --------------Toggle Three Start--------------- */}
                                        <Tab.Pane eventKey="third">
                                            <Row>
                                     
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminPGCommission")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        PG Commission
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminPaymentFlows")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Payment Flows
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminFailedPayments")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Failed Payments
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={12} lg={4} className="mt-3 cursor-pointer" onClick={() => changeContainer("/admin/AdminDisputes")}>
                                                    <div className="card my-card reports-list">
                                                        <p className="d-flex justify-content-between f-16 mb-0 p-3 align-items-center">
                                                        Disputes
                                                            <span><i class="fa-solid fa-chevron-right my-auto"></i></span>
                                                        </p>
                                                    </div>
                                                </Col>
                                               
                                            </Row>

                                            
                                        </Tab.Pane>
                                        
                                        {/* --------------Toggle Three End--------------- */}

                                    </Tab.Content>
                                </Col>
                                {/* --------------Toggle Selector End--------------- */}

                            </Row>
                        </Tab.Container>

                    </div>
                </Col>
            </Row>
            {/* --------------Tabs Section-1 End--------------- */}




            {/* --------------Page End--------------- */}

        </Container>
    );
}

export default AdminReport;