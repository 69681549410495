
/*
Purpose: add subject details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from 'react-bootstrap/Stack';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Row, Col, Dropdown, DropdownButton, Card, Form, Modal, Button, ButtonGroup } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL } from "../global";


function EditSubject() {
    const basicSchema = yup.object().shape({
        subject_name : yup.string().required("Subject name is required.").min(2, "Minimum 2 characters is required.").max(50, "Maximum 50 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        sub_abbreviation : yup.string().required("Subject abbreviation is required.").max(50, "Maximum 50 characters is required.").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        sub_details : yup.string().required("Description is required.").min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),     
    });
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [SubjectData, setSubjectData] = useState({});

    const [showForfeit, setForfeitShow] = useState(false);

      //Get key value start
      const location = useLocation();
      useEffect(() => {
  
          const { subject_id } = location.state || {};
          const extractedSubId = subject_id;
          if (extractedSubId !=='' && extractedSubId !== undefined) {
          //setId(extractedId);
          fetchData(extractedSubId);
          }else{
            navigate("/Subject");
          }
      }, [location]);
      //Get key value end
  
      //Api call start
  
      const fetchData = async (id) => {
          try {
              const response = await axios.post(BASE_URL+'API/api_index.php', {
                  security_key: security_key,
                  mode: "getSingleSubject",
                  subject_id: id
              });
  
              if (response.data.success === true) {
                  if(response.data.data.length > 0){
                    setSubjectData(response.data.data[0]);
                  
                  }
              
             
              } else {
                  // Handle unsuccessful response, e.g., show an alert to the user.
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

    const calculateAbbreviation = (subjectName) => {
        // Simple logic to create an abbreviation (e.g., first letter of each word)
        const words = subjectName.split(' ');
        const abbreviation = words.map((word) => word.charAt(0).toUpperCase()).join('');
       
        return abbreviation;
      };

    const initialValues = {
        /*subjects*/
        subject_id : SubjectData ? SubjectData.subject_id : '',
        subject_name : SubjectData ? SubjectData.subject_name : '',
        sub_abbreviation : SubjectData ? SubjectData.sub_abbreviation : '',
        sub_details : SubjectData ? SubjectData.sub_details : '',

        fk_cent_key: 0,
        updated_by: sessionStorage.getItem('key'),
        visibility: 1
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        //  onSubmit,
        onSubmit: async (formValues) => {
            // setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "updateSubject",
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                    
                    if (response.data.data === 1) {
                        toast.success("Success! Your subject was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Subject");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 2) {
                        toast.error("Sorry! Your subject abbreviation already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 3) {
                        toast.error("Sorry! Your subject name already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to update subject. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update subject. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update subject. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };

    
    function onSubmitForfeit() {

        const responseforfeit = axios.post(BASE_URL+"API/api_index.php", {
    
          security_key: security_key,
          subject_id:SubjectData.subject_id,
          mode: "deleteSubject",
         
        }).then((responseforfeit) => {
          setForfeitShow(false)
          // Handle the successful response here
          toast.success("Success! Subject has been deleted.", {
            autoClose: 3000, // 3 seconds in this example
          });
           // After 3 seconds, redirect to another page
           setTimeout(function () {
            navigate("/Subject");
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
    
        })
          .catch((error) => {
            // Handle any errors that occurred during the request
            toast.error("Failure! Unable to delete subject. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          });
    
    
    
    
    
    
      }

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Modal show={showForfeit} variant="danger">

          <Modal.Header>Are you sure?</Modal.Header>

          <Modal.Body>
            <p>You want to <b className="bg-warning">delete</b>? </p>

            <Card className='rounded-1 mt-2 card-bdr' >
              <Card.Body>

                <div>
                  <div className="d-flex justify-content-between align-self-center">
                    <div className="me-5">
                      <div className="">

                        
                        <h6>Subject : {SubjectData.subject_name}</h6>
                        {/* <p className="mb-0">
                        
      
                        </p> */}
                        <h6>Abbreviation : {SubjectData.sub_abbreviation}</h6>
                        {/* <p className="mb-0">

                          
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <span>

                  </span>
                </div>

              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer>

            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setForfeitShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " onClick={() => onSubmitForfeit()} >Yes</Button>
            </div>

          </Modal.Footer>

        </Modal>

            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>Edit Subject</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Subject</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div className='d-flex gap-2'>
                            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                            <div className='d-flex justify-content-end gap-2'>
                            <div >
                                <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                    <Dropdown.Item onClick={() =>  setForfeitShow(true)}>  Delete
                                    </Dropdown.Item>
                                   
                                    
                                </DropdownButton>
                            </div>
                        </div>
                        </div>

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Subject </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Subject Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.subject_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const abbreviation = calculateAbbreviation(e.target.value);
                                            handleChange({ target: { name: 'sub_abbreviation', value: abbreviation } });
                                          }}
                                        onBlur={handleBlur}
                                        id="subject_name"
                                        className={errors.subject_name && touched.subject_name ? "input-error" : ""}
                                    />
                                    {errors.subject_name && touched.subject_name && <p className="error">{errors.subject_name}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Subject Abbreviation</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.sub_abbreviation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sub_abbreviation"
                                        className={errors.sub_abbreviation && touched.sub_abbreviation ? "input-error" : ""}
                                    />
                                    {errors.sub_abbreviation && touched.sub_abbreviation && <p className="error">{errors.sub_abbreviation}</p>}
                                </Col>
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Description</h6>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        
                                        value={values.sub_details}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sub_details"
                                        className={errors.sub_details && touched.sub_details ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sub_details && touched.sub_details && <p className="error">{errors.sub_details}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Subject')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default EditSubject;