/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';

import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Button as Buttonmui } from '@mui/material';

import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, BASE_URL} from "../global";
  import DoneIcon from '@mui/icons-material/Done';

  import CloseIcon from '@mui/icons-material/Close';
  import 'react-quill/dist/quill.snow.css'; 
  import Box from "@mui/material/Box";
  import { DataGrid, GridToolbar} from "@mui/x-data-grid";
  import LinearProgress from "@mui/material/LinearProgress";
  
const ListSM = () => {



  const [records, setRecords] = useState([]);

  
  const [data, setItems] = useState([]);
  
  
  const [loading, setLoading] = useState(true);

  //Api call start
  const formRef = useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
const [data_delete, setDeleteLabelData] = useState([]);
const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);

const [data_edit, setEditLabelData] = useState([]);
const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
const handleEditClick = (row) => {


  const updatedEditData = { ...row};
  setEditLabelData(updatedEditData);
  setModalShowEdit(true);
};


const handleDeleteClick = (row) => {
   
    const updatedData = { ...row};
    setDeleteLabelData(updatedData);
 
    setDeleteShow(true);
 };


 const [showVisible, setVisibleShow] = useState(false);
 const [data_visible, setVisibleLabelData] = useState([]);
 const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
 
 
 const handleVisibleClick = (row) => {
    
     const updatedData = { ...row};
     setVisibleLabelData(updatedData);
  
     setVisibleShow(true);
  };

    
  useEffect(() => {
    fetchData();
}, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllMaintenance"
      });
    
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    
  };

 

  // Sample columns configuration
  const columns = [

    {
      headerName: 'App Name',
      field: "App Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      valueGetter: (params) => {
        return `${
          params.row.sm_tcms == null ||  params.row.sm_tcms == '' ? '' : parseInt(params.row.sm_tcms)==1 ? 'TCMS App': ''
        }${
          params.row.sm_tutor == null ||  params.row.sm_tutor == '' ? '' : parseInt(params.row.sm_tutor)==1 ? 'Tutor App' : ''
        }${
          params.row.sm_student == null ||  params.row.sm_student == '' ? '' : parseInt(params.row.sm_student)==1 ? 'Student App' : ''
        }${
          params.row.sm_parent == null ||  params.row.sm_parent == '' ? '' : parseInt(params.row.sm_parent)==1 ? 'Parent App' : ''
        }${
          params.row.sm_lcms == null ||  params.row.sm_lcms == '' ? '' : parseInt(params.row.sm_lcms)==1 ? 'LCMS App' : ''
        }`;
      }
    },
    {
      headerName: 'Message',
      field: "Message",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sm_narration == null ||  params.row.sm_narration == '' ? '-' : params.row.sm_narration}`;
      }
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      width: 250,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.is_visible)==0? 'Active' : 'Deactive'}`;
      },
      renderCell: (params) => {
            const row = params.row;
        if(parseInt(row.is_visible)==0){
          return (
            <div className='d-flex justify-content-center'> <Buttonmui variant="outlined-red"  onClick={() => handleEditClick(row)}  className="primary-blue  btnmr-2 my-auto" >
            <VisibilityIcon className="btn-icon me-1"/>Edit Message
           </Buttonmui>
              <span className='active-btn' onClick={() => handleDeleteClick(row)} >
               <DoneIcon className="icon-blue cursor-pointer btn-icon me-1" />
               Active
               </span>
               
            </div>
          );
        }else{
          return (
            <div className='d-flex justify-content-center'> <Buttonmui variant="outlined-red"  onClick={() => handleEditClick(row)}  className="primary-blue  btnmr-2 my-auto" >
            <VisibilityIcon className="btn-icon me-1"/>Edit Message
           </Buttonmui>
              <span className='deactive-btn'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className="cursor-pointer btn-icon me-1" />
                Deactive
              </span>
             
            </div>
          );
        }
          
            
          
      }
    },
   // { name: "id", selector: (row) => row.pk_content_key, omit: true }
   
  ];




  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
   
  };


  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteSMMessage",
      pk_sm_id: data_delete.pk_sm_id
    }).then((responseuserdelete) => {

        if(responseuserdelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! Scheduled maintenance has been deactivated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to deactivate Scheduled maintenance. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to deactivate Scheduled maintenance. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }



  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "VisibleSMMessage",
      pk_sm_id: data_visible.pk_sm_id
    }).then((responseVisible) => {

        if(responseVisible.data.data == 1){

          setVisibleShow(false)
            // Handle the successful response here
            toast.success("Success! Scheduled maintenance has been activated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

           setIsVisibleSubmitting(false);

        }else{
            toast.error("Failure! Unable to change scheduled maintenance status. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsVisibleSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to change scheduled maintenance status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }

  
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const validationSchema1 = yup.object().shape({
   // e_content_title: yup.string().required("Content Title is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
   sm_narration: yup.string().required("Message is required.").min(2, "Minimum 2 characters is required.").max(400, "Maximum 400 characters is required.").matches(/^[^,\s].*[^,\s]$/, "Avoid start & end with a space or comma"),
  //  e_content_details: yup.string().required("Content Details is required.").min(2, "Minimum 2 characters is required."), 


  });


  const initialValues1 = {
      pk_sm_id: data_edit ? data_edit.pk_sm_id!=null && data_edit.pk_sm_id!='' ? data_edit.pk_sm_id : '' : '',
        
      sm_narration: data_edit ? data_edit.sm_narration!=null && data_edit.sm_narration!='' ? data_edit.sm_narration : '' : '',

      updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingEdit(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "EditSMMessage",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
         
          if (response.data.data === 1) {
            toast.success("Success! Scheduled maintenance message has been updated ", {
              autoClose: 3000, // 3 seconds in this example
            });

            fetchData();
            
            setModalShowEdit(false);


            setIsSubmittingEdit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update message. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingEdit(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to update message. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingEdit(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update message. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingEdit(false); // Re-enable the submit button

      } finally {
        setIsSubmittingEdit(false); // Re-enable the submit button

      }

    },
  });




  return (
    <>
       <ToastContainer position="top-center" />

      
      <Modal show={showDelete} variant="danger">

<Modal.Header>Scheduled Maintenance</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to deactivate scheduled maintenance? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>


  <Modal show={showVisible} variant="danger">

<Modal.Header>Scheduled Maintenance</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to activate scheduled maintenance? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

      <div className="page-wrapper">

        <Modal
          show={modalShowEdit} onHide={() => setModalShowEdit(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={formik1.handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> 
                
                Edit Scheduled Maintenance Message </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                    
                    <Col lg="12" md="12" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Message</h6>
                        <Form.Control
                            as="textarea" rows={3}
                            
                            value={formik1.values.sm_narration}
                            onChange={formik1.handleChange}
                            onBlur={formik1.handleBlur}
                            id="sm_narration"
                            className={formik1.errors.sm_narration && formik1.touched.sm_narration ? "input-error control_textarea" : "control_textarea"}
                        />
                       {formik1.errors.sm_narration && formik1.touched.sm_narration && <p className="error">{formik1.errors.sm_narration}</p>}
                    </Col>
                      
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowEdit(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingEdit}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>


        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Scheduled Maintenance</h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                <Typography color="text.primary">Scheduled Maintenance</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

                {/* <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                  <AddIcon className="btn-icon me-2"/> 
                Add Content
                </Button> */}
                {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
            
            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap  justify-content-end">
                    
                    <div className='d-flex gap-2 '>
                     
                        {/* <div class="form-group has-search my-auto mb-2">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                        </div> */}
                     

                      
                    </div>

                  </div>
                  
                </Col>

                <Col sm={12}>

                <Box
                                      sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#f4f4f4",
                                        },
                                    }}
                                    style={{ height: '100%', width: "100%" }}
                                >
                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        loading={loading}
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: { fileName: "SMList" },
                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "SMList" },
                                                records,
                                                showQuickFilter: true
                                            },
                                        }}
                                        initialState={{
                                          ...records.initialState,
                                          pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        //onRowClick={(params) => handleRowClick(params.row)}
                                        disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                                    />
                                </Box>
                   
                </Col>
              </Row>
            

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default ListSM;
