/*
Purpose: View details of stripe
Developer: BY KTH
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Modal} from 'react-bootstrap';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, BASE_URL} from "../global";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



function CentreStripeApp() {
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();


    const [showDelete, setDeleteShow] = useState(false);
    const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);
  
  
    const handleDeleteClick = () => {
     
      setDeleteShow(true);
    };


    const [CentreData, setCentreData] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== '') {
           
            fetchData(extractedId);
        }
    }, [location]);
   
    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getCenterStripeDetails",
                pk_cent_key: sessionStorage.getItem('key'),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCentreData(response.data.data[0]);
                   
                }

                
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function onSubmitDelete() {
        setIsUserDeleteSubmitting(true);
    
        const responsedelete = axios.post(BASE_URL + "API/api_index.php", {
    
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem('key'),
          mode: "ResetCenterStripe"
        }).then((responsedelete) => {
    
          if (responsedelete.data.data == 1) {
    
            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! stripe api has been reset.", {
              autoClose: 3000, // 3 seconds in this example
            });
    
            setTimeout(function () {
                window.location.reload(false);
  
              }, 2000);
    
    
          } else {
            toast.error("Failure! Unable to stripe api has been reset. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsUserDeleteSubmitting(false);
          }
    
    
        })
          .catch((error) => {
            // Handle any errors that occurred during the request
            toast.error("Failure! Unable to stripe api has been reset. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsUserDeleteSubmitting(false);
          });
      }



    

   

   const basicSchema = yup.object().shape({
    sa_publishable_key: yup.string().required("This field is required"),
    sa_secret_key: yup.string().required("This field is required")
});
    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched } = useFormik({
        initialValues: {
            /*tbl_certre*/
            sa_publishable_key: CentreData ? CentreData.sa_publishable_key : '',
            sa_secret_key: CentreData ? CentreData.sa_secret_key : '',
            /*Common*/
            fk_cent_key: sessionStorage.getItem('key')

        },
        enableReinitialize: true,
       validationSchema: basicSchema,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            setIsSubmitting(true);
    
                
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "updateCenterStripe",
                    ...formValues,
                });
    
                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if(response.data.data === 1){
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });
    
                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/StripeApi");
                        }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds
    
                        setIsSubmitting(false); // Re-enable the submit button
                    }else{
                        toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                            autoClose: 3000, // 3 seconds in this example
                        });
    
                        setIsSubmitting(false); // Re-enable the submit button
                    }
                    
                } else {
    
                    toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                        autoClose: 3000, // 3 seconds in this example
                    });
    
                    setIsSubmitting(false); // Re-enable the submit button
                    
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                    autoClose: 3000, // 3 seconds in this example
                });
    
                setIsSubmitting(false); // Re-enable the submit button
                
            } finally {
                setIsSubmitting(false); // Re-enable the submit button
                
            }
              
        },
    });



    return (
        <>
             <ToastContainer position="top-center" />
             <Modal show={showDelete} variant="danger">

        <Modal.Header>Stripe</Modal.Header>

        <Modal.Body>
        <div style={{ fontSize: 'smaller' }}>Note: Please do not move one Stripe account to another because of student subscriptions. If you want to move, you will need to restart the student payment with a verified account.  </div>
          <p className="mt-2">Are you sure? You want reset stripe api account? </p>

        </Modal.Body>
        <Modal.Footer className='pt-0'>
          <div className="mx-auto d-flex gap-2" >

            <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
            <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
          </div>

        </Modal.Footer>

      </Modal>
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Stripe API </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Stripe API</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>



                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3 d-flex justify-content-between'>
                                <h6 className='mb-0'>Stripe API Setting {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                             Note: Please do not move one Stripe account to another because of student subscriptions. If you want to move, you will need to restart the student payment with a verified account.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>

                        <div className="text-end">
                        {CentreData && CentreData.count_scription && CentreData.count_scription > 0 ?  <Button type="button" variant="contained" className="primary-filled " onClick={handleDeleteClick} disabled={isUserDeleteSubmitting}>Reset</Button> : null}
                        </div>
                            </div>
                            <Row>

                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Publishable key</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.sa_publishable_key}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sa_publishable_key"
                                        className={errors.sa_publishable_key && touched.sa_publishable_key ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sa_publishable_key && touched.sa_publishable_key && <p className="error">{errors.sa_publishable_key}</p>}
                                    
                                </Col>

                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Secret key</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.sa_secret_key}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sa_secret_key"
                                        className={errors.sa_secret_key && touched.sa_secret_key ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sa_secret_key && touched.sa_secret_key && <p className="error">{errors.sa_secret_key}</p>}
                                </Col>
                                
                                

                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                        {CentreData && CentreData.count_scription && CentreData.count_scription > 0 ? null : <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>}
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default CentreStripeApp;