/*
Designed By R.Sanjai
Created : 24/08/2023
Screen : Log-in
*/

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './login.css';
import 'react-notifications-component/dist/theme.css'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useJwt } from "react-jwt";

import { security_key, BASE_URL } from "../global";


//const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const basicSchema = yup.object().shape({
    ca_login_id: yup.string().required("A username is required.").min(2, "Minimum 2 characters is required.").matches(/^[^\s]+$/, "Not allowed whitespaces."),
    ca_password: yup.string().min(5, "Minimum 5 characters is required.").required("A password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),

});

function Login() {
    const [IsContent, setContent] = useState([]);
    const [IsMaintenance, setMaintenance] = useState([]);
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    
    const navigate = useNavigate();


    const [showTC, setTCShow] = useState(false);


    const [IsContentFilter, setContentFilter] = useState([]);

    const [isAdminCheck, setIsAdminCheck] = useState(null);
    const [token, setToken] = useState(null);

    const location = useLocation();
    useEffect(() => {
        document.title = 'TCMS';
        const checkAdminStatus = async () => {
            const isAdminValue = sessionStorage.getItem('is_admin');
            if (isAdminValue !== null && isAdminValue === '0') {
                navigate("/calendar");
            } else {
                setIsAdminCheck(false); // Set state to indicate that the condition is checked
            }
        };

        fetchData();
        fetchMaintenance();
        checkAdminStatus();
    }, [location]);


    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        timeout: 3000,
        headers: { Authorization: `bearer ${token}` },
      });

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAllContent"
            });
            
            if (response.data.success === true) {
                setContent(response.data.data);
             

            } else {
                //alert message provide
               
                console.error('Error fetching data');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchMaintenance = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "TCMSMaintenance"
            });
            
            if (response.data.success === true) {
                setMaintenance(response.data.data[0]);
               

            } else {
                //alert message provide
               
                console.error('Error fetching data');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_center_auth*/
        ca_login_id: "", //Not in db
        ca_password: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } =
        useFormik({
            // const formik = useFormik({
            initialValues,
            validationSchema: basicSchema,
            // onSubmit,
            onSubmit: async (formValues) => {
                // Disable the submit button and reset alerts
                setIsSubmitting(true);


                try {
                    const response = await axios.post(BASE_URL + "API/api_index.php", {
                        security_key: security_key,
                        mode: 'getSingleCenterAuth_jwt',
                        ...formValues,
                    });

                    if (response.data.success === true) {
                        // Reset the form

                        if (response.data.data.length > 0) {

                            resetFormFields(resetForm, setValues, initialValues);

                            if (response.data.data[0]['pk_cent_key'] !== '') {

                                sessionStorage.setItem('key', response.data.data[0]['pk_cent_key']);
                                sessionStorage.setItem('is_admin', '0');
                                sessionStorage.setItem('center_name', response.data.data[0]['cent_name']);
                                sessionStorage.setItem('first_name', response.data.data[0]['ci_first_name']);
                                sessionStorage.setItem('sur_name', response.data.data[0]['ci_surname']);
                                sessionStorage.setItem('time_zone', response.data.data[0]['cent_timezone']);
                                sessionStorage.setItem('timezone_identifier', response.data.data[0]['cent_timezone_identifier']);
                                sessionStorage.setItem('user_id', response.data.data[0]['pk_ci_id']);
                                //user_type
                                //0- Center super admin and admin.
                                //1- staff
                                sessionStorage.setItem('user_type', response.data.data[0]['user_type']);


                                sessionStorage.setItem('country_alph_code', response.data.data[0]['country_code']);
                                sessionStorage.setItem('country_code', response.data.data[0]['cent_country']);
                                sessionStorage.setItem('currency_code', response.data.data[0]['country_currency']);
                                sessionStorage.setItem('cent_profile', response.data.data[0]['cent_profile']);

                                sessionStorage.setItem('isSuccess', '1');
                                
                                // After 3 seconds, redirect to another page
                                setTimeout(function () {
                                navigate("/calendar");
                                 }, 700); // 3000 milliseconds, which is equivalent to 3 seconds

                            } else {

                                
                                
                                toast.error("The entered username or password is wrong.", {
                                    autoClose: 3000, // 3 seconds in this example
                                });

                                setIsSubmitting(false); // Re-enable the submit button
                            }

                            // alert("Success! Your form was submitted.");
                            setToken(response.data.token);

                        } else {
                            
                            if(response.data.data.loginAccount == 0){
                                toast.error("Account inactive, Please contact admin.", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
    
                            }else{
                                toast.error("The entered username or password is wrong.", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
                            }
                            

                            setIsSubmitting(false); // Re-enable the submit button
                        }

                    } else {

                        
                        toast.error("The entered username or password is wrong.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);

                  
                    toast.error("The entered username or password is wrong.", {
                        autoClose: 3000, // 3 seconds in this example
                    });
                    setIsSubmitting(false); // Re-enable the submit button
                } finally {
                    setIsSubmitting(false); // Re-enable the submit button
                }

            },
        });

        useEffect(() => {
            setTimeout(() => {
              if (token) {
                async function validateToken() {
                  try {

                    const response = await axios.post(BASE_URL + "API/api_index.php", {
                        security_key: security_key,
                        mode: 'checkValidateJWT',
                    });
                   
                    if (response) {

                    
                    }
                  } catch (error) {
                   
                  }
                }
        
                validateToken();
              }
            }, 1000);
          }, [token, axiosInstance]);

    const [passwordVisible, setPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };



 

    return (
        <>{isAdminCheck !== null ? (
            <> 
                <ToastContainer position="top-center" />
                {IsMaintenance? IsMaintenance.sm_narration && IsMaintenance.sm_narration != '' && parseInt(IsMaintenance.is_visible)==0 ? (
                <div className='headerlogin'>
                    <div className='d-flex justify-content-between'>
                   <span><WarningRoundedIcon color="warning"/> Scheduled maintenance</span>
                   <span className='mt-1'>{IsMaintenance? IsMaintenance.sm_narration && IsMaintenance.sm_narration!=null && IsMaintenance.sm_narration!=''  ? IsMaintenance.sm_narration : null : null }</span>
                   </div>
                </div>) : null : null }
                <Modal show={showTC} onHide={() => setTCShow(false)}

                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="xl">
                    <Modal.Header closeButton>{IsContentFilter && IsContentFilter.content_title != '' ? IsContentFilter.content_title : ''}</Modal.Header>
                    <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>

                        {IsContentFilter.content_details !== '' ? (
                            <div>
                                {/* Loop through each element in IsContent using map */}
                                {/* {IsContentFilter.map((item, index) => (
          <div key={index}> */}
                                <h6><strong>{IsContentFilter.content_subject}</strong></h6>
                                {/* <p>{item.content_subject}</p> */}
                                <div dangerouslySetInnerHTML={{ __html: IsContentFilter.content_details }} />

                                {/* </div>
        ))} */}
                            </div>
                        ) : (
                            <div>No content available</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className='pt-0'>
                        <div className="mx-auto d-flex gap-2">
                            <Button variant="contained" className="primary-gray" onClick={() => setTCShow(false)}>
                                Ok
                            </Button>
                            {/* Additional buttons or actions */}
                        </div>
                    </Modal.Footer>
                </Modal>


                <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef} className='page-center cus-login mx-auto'>

                    <Container className='position-relative cus-form'>


                        <Row className='bg-white justify-content-center rounded-15 p-3'>
                            <Col xs="12" md="12" lg="12">

                                <div className='justify-content-center'>
                                    <div className='text-center'>
                                        <img src="mygooroo-tcms.png" width={150} className="page-center mb-2" />
                                        <p className="text-center para">Tutoring Centre Management System (TCMS)</p>
                                    </div>

                                    <div className='mt-4'>
                                        <div className='position-relative'>
                                            <h6 className="form-lable required-field">Username</h6>
                                            <Form.Control
                                                type="text"

                                                value={values.ca_login_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="ca_login_id"
                                                className={errors.ca_login_id && touched.ca_login_id ? "input-error" : ""}
                                            />
                                            {errors.ca_login_id && touched.ca_login_id && <p className="lo-error">{errors.ca_login_id}</p>}
                                        </div>
                                        <div className='position-relative'>
                                            <h6 className="form-lable required-field">Password</h6>

                                            <Form.Control
                                                type={passwordVisible ? "text" : "password"}

                                                value={values.ca_password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="ca_password"

                                                className={errors.ca_password && touched.ca_password ? "input-error"
                                                    : ""}
                                            />
                                            <button
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                                className="password-toggle-button pswlgin" title="show / hide"
                                            >
                                                {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                            </button>
                                            {errors.ca_password && touched.ca_password && <p className="lo-error">{errors.ca_password}</p>}

                                        </div>

                                        <div className='text-center'>

                                            <Button type="submit" variant="contained" className='primary-filled w-100 py-2 mt-3'
                                                disabled={isSubmitting}>Sign in</Button>

                                        </div>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                        {/* <Row> 
                            <Col> */}

                        <div className='w-100 mt-4' >
                        {IsContent.length > 0 ? (
                                <div className='cus-log-btm'>
                                    {/* Loop through each element in IsContent using map */}
                                    {/* {IsContent.map((item, index) => (
                                       
                                        <>
                                        <div key={index} className='cus-w-fill mb-0'>
                                            <NavLink
                                                underline="hover"
                                                color="inherit"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    setContentFilter(item);
                                                    setTCShow(true);
                                                }}
                                                // className="link-dec"
                                            >
                                                {item.content_title}
                                            </NavLink>
                                          
                                        </div>
                                        {index < IsContent.length - 1 ?  <p className="cus-w-fill mb-0"> | </p> : null}
                                        </>
                                       
                                    ))} */}

{IsContent.map((item, index) => (
    
  item.content_title == "Terms & Conditions" ? (
   
    <>
      <div key={index} className='cus-w-fill mb-0'>
        <NavLink
          underline="hover"
          color="inherit"
          onClick={async (e) => {
            e.preventDefault();
            setContentFilter(item);
            setTCShow(true);
          }}
        >
          {item.content_title}
        </NavLink>
      </div>
      {/* {index < IsContent.length - 1 ? <p className="cus-w-fill mb-0"> | </p> : null} */}
    </>
  ) : null
))}


{IsContent.map((item, index) => (
    
    item.content_title == "Privacy Policy" ? (
     
      <>
      <p className="cus-w-fill mb-0"> | </p>
        <div key={index} className='cus-w-fill mb-0'>
          <NavLink
            underline="hover"
            color="inherit"
            onClick={async (e) => {
              e.preventDefault();
              setContentFilter(item);
              setTCShow(true);
            }}
          >
            {item.content_title}
          </NavLink>
        </div>
        {/* {index < IsContent.length - 1 ? <p className="cus-w-fill mb-0"> | </p> : null} */}
      </>
    ) : null
  ))}

                                </div>
                            ) : (
                                null
                            )}


                        </div>

                    {/* </Col>

                </Row> */}
            </Container>

        </Form > </>) : (null)
}
        </>
    );
}

export default Login;