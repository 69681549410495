/*
Purpose: View details of student
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/
/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import CallIcon from '@mui/icons-material/Call';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Badge from 'react-bootstrap/Badge';

import React from 'react';
import 'react-data-table-component-extensions/dist/index.css';
import { columns, data } from './data.js';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import axios from "axios";
import {
    security_key, BASE_URL
} from "../global";

import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
import PhoneInput from 'react-phone-input-2';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';



function ViewStudent() {

    const navigate = useNavigate();
    

    const [modalShow, setModalShow] = useState(false);

//external parent start
    const [showModal1, setShowModal1] = useState(false);
    const toggleModal1 = () => setShowModal1(!showModal1);

    const [Options_parent, setOptions_par] = useState([]);
    const [IsSubmittingParent, setIsSubmittingParent] = useState(false);


    const [showPrimary, setPrimaryShow] = useState(false);
    const [data_Primary, setPrimaryLabelData] = useState({});
    const [isPrimarySubmitting, setIsPrimarySubmitting] = useState(false);


    const handlePrimaryClick = (row) => {
     
        setPrimaryLabelData(row);

        setPrimaryShow(true);
    };

    const [showDelete, setDeleteShow] = useState(false);
    const [data_delete, setDeleteLabelData] = useState({});
    const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);
  
  
    const handleDeleteClick = (row) => {
     
      setDeleteLabelData(row);
  
      setDeleteShow(true);
    };

    
    const [externalLoading, setExternalLoading] = useState(true);
    
    
    const [Id, setId] = useState(null);
    const [note_data, setNotes] = useState([]);

    const [lesson_data, setLessonData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [coin_data, setCoinData] = useState([]);
    const [test_data, setTestData] = useState([]);

    
    //Get key value start
    const location = useLocation();
    useEffect(() => {

        const { pk_student_key } = location.state || {};
       
        const extractedSId = pk_student_key;

       
        if (extractedSId !== '' && extractedSId !== undefined) {
            setId(extractedSId);
            fetchData(extractedSId);
            fetchMasterData(extractedSId);
        }else{
            navigate("/list-students");
        }
    }, [location]);
    //Get key value end


    const [StudentData, setStudentData] = useState({});
    const [StudentSecParData, setSecondaryParentData] = useState({});
    const [StudentAge, setAge] = useState(0);

    const [PendingAmount, setPendingAmount] = useState(0.00);
    
    const [TotalAmountPaid, setTotalAmountPaid] = useState(0.00);

    const [passwordVisible, setPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [ConpasswordVisible, setConPasswordVisible] = useState(false);


    const toggleConPasswordVisibility = () => {
        setConPasswordVisible(!ConpasswordVisible);
    };



    const [passwordVisible2, setPasswordVisible2] = useState(false);


    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };

    
    //Api call start

    const fetchMasterData = (Id) => {
        // Make an API request to fetch the options
        
            axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                fk_student_key: Id,
                fk_cent_key: sessionStorage.getItem('key'),
                mode: "getAllStudentParentbyCenter"
            })
                .then(response_par => {
                    // Assuming the API response is an array of objects with 'id' and 'value' properties
                    const formattedOptions_par = response_par.data.data.map(option => ({
                        value: option.pk_par_key,
                        label: option.par_firstname + ' ' + option.par_surname
                    }));
                    formattedOptions_par.unshift({
                        value: '0',
                        label: 'Select Existing Parent'
                    });
                    setOptions_par(formattedOptions_par);
                })
                .catch(error => {
                    console.error('Error fetching options from API: ', error);
                });

                axios.post(BASE_URL + 'API/api_index.php', {
                    security_key: security_key,
                    fk_student_key: Id,
                    fk_cent_key: sessionStorage.getItem('key'),
                    mode: "getPendingAmountStudent"
                })
                    .then(response_pending => {
                        // Assuming the API response is an array of objects with 'id' and 'value' properties
                        

                        if(response_pending.data.success === true){

                            if(response_pending.data.data.length > 0){
                                let pending_amount = response_pending.data.data[0].nextPaymentAmount;
                                let final_amount = parseFloat(pending_amount).toFixed(2);

                                let paid_amount = response_pending.data.data[0].nextPaymentAmount;
                                let total_paid_amount = parseFloat(paid_amount).toFixed(2);
                                setTotalAmountPaid(total_paid_amount);
                                setPendingAmount(final_amount);

                               
                            }else{
                                setTotalAmountPaid(0.00);
                                setPendingAmount(0.00);
                            }

                        }else{
                            setTotalAmountPaid(0.00);
                            setPendingAmount(0.00);
                        }
                    })
                    .catch(error => {
                        setTotalAmountPaid(0.00);
                        setPendingAmount(0.00);
                        console.error('Error fetching options from API: ', error);
                    });
        // axios.post(BASE_URL + 'API/api_index.php', {
        //     security_key: security_key,
        //     mode: "getAllSessionTimeByStudent",
        //     fk_student_key: Id,
        //     fk_cent_key: sessionStorage.getItem('key')
        // })
        //     .then(response_session => {
        //         // Assuming the API response is an array of objects with 'id' and 'value' properties
        //         const SessionOptions = response_session.data.data.map(option => ({
        //             value: option.pk_sest_key,
        //             label: option.sest_name
        //         }));
        //         SessionOptions.unshift({
        //             value: '',
        //             label: '--Select--'
        //         });
        //         setSessionOptions(SessionOptions);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching options from API: ', error);
        //     });

        // axios.post(BASE_URL + 'API/api_index.php', {
        //     security_key: security_key,
        //     country_id: sessionStorage.getItem('country_code'),
        //     mode: "getCountryYearLevel"
        // })
        //     .then(response_year => {
        //         // Assuming the API response is an array of objects with 'id' and 'value' properties
        //         const formattedyearOptions = response_year.data.data.map(option => ({
        //             value: option.activity_level_id,
        //             label: option.level_name
        //         }));
        //         setYearLevelOptions(formattedyearOptions);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching options from API: ', error);
        //     });

        // axios.post(BASE_URL + 'API/api_index.php', {
        //     security_key: security_key,
        //     mode: "readStudentEnrolmentSectionOnly",
        //     fk_student_key: Id,
        //     fk_cent_key: sessionStorage.getItem('key')
        // })
        //     .then(response_unenrol => {
        //         // Assuming the API response is an array of objects with 'id' and 'value' properties
        //         const UnenrolSupOptions = response_unenrol.data.data.map(option => ({
        //             value: option.pk_enrol_key,
        //             label: option.sest_name
        //         }));
        //         UnenrolSupOptions.unshift({
        //             value: '',
        //             label: '--Select--'
        //         });
        //         SetUnenrolSupOptions(UnenrolSupOptions);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching options from API: ', error);
        //     });

    };

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleStudent",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setStudentData(response.data.data[0]);

                    if (response.data.data[0]['st_dob'] != null && response.data.data[0]['st_dob'] != '') {

                        var today = new Date();
                        var birthDate = new Date(response.data.data[0]['st_dob']);  // create a date object directly from `dob1` argument
                        var age_now = today.getFullYear() - birthDate.getFullYear();
                        var m = today.getMonth() - birthDate.getMonth();
                        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                            age_now--;
                        }
                      
                        setAge(age_now);
                    }
                    
                }

                
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleStudentSecondaryParent",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setSecondaryParentData(response.data.data[0]);
                    setExternalLoading(false);
                  
                 
                }else{
                    setSecondaryParentData([]);
                    setExternalLoading(false);
                }

               
            } else {
                setSecondaryParentData([]);
                setExternalLoading(false);
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAllStudentNotes",
                fk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setNotes(response.data.data);
                   
                }

                
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }



        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getLesson",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setLessonData(response.data.data);
                    //setLessionRecords(response.data.data);

                    const today = new Date().toISOString().split('T')[0];
                    const nextMonth = new Date();
                    nextMonth.setMonth(nextMonth.getMonth() + 1);
                    const endDateValue = nextMonth.toISOString().split('T')[0];

                    setStartDate(today);
                    setEndDate(endDateValue);
                    const filterStartDate = today ? new Date(today).getTime() : null;
                    const filterEndDate = endDateValue ? new Date(endDateValue).getTime() : null;

                    const filteredData = response.data.data.filter((record) => {
                        const lessonStartDate = new Date(record.ses_date).getTime();
                        const lessonEndDate = new Date(record.ses_date).getTime();

                        return (
                            (filterStartDate === null || lessonStartDate >= filterStartDate) &&
                            (filterEndDate === null || lessonEndDate <= filterEndDate)
                        );
                    });

                    setLessionRecords(filteredData);
                    setFilteredLessonRecords(filteredData);

                    setLoading(false);

                   
                } else {
                    setLoading(false);
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }

        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getTotalCoins",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCoinData(response.data.data);
                    setCoinRecords(response.data.data);
                    setLoading(false);

                   
                } else {
                    setLoading(false);
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }


        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleStudentTestDetails",
                pk_student_key: id
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setTestData(response.data.data);
                    setTestRecords(response.data.data);
                    setLoading(false);

                   
                } else {
                    setLoading(false);
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }


        
    };
    //Api call End
    const [lessonRecords, setLessionRecords] = useState(lesson_data);
    const [filteredLessonRecords, setFilteredLessonRecords] = useState([]);
    const [TestRecords, setTestRecords] = useState(test_data);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    
    //Lesson Start
    const columnsLesson = [

        {
            headerName: 'Title',
            field: "Title",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.title == null || params.row.title == '' ? '-' : params.row.title}`;
            },
        },
        {
            headerName: 'Description',
            field: "Description",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.description == null || params.row.description == '' ? '-' : params.row.description}`;
            },
        },
        {
            headerName: 'Session Name',
            field: "Session Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
            },
        },
        {
            headerName: 'Tutor Name',
            field: "Tutor Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
            },

        },
        {

            headerName: 'Date',
            field: "Date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
            },
            sortComparator: (valueA, valueB, rowA, rowB, direction) => {
                if (valueA === '-' || valueB === '-') {
                  // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
                  return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
                }
          
                const dateA = Moment(valueA, 'DD-MM-YYYY');
                const dateB = Moment(valueB, 'DD-MM-YYYY');
          
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
              },
        },
        {
            headerName: 'Start Time',
            field: "Start Time",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.ses_start_time == null || params.row.ses_start_time == '' ? '-' : Moment(params.row.ses_start_time, "HH:mm:ss").format('HH:mm')}`;
            },

        },
        {
            headerName: 'End Time',
            field: "End Time",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.ses_end_time == null || params.row.ses_end_time == '' ? '-' : Moment(params.row.ses_end_time, "HH:mm:ss").format('HH:mm')}`;
            },

        },
        {

            headerName: 'Activity Count',
            field: "Activity Count",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.count_lesson_activity == null || params.row.count_lesson_activity == '' ? '0' : params.row.count_lesson_activity}`;
            },

        },
    ];




    const handleStartDateChange = (event) => {
      
        const newStartDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
      
        setStartDate(newStartDate);
        handleDateFilter(newStartDate, endDate);
    };

    const handleEndDateChange = (event) => {
        const newEndDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
        //const newEndDate = event.target.value;
        setEndDate(newEndDate);
        handleDateFilter(startDate, newEndDate);
    };

    const handleDateFilter = (start, end) => {
        const filterStartDate = start ? new Date(start).getTime() : null;
        const filterEndDate = end ? new Date(end).getTime() : null;

        const filteredData = lesson_data.filter((record) => {
            const lessonStartDate = new Date(record.ses_date).getTime();
            const lessonEndDate = new Date(record.ses_date).getTime();

            return (
                (filterStartDate === null || lessonStartDate >= filterStartDate) &&
                (filterEndDate === null || lessonEndDate <= filterEndDate)
            );
        });

        setLessionRecords(filteredData);
        setFilteredLessonRecords(filteredData);
    };

    const handleDateReset = () => {
        const today = new Date().toISOString().split('T')[0];
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        const endDateValue = nextMonth.toISOString().split('T')[0];

        setStartDate(today);
        setEndDate(endDateValue);
        handleDateFilter(today, endDateValue);
        //setLessionRecords(lesson_data);
    };

    //Lesson End



    //Coin Start

    const [CoinRecords, setCoinRecords] = useState(coin_data);
   
    //Lesson Start
    const columnsCoin = [

        {
            headerName: 'Name',
            field: "Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.st_first_name || ''} ${params.row.st_surname || ''}`;
            },

        },
        {
            headerName: 'Total Coins',
            field: "Total Coins",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.coins == null || params.row.coins == '' ? '0' : params.row.coins}`;
            },
        }
    ];
    //Coin End
    //Test Start

    const columns_test = [
        {

            headerName: 'Name',
            field: "Name",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.dt_test_name == null || params.row.dt_test_name == '' ? '-' : params.row.dt_test_name}`;
            },

        },
        {
            headerName: 'Type',
            field: "Type",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.dtm_name == null || params.row.dtm_name == '' ? '-' : params.row.dtm_name}`;
            },
        },
        {
            headerName: 'Value',
            field: "Value",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.dt_test_mark == null || params.row.dt_test_mark == '' ? '-' : params.row.dt_test_mark}`;
            },


        },
        {
            headerName: 'Date',
            field: "Date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.dt_date == null || params.row.dt_date == '' ? '-' : Moment(params.row.dt_date).format('DD-MM-YYYY')}`;
            },
            sortComparator: (valueA, valueB, rowA, rowB, direction) => {
                if (valueA === '-' || valueB === '-') {
                  // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
                  return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
                }
          
                const dateA = Moment(valueA, 'DD-MM-YYYY');
                const dateB = Moment(valueB, 'DD-MM-YYYY');
          
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
              },

        },
        {
            headerName: 'Comment',
            field: "Comment",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.dt_comment == null || params.row.dt_comment == '' ? '-' : params.row.dt_comment}`;
            },

        },

    ];
    //Test End

    // Sample columns configuration
    const columns_notes = [
        {
            headerName: 'Priority',
            field: "Priority",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sn_is_critical == 0 ? 'Normal' : 'Critical'}`;
            },

        },
        {

            headerName: 'Date',
            field: "Date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sn_date == null || params.row.sn_date == '' ? '-' : Moment(params.row.sn_date).format('DD-MM-YYYY')}`;
            },
            sortComparator: (valueA, valueB, rowA, rowB, direction) => {
                if (valueA === '-' || valueB === '-') {
                  // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
                  return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
                }
          
                const dateA = Moment(valueA, 'DD-MM-YYYY');
                const dateB = Moment(valueB, 'DD-MM-YYYY');
          
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
              },

        },
        {
            headerName: 'Note',
            field: "Note",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.sn_note == null || params.row.sn_note == '' ? '-' : params.row.sn_note}`;
            },
        },

    ];

    const handleClick = () => {
        // You can use the 'id' value here for further processing

        navigate(`/student-profile`, { state: { pk_student_key: `${Id}` } });

    };


    const handleClick_enroll = () => {
        // You can use the 'id' value here for further processing

        navigate(`/student-entrolments`, { state: { pk_student_key: `${Id}` } });

    };


    const handleLessonViewClick = async (e) => {


        navigate(`/ListLessonActivity`, { state: { pk_lesson_key: `${e.pk_lesson_key}`, pk_student_key: `${Id}` } });


    }



    const [options_subject, setOptions] = useState([]);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            fk_cent_key: sessionStorage.getItem('key'),
            mode: "getAllSubjectby_center"
        })
            .then(response => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedOptions = response.data.data.map(option => ({
                    value: option.subject_id,
                    label: option.subject_name
                }));
                formattedOptions.unshift({
                    value: '',
                    label: '--Select--'
                });
                setOptions(formattedOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });

    }, []);




    function onSubmitPrimary() {
        setIsPrimarySubmitting(true);
    
        const responsePrimary = axios.post(BASE_URL + "API/api_index.php", {
    
          security_key: security_key,
          updated_by: sessionStorage.getItem('key'),
          mode: "ParentSetPrimary",
          fk_cent_key: sessionStorage.getItem('key'),
          pk_student_key: Id,
          pk_parent_key: data_Primary
        }).then((responsePrimary) => {
    
          if (responsePrimary.data.data == 1) {
    
            setPrimaryShow(false)
            // Handle the successful response here
            toast.success("Success! Parent has been set primary account.", {
              autoClose: 3000, // 3 seconds in this example
            });
    
            fetchData(Id);
    
            setIsPrimarySubmitting(false);
    
          } else {
            setPrimaryShow(false)
            toast.error("Failure! Unable to parent account primary. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsPrimarySubmitting(false);
          }
    
    
        })
          .catch((error) => {
            setPrimaryShow(false)
            // Handle any errors that occurred during the request
            toast.error("Failure! Unable to parent account primary. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsPrimarySubmitting(false);
          });
      }
    

    const basicSchema = yup.object().shape({

        sn_date: yup
        .string()
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          const parsedDate = dayjs(value, 'YYYY-MM-DD');
          return parsedDate.isValid();
        }).required("Date is required."),
        sn_category: yup.mixed().required("Category is required."),
        sn_note: yup.string().required("Note is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

    });

    const initialValues = {
        /*tbl_student*/
        sn_date: "",
        sn_category: "",
        sn_note: "",
        sn_is_critical: false,
        fk_stu_key: Id,
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),
    };



    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        // onSubmit,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "createStudentNote",
                    ...formValues,
                });

                if (response.data.success === true) {
                    // Reset the form
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        fetchData(Id);
                        // setShow(false);
                        setModalShow(false);

                        resetForm();
                       
                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create notes. Try again later.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create notes. Try again later.", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create notes. Try again later.", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;


    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
       
    };







    const validationSchema1 = yup.object().shape({
        par_firstname: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        par_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        par_emailid: yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
            return !value || value && value.includes('.');
          }),
        par_countryCode: yup.string().notRequired(),
        par_phone: yup
        .string()
        .test(
          'minDigits',
          'Minimum 5 digits required',
          function (value) {
            const { par_countryCode } = this.parent;
            const countryCodeLength = par_countryCode ? par_countryCode.length : 0;
            const phoneLength = value ? value.length : 0;
            const difference = phoneLength - countryCodeLength;
  
            // Check if the difference is less than 5
            return difference >= 5 || difference === 0;
          }
        )
        .notRequired(),
        ps_relationship: yup.string().required("Relationship is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
       
        pa_login_id: yup.string().when(['ex_par_key'], {
            is: (ex_par_key) =>
                ex_par_key == '0',
            then: () => yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
        }),
        pa_password: yup.string().when(['ex_par_key'], {
            is: (ex_par_key) =>
                ex_par_key == '0',
            then: () => yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        }),
        pa_conform_password: yup.string().when(['ex_par_key'], {
            is: (ex_par_key) =>
                ex_par_key == '0',
            then: () => yup
                .string()
                .oneOf([yup.ref("pa_password"), null], "Password is not matching")
                .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        }),
    });


    const initialValues1 = {
        /*tbl_parent*/
        par_firstname:'',
        par_surname: '',
        par_emailid: '',
        par_countryCode:'',
        par_phone: '',
        ex_par_key: '0',
        par_address: StudentData ? StudentData.par_address : '',
        par_city: StudentData ? StudentData.par_city : '',
        par_state: StudentData ? StudentData.par_state : '',
        //par_country: StudentData ? StudentData.par_country : '', 
        par_country: StudentData ? StudentData.par_country != null && StudentData.par_country != '' ? StudentData.par_country : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG' : sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG',
        par_zip: StudentData ? StudentData.par_zip : '',
      
        /*tbl_parent_auth*/
        pa_login_id: "",
        pa_password: "",
        pa_conform_password: "",
        pk_student_key: Id,
        created_by: sessionStorage.getItem('key'),
        fk_cent_key: sessionStorage.getItem('key')
    };


    const formik1 = useFormik({
        initialValues: initialValues1,
        validationSchema: validationSchema1,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmittingParent(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "createStudentParent",
                    ...values,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // setShow(false);
                        fetchData(Id);
                        fetchMasterData(Id);
                        resetForm();
                        toggleModal1();



                        setIsSubmittingParent(false); // Re-enable the submit button
                    }else if (response.data.data === 2) {
                        toast.error("Parents / Guardians username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingParent(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create Parent. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingParent(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create Parent. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmittingParent(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create Parent. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmittingParent(false); // Re-enable the submit button

            } finally {
                setIsSubmittingParent(false); // Re-enable the submit button

            }

        },
    });

    const [isSubmittingPass, setIsSubmittingPass] = useState(false);

    const [showModalPass, setPassShowModal] = useState(false);
    const toggleModalpass = () => setPassShowModal(!showModalPass);

    
    const validationSchema3 = yup.object().shape({
        // pa_login_id: yup.mixed().required("Username is required."),
        pa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        pa_conform_password: yup
            .string()
            .oneOf([yup.ref("pa_password"), null], "Password is not matching")
            .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

    });



    const initialValues3 = {

        pk_par_key: StudentData ? StudentData.pk_par_key : '',

        /*tbl_center_auth*/
        pa_login_id: StudentData ? StudentData.pa_login_id : '',
        pa_password: StudentData ? StudentData.pa_direct_password : '',
        pa_conform_password: '',
        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
    };

    const formik3 = useFormik({
        initialValues: initialValues3,
        validationSchema: validationSchema3,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmittingPass(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateParentAuth_new",
                    ...values,
                });

                if (response.data.success === true) {
                    // Reset the form
                    
                    if (response.data.data === 1) {
                        toast.success("Success! Manage Login.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setTimeout(function () {
                            window.location.reload(false);
                            setIsSubmittingPass(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                      



                    } else if (response.data.data === 2) {

                        toast.error("Username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button

                    } else {
                        toast.error("Failure! Unable to update manage login. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update manage login. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmittingPass(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update manage login. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmittingPass(false); // Re-enable the submit button

            } finally {
                setIsSubmittingPass(false); // Re-enable the submit button

            }

        },
    });

   

    const [isSubmittingPass2, setIsSubmittingPass2] = useState(false);

    const [showModalPass2, setPassShowModal2] = useState(false);
    const toggleModalpass2 = () => setPassShowModal2(!showModalPass2);

  
    const validationSchema4 = yup.object().shape({
        // pa_login_id: yup.mixed().required("Username is required."),
        pa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        pa_conform_password: yup
            .string()
            .oneOf([yup.ref("pa_password"), null], "Password is not matching")
            .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),

    });



    const initialValues4 = {

        pk_par_key: StudentSecParData ? StudentSecParData.pk_par_key : '',

        /*tbl_center_auth*/
        pa_login_id: StudentSecParData ? StudentSecParData.pa_login_id : '',
        pa_password: StudentSecParData ? StudentSecParData.pa_direct_password : '',
        pa_conform_password: '',
        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
    };

    const formik4 = useFormik({
        initialValues: initialValues4,
        validationSchema: validationSchema4,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsSubmittingPass2(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateParentAuth_new",
                    ...values,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Manage Login.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setTimeout(function () {
                            window.location.reload(false);
                            setIsSubmittingPass2(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                      



                    } else if (response.data.data === 2) {

                        toast.error("Username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass2(false); // Re-enable the submit button

                    } else {
                        toast.error("Failure! Unable to update manage login. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmittingPass2(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update manage login. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmittingPass2(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update manage login. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmittingPass2(false); // Re-enable the submit button

            } finally {
                setIsSubmittingPass2(false); // Re-enable the submit button

            }

        },
    });



    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (

        <div className="three-dot-icon" >
            <i className="fas fa-ellipsis-v"  ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}></i>{children}
        </div>
       
      ));


      const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (

        <div className="three-dot-icon" >
            <i className="fas fa-ellipsis-v"  ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}></i>{children}
        </div>
       
      ));


      function onSubmitDelete() {
        setIsUserDeleteSubmitting(true);
    
        const responsedelete = axios.post(BASE_URL + "API/api_index.php", {
    
          security_key: security_key,
          updated_by: sessionStorage.getItem('key'),
          mode: "ParentRemovestudent",
          fk_cent_key: sessionStorage.getItem('key'),
          pk_student_key: Id,
          pk_parent_key: data_delete
        }).then((responsedelete) => {
    
          if (responsedelete.data.data == 1) {
    
            setDeleteShow(false);
            fetchData(Id);
            // Handle the successful response here
            toast.success("Success! Parent has been removed.", {
              autoClose: 3000, // 3 seconds in this example
            });
    
           
    
            setIsUserDeleteSubmitting(false);
    
          } else {
            toast.error("Failure! Unable to Parent has been remove. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsUserDeleteSubmitting(false);
          }
    
    
        })
          .catch((error) => {
            // Handle any errors that occurred during the request
            toast.error("Failure! Unable to Parent has been remove. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsUserDeleteSubmitting(false);
          });
      }
    

      function getDayWithSuffix(day) {
        if (day >= 11 && day <= 13) {
          // If the day is between 11 and 13, use 'th' suffix
          return day + 'th';
        }
      
        const lastDigit = day % 10;
        switch (lastDigit) {
          case 1:
            return day + 'st';
          case 2:
            return day + 'nd';
          case 3:
            return day + 'rd';
          default:
            return day + 'th';
        }
      }
      
      function getDayOfWeek(dayNumber) {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
         
        ];
      
        if (dayNumber >= 1 && dayNumber <= 7) {
          return daysOfWeek[dayNumber - 1];
        } else {
          return "Invalid day number";
        }
      }
    
    return (
        <>
            {/* React alert */}
            <ToastContainer position="top-center" />


            <Modal show={showDelete} variant="danger">

                <Modal.Header>Parent</Modal.Header>

                <Modal.Body>
                <p>Are you sure? You want change to remove this parent? </p>

                </Modal.Body>
                <Modal.Footer className='pt-0'>
                <div className="mx-auto d-flex gap-2" >

                    <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
                    <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
                </div>

                </Modal.Footer>

            </Modal>
            
            <Modal show={showPrimary} variant="danger">

                <Modal.Header>Parent</Modal.Header>

                <Modal.Body>
                <p>Are you sure? You want change to primary account? </p>

                </Modal.Body>
                <Modal.Footer className='pt-0'>
                <div className="mx-auto d-flex gap-2" >

                    <Button variant="contained" className="primary-gray" onClick={() => setPrimaryShow(false)}>Cancel</Button>
                    <Button variant="contained" type='submit' className="primary-filled " disabled={isPrimarySubmitting} onClick={() => onSubmitPrimary()} >Yes</Button>
                </div>

                </Modal.Footer>

            </Modal>
            <Modal show={showModalPass} onHide={toggleModalpass} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Login</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik3.handleSubmit}>
                    <Modal.Body>

                        {/* --------------Date--------------- */}

                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Parent App Login</h6>
                        </div>
                        <Row>


                            {/* --------------Student Username--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Username</h6>
                                <Form.Control type="text"
                                    name="pa_login_id"
                                    readOnly
                                    value={formik3.values.pa_login_id}
                                    onChange={formik3.handleChange}
                                    // onBlur={handleBlur}
                                    id="pa_login_id"
                                    onBlur={formik3.handleBlur}
                                    className={formik3.errors.pa_login_id && formik3.touched.pa_login_id ? "input-error" : ""}
                                />
                                {formik3.errors.pa_login_id && formik3.touched.pa_login_id && <p className="error">{formik3.errors.pa_login_id}</p>}
                            </Col>

                            {/* --------------Student Password--------------- */}


                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control type={passwordVisible ? "text" : "password"}

                                    value={formik3.values.pa_password}
                                    onChange={formik3.handleChange}
                                    onBlur={formik3.handleBlur}
                                    id="pa_password"
                                    className={formik3.errors.pa_password && formik3.touched.pa_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik3.errors.pa_password && formik3.touched.pa_password && <p className="error">{formik3.errors.pa_password}</p>}
                            </Col>


                            {/* --------------Student Confirm Password--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control type={ConpasswordVisible ? "text" : "password"}
                                    value={formik3.values.pa_conform_password}
                                    onChange={formik3.handleChange}
                                    onBlur={formik3.handleBlur}
                                    id="pa_conform_password"
                                    className={formik3.errors.pa_conform_password && formik3.touched.pa_conform_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConPasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik3.errors.pa_conform_password && formik3.touched.pa_conform_password && <p className="error">{formik3.errors.pa_conform_password}</p>}
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>

                        <div className="mx-auto d-flex gap-2" >

                            <Button variant="contained" className="primary-gray" onClick={toggleModalpass}>Cancel</Button>
                            <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass}>Submit</Button>

                        </div>

                    </Modal.Footer>
                </Form>
            </Modal>



          {/* Addtional parent login */}

          <Modal show={showModalPass2} onHide={toggleModalpass2} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Login</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik4.handleSubmit}>
                    <Modal.Body>

                        {/* --------------Date--------------- */}

                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Parent App Login</h6>
                        </div>
                        <Row>


                            {/* --------------Student Username--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Username</h6>
                                <Form.Control type="text"
                                    name="pa_login_id"
                                    readOnly
                                    value={formik4.values.pa_login_id}
                                    onChange={formik4.handleChange}
                                    // onBlur={handleBlur}
                                    id="pa_login_id"
                                    onBlur={formik4.handleBlur}
                                    className={formik4.errors.pa_login_id && formik4.touched.pa_login_id ? "input-error" : ""}
                                />
                                {formik4.errors.pa_login_id && formik4.touched.pa_login_id && <p className="error">{formik4.errors.pa_login_id}</p>}
                            </Col>

                            {/* --------------Student Password--------------- */}


                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Password</h6>
                                <Form.Control type={passwordVisible2 ? "text" : "password"}

                                    value={formik4.values.pa_password}
                                    onChange={formik4.handleChange}
                                    onBlur={formik4.handleBlur}
                                    id="pa_password"
                                    className={formik4.errors.pa_password && formik4.touched.pa_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility2}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {passwordVisible2 ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik4.errors.pa_password && formik4.touched.pa_password && <p className="error">{formik4.errors.pa_password}</p>}
                            </Col>


                            {/* --------------Student Confirm Password--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Confirm Password</h6>
                                <Form.Control type={ConpasswordVisible ? "text" : "password"}
                                    value={formik4.values.pa_conform_password}
                                    onChange={formik4.handleChange}
                                    onBlur={formik4.handleBlur}
                                    id="pa_conform_password"
                                    className={formik4.errors.pa_conform_password && formik4.touched.pa_conform_password ? "input-error" : ""}
                                    autocomplete="off"
                                />
                                <button
                                    type="button"
                                    onClick={toggleConPasswordVisibility}
                                    className="password-toggle-button pswicon" title="show / hide"
                                >
                                    {ConpasswordVisible ? <FiEye /> : <FiEyeOff />}

                                </button>
                                {formik4.errors.pa_conform_password && formik4.touched.pa_conform_password && <p className="error">{formik4.errors.pa_conform_password}</p>}
                            </Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>

                        <div className="mx-auto d-flex gap-2" >

                            <Button variant="contained" className="primary-gray" onClick={toggleModalpass2}>Cancel</Button>
                            <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPass2}>Submit</Button>

                        </div>

                    </Modal.Footer>
                </Form>
            </Modal>
            <div className='page-wrapper'>

                <Modal
                    show={modalShow} onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h5 className='page-title'> Student Notes </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            {/* --------------Date--------------- */}
                            <Row>


                                <Col lg="12" md="12" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable required-field">Date</h6>
                                    {/* <Form.Control
                                        type="date"

                                        value={values.sn_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sn_date"
                                        className={errors.sn_date && touched.sn_date ? "input-error" : ""}
                                    /> */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.sn_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "sn_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, sn_date: true });
                                            }}
                                            name="sn_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'sn_date',
                                                },
                                                }}
                                            className={errors.sn_date && touched.sn_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs()}
                                            // maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.sn_date && touched.sn_date && <span className="error">{errors.sn_date}</span>}
                                </Col>


                                {/* --------------Category--------------- */}

                                <Col lg="12" md="12" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable required-field">Category</h6>
                                    <Select menuPlacement="auto" options={options_subject}
                                        name="sn_category"
                                        value={values.sn_category['value']}
                                        id="sn_category"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sn_category",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sn_category: true });
                                        }}
                                        className={errors.sn_category && touched.sn_category ? "input-error" : ""} />
                                    {errors.sn_category && touched.sn_category && <span className="error">{errors.sn_category}</span>}
                                </Col>


                                {/* --------------Notes--------------- */}

                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Note</h6>
                                    <Form.Control as="textarea" rows={3}

                                        value={values.sn_note}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sn_note"
                                        className={errors.sn_note && touched.sn_note ? "input-error" : ""}
                                    />
                                    {errors.sn_note && touched.sn_note && <p className="error">{errors.sn_note}</p>}
                                </Col>


                                {/* --------------Notes check--------------- */}

                                {/* --------------Contact Checkbox--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>

                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        label="Critical (Anyways on Top)"
                                        name="sn_is_critical"
                                        id="sn_is_critical"
                                        checked={values.sn_is_critical}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.sn_is_critical && touched.sn_is_critical && <p className="error">{errors.sn_is_critical}</p>}
                                </Col>

                            </Row>
                        </Modal.Body>

                        <Modal.Footer>

                            <div className="mx-auto d-flex gap-2" >

                                <Button variant="contained" className="primary-gray" onClick={() => setModalShow(false)}>Cancel</Button>
                                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
                            </div>

                        </Modal.Footer>
                    </Form>
                </Modal>



{/* 
                <Modal
                    show={modalShowAdd} onHide={() => setModalShowAdd(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form autoComplete="off" onSubmit={formik2.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h5 className='page-title'> Add Enrolment </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                           
                            <Row>


                               

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Subject</h6>
                                    <Select menuPlacement="auto" options={options_subject_student}
                                        name="fk_sub_id"
                                        value={formik2.values.fk_sub_id['value']}
                                        id="fk_sub_id"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fk_sub_id",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik2.handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            formik2.handleBlur(event);
                                            formik2.setTouched({ ...formik2.touched, fk_sub_id: true });
                                        }}
                                        className={formik2.errors.fk_sub_id && formik2.touched.fk_sub_id ? "input-error" : ""} />
                                    {formik2.errors.fk_sub_id && formik2.touched.fk_sub_id && <p className="error">{formik2.errors.fk_sub_id}</p>}
                                </Col>


                               

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Enrolment Category</h6>
                                    <Select menuPlacement="auto" options={options_dig_type}
                                        name="enrol_category"
                                        value={formik2.values.enrol_category['value']}
                                        id="enrol_category"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "enrol_category",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik2.handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            formik2.handleBlur(event);
                                            formik2.setTouched({ ...formik2.touched, enrol_category: true });
                                        }}
                                        className={formik2.errors.enrol_category && formik2.touched.enrol_category ? "input-error" : ""} />
                                    {formik2.errors.enrol_category && formik2.touched.enrol_category && <p className="error">{formik2.errors.enrol_category}</p>}
                                </Col>

                             

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Session</h6>
                                    <Select menuPlacement="auto" options={options_session}
                                        name="fk_sest_key"
                                        value={formik2.values.fk_sest_key['value']}
                                        id="fk_sest_key"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fk_sest_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik2.handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            formik2.handleBlur(event);
                                            formik2.setTouched({ ...formik2.touched, fk_sest_key: true });
                                        }}
                                        className={formik2.errors.fk_sest_key && formik2.touched.fk_sest_key ? "input-error" : ""} />
                                    {formik2.errors.fk_sest_key && formik2.touched.fk_sest_key && <p className="error">{formik2.errors.fk_sest_key}</p>}
                                </Col>


                         

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Fees</h6>
                                    <Select menuPlacement="auto" options={options_pricing}
                                        name="enrol_price"
                                        value={formik2.values.enrol_price['value']}
                                        id="enrol_price"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "enrol_price",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik2.handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            formik2.handleBlur(event);
                                            formik2.setTouched({ ...formik2.touched, enrol_price: true });
                                        }}
                                        className={formik2.errors.enrol_price && formik2.touched.enrol_price ? "input-error" : ""} />
                                    {formik2.errors.enrol_price && formik2.touched.enrol_price && <p className="error">{formik2.errors.enrol_price}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Enrolment Date</h6>
                                    <Form.Control
                                        type="date"

                                        value={formik2.values.enrol_start_date}
                                        onChange={formik2.handleChange}
                                        onBlur={formik2.handleBlur}
                                        id="enrol_start_date"
                                        className={formik2.errors.enrol_start_date && formik2.touched.enrol_start_date ? "input-error" : ""}
                                    />
                                    {formik2.errors.enrol_start_date && formik2.touched.enrol_start_date && <p className="error">{formik2.errors.enrol_start_date}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                                    <Form.Control
                                        type="number"

                                        value={formik2.values.enrol_reg_fees}
                                        onChange={formik2.handleChange}
                                        onBlur={formik2.handleBlur}
                                        id="enrol_reg_fees"
                                        className={formik2.errors.enrol_reg_fees && formik2.touched.enrol_reg_fees ? "input-error" : ""}
                                    />
                                    {formik2.errors.enrol_reg_fees && formik2.touched.enrol_reg_fees && <p className="error">{formik2.errors.enrol_reg_fees}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                                    <Form.Control
                                        type="number"

                                        value={formik2.values.enrol_deposit}
                                        onChange={formik2.handleChange}
                                        onBlur={formik2.handleBlur}
                                        id="enrol_deposit"
                                        className={formik2.errors.enrol_deposit && formik2.touched.enrol_deposit ? "input-error" : ""}
                                    />
                                    {formik2.errors.enrol_deposit && formik2.touched.enrol_deposit && <p className="error">{formik2.errors.enrol_deposit}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="6" className='position-relative'>
                                    <h6 className="form-lable">Fees Type</h6>
                                    <Select menuPlacement="auto" options={options_payment_type}
                                        name="enrol_price_type"
                                        value={formik2.values.enrol_price_type['value']}
                                        id="enrol_price_type"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "enrol_price_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik2.handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            formik2.handleBlur(event);
                                            formik2.setTouched({ ...formik2.touched, enrol_price_type: true });
                                        }}
                                        className={formik2.errors.enrol_price_type && formik2.touched.enrol_price_type ? "input-error" : ""} />
                                    {formik2.errors.enrol_price_type && formik2.touched.enrol_price_type && <p className="error">{formik2.errors.enrol_price_type}</p>}
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>

                            <div className="mx-auto d-flex gap-2" >

                                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingEnrol}>Save</Button>
                            </div>

                        </Modal.Footer>
                    </Form>
                </Modal> */}
{/* 
                <Modal
                    show={modalShowNotify} onHide={() => setModalShowNotyfy(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h5 className='page-title'> Add Enrolment </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                           
                            <Row>


                                <div className="ten columns col-12">
                                    <ul className="">
                                        <li> Mon 04-sep-23 &rArr;	 Lesson Added For Student</li>
                                        <li> Mon 11-sep-23 &rArr;	 Lesson Added For Student </li>
                                        <li> Mon 18-sep-23 &rArr;	 Lesson Added For Student</li>
                                        <li> Mon 25-sep-23 &rArr;	 Lesson Added For Student</li>
                                    </ul>


                                </div>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>

                            <div className="mx-auto d-flex gap-2" >

                                <Button variant="contained" className="primary-gray" onClick={() => setModalShowNotyfy(false)}>Close</Button>

                            </div>

                        </Modal.Footer>
                    </Form>
                </Modal> */}


                <Modal show={showModal1} onHide={toggleModal1} size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Parents / Guardians Details</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={formik1.handleSubmit}>
                        <Modal.Body>

                        <Stack className='mt-4' >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3 row'>
                                <Col lg="8" md="8" sm="12" className='position-relative'>
                                    <h6 className='mb-0'>Parents / Guardians Details </h6></Col>

                                <Col lg="4" md="4" sm="12" className='position-relative'>
                                    {/* <h6 className="form-lable required-field">Parent Name</h6> */}
                                    <Select menuPlacement="auto" options={Options_parent}
                                        name="ex_par_key"
                                        value={Options_parent.find(option => option.value && formik1.values.ex_par_key && option.value.toString() === formik1.values.ex_par_key.toString())}
                                        id="ex_par_key"
                                        //  onChange={(selectedOption) => {
                                        //      const event = {
                                        //          target: {
                                        //              name: "ex_par_key",
                                        //              value: selectedOption["value"],
                                        //          },
                                        //      };
                                        //      handleChange(event);
                                        //  }}
                                        onChange={async (selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ex_par_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik1.handleChange(event);

                                            // Fetch data based on the selected country
                                            try {

                                                const response = await axios.post(BASE_URL + 'API/api_index.php', {
                                                    security_key: security_key,
                                                    mode: "getSingleParentbyCenter",
                                                    pk_par_key: selectedOption["value"]
                                                });
                                                
                                                if (response.data.success === true) {
                                                    if (response.data.data.length > 0) {
                                                        
                                                        formik1.handleChange({ target: { name: 'ps_relationship', value: response.data.data[0].ps_relationship } });
                                                        formik1.handleChange({ target: { name: 'par_firstname', value: response.data.data[0].par_firstname } });
                                                        formik1.handleChange({ target: { name: 'par_surname', value: response.data.data[0].par_surname } });
                                                        formik1.handleChange({ target: { name: 'par_emailid', value: response.data.data[0].par_emailid } });
                                                        formik1.handleChange({ target: { name: 'par_phone', value: response.data.data[0].par_phone } });
                                                        formik1.handleChange({ target: { name: 'par_address', value: response.data.data[0].par_address } });
                                                        formik1.handleChange({ target: { name: 'par_city', value: response.data.data[0].par_city } });
                                                        formik1.handleChange({ target: { name: 'par_country', value: response.data.data[0].par_country } });
                                                        formik1.handleChange({ target: { name: 'par_state', value: response.data.data[0].par_state } });
                                                        formik1.handleChange({ target: { name: 'par_zip', value: response.data.data[0].par_zip } });

                                                    } else {
                                                        
                                                        formik1.handleChange({ target: { name: 'ps_relationship', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_firstname', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_surname', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_emailid', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_phone', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_address', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_city', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_country', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_state', value: '' } });
                                                        formik1.handleChange({ target: { name: 'par_zip', value: '' } });
                                                    }

                                                } else {
                                                    //console.log("Error fetching abbreviation data");
                                                }

                                                // Update the "yl_abbreviation" field in the Formik values

                                            } catch (error) {
                                                // Handle any errors that occur during the API call
                                                console.error("Error fetching abbreviation data:", error);
                                            }
                                        }}
                                        onBlur={(event) => {
                                            formik1.handleBlur(event);
                                            formik1.setTouched({ ...formik1.touched, ex_par_key: true });
                                        }}
                                        className={formik1.errors.ex_par_key && formik1.touched.ex_par_key ? "input-error" : "mb-0"}
                                    />
                                    {formik1.errors.ex_par_key && formik1.touched.ex_par_key && <p className="error">{formik1.errors.ex_par_key}</p>}
                                </Col>

                            </div>

                            <Row>



                                {/* --------------Student Parents / Guardians --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Relationship</h6>
                                    <Form.Control type="text"
                                        value={formik1.values.ps_relationship}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="ps_relationship"
                                        className={formik1.errors.ps_relationship && formik1.touched.ps_relationship ? "input-error" : ""}
                                    />
                                    {formik1.errors.ps_relationship && formik1.touched.ps_relationship && <p className="error">{formik1.errors.ps_relationship}</p>}
                                </Col>


                                {/* --------------Contact First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control type="text"
                                        value={formik1.values.par_firstname}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="par_firstname"
                                        className={formik1.errors.par_firstname && formik1.touched.par_firstname ? "input-error" : ""} />
                                    {formik1.errors.par_firstname && formik1.touched.par_firstname && <p className="error">{formik1.errors.par_firstname}</p>}
                                </Col>

                                {/* --------------Contact SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control type="text"
                                        value={formik1.values.par_surname}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="par_surname"
                                        className={formik1.errors.par_surname && formik1.touched.par_surname ? "input-error" : ""} />
                                    {formik1.errors.par_surname && formik1.touched.par_surname && <p className="error">{formik1.errors.par_surname}</p>}
                                </Col>

                                {/* --------------Contact Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Email <a href={`mailto:userID@example.com`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a></h6>
                                    <Form.Control type="email"
                                        value={formik1.values.par_emailid}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="par_emailid"
                                        className={formik1.errors.par_emailid && formik1.touched.par_emailid ? "input-error" : "mb-0"}
                                    />
                                    {formik1.errors.par_emailid && formik1.touched.par_emailid && <p className="error">{formik1.errors.par_emailid}</p>}
                                </Col>

                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number <a href='https://web.whatsapp.com/' target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a></h6>
                                    {/* <Form.Control type="number" 
                                     value={values.par_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="par_phone"
                                     className={errors.par_phone && touched.par_phone ? "input-error" : ""} 
                                      /> */}
                                    <PhoneInput
                                        name="par_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={formik1.values.par_phone}
                                        onChange={(phone, country) => {
                                            const event = {
                                                target: {
                                                    name: "par_phone",
                                                    value: phone,
                                                },
                                            };
                                            const event2 = {
                                                target: {
                                                    name: "par_countryCode",
                                                    value: country.dialCode,
                                                },
                                            };
                                            formik1.handleChange(event2);
                                            formik1.handleChange(event);
                                        }}

                                        // onChange={(phone, country) => 
                                        
                                        //     formik1.setValues({
                                        //     ...formik1.values,
                                        //     par_phone: phone,
                                        //     par_countryCode: country.dialCode
                                        //     })
                                        // }
                                        onBlur={(event) => {
                                            formik1.handleBlur(event);
                                            formik1.setTouched({ ...formik1.touched, par_phone: true });
                                        }}
                                        inputProps={{id: 'par_phone'}}
                                        id="par_phone"
                                        inputClass={formik1.errors.par_phone && formik1.touched.par_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {formik1.errors.par_phone && formik1.touched.par_phone && <span className="error">{formik1.errors.par_phone}</span>}
                                </Col>





                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Contact Details End--------------- */}


                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-4' style={{ display: formik1.values.ex_par_key != '0' ? 'none' : 'block' }} >
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Parent App Login</h6>
                            </div>

                            <Row>
                                {/* --------------Student Username--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control type="text"
                                        value={formik1.values.pa_login_id}
                                        autoComplete="off"
                                       
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="pa_login_id"
                                       // onBlur={handleUsernameBlur1}
                                        className={formik1.errors.pa_login_id && formik1.touched.pa_login_id ? "input-error" : "mb-0"}
                                    />
                                    {formik1.errors.pa_login_id && formik1.touched.pa_login_id && <p className="error">{formik1.errors.pa_login_id}</p>}
                                </Col>

                                {/* --------------Student Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={formik1.values.pa_password}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="pa_password"
                                        className={formik1.errors.pa_password && formik1.touched.pa_password ? "input-error" : "mb-0"}
                                    />
                                    {formik1.errors.pa_password && formik1.touched.pa_password && <p className="error">{formik1.errors.pa_password}</p>}
                                </Col>


                                {/* --------------Student Confirm Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control type="password"
                                        autoComplete="new-password"

                                        value={formik1.values.pa_conform_password}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                        id="pa_conform_password"
                                        className={formik1.errors.pa_conform_password && formik1.touched.pa_conform_password ? "input-error" : "mb-0"} />
                                    {formik1.errors.pa_conform_password && formik1.touched.pa_conform_password && <p className="error">{formik1.errors.pa_conform_password}</p>}
                                </Col>





                            </Row>
                        </div>
                    </Stack>



                        </Modal.Body>
                        <Modal.Footer>

                            <div className="mx-auto d-flex gap-2" >

                                <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                                <Button variant="contained" type='submit' className="primary-filled " disabled={IsSubmittingParent}>Submit</Button>

                            </div>

                        </Modal.Footer>
                    </Form>
                </Modal>


                {/* --------------Page Start--------------- */}


                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className='page-title'> Student Particulars </h5>

                        <div role="presentation">
                            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                <Typography color="text.primary">Students</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className='d-flex gap-2'>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                        <div className='d-flex justify-content-end gap-2'>
                            <div>
                                <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                    <Dropdown.Item eventKey="1" onClick={handleClick} >  Student Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={handleClick_enroll}> Student Enrolments</Dropdown.Item>
                                    <Dropdown.Item eventKey="3"><NavLink to='/list-students' > Student List</NavLink></Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>

                </div>
                <Row className='g-3'>

                    <Col sm="12" lg="4">
                        {/* --------------Student Details Start--------------- */}


                        {/* --------------Card-1 Start--------------- */}

                        <Card className='mb-3'>
                            <Card.Body>

                                <div>
                                    <p>
                                        <ManageAccountsIcon className='me-2 prime-clr' />
                                        <span className='f-16'>
                                            Student Particulars
                                        </span>
                                    </p>
                                </div>

                                <div>

                                    <div className=''>
                                        <p className='mb-2 cus-name'>
                                            {StudentData && StudentData.st_first_name != null ? StudentData.st_first_name + ' ' + StudentData.st_surname : 'N/A'} (#{StudentData ? StudentData.pk_student_id : 0})
                                        </p>
                                    </div>
                                    <div className='d-flex flex-wrap justify-between-content'>
                                        <div className='flex-grow-1'>
                                            <p className='heading-card-cus'>
                                                Age
                                            </p>
                                            <p className='mb-0'>
                                                {StudentAge} years
                                            </p>
                                        </div>

                                        <div className='flex-grow-1'>
                                            <p className='heading-card-cus'>
                                                Year Level
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData ? StudentData.level_name != null && StudentData.level_name != '' ? StudentData.level_name : 'N/A' : 'N/A'}
                                            </p>
                                        </div>

                                        <div className='flex-grow-1'>
                                            <p className='heading-card-cus'>
                                                Date of Birth
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData && StudentData.st_dob != '' && StudentData.st_dob != null ? Moment(StudentData.st_dob).format('DD-MM-YYYY') : 'N/A'}
                                            </p>
                                        </div>

                                        {/* <Col lg="12" md="12" sm="12" className='position-relative'>
                                            <h6 className="form-lable required-field">Note</h6>
                                            <Form.Control as="textarea" rows={3}

                                                value={values.sn_note}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="sn_note"
                                                className={errors.sn_note && touched.sn_note ? "input-error" : ""}
                                            />
                                            {errors.sn_note && touched.sn_note && <p className="error">{errors.sn_note}</p>}
                                        </Col> */}
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>

                        {/* --------------Card-1 end--------------- */}

                        {/* --------------Card-2 Start--------------- */}

                        <Card className='mb-3'>
                            <Card.Body>


                                <div>
                                    <p>
                                        <ListAltIcon className='me-2 prime-clr' />
                                        <span className='f-16'>
                                            Initial Diagnostics Scores
                                        </span>
                                    </p>
                                </div>

                                <div className='d-flex flex-wrap justify-between-content'>
                                    <div className='flex-grow-1'>

                                        <div className='mb-2'>
                                            <p className='heading-card-cus'>
                                                Reading Age
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData && StudentData.la_reading_age != '' && StudentData.la_reading_age != null ? StudentData.la_reading_age : 0} years
                                            </p>
                                        </div>

                                        <div className=''>
                                            <p className='heading-card-cus'>
                                                Spelling Year Level
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData && StudentData.lable_spell_year != '' && StudentData.lable_spell_year != null ? StudentData.lable_spell_year : 'N/A'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='flex-grow-1'>
                                        <div className='mb-2'>
                                            <p className='heading-card-cus'>
                                                Maths Year Level
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData && StudentData.lable_maths_year != '' && StudentData.lable_maths_year != null ? StudentData.lable_maths_year : 'N/A'}
                                            </p>
                                        </div>


                                        <div>
                                            <p className='heading-card-cus'>
                                                Comprehension Age
                                            </p>
                                            <p className='mb-0'>
                                                {StudentData && StudentData.la_comprehension_age != '' && StudentData.la_comprehension_age != null ? StudentData.la_comprehension_age : 0} Year
                                            </p>
                                        </div>

                                    </div>
                                </div>


                            </Card.Body>
                        </Card>

                        {/* --------------Card-2 end--------------- */}

                        {/* --------------Card-3 Start--------------- */}

                        <Card className='mb-3'>
                            <Card.Body>

                                <div>

                                    <div>
                                        <p>
                                            <CallIcon className='me-2 prime-clr' />
                                            <span className='f-16'>
                                                Parents / Guardians 
                                            </span>
                                        </p>
                                    </div>

                                    <div>
                                        <div className="">
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <p className="mb-0 pb-2">{StudentData && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_firstname != '' && StudentData.par_firstname != null ? StudentData.par_firstname + ' ' + StudentData.par_surname : 'N/A' : 'Adult Learner'} <span className=''>({StudentData ? (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.ps_relationship != '' && StudentData.ps_relationship != null ? StudentData.ps_relationship : 'N/A' : 'No Relationship' : 'N/A'})</span> {StudentData && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_visibility != '' && StudentData.par_visibility != null && parseInt(StudentData.par_visibility)==1 ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge> : ''} </p>
                                                    <div className='d-flex flex-wrap justify-between-content'>
                                                        <p className="mb-0 me-3 flex-grow-1">
                                                            <a href={`https://wa.me/${StudentData ? StudentData.par_phone != '' && StudentData.par_phone != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_phone : '' : ''}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a>
                                                            {StudentData ? StudentData.par_phone != '' && StudentData.par_phone != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_phone : 'N/A' : ''}
                                                        </p>
                                                        <p className="mb-0 flex-grow-1">
                                                            <a href={`mailto:${StudentData && StudentData.par_emailid != '' && StudentData.par_emailid != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_emailid : 'userID@example.com'}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a>

                                                            {StudentData ? StudentData.par_emailid != '' && StudentData.par_emailid != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_emailid : 'N/A' : 'N/A'}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <p className="menu-icon"><Icon.ThreeDotsVertical onClick={() => (StudentData.st_is_adultlearner!=null && StudentData.st_is_adultlearner==0) && (StudentData.pk_par_key!=null && StudentData.pk_par_key!='') ? toggleModalpass() : ''}/></p> */}

                                                {/* {isDropdownVisible && (
                                                    <div className="Cusdropdown">
                                                    <div>Option 1</div>
                                                    <div>Option 2</div>
                                                    </div>
                                                )} */}
                                                {(StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) && (StudentData.pk_par_key != null && StudentData.pk_par_key != '') && (StudentData.par_visibility != '' && StudentData.par_visibility != null && parseInt(StudentData.par_visibility)==1) ?
                                                     <Dropdown>
                                                     <Dropdown.Toggle as={CustomToggle2} />
                                                     <Dropdown.Menu size="sm" title="">
                                                       <Dropdown.Item onClick={() => toggleModalpass()}>Manage Login</Dropdown.Item>
                                                       {/* <Dropdown.Item>abcd</Dropdown.Item>
                                                       <Dropdown.Item>erty</Dropdown.Item>
                                                       <Dropdown.Item>hnjm</Dropdown.Item> */}
                                                     </Dropdown.Menu>
                                                   </Dropdown>
                                                    
                                                    
                                                    // <div className="three-dot-menu">
                                                    //     <div className="three-dot-icon" onClick={toggleDropdown}>
                                                    //         <i className="fas fa-ellipsis-v"></i>
                                                    //     </div>
                                                    //     {isDropdownVisible && (
                                                    //         <div className="dropdown-options">
                                                    //             <div className='d-flex' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }} onClick={() => toggleModalpass()}>Manage Login</div>

                                                    //         </div>
                                                    //     )}
                                                    // </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        <span>

                                        </span>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>
                        
                        <Card className='mb-3'>
                            <Card.Body>

                                <div>

                                    <div>
                                        <p className=' d-flex justify-content-between'>
                                            <span><CallIcon className='me-2 prime-clr' />
                                            
                                                <span className='f-16 '>
                                                    Additional Parents / Guardians 
                                                    
                                                </span></span>
                                                
                                           <span className='d-flex justify-content-end'> {StudentSecParData && (StudentSecParData.pk_par_key != null && StudentSecParData.pk_par_key != '')  && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) && (StudentSecParData.par_visibility != '' && StudentSecParData.par_visibility != null && parseInt(StudentSecParData.par_visibility)==1)?
                                                    <Button variant="secondary" className="btn-sec-outline" onClick={() => handlePrimaryClick(StudentSecParData.pk_par_key)} >
                                                    Set Primary
                                                </Button>
                                                    :  externalLoading===false && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) && (parseInt(StudentSecParData.par_visibility)!=0)  ? <Button variant="secondary" onClick={() => toggleModal1()} className="btn-sec-outline" >
                                                    Add
                                                </Button> : null }</span>
                                        </p>
                                    </div>

                                    <div>
                                        <div className="">
                                        {StudentSecParData && (StudentSecParData.pk_par_key != null && StudentSecParData.pk_par_key != '') && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0)  ?
                                            <div className="d-flex justify-content-between">
                                                <div className="">
                                                    <p className="mb-0 pb-2">{StudentSecParData ? StudentSecParData.par_firstname != '' && StudentSecParData.par_firstname != null ? StudentSecParData.par_firstname + ' ' + StudentSecParData.par_surname : 'Adult Learner' : 'N/A'} <span className=''>({StudentSecParData ?  StudentSecParData.ps_relationship != '' && StudentSecParData.ps_relationship != null ? StudentSecParData.ps_relationship : 'No Relationship' :  'N/A'})</span>{ StudentSecParData ? StudentSecParData.par_visibility != '' && StudentSecParData.par_visibility != null && parseInt(StudentSecParData.par_visibility)==1 ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge> : ''}</p>
                                                    <div className='d-flex flex-wrap justify-between-content'>
                                                        <p className="mb-0 me-3 flex-grow-1">
                                                            <a href={`https://wa.me/${StudentSecParData ? StudentSecParData.par_phone != '' && StudentSecParData.par_phone != null ? StudentSecParData.par_phone : '' : ''}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i className="fa fa-whatsapp me-2"></i></a>
                                                            {StudentSecParData ? StudentSecParData.par_phone != '' && StudentSecParData.par_phone != null ? StudentSecParData.par_phone : 'N/A' : ''}
                                                        </p>
                                                        <p className="mb-0 flex-grow-1">
                                                            <a href={`mailto:${StudentSecParData && StudentSecParData.par_emailid != '' && StudentSecParData.par_emailid != null ? StudentSecParData.par_emailid : 'userID@example.com'}`} target="_blank" style={{ color: 'black', textDecoration: 'none', }}><i class="fa-solid fa-envelope me-2"></i></a>

                                                            {StudentSecParData ? StudentSecParData.par_emailid != '' && StudentSecParData.par_emailid != null ? StudentSecParData.par_emailid : 'N/A' : 'N/A'}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <p className="menu-icon"><Icon.ThreeDotsVertical onClick={() => (StudentData.st_is_adultlearner!=null && StudentData.st_is_adultlearner==0) && (StudentData.pk_par_key!=null && StudentData.pk_par_key!='') ? toggleModalpass() : ''}/></p> */}

                                                {/* {isDropdownVisible && (
                                                    <div className="Cusdropdown">
                                                    <div>Option 1</div>
                                                    <div>Option 2</div>
                                                    </div>
                                                )} */}
                                                {(StudentSecParData.par_firstname != '' && StudentSecParData.par_firstname != null) && (StudentSecParData.pk_par_key != null && StudentSecParData.pk_par_key != '') && (StudentSecParData.par_visibility != null && StudentSecParData.par_visibility != '' && parseInt(StudentSecParData.par_visibility)==1)?
                                                    <Dropdown>
                                                    <Dropdown.Toggle as={CustomToggle} />
                                                    <Dropdown.Menu size="sm" title="">
                                                      <Dropdown.Item onClick={() => toggleModalpass2()}>Manage Login</Dropdown.Item>
                                                      <Dropdown.Item onClick={() => handleDeleteClick(StudentSecParData.pk_par_key)}>Remove</Dropdown.Item>
                                                      {/* <Dropdown.Item>abcd</Dropdown.Item>
                                                      <Dropdown.Item>erty</Dropdown.Item>
                                                      <Dropdown.Item>hnjm</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                    // <div className="three-dot-menu">
                                                    //     <div className="three-dot-icon" onClick={toggleDropdown1}>
                                                    //         <i className="fas fa-ellipsis-v"></i>
                                                    //     </div>
                                                    //     {isDropdownVisible1 && (
                                                    //         <div className="dropdown-options">
                                                    //             <div className='d-flex' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }} onClick={() => toggleModalpass2()}>Manage Login</div>

                                                    //         </div>
                                                    //     )}
                                                    // </div>
                                                    : ''}
                                            </div>
                                             : <p>No Additional Parents / Guardians Details</p>}


                                        </div>

                                       
                                        <span>

                                        </span>
                                    </div>

                                </div>

                            </Card.Body>
                        </Card>


                        {/* --------------Card-4 Start--------------- */}

                        <Card className='mb-3'>
                            <Card.Body>

                                <div>

                                    <div>
                                        <p>
                                            <AccountBalanceIcon className='me-2 prime-clr' />
                                            <span className='f-16'>
                                                Accounts
                                            </span>
                                        </p>
                                    </div>

                                    <Table responsive className="mb-0">

                                        <thead>
                                            <tr>
                                                <th className='p-0'>Credit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</th>
                                                <th className='p-0'>Owing ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</th>
                                                <th className='p-0'>Paid ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</th>
                                                <th className='p-0' style={{display:'none'}}>Lessons In Credit</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border-0-cus'>
                                            <tr>
                                                {/* <td className='p-0'>{StudentPayData && StudentPayData.total_reg_fees != '' && StudentPayData.total_reg_fees != null ? (parseInt(StudentPayData.total_reg_fees)+parseInt(StudentPayData.total_deposit)+parseInt(StudentPayData.total_fees)) : parseInt(0.00)}</td>
                                                <td className='p-0'>{StudentPayData && StudentPayData.total_reg_fees != '' && StudentPayData.total_reg_fees != null ? (parseInt(StudentPayData.total_reg_fees)+parseInt(StudentPayData.total_deposit)+parseInt(StudentPayData.total_fees)) - (StudentPayData && StudentPayData.paid_total != '' && StudentPayData.paid_total != null ? parseInt(StudentPayData.paid_total) : parseInt(0.00)): parseInt(0.00) }</td>
                                                <td className='p-0'>{StudentPayData && StudentPayData.paid_total != '' && StudentPayData.paid_total != null ? StudentPayData.paid_total : parseInt(0.00)}</td> */}

                                                <td className='p-0'>{TotalAmountPaid > 0 || PendingAmount > 0 ? (parseFloat(TotalAmountPaid)+parseFloat(PendingAmount)) : 0.00}</td>
                                                <td className='p-0'>{PendingAmount > 0 ? parseFloat(PendingAmount) : 0.00 }</td>
                                                <td className='p-0'>{TotalAmountPaid > 0 ? parseFloat(TotalAmountPaid) : 0.00}</td>
                                                <td className='p-0' style={{display:'none'}}><Badge bg="success">Active</Badge></td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>

                            </Card.Body>
                        </Card>

                        {/* --------------Card-4 end--------------- */}

                        <Card className=''>
                            <Card.Body>

                                <div>

                                    <div>
                                        <p>
                                            <AccountBalanceWalletIcon className='me-2 prime-clr' />
                                            <span className='f-16'>
                                                Payment Frequency
                                            </span>
                                        </p>
                                    </div>

                                    <Table responsive className="mb-0">

                                        <thead>
                                            <tr>
                                                <th className='p-0'>Payment Type</th>
                                                <th className='p-0'>Payment Day</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border-0-cus'>
                                            <tr>
                                                <td className='p-0'>{StudentData && (StudentData.sp_pay_type != '' && StudentData.sp_pay_type != null) && (StudentData.sp_start_ofDay != '' && StudentData.sp_start_ofDay != null)  ? parseInt(StudentData.sp_pay_type)==1 ? 'Weekly' : 'Monthly' : 'N/A'}</td>
                                                <td className='p-0'>{StudentData && StudentData.sp_start_ofDay != '' && StudentData.sp_start_ofDay != null ? parseInt(StudentData.sp_pay_type)==1 ? getDayOfWeek(StudentData.sp_start_ofDay) : getDayWithSuffix(StudentData.sp_start_ofDay) : 'N/A'}</td>
                                                {/* <td className='p-0'><Badge bg="success">Active</Badge></td> */}
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>

                            </Card.Body>
                        </Card>

                        {/* --------------Student Details End--------------- */}
                    </Col>



                    <Col sm="12" lg="8">
                        {/* --------------Tabs Section-2 Start--------------- */}

                        <Card className='my-card mb-3 h-100'>

                            <Card.Body>

                                {/* --------------Tabs Section-1 Start--------------- */}

                                <div className="">

                                    <Tab.Container defaultActiveKey="first">

                                        <Nav variant="pills" className="flex-row rounded-2 text-dark">
                                            <Nav.Item className="">
                                                <Nav.Link eventKey="first" className="d-flex rounded-2 px-2">
                                                    <i class="fa-solid fa-book-open me-md-2 my-auto"></i>
                                                    <p className="mb-0 d-none d-md-block">Lessons </p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="">
                                                <Nav.Link eventKey="second" className="d-flex rounded-2 px-3">
                                                    <i class="fa-solid fa-bullseye me-md-2 my-auto"></i>
                                                    <p className="mb-0 d-none d-md-block">Goals</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="">
                                                <Nav.Link eventKey="third" className="d-flex rounded-2 px-3">
                                                    <i class="fa-solid fa-note-sticky me-md-2 my-auto"></i>
                                                    <p className="mb-0 d-none d-md-block">Notes</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="">
                                                <Nav.Link eventKey="four" className="d-flex rounded-2 px-3">
                                                    <i class="fa-solid fa-book-reader me-md-2 my-auto"></i>
                                                    <p className="mb-0 d-none d-md-block">myGooRoo Points</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="">
                                                <Nav.Link eventKey="five" className="d-flex rounded-2 px-3">
                                                    <i class="fa-solid fa-book-open me-md-2 my-auto"></i>
                                                    <p className="mb-0 d-none d-md-block">Test Details</p>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        {/* --------------Toggle Selector End--------------- */}

                                        {/* --------------Toggle Content Start--------------- */}

                                        <Tab.Content className='mt-3'>
                                            {/* --------------Toggle One Start--------------- */}
                                            <Tab.Pane eventKey="first" className="">

                                                <Stack gap={3} >
                                                    <div className="mb-3">

                                                        <div className="cus-les-sec">

                                                            <div className='cus-les-left'>
                                                                <div className="cus-les-sd">
                                                                    <Form>

                                                                        {/* --------------Start Date--------------- */}
                                                                        <Form.Group className="form-group d-flex align-items-center" controlId="formsdate">
                                                                            <Form.Label className="pe-2 mb-0">
                                                                                <p className="mb-0">Start Date</p>
                                                                            </Form.Label>
                                                                            <Col className="">
                                                                                {/* <input type="date" className="form-control mb-0" onChange={handleStartDateChange} value={startDate} /> */}
                                                                                <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                                                                        <DatePicker  views={['year', 'month', 'day']}
                                                                                            value={dayjs(startDate, 'YYYY-MM-DD')}
                                                                                            onChange={handleStartDateChange}
                                                                                            // minDate={dayjs()}
                                                                                            format="DD-MM-YYYY"
 />
                                                                                    </LocalizationProvider>
                                                                            </Col>
                                                                        </Form.Group>

                                                                    </Form>
                                                                </div>

                                                                <div className="cus-les-ed">
                                                                    <Form>

                                                                        {/* --------------End Date--------------- */}
                                                                        <Form.Group className="form-group d-flex align-items-center" controlId="formedate">
                                                                            <Form.Label className="pe-2 mb-0">
                                                                                <p className="mb-0">End Date</p>
                                                                            </Form.Label>
                                                                            <Col className="">
                                                                                {/* <input type="date" className="form-control mb-0" onChange={handleEndDateChange} value={endDate} /> */}
                                                                                <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                                                                    <DatePicker  views={['year', 'month', 'day']}
                                                                                        value={dayjs(endDate, 'YYYY-MM-DD')}
                                                                                        onChange={handleEndDateChange}
                                                                                        minDate={dayjs(startDate, 'YYYY-MM-DD')}
                                                                                        format="DD-MM-YYYY"
                                                                                        />
                                                                                </LocalizationProvider>
                                                                            </Col>
                                                                        </Form.Group>

                                                                    </Form>
                                                                </div>
                                                            </div>

                                                            <div className='cus-les-right'>
                                                             

                                                                <div className="cus-les-rst">
                                                                    <Button variant="secondary" className="btn-sec-outline" onClick={handleDateReset}>
                                                                        Reset
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Stack>

                                                {/* <DataTableExtensions {...tableData}> */}

                                                <Row>
                                                    <Col sm={12}>
                                                        <Box
                                                            sx={{
                                                                height: "fit-content",
                                                                width: "100%",
                                                                "& .actions": {
                                                                    color: "text.secondary",
                                                                },
                                                                "& .textPrimary": {
                                                                    color: "text.primary",
                                                                },
                                                                "& .super-app-theme--header": {
                                                                    backgroundColor: "#f4f4f4",
                                                                },
                                                            }}
                                                            style={{ height: '100%', width: "100%" }}
                                                        >
                                                           <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                                //sx={{ border: "none", m: 2 }}
                                                                autoHeight
                                                                slots={{
                                                                    loadingOverlay: LinearProgress,
                                                                    //noRowsOverlay: CustomNoRowsOverlay,
                                                                    toolbar: GridToolbar,
                                                                    // toolbar: EditToolbar,
                                                                }}
                                                                slotProps={{
                                                                    toolbar: {
                                                                        csvOptions: { fileName: "LessonList" },
                                                                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "LessonList" },
                                                                        lessonRecords,
                                                                        showQuickFilter: true
                                                                    },


                                                                }}
                                                                initialState={{
                                                                    ...lessonRecords.initialState,
                                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                                }}
                                                                showQuickFilter={true}

                                                                pageSizeOptions={[10, 25, 50, 100]}

                                                                loading={loading}
                                                                rows={lessonRecords.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                                columns={columnsLesson}
                                                                rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                                                                onRowClick={(params) => handleLessonViewClick(params.row)}


                                                            />


                                                        </Box>


                                                    </Col>
                                                </Row>

                                                {/* </DataTableExtensions> */}
                                            </Tab.Pane>
                                            {/* --------------Toggle One End--------------- */}

                                            {/* --------------Toggle Second Start--------------- */}
                                            <Tab.Pane eventKey="second">
                                                <Row className="">
                                                    <Col style={{ display: 'none' }}>
                                                        <FloatingLabel controlId="floatingTextarea2" label="Strengths">
                                                            <Form.Control
                                                                as="textarea"

                                                                style={{ height: '200px' }}
                                                            />
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col style={{ display: 'none' }}>
                                                        <FloatingLabel controlId="floatingTextarea2" label="Weaknesses">
                                                            <Form.Control
                                                                as="textarea"

                                                                style={{ height: '200px' }}
                                                            />
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col>
                                                        <Form.Group className="mb-2" controlId="floatingTextarea2" >
                                                            <Form.Label>Goals</Form.Label>
                                                            <Form.Control as="textarea"
                                                                value={StudentData ? StudentData.la_goals : ''}

                                                                style={{ height: '200px' }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            {/* --------------Toggle Second End--------------- */}

                                            {/* --------------Toggle Third Start--------------- */}
                                            <Tab.Pane eventKey="third">

                                                <Stack gap={3}>
                                                    <div className="mb-3">
                                                        <Row>

                                                            <Col md={12} lg={8}>
                                                                {/* 
                                                            <Row>
                                                                <Col xs={12} md={6} lg={6}>
                                                                    <Form>

                                                                    
                                                                        <Form.Group className="d-flex align-items-center" controlId="formsdate">
                                                                            <Form.Label className="my-auto me-2">
                                                                                <p className="mb-0">Start Date</p>
                                                                            </Form.Label>
                                                                            <Col className="mx-0">
                                                                                <input type="date" className="form-control mb-0" />
                                                                            </Col>
                                                                        </Form.Group>

                                                                    </Form>
                                                                </Col>

                                                                <Col xs={12} md={6} lg={6}>
                                                                    <Form>

                                                                    
                                                                        <Form.Group className="d-flex align-items-center" controlId="formedate">
                                                                            <Form.Label className="my-auto me-2">
                                                                                <p className="mb-0">End Date</p>
                                                                            </Form.Label>
                                                                            <Col className="mx-0">
                                                                                <input type="date" className="form-control mb-0" />
                                                                            </Col>
                                                                        </Form.Group>

                                                                    </Form>
                                                                </Col>
                                                            </Row> */}

                                                            </Col>


                                                            <Col md={12} lg={4} className="d-flex justify-content-end align-self-center">

                                                                <Button variant="primary" className="me-2" size="sm" onClick={() => setModalShow(true)}>
                                                                    Add Notes
                                                                </Button>
                                                                {/* <Button  variant="secondary" className="btn-sec-outline" onClick={toggleModal1}>
                                                                Unenrol
                                                            </Button> */}

                                                            </Col>

                                                        </Row>
                                                    </div>
                                                </Stack>




                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#f4f4f4",
                                                        },
                                                    }}
                                                    style={{ height: '100%', width: "100%" }}
                                                >
                                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                        //sx={{ border: "none", m: 2 }}
                                                        autoHeight
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            //noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "NoteList" },
                                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "NoteList" },
                                                                note_data,
                                                                showQuickFilter: true
                                                            },


                                                        }}
                                                        initialState={{
                                                            ...note_data.initialState,
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        showQuickFilter={true}

                                                        pageSizeOptions={[10, 25, 50, 100]}

                                                        loading={loading}
                                                        rows={note_data.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                        columns={columns_notes}
                                                        rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                                                    />


                                                </Box>

                                            </Tab.Pane>
                                            {/* --------------Toggle Third End--------------- */}
                                            {/* --------------Toggle One Start--------------- */}
                                            <Tab.Pane eventKey="four" className="">

                                                <Stack gap={3}>
                                                    <div className="mb-3">
                                                        <Row>

                                                            {/* <Col md={12} lg={8}>

                                                                <Row>
                                                                    <Col xs={12} md={6} lg={6}>
                                                                        <Form>

                                                                        
                                                                            <Form.Group className="d-flex align-items-center" controlId="formsdate">
                                                                                <Form.Label className="my-auto me-2">
                                                                                    <p className="mb-0">Start Date</p>
                                                                                </Form.Label>
                                                                                <Col className="mx-0">
                                                                                    <input type="date" className="form-control mb-0" />
                                                                                </Col>
                                                                            </Form.Group>

                                                                        </Form>
                                                                    </Col>

                                                                    <Col xs={12} md={6} lg={6}>
                                                                        <Form>

                                                                            
                                                                            <Form.Group className="d-flex align-items-center" controlId="formedate">
                                                                                <Form.Label className="my-auto me-2">
                                                                                    <p className="mb-0">End Date</p>
                                                                                </Form.Label>
                                                                                <Col className="mx-0">
                                                                                    <input type="date" className="form-control mb-0" />
                                                                                </Col>
                                                                            </Form.Group>

                                                                        </Form>
                                                                    </Col>
                                                                </Row>

                                                            </Col> */}


                                                            {/* <Col md={12} lg={4} className="d-flex justify-content-end align-self-center">

                                                                <Button  variant="primary" className="me-2" onClick={() => setModalShowAdd(true)}>
                                                                    Enrolment
                                                                </Button>
                                                                <Button  variant="secondary" className="btn-sec-outline" onClick={toggleModal1}>
                                                                    Unenrol
                                                                </Button>

                                                            </Col> */}

                                                        </Row>
                                                    </div>
                                                </Stack>




                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#f4f4f4",
                                                        },
                                                    }}
                                                    style={{ height: '100%', width: "100%" }}
                                                >
                                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                        //sx={{ border: "none", m: 2 }}
                                                        autoHeight
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            //noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "PointList" },
                                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PointList" },
                                                                CoinRecords,
                                                                showQuickFilter: true
                                                            },


                                                        }}
                                                        initialState={{
                                                            ...CoinRecords.initialState,
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        showQuickFilter={true}

                                                        pageSizeOptions={[10, 25, 50, 100]}

                                                        loading={loading}
                                                        rows={CoinRecords.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                        columns={columnsCoin}
                                                        rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                                                    />


                                                </Box>

                                            </Tab.Pane>
                                            {/* --------------Toggle One End--------------- */}

                                            {/* --------------Toggle One Start--------------- */}
                                            <Tab.Pane eventKey="five" className="">

                                                <Stack gap={3}>
                                                    <div className="mb-3">
                                                        <Row>

                                                            {/* <Col md={12} lg={8}>

                                                                <Row>
                                                                    <Col xs={12} md={6} lg={6}>
                                                                        <Form>

                                                                        
                                                                            <Form.Group className="d-flex align-items-center" controlId="formsdate">
                                                                                <Form.Label className="my-auto me-2">
                                                                                    <p className="mb-0">Start Date</p>
                                                                                </Form.Label>
                                                                                <Col className="mx-0">
                                                                                    <input type="date" className="form-control mb-0" />
                                                                                </Col>
                                                                            </Form.Group>

                                                                        </Form>
                                                                    </Col>

                                                                    <Col xs={12} md={6} lg={6}>
                                                                        <Form>

                                                                            
                                                                            <Form.Group className="d-flex align-items-center" controlId="formedate">
                                                                                <Form.Label className="my-auto me-2">
                                                                                    <p className="mb-0">End Date</p>
                                                                                </Form.Label>
                                                                                <Col className="mx-0">
                                                                                    <input type="date" className="form-control mb-0" />
                                                                                </Col>
                                                                            </Form.Group>

                                                                        </Form>
                                                                    </Col>
                                                                </Row>

                                                            </Col> */}


                                                            {/* <Col md={12} lg={4} className="d-flex justify-content-end align-self-center">

                                                                <Button  variant="primary" className="me-2" onClick={() => setModalShowAdd(true)}>
                                                                    Enrolment
                                                                </Button>
                                                                <Button  variant="secondary" className="btn-sec-outline" onClick={toggleModal1}>
                                                                    Unenrol
                                                                </Button>

                                                            </Col> */}

                                                        </Row>
                                                    </div>
                                                </Stack>


                                                <Box
                                                    sx={{
                                                        height: "fit-content",
                                                        width: "100%",
                                                        "& .actions": {
                                                            color: "text.secondary",
                                                        },
                                                        "& .textPrimary": {
                                                            color: "text.primary",
                                                        },
                                                        "& .super-app-theme--header": {
                                                            backgroundColor: "#f4f4f4",
                                                        },
                                                    }}
                                                    style={{ height: '100%', width: "100%" }}
                                                >
                                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                        //sx={{ border: "none", m: 2 }}
                                                        autoHeight
                                                        slots={{
                                                            loadingOverlay: LinearProgress,
                                                            //noRowsOverlay: CustomNoRowsOverlay,
                                                            toolbar: GridToolbar,
                                                            // toolbar: EditToolbar,
                                                        }}
                                                        slotProps={{
                                                            toolbar: {
                                                                csvOptions: { fileName: "TestList" },
                                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "TestList" },
                                                                TestRecords,
                                                                showQuickFilter: true
                                                            },


                                                        }}
                                                        initialState={{
                                                            ...TestRecords.initialState,
                                                            pagination: { paginationModel: { pageSize: 10 } },
                                                        }}
                                                        showQuickFilter={true}

                                                        pageSizeOptions={[10, 25, 50, 100]}

                                                        loading={loading}
                                                        rows={TestRecords.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                        columns={columns_test}
                                                        rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                                                    />


                                                </Box>


                                              

                                            </Tab.Pane>
                                            {/* --------------Toggle One End--------------- */}
                                        </Tab.Content>
                                        {/* --------------Toggle Content Start--------------- */}
                                    </Tab.Container>

                                </div>

                                {/* --------------Tabs Section-1 End--------------- */}


                            </Card.Body>

                        </Card>

                        {/* --------------Tabs Section-2 End--------------- */}
                    </Col>
                </Row>



                {/* --------------Page End--------------- */}

            </div>
        </>
    );
}

export default ViewStudent;