/*
Purpose: list details of report with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/



import { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import DataTable from 'react-data-table-component';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function ReportRevenue() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'Opportunity ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Expected Close Date',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Opportunity Name',
      selector: 'discription',
      sortable: true,
    },
    {
      name: 'Account Number',
      selector: 'account',
      sortable: true,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
    },

  ];
  const data = [];
  const customeStyle = {
    headRow: {
      style: {
        borderRadius: 'var(--br-3xs)',
        backgroundColor: 'var(--color-gainsboro-400)'
      }
    }
  };

  const [records, setRecords] = useState(data);


  return (

    <div className="">

      {/* --------------Page Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Expected Revenue Reports  </h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>
              {
                (sessionStorage.getItem('is_admin') == 0) ?
                  (<NavLink underline="hover" color="inherit" to='/reports'> Reports</NavLink>)
                  : ''
              }
              <Typography color="text.primary">Expected Revenue Reports </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2'>
          {
            (sessionStorage.getItem('is_admin') == 0) ?
              (<Button onClick={() => navigate('/reports')} className="secondary-filled" variant="contained"> Back </Button>
              )
              :  (sessionStorage.getItem('is_admin') == 1) ? (<Button onClick={() => navigate('/admin/admin-list')} className="secondary-filled" variant="contained"> Back </Button>
              ) : ''
          }
           

        </div>

      </div>
      {/* --------------Section Start--------------- */}

      <Card className='card my-card mb-3'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <div className="d-flex flex-wrap justify-content-end mb-3">

                      <div class="form-group has-search my-auto">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control mb-0"  />
                      </div>


                    </div>

                  </Col>
                  {/* --------------Table Content Start--------------- */}



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <DataTable
                     progressPending={loading} 
                      columns={columns}
                      data={records}
                      fixedHeader
                      pagination
                      customStyles={customeStyle}
                     className="cursor-pointer  list-cus-h"
                    />

                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>

  );
}

export default ReportRevenue;