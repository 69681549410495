/*
Purpose: Header of AP
Developer: BY P.KS
Date:22/08/2023
Project: TCMS
*/

/*
Purpose: Header of AP
Designer: R.Sanjai
Date:23/08/2023
Project: TCMS
*/

import React, { useState,useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { Dropdown, ButtonGroup, Image } from 'react-bootstrap';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Drawer from '@mui/material/Drawer';

const Header = ({ isOpen, toggleSidebar }) => {

    ///Restrict for Login component
    const location = useLocation();
    const navigate = useNavigate();

    // List of routes where the header should not be displayed
    const headerExcludedRoutes = ['/admin'];


    //default sidebar
    const url = location.pathname + location.search;

    if (headerExcludedRoutes.includes(location.pathname)) {
        return null; // Don't render the header
    }

    const handleLogout = () => {
        // Clear the sessionStorage
        sessionStorage.clear();
        // Redirect to the login page
        navigate("/admin/login");
    };




    //const [data, setData] = useState([]); // Unconditionally call useState
    if (!sessionStorage.getItem('key')) {
            navigate("/admin/login");
     }


    //   if (sessionStorage.getItem('key') == 0) {
    //     const new_data = [
    //       {
    //         user_name: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
    //         account_type: "Super Admin"
    //       }
    //     ];
    //     setData(new_data);
    //   } else {
    //     const new_data = [
    //       {
    //         user_name: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
    //         account_type: "Center Administrator"
    //       }
    //     ];
    //     setData(new_data);
    //   }




    var profilecentreimageUrl = 'myGooRoo-dark.png';
    if (sessionStorage.getItem('cent_profile') != 'null' && sessionStorage.getItem('cent_profile') != null && sessionStorage.getItem('cent_profile') != '') {

         const fullImageUrlprofimagect = `${sessionStorage.getItem('cent_profile')}`;

         profilecentreimageUrl = fullImageUrlprofimagect;
    }
  

    return (
        <Drawer sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
            },
        }}
            componentsProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
            variant="persistent"
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}  >
            <header>

                <div className=''>
                    <div id="sidebar">
                        
                        <div className='site-identity d-flex align-item-top'>

                        
                            <Image src={profilecentreimageUrl} width={108} className="img-fluid mx-auto d-block" />    
                        </div>
                        <Dropdown className='menu-dd mb-3'><Dropdown as={ButtonGroup} align="end" size='lg' id="dropdown-menu-align-responsive-1"
                            className="d-flex gap-2 align-items-center">

                            {/* <img src="icons/avatar-6.jpg" alt="Profile" className='profile-img float-start'></img> */}
                            <div className='text-start'>

                                <h6 className="mb-0"> {sessionStorage.getItem('center_name') } </h6>
                                <small className="mb-0"> Administrator </small>
                            </div>

                        </Dropdown> </Dropdown>
                   

                       


                        <ul className='menu-list mb-0 list-unstyled' >
                            <li className='menu-item'>

                                <NavLink activeClassName="active" className={url == '/admin/Add-admin' || url == '/admin/Edit-admin' ? 'active' : ''} to='/admin/admin-list'>
                                    <GroupIcon className='menu-icon' /> Centres</NavLink>
                            </li>
                            {/* <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>
                                <NavLink to='/ListUser'>
                                    <GroupIcon className='menu-icon' /> User</NavLink>
                            </li> */}

                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                            <NavLink className={url == '/admin/ListBaner'? 'active' : ''} to='/admin/ListBanner'>
                            <SummarizeIcon className='menu-icon' /> Banners</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                            <NavLink className={url == '/admin/ListForm'? 'active' : ''} to='/admin/ListForm'>
                            <ArticleIcon className='menu-icon' /> Forms</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                                <NavLink className={url == '/admin/ListContent'? 'active' : ''} to='/admin/ListContent'>
                                <SummarizeIcon className='menu-icon' /> CMS</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                            <NavLink className={url == '/admin/ListScheduledMaintenance'? 'active' : ''} to='/admin/ListScheduledMaintenance'>
                            <ArticleIcon className='menu-icon' />SM</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                            <NavLink className={url == '/admin/ListDiagnosticType'? 'active' : ''} to='/admin/ListDiagnosticType'>
                            <ArticleIcon className='menu-icon' />Diagnostic Test</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>

                            <NavLink className={url == '/admin/ListDiagnosticMaster'? 'active' : ''} to='/admin/ListDiagnosticMaster'>
                            <ArticleIcon className='menu-icon' />Diagnostic Type</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>
                                <NavLink activeClassName="active" to='/admin/ListYearLevel'>
                                    <CalendarMonthIcon className='menu-icon' /> Year Level</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>
                                <NavLink exact activeClassName="active" to='/admin/AdminReport'>
                                    <SummarizeIcon className='menu-icon' />Report</NavLink>
                            </li>
                            

                        </ul>
                       

                        <div className='logout-section'>

                            <div className=''>
                                <div className='pad-btn w-100 cursor-pointer' variant="contained" aria-label="delete" size="small" onClick={handleLogout}>
                                    <PowerSettingsNewIcon className='me-1 ' /> Logout
                                </div>
                            </div>
                        </div>

                        <div className=''>

                            <div className=''>
                                <div className='pad-btn w-100 d-flex' variant="contained" aria-label="delete" size="small">
                                    <p className='power-cus w-100 '>Powered by</p> 
                                    <Image src='myGooRoo-dark.png'  className="img-fluid d-block powbyCss" />  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </Drawer >
    );

}

export default Header;