/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import "react-phone-input-2/lib/style.css"; // Import CSS styles for the phone input

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { security_key, BASE_URL } from "../global";

function CentreWhatsApp() {
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();
    const [CentreData, setCentreData] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== "") {
            fetchData(extractedId);
        }
    }, [location]);
    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "getCenterQuickBookDetails",
                pk_cent_key: sessionStorage.getItem("key"),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCentreData(response.data.data[0]);
                  
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    //  const currentDate = new Date(); // Get the current date and time
    // const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " "); // Format it as "YYYY-MM-DD HH:mm:ss"

    /*
        const resetFormFields = (resetForm, setValues) => {
           // resetForm(); // Reset Formik-controlled fields
            setValues({
             //   ...initialValues, // Reset select fields
            });
        };*/

    // });
    
    const basicSchema = yup.object().shape({
        qb_authorizationRequestUrl: yup.string().required("This field is required"),
        qb_tokenEndPointUrl: yup.string().required("This field is required"),
        qb_client_id: yup.string().required("This field is required"),
        qb_client_secret: yup.string().required("This field is required"),
        qb_oauth_scope: yup.string().required("This field is required"),
        qb_oauth_redirect_uri: yup.string().required("This field is required"),
        realm_id: yup.string().required("This field is required"),
    });
    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched } = useFormik({
        initialValues: {
            /*tbl_certre*/
            qb_authorizationRequestUrl: CentreData ? CentreData.qb_authorizationRequestUrl : "",
            qb_tokenEndPointUrl: CentreData ? CentreData.qb_tokenEndPointUrl : "",
            qb_client_id: CentreData ? CentreData.qb_client_id : "",
            qb_client_secret: CentreData ? CentreData.qb_client_secret : "",
            qb_oauth_scope: CentreData ? CentreData.qb_oauth_scope : "",
            qb_oauth_redirect_uri: CentreData ? CentreData.qb_oauth_redirect_uri : "",
            realm_id: CentreData ? CentreData.realm_id : "",
            /*Common*/
            fk_cent_key: sessionStorage.getItem("key"),
        },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            setIsSubmitting(true);
            
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "updateCenterQuickBook",
                    ...formValues,
                });

                if (response.data.success === true) {
                    // Reset the form
                    
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/QuickBookApi");
                        }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }
                } else {
                    toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button
            } finally {
                setIsSubmitting(false); // Re-enable the submit button
            }
        },
    });

    

    return (
        <>
            <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className="page-title"> QuickBooks API</h5>
                            <div role="presentation">
                                <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                                    <NavLink underline="hover" color="inherit" to="/calendar">
                                        {" "}
                                        Home
                                    </NavLink>

                                    <Typography color="text.primary">QuickBooks API</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>

                    <Stack className="mt-4">
                        <div className="my-card p-4 bg-white">
                            <div className="section-heading border-bottom pb-3 mb-3">
                                <h6 className="mb-0">QuickBooks API Setting</h6>
                            </div>
                            <Row>
                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Authorization Request Url</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_authorizationRequestUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_authorizationRequestUrl"
                                        className={errors.qb_authorizationRequestUrl && touched.qb_authorizationRequestUrl ? "input-error" : ""}
                                    />
                                    {errors.qb_authorizationRequestUrl && touched.qb_authorizationRequestUrl && (
                                        <p className="error">{errors.qb_authorizationRequestUrl}</p>
                                    )}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Token End Point Url</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_tokenEndPointUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_tokenEndPointUrl"
                                        className={errors.qb_tokenEndPointUrl && touched.qb_tokenEndPointUrl ? "input-error" : ""}
                                    />
                                    {errors.qb_tokenEndPointUrl && touched.qb_tokenEndPointUrl && <p className="error">{errors.qb_tokenEndPointUrl}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Client ID</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_client_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_client_id"
                                        className={errors.qb_client_id && touched.qb_client_id ? "input-error" : ""}
                                    />
                                    {errors.qb_client_id && touched.qb_client_id && <p className="error">{errors.qb_client_id}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Client Secret</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_client_secret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_client_secret"
                                        className={errors.qb_client_secret && touched.qb_client_secret ? "input-error" : ""}
                                    />
                                    {errors.qb_client_secret && touched.qb_client_secret && <p className="error">{errors.qb_client_secret}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Oauth Scope</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_oauth_scope}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_oauth_scope"
                                        className={errors.qb_oauth_scope && touched.qb_oauth_scope ? "input-error" : ""}
                                    />
                                    {errors.qb_oauth_scope && touched.qb_oauth_scope && <p className="error">{errors.qb_oauth_scope}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Oauth Redirect Uri</h6>
                                    <Form.Control
                                        type="text"
                                        value={values.qb_oauth_redirect_uri}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_oauth_redirect_uri"
                                        className={errors.qb_oauth_redirect_uri && touched.qb_oauth_redirect_uri ? "input-error" : ""}
                                    />
                                    {errors.qb_oauth_redirect_uri && touched.qb_oauth_redirect_uri && <p className="error">{errors.qb_oauth_redirect_uri}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Realm ID</h6>
                                    <Form.Control type="number" min={0} value={values.realm_id} onChange={handleChange} onBlur={handleBlur} id="realm_id" />
                                    {errors.realm_id && touched.realm_id && <p className="error">{errors.realm_id}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>
                                Save
                            </Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default CentreWhatsApp;
