/*
Purpose: Edit session form
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import { useNavigate,  NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import Select from "react-select";

import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import axios from "axios";
import {
    security_key, options_duration 
, BASE_URL} from "../global";



const basicSchema = yup.object().shape({
    sess_title: yup.string().required("Title is required."),
    sess_date: yup.date().required("Date is required."),
    sess_tutor: yup.mixed().required("Tutor is required."),
    sess_notes: yup.mixed().required("Notes is required."),
    sess_type: yup.mixed().required("Type is required."),
    sess_time: yup.mixed().required("Time is required."),
    sess_duration: yup.mixed().required("Duration is required."),

   
});

function EditSession() {

    const [options_tutor, setOptions] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const handleSave = () => {
        // navigate("/list-tutor");
    };



    const currentDate = new Date(); // Get the current date and time
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " "); // Format it as "YYYY-MM-DD HH:mm:ss"
   


    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_tutor*/
        sess_title: "",
        sess_date: "",
        sess_tutor: "",
        sess_notes: "",
        sess_type: "",
        sess_time: "",
        sess_duration: "",

        /*Common*/
        created_by: "",
        created_on: formattedDate,
        updated_by: "0",
        updated_on: formattedDate,
        visibility: "1",
        fk_cent_key: sessionStorage.getItem('key'),
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        // const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
           
    
                    try {
                        const response = await axios.post(BASE_URL+"API/api_index.php", {
                            security_key: security_key,
                            mode: "createSessionTime",
                            ...formValues,
                        });

                        if (response.data.success === true) {



                            if (response.data.data === 1) {
                                resetFormFields(resetForm, setValues, initialValues);
                                toast.success("Success! Your form was submitted", {
                                    autoClose: 3000, // 3 seconds in this example
                                });

                                // After 3 seconds, redirect to another page
                                setTimeout(function () {
                                    navigate("/list-tutor");
                                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                                setIsSubmitting(false); // Re-enable the submit button
                            } else {
                                toast.error("Oops! Something went wrong.", {
                                    autoClose: 3000, // 3 seconds in this example
                                });

                                setIsSubmitting(false); // Re-enable the submit button
                            }

                        } else {
                            toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                                autoClose: 3000, // 3 seconds in this example
                            });

                            setIsSubmitting(false); // Re-enable the submit button
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } finally {
                        setIsSubmitting(false); // Re-enable the submit button
                    }

               

                    setIsSubmitting(false); // Re-enable the submit button
                }

                // You can also perform further actions based on the API response
                // For example, show a success message or redirect to another page.
           



 
    });

   

    const options_type = [
        { value: "1", label: "Online" },
        { value: "2", label: "In-centre" },

      ];


      useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL+'API/api_index.php', {
            security_key: security_key,
            mode: "getAllSchool"
        })
          .then(response => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const formattedOptions = response.data.data.map(option => ({
              value: option.pk_sc_id,
              label: option.sc_name
            }));
            setOptions(formattedOptions);
          })
          .catch(error => {
            console.error('Error fetching options from API: ', error);
          });
      }, []);


    return (

        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>


                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Edit Session </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Session</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>

                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Personal </h6>
                            </div>
                            <Row>
                                {/* -------------- Session Title--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Title</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.sess_title}
                                        id="tut_fname"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.sess_title && touched.sess_title ? "input-error" : ""}
                                    />
                                    {errors.sess_title && touched.sess_title && <p className="error">{errors.sess_title}</p>}
                                </Col>

                                {/* --------------Session Date--------------- */}

                              <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Date</h6>
                                    <Form.Control
                                        type="date"
                                        id="sess_date"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sess_date}
                                        
                                        className={errors.sess_date && touched.sess_date ? "input-error" : ""}
                                    />
                                    {errors.sess_date && touched.sess_date && <p className="error">{errors.sess_date}</p>}
                                </Col>

                                                      
                                

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Tutor</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_tutor}
                                        
                                        name="sess_tutor"
                                        id="sess_tutor"
                                       // value={values.te_wage_typ["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sess_tutor",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sess_tutor: true });
                                        }}
                                        className={errors.sess_tutor && touched.sess_tutor ? "input-error" : ""}
                                    />
                                    {errors.sess_tutor && touched.sess_tutor && <p className="error">{errors.sess_tutor}</p>}
                                </Col>

                                {/* --------------Tutor Notes--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Notes</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        id="sess_notes"
                                        
                                        value={values.sess_notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.sess_notes && touched.sess_notes ? "input-error" : ""}
                                    />
                                    {errors.sess_notes && touched.sess_notes && <p className="error">{errors.sess_notes}</p>}
                                </Col>




                                
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Type</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_type}
                                        
                                        name="sess_type"
                                        id="sess_type"
                                        //value={values.te_wage_typ["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sess_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sess_type: true });
                                        }}
                                        className={errors.sess_type && touched.sess_type ? "input-error" : ""}
                                    />
                                    {errors.sess_type && touched.sess_type && <p className="error">{errors.sess_type}</p>}
                                </Col>


                                           {/* --------------Session Time--------------- */}

                              <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Time</h6>
                                    <Form.Control
                                        type="time"
                                        id="sess_time"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sess_time}
                                        
                                        className={errors.sess_time && touched.sess_time ? "input-error" : ""}
                                    />
                                    {errors.sess_time && touched.sess_time && <p className="error">{errors.sess_time}</p>}
                                </Col>


                                {/* --------------Duration--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Duration</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_duration}
                                        
                                        name="sess_duration"
                                        id="sess_duration"
                                        value={values.sess_duration["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sess_duration",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sess_duration: true });
                                        }}
                                        className={errors.sess_duration && touched.sess_duration ? "input-error" : ""}
                                    />
                                    {errors.sess_duration && touched.sess_duration && <p className="error">{errors.sess_duration}</p>}
                                </Col>
                            </Row>

                        </div>
                    </Stack>

                    {/* --------------Tutor Personal End--------------- */}


                    {/* --------------Conditions Details End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>



                </div>

            </Form>
        </>)

        
}

export default EditSession;