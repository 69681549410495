/*
Purpose: list of Entrolment with use datatable
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import { Row, Col, Tab, Nav, Dropdown, DropdownButton, Card, Form, Modal, Button, ButtonGroup} from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Button as Buttonmui } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Moment from 'moment';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


import {
  security_key, options_dig_type
  , BASE_URL
} from "../global";




const SessionList = () => {

  const options_payment_type = [
    { value: '', label: '---Select---' },
    { value: 'Online', label: 'Online' },
    { value: 'Offline', label: 'Offline' },

  ];

  const handleClick_student_edit = () => {
    // You can use the 'id' value here for further processing
    navigate(`/student-profile`, { state: { pk_student_key: `${Id}` } });

  };
  const handleClick_student_view = () => {
    // You can use the 'id' value here for further processing
    navigate(`/view-student`, { state: { pk_student_key: `${Id}` } });

  };


  const handleClick_student_xero = () => {
    // You can use the 'id' value here for further processing
    navigate(`/ListXeroEntry`, { state: { pk_student_key: `${Id}` } });

  };

  const handleClick_student_quickbook = () => {
    navigate(`/ListQBEntry`, { state: { pk_student_key: `${Id}` } });
};


  const [showForfeit, setForfeitShow] = useState(false);


  const [dataPrice, setPrice] = useState([]);

  const [records, setRecords] = useState([]);

  const [records_l, setRecords_d] = useState([]);
  const [records_p, setRecords_p] = useState([]);
  const [records_ph, setRecords_ph] = useState([]);
  const [records_c, setRecords_c] = useState([]);


  const [data, setItems] = useState([]);

  const [data_d, setItems_d] = useState([]);

  const [data_c, setItems_c] = useState([]);
  const [data_p, setItems_p] = useState([]);
  const [data_ph, setItems_ph] = useState([]);


//stripe Payment history

const [StripePayment_record, setStripePayment_record] = useState([]);
//stripe paymet history


  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [isActive, setIsActive] = useState(true);

  const handleClick = event => {
    setIsActive(isActive => !isActive);
  };


  const [Id, setId] = useState(null);

  //Get key value start
  const location = useLocation();
  useEffect(() => {


    const { pk_student_key } = location.state || {};
    const extractedSId = pk_student_key;

    if (extractedSId !== '' && extractedSId !== undefined) {
      setId(extractedSId);
      //fetchData(extractedId);
      fetchData(extractedSId);
      fetchMasterData(extractedSId);
      fetchMasterSessionData(extractedSId);
    }else{
      navigate("/list-students");
    }
  }, [location]);
  //Get key value end


  //Api call start



  const [data_forfeit, setForfeitLabelData] = useState([]);


  const [options_subject, setOptions] = useState([]);
  const [options_pricing, setFeesOptions] = useState([]);
  const [options_session, setSessionOptions] = useState([]);
  const [options_unenrol_session, SetUnenrolSupOptions] = useState([]);
  const [SestTypeData, SetSestTypeData] = useState('In Centre');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingUnenrol, setIsSubmittingUnenrol] = useState(false);

  const [isSubmittingPrice, setIsSubmittingPrice] = useState(false);

  const [isSubmittingForfeit, setIsSubmittingForfeit] = useState(false);
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const [showPopupGeneratePayment, setShowPopupGeneratePayment] = useState(false);
  const [DataGP, setDataGP] = useState([]);

  const [StudentData, setStudentData] = useState({});


  const handleSessionViewClick = async (e) => {
    navigate(`/ViewEntrolments`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}` } });
  }

  const handleUnEnrollSessionViewClick = async (e) => {
    navigate(`/ViewUnEntrolments`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}` } });
  }


  const formRef = useRef(null);
  const fetchMasterSessionData = (Id, clsid = '') => {
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      class_id: clsid,
      mode: "getAllSessionTimeByStudent",
      fk_student_key: Id,
      sest_type: SestTypeData,
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response_session => {

        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const SessionOptions = response_session.data.data.map(option => ({
          value: option.pk_sest_key,
          label: option.sest_name
        }));
        SessionOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setSessionOptions(SessionOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });
  }

  const fetchMasterData = (Id, clsid = '') => {


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      pk_student_key: Id,
      mode: "getSingleStudent"

    })
      .then(response_stu => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_stu.data.success === true) {
          if (response_stu.data.data.length > 0) {
            setStudentData(response_stu.data.data[0]);
          }
          setLoading(false);

        }
        else {
          setLoading(false);

        }

      })
      .catch(error => {
        //console.error('Error fetching options from API: ', error);
      });


    // Make an API request to fetch the options
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      fk_student_key: Id,
      mode: "getAllSubjectby_center", //"getAllSubjectByStudent" 
      fk_cent_key: sessionStorage.getItem('key')

    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.subject_id,
          label: option.subject_name
        }));
        // formattedOptions.unshift({
        //   value: '',
        //   label: '--Select--'
        // });
        setOptions(formattedOptions);
      })
      .catch(error => {
        //console.error('Error fetching options from API: ', error);
      });


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getAllPriceData",
      fk_cent_key: sessionStorage.getItem('key'),
      student_key: Id
    })
      .then(response_fees => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const feesOptions = response_fees.data.data.map(option => ({
          value: option.ss_price,
          label: option.stp_product_name + (option.stp_product_name !== 'Offline' ? ' (' + option.ss_price + ')' : '')
        }));
        feesOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setFeesOptions(feesOptions);
      })
      .catch(error => {
       // console.error('Error fetching options from API: ', error);
      });
  };



  const fetchData = async (Id) => {
if(Id!=null && Id!=''){


    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getStudentEnrolmentData",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


        // const UnenrolSupOptions = response.data.data.map(option => ({
        //   value: option.pk_enrol_key,
        //   label: option.sest_name
        // }));
        // UnenrolSupOptions.unshift({
        //     value: '',
        //     label: '--Select--'
        //   });
        //   SetUnenrolSupOptions(UnenrolSupOptions);

      } else {
        setLoading(false);

        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentPayment",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords_p(response.data.data);
        setItems_p(response.data.data);
        setLoading(false);


      } else {
        setLoading(false);

        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentPaymentHistory",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords_ph(response.data.data);
        setItems_ph(response.data.data);
        setLoading(false);


      } else {
        setLoading(false);

        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }


    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getStudentEnrolmentCompletedData",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords_c(response.data.data);
        setItems_c(response.data.data);
        setLoading(false);


      } else {
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getStudentUnEnrolmentdData",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords_d(response.data.data);
        setItems_d(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }

    try {
      const response_section = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentSectionOnly",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response_section.data.success === true) {


        const UnenrolSupOptions = response_section.data.data.map(option => ({
          value: option.pk_enrol_key,
          label: option.sest_name
        }));
        UnenrolSupOptions.unshift({
          value: '',
          label: '--Select--'
        });
        SetUnenrolSupOptions(UnenrolSupOptions);

      } else {
        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }


    try {
      const responseInvoice = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
        security_key: security_key,
        mode: "getInvoiceData",
        pk_student_kay: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (responseInvoice.data.success === true) {
            let invoices = responseInvoice.data.invoices;
            // Filter invoices: include the last one and those with amount_paid > 0
            let filterInvoice = invoices.filter((invoice, index) => {
              return index === invoices.length - 1 || parseInt(invoice.amount_paid) > 0;
            });

            // Get the current year
            let currentYear = new Date().getFullYear();

            // Add recipt_no to each filtered invoice
            filterInvoice.forEach((invoice, index) => {
              let reciptNo = `${currentYear}/${String(filterInvoice.length - index).padStart(4, '0')}`;
              invoice.recipt_no = reciptNo;
            });


        setStripePayment_record(filterInvoice);
        setLoading(false);


      } else {
        setLoading(false);

      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }
  };

  const downloadInvoice = async (invoiceId) => {
    try {
        const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
            invoice_id: invoiceId,
            pk_student_kay: Id,
            mode: 'getDownloadInvoiceData'
        }, {
            responseType: 'blob' // Ensure response type is blob for binary data (PDF)
        });
        
        // Create a blob object from the PDF Blob data received
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `invoice_${Id}.pdf`;

        // Append the link to the body and click it to trigger download
        document.body.appendChild(a);
        a.click();

        // Clean up: remove the temporary link and URL object
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } catch (error) {
        console.error('Error downloading invoice:', error);
    }
};


const downloadInvoiceCustom = async (invoiceId, fromDate, endDate, type) => {
  try {
      const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
          invoice_id: invoiceId,
          pk_student_kay: Id,
          mode: 'getDownloadInvoiceCustom',
          from_date: fromDate,
          end_date: endDate,
          type: type
      }, {
          responseType: 'blob' // Ensure response type is blob for binary data (PDF)
      });
      
      // Create a blob object from the PDF Blob data received
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `receipt_${invoiceId}.pdf`;

      // Append the link to the body and click it to trigger download
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the temporary link and URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
  } catch (error) {
      console.error('Error downloading invoice:', error);
  }
};
  // Sample columns configuration
  const columns = [


    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    {
      headerName: 'Enrol Date',
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Start Date',
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {

      headerName: 'End Date',
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },


    },
    {
      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {

      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },


    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_is_holidays == 1 ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {

      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },

    },


    // {
    //   field: "actions",
    //   type: "actions",

    //   //field: "#",
    //   //  type: "actions",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",
    //   width: 250,
    //   // selector: (row) => row.payslip,
    //   // sortable: true,
    //   //id: "id",

    //   //cell: (row) => (<><Button variant="outlined-red" onClick={() => handleForfeitClick(row)} className="primary-outlined" >Forfeit </Button><Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button></>),
    //   headerAlign:"left",
    //   renderCell: (params) => {
    //     const row = params.row;

    //     // if (row.pay_gateway_response_code == 'Authorised') {

    //     //   return (
    //     //     <div className='d-flex flex-wrap pad-12'>

    //     //       Completed
    //     //     </div>
    //     //   );
    //     // }
    //     // else {
    //       return (
    //         <div className='d-flex  pad-12'>

    //           {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue my-2" disabled={isSubmittingPayment}>Generate Payment </Buttonmui> */}

    //           <Buttonmui variant="outlined-red" onClick={() => { setPrice(row); toggleModalPrice(); }} className="primary-blue my-2">Edit Price </Buttonmui>
    //         </div>
    //       );
    //     // }

    //   }
    // },


    // {
    //   name: " Write off",
    //   // selector: (row) => row.payslip,
    //   // sortable: true,
    //   cell:  row  => (<Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button> )
    // },



  ];



  // Sample columns configuration
  const columns_l = [

    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    {

      headerName: 'Enrol Date',
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Start Date',
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'End Date',
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    {
      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {

      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },

    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
    {

      headerName: 'Unenrol Date',
      field: "Unenrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_disc_date == null || params.row.enrol_disc_date == '' ? '-' : Moment(params.row.enrol_disc_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Reason',
      field: "Reason",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_disc_reason == null || params.row.enrol_disc_reason == '' ? '-' : params.row.enrol_disc_reason}`;
      },

    },

  ];



  // Sample columns configuration
  const columns_p = [

    {

      headerName: 'Enrolment Date',
      field: "Enrolment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },


    },
    {

      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    // {
    //   name: 'Class',
    //   selector: row => (row.sest_is_holidays == 1) ? 'Regular' : (row.sest_is_holidays == 2) ? 'One Off Session' : (row.sest_is_holidays == 3) ? 'Make up Session': 'N/A',

    //   sortable: true
    // },

    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname}`;
      },

    },

    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
    {
      headerName: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_deposit == null || params.row.enrol_deposit == '' ? '-' : params.row.enrol_deposit+' - ('+paystatus+')'}`;
      },

    },
    {

      headerName: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_reg_fees == null || params.row.enrol_reg_fees == '' ? '-' : params.row.enrol_reg_fees +' - ('+paystatus+')'}`;
      },


    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        // const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';

        return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      },

    },

    

  ];

  // Sample columns configuration
  const columns_ph = [

    {

      headerName: 'Enrolment Date',
      field: "Enrolment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },


    },
    {

      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },
    // {
    //   name: 'Class',
    //   selector: row => (row.sest_is_holidays == 1) ? 'Regular' : (row.sest_is_holidays == 2) ? 'One Off Session' : (row.sest_is_holidays == 3) ? 'Make up Session': 'N/A',

    //   sortable: true
    // },

    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname}`;
      },

    },

    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },


    },
      {
      headerName: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Deposit (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_deposit == null || params.row.enrol_deposit == '' ? '-' : params.row.enrol_deposit+' - ('+paystatus+')'}`;
      },

    },
    {

      headerName: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Registration Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const paystatus = params.row.regdeposit_status == 1 ? 'Paid' : 'Unpaid';
        return `${params.row.enrol_reg_fees == null || params.row.enrol_reg_fees == '' ? '-' : params.row.enrol_reg_fees +' - ('+paystatus+')'}`;
      },


    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
{/* enrollment-receipts
fees-view */}

            <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui>

           
          </div>
        );
      }
      // valueGetter: (params) => {
      //   return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      // },

    },

    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      // selector: (row) => row.payslip,
      // sortable: true,
      //id: "id",
      headerAlign:"left",
      //cell: (row) => (<><Button variant="outlined-red" onClick={() => handleForfeitClick(row)} className="primary-outlined" >Forfeit </Button><Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button></>),

      renderCell: (params) => {
        const row = params.row;

        // if (row.pay_gateway_response_code == 'Authorised') {

        //   return (
        //     <div className='d-flex flex-wrap pad-12'>

        //       Completed
        //     </div>
        //   );
        // }
        // else {
          return (
            <div className='d-flex  pad-12'>

              {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue my-2" disabled={isSubmittingPayment}>Receipts </Buttonmui> */}
              <Buttonmui variant="outlined-red" onClick={() => handleEnrollmentReceipts(row)} className="primary-blue my-2">Receipts </Buttonmui>

            </div>
          );
        // }

      }
    },

  ];

  // Sample columns configuration
  const columns_c = [


    {

      headerName: "Session Name",
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },
    },
    {

      headerName: "Enrol Date",
      field: "Enrol Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: "Start Date",
      field: "Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_date == null || params.row.ses_start_date == '' ? '-' : Moment(params.row.ses_start_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {

      headerName: "End Date",
      field: "End Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: "Day",
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },
    },
    {
      headerName: "Session Start Time",
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_start_time == null || params.row.sest_start_time == '' ? '-' : Moment(params.row.sest_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {
      headerName: "Tutor",
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },


    },
    {
      headerName: "Class",
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },


    },
    {
      headerName: "Subject",
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;

      },

    },


  ];



  const columns_stripePayment = [

    {

      headerName: 'Receipt No',
      field: "recipt_no",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.recipt_no && params.row.recipt_no != '' ? params.row.recipt_no : '-'}`;
      },
    },
    {

      headerName: 'From Date',
      field: "From Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.period_start && params.row.period_start  != '' ? Moment.unix(params.row.period_start).format('DD-MM-YYYY') : '-'}`;
      },
    },
    {
      headerName: 'To Date',
      field: "To Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.period_end && params.row.period_end != '' ?  Moment.unix(params.row.period_end).format('DD-MM-YYYY') : '-'}`;
      },
    },
    {
      headerName: "Amount(" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.number && params.row.number != '' ?  params.row.amount_due / 100 : '-'}`;
      },
    }, 
    {
      headerName: 'Status',
      field: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.paid && params.row.paid != '' ?  params.row.paid ? 'Paid' : 'Unpaid' : '-'}`;
      },
      renderCell: (params) => {
        const row = params.row;
        return (
         <>
              <div className={`text-${row.paid ? 'success' : 'danger'}`}>{row.paid ? 'Paid' : 'Unpaid'}</div>
             {/* <Buttonmui variant="outlined-red" onClick={() => handleViewFees(row)} className="primary-blue my-2" >View </Buttonmui> */}
             </>
        );
      }

    },
    {
      headerName: 'Invoice',
      field: "Action",
      type: "actions",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign:"left",
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className='d-flex  pad-12'>
            
             <Buttonmui variant="outlined-red" onClick={() => downloadInvoice(row.id)} className="primary-blue my-2" >Stripe</Buttonmui>
             {row.period_start && row.period_end ? <Buttonmui variant="outlined-red" onClick={() => downloadInvoiceCustom(row.recipt_no, Moment.unix(params.row.period_start).format('YYYY-MM-DD'), Moment.unix(params.row.period_end).format('YYYY-MM-DD'), row.id_row)} className="primary-blue my-2" >TCMS </Buttonmui> : null}
          </div>
        );
      }

    }


  ];




  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowNotify, setModalShowNotyfy] = useState(false);

  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);

  const [showModalPrice, setShowModalPrice] = useState(false);
  const toggleModalPrice = () => setShowModalPrice(!showModalPrice);



  const basicSchema = yup.object().shape({

    enrol_start_date: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required("Date is required."),
    fk_sub_id: yup.array().min(1, 'Subject is required.'),
    fk_sest_key: yup.mixed().required("Session is required."),
    enrol_category: yup.mixed().required("Category is required."),
    sest_class: yup.bool(), // Assuming sest_class is a boolean checkbox


    enrol_price: yup.mixed().when('sest_class', {
      is: false,
      then: () => yup.mixed().required("Fees is required."),
    }),
    // enrol_reg_fees: yup.mixed().when('sest_class', {
    //   is: false,
    //   then: () => yup.mixed().required("Fees is required."),
    // }),
    // enrol_deposit: yup.mixed().when('sest_class', {
    //   is: false,
    //   then: () => yup.mixed().required("Deposit is required."),
    // }),



    // sest_class: yup.mixed(),
    // enrol_price: yup.mixed().when('sest_class', {
    //   is: true,
    //   then: yup.mixed().notRequired() // Skip validation when isChecked is true (checked)
    // }),
    // enrol_price:  yup.string().required('Fees is required.'),


    //  enrol_price: yup.mixed().required('Fees is required.'),

    //   enrol_reg_fees: yup.mixed().required('Fees is required.'),

    //   enrol_deposit:yup.mixed().required('Deposit is required.'),

    //enrol_price_type: yup.mixed().required("Type is required."),
    enrol_reg_fees: yup.number().integer().min(0, "Minimum value 0 required"),
    enrol_deposit: yup.number().integer().min(0, "Minimum value 0 required")
  });

  const initialValuesmain = {
    /*tbl_student_enrol*/

    fk_sub_id: [],
    fk_sest_key: '',
    sest_class: false,
    enrol_category: "In Centre",
    enrol_price: "",
    enrol_start_date: "",
    enrol_reg_fees: 0,
    enrol_price_type: "",
    enrol_deposit: 0,

    fk_stu_key: Id,
    fk_cent_key: sessionStorage.getItem('key'),
    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues: initialValuesmain,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "createStudentEnrolment",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //handleClose();
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data == 2) {
            toast.error("Sorry! The session was deleted. Please try another session.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else if (response.data.data == 3) {
            toast.error("Sorry! Generate session is not available after enrolment date. Try another session or date.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create student enrolled. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create student enrolled. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create student enrolled. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };




  const validationSchema1 = yup.object().shape({
    pk_enrol_key_e: yup.string().required('Session is required.'),
    enrol_disc_date:yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required('Date is required.'),
    enrol_disc_reason: yup.string().required('Reason is required.').matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
  });


  const initialValues1 = {
    pk_enrol_key_e: '',
    enrol_disc_date: new Date().toISOString().split("T")[0],
    enrol_disc_reason: '',
    updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,

    onSubmit: async (values) => {
      setIsSubmittingUnenrol(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "StudentUnenrolment",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            fetchMasterSessionData(Id);
            resetForm();
            toggleModal1();



            setIsSubmittingUnenrol(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create student unenrolled. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingUnenrol(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create student unenrolled. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingUnenrol(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create student unenrolled. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingUnenrol(false); // Re-enable the submit button

      } finally {
        setIsSubmittingUnenrol(false); // Re-enable the submit button

      }

    },
  });




  function onSubmitForfeit(type) {
    setIsSubmittingForfeit(true);

    const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),

      mode: "changeStudentEnrolmentForfeitWriteoff",
      pk_enrol_key: data_forfeit.pk_enrol_key,
      fw_session_date: data_forfeit.ses_date,
      fw_session_time: data_forfeit.ses_start_time,
      fw_sest_key: data_forfeit.fk_sest_key,
      fw_type: type,
    }).then((responseforfeit) => {
      setForfeitShow(false)
      // Handle the successful response here
      toast.success("Success! Your form was submitted", {
        autoClose: 3000, // 3 seconds in this example
      });
      setTimeout(function () {
        window.location.reload(false);

      }, 3000);

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to forfeit or writeoff. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
      });






  }
  const handleChangeCategory = async (e) => {
    SetSestTypeData(e);
  };
  
  useEffect(() => {
    // This will run after the component renders or whenever SestTypeData changes
    formik.setFieldValue("fk_sest_key", '');
    formik.setFieldTouched("fk_sest_key", false);

    if(Id!=null && Id!=''){
  
      fetchMasterSessionData(Id, values.sest_class);
    }
  }, [SestTypeData,values.sest_class]);



  const handleClassChange = async (e) => {
    formik.setFieldValue("fk_sest_key", '');
    formik.setFieldTouched("fk_sest_key", false);


  };

  // const openPopupGP = (dataGP) => {

  //   setDataGP(dataGP);
  //   setShowPopupGeneratePayment(!showPopupGeneratePayment);
  // };
  const closePopupGeneratePayment = () => {
    setShowPopupGeneratePayment(false);
  };



  const basicSchema2 = yup.object().shape({
    payment_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
      return value && value.includes('.');
    }),

  });
  const initialValues2 = {
    /*tbl_center_master*/
    payment_emailid: StudentData ? StudentData.par_emailid != '' && StudentData.par_emailid != null && (StudentData.st_is_adultlearner != null && StudentData.st_is_adultlearner == 0) ? StudentData.par_emailid : '' : '', //Centre name-1

    created_by: sessionStorage.getItem('key'),
    visibility: "1",
  };


  // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: basicSchema2,
    enableReinitialize: true,
    // onSubmit,

    onSubmit: async (formValues) => {
      setIsSubmittingPayment(true);

      try {
        const responseSE = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          fk_cent_key: sessionStorage.getItem('key'),
          mode: "generatePaymentMail",
          pk_enrol_key: DataGP.pk_enrol_key,
          fk_stu_key: DataGP.fk_stu_key,
          st_email: DataGP.st_email,
          st_first_name: DataGP.st_first_name,
          st_surname: DataGP.st_surname,
          ...formValues,
        });

        if (responseSE.data.success === true) {

          // Reset the form
          //formRef.current.reset();
          if (responseSE.data.data === 1) {
            toast.success("Success! Your enrolment request mail has been successfully sent.", {
              autoClose: 3000, // 3 seconds in this example
            });


            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false);
            window.open(responseSE.data.dataRes, '_blank', 'noopener,noreferrer');

          } else {
            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setTimeout(function () {
              setIsSubmittingPayment(false);
              setShowPopupGeneratePayment(false)
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          }

        } else {

          if (responseSE.data.data === 2) {
            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          } else {



            toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
          }

          setTimeout(function () {
            setIsSubmittingPayment(false);
            setShowPopupGeneratePayment(false)
          }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Your enrolment request mail has been not sent. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      } finally {
        setTimeout(function () {
          setIsSubmittingPayment(false);
          setShowPopupGeneratePayment(false)
        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
      }

    },
  });




  // function onSubmitGeneratePayment(data) {

  //   // window.location.href = BASE_URL+'webpages/otp-checkout.php'; //relative to domain

  //   setIsSubmittingPayment(true);



  //   const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

  //     security_key: security_key,
  //     fk_cent_key: sessionStorage.getItem('key'),
  //     mode: "generatePaymentMail",
  //     pk_enrol_key: data.pk_enrol_key,
  //     fk_stu_key: data.fk_stu_key,
  //     st_email: data.st_email,
  //     st_first_name: data.st_first_name,
  //     st_surname: data.st_surname,

  //   }).then((responseforfeit) => {
  //     setIsSubmittingPayment(false);
  //     // Handle the successful response here
  //     toast.success("Success! Your payment request mail has been successfully sent. ", {
  //       autoClose: 5000, // 3 seconds in this example
  //     });
  //     setTimeout(function () {
  //       window.location.reload(false);

  //     }, 5000);
  //     setIsSubmittingPayment(false);

  //   })
  //     .catch((error) => {
  //       // Handle any errors that occurred during the request
  //       toast.error("Oops! Something went wrong.", {
  //         autoClose: 5000, // 3 seconds in this example
  //       });
  //       setIsSubmittingPayment(false);

  //     });






  // }

  const validationSchema3 = yup.object().shape({
    enrol_price: yup.mixed().required("Fees is required."),
    // enrol_reg_fees: yup.mixed().required("Fees is required."),
    // enrol_deposit: yup.mixed().required("Deposit is required.")
  });


  const initialValues3 = {
    pk_enrol_key: dataPrice ? dataPrice.pk_enrol_key : '',
    enrol_sest_name: dataPrice ? dataPrice.sest_name : '',
    enrol_price: dataPrice ? dataPrice.enrol_price : '',
    enrol_reg_fees: dataPrice ? dataPrice.enrol_reg_fees : '',
    enrol_deposit: dataPrice ? dataPrice.enrol_deposit : '',
    updated_by: sessionStorage.getItem('key')
  };


  const formik3 = useFormik({
    initialValues: initialValues3,
    validationSchema: validationSchema3,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingPrice(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateStudentPriceEnrolment",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            //fetchMasterData(Id);
            resetForm();
            toggleModalPrice();



            setIsSubmittingPrice(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update price. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingPrice(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to update price. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingPrice(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update price. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingPrice(false); // Re-enable the submit button

      } finally {
        setIsSubmittingPrice(false); // Re-enable the submit button

      }

    },
  });



  const handleViewFees = async (e) => {

      navigate(`/fees-view`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}`, sest_name: `${e.sest_name}`, enrol_start_date: `${e.enrol_start_date}`, sest_day: `${e.sest_day}` } });
  }
  const handleEnrollmentReceipts = async (e) => {
      navigate(`/enrollment-receipts`, { state: { fk_stu_key: `${e.fk_stu_key}`, fk_sest_key: `${e.fk_sest_key}`, pk_enrol_key: `${e.pk_enrol_key}`, sest_name: `${e.sest_name}`, enrol_start_date: `${e.enrol_start_date}`, sest_day: `${e.sest_day}` } });
  }


  return (
    <>
      <ToastContainer position="top-center" />

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Subject</h6>
                  <Select menuPlacement="auto" options={options_subject}
                    name="fk_sub_id"
                    value={values.fk_sub_id}
                    id="fk_sub_id"
                    isMulti
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sub_id",
                          value: selectedOption,
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sub_id: true });
                    }}
                    className={errors.fk_sub_id && touched.fk_sub_id ? "input-error" : "mb-3"} />
                  {errors.fk_sub_id && touched.fk_sub_id && <span className="error">{errors.fk_sub_id}</span>}
                </Col>



                {/* --------------Category--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Category</h6>
                  <Select menuPlacement="auto" options={options_dig_type}
                    name="enrol_category"
                    value={options_dig_type.find(option => option.value && values.enrol_category && option.value.toString() === values.enrol_category.toString())}
                    id="enrol_category"
                    onChange={ (selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_category",
                          value: selectedOption["value"],
                        },
                      };
                     handleChangeCategory(selectedOption["value"]);
                      // Assuming handleChange is asynchronous
                      // handleChange({ target: { name: 'fk_sest_key', value: '' } }, () => {
                      //   handleChange(event);
                      // });
                      //handleChange({ target: { name: 'fk_sest_key', value: '' } });

                      // formik.setFieldValue("fk_sest_key", '');
                      // formik.setFieldTouched("fk_sest_key", false);

       
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, enrol_category: true });
                    }}
                    className={errors.enrol_category && touched.enrol_category ? "input-error" : "mb-3"} />
                  {errors.enrol_category && touched.enrol_category && <p className="error">{errors.enrol_category}</p>}
                </Col>

                {/* --------------Class Session--------------- */}


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Class</h6>
                  <Form.Check
                    type="checkbox"
                    label="Makeup Session"
                    name="sest_class"
                    id="sest_class"
                    checked={values.sest_class}
                    onClick={handleClassChange}
                    onChange={handleChange}
                    className='mt-2'
                  />
                  {/*  <Select menuPlacement="auto"  options={options_isholiday}
                    name="sest_is_holidays"
                   value={values.sest_is_holidays['value']}
                    id="sest_is_holidays"
                    onChange={(event) => {
                      handleClassChange(event);                     
                       setTouched({ ...touched, sest_is_holidays: true });


                    }}
                    
                    // onChange={(events) => {
                    //   const event = {
                    //     target: {
                    //       name: "sest_is_holidays",
                    //       value: events["value"],
                    //     },
                    //   };
                    //  // handleChange(event);
                    //   handleClassChange(event);                     

                    // }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_is_holidays: true });
                    }}
                    className={errors.sest_is_holidays && touched.sest_is_holidays ? "input-error" : ""} />
                    {errors.sest_is_holidays && touched.sest_is_holidays && <p className="error">{errors.sest_is_holidays}</p>} */}

                </Col>
                {/* --------------Session--------------- */}
                {/* {console.log(values.fk_sest_key)}
                {console.log("Matching option:", options_session.find(option => option.value === values.fk_sest_key!=null && values.fk_sest_key!=''? values.fk_sest_key : ''))} */}
                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                               Session options are controlled so that already completed sessions do not shown. Additionally, the date and time of sessions for which students are already enrolled are not shown. Furthermore, sessions that have reached their maximum student capacity are also not shown.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>

                  <Select menuPlacement="auto" options={options_session}
                    name="fk_sest_key"

                    //isClearable={true} 
                   // value={values.fk_sest_key['value']}
                   value={options_session.find(option => option.value.toString() === values.fk_sest_key.toString())}
                    id="fk_sest_key"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sest_key",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sest_key: true });
                    }}
                    className={errors.fk_sest_key && touched.fk_sest_key ? "input-error" : "mb-3"} />
                  {errors.fk_sest_key && touched.fk_sest_key && <span className="error">{errors.fk_sest_key}</span>}


                </Col>


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Date</h6>
                  {/* <Form.Control
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    value={values.enrol_start_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="enrol_start_date"
                    className={errors.enrol_start_date && touched.enrol_start_date ? "input-error" : "mb-3"}
                  />
                  {errors.enrol_start_date && touched.enrol_start_date && <p className="error">{errors.enrol_start_date}</p>} */}


                  

                  <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                          <DatePicker  views={['year', 'month', 'day']}
                              value={dayjs(values.enrol_start_date, 'YYYY-MM-DD')}
                              onChange={(date) =>  {
                                const event = {
                                target: {
                                    name: "enrol_start_date",
                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                },
                                };
                                handleChange(event);
                            }}
                              onBlur={(event) => {
                                  handleBlur(event);
                                  setTouched({ ...touched, enrol_start_date: true });
                              }}
                              name="enrol_start_date"
                              slotProps={{
                                  textField: { readOnly: true, size: 'small',
                                    id: 'enrol_start_date',
                                  },
                                }}
                              className={errors.enrol_start_date && touched.enrol_start_date ? "input-error w-100" : "w-100"}
                              minDate={dayjs().subtract(1, 'year')}
                              format="DD-MM-YYYY"
                          />
                      </LocalizationProvider>
                      {errors.enrol_start_date && touched.enrol_start_date && <span className="error">{errors.enrol_start_date}</span>}
                </Col>

                {/* --------------Fees--------------- */}
                {values.sest_class === false && (
                  <>
                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field">Fees {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                             Please subscribe this student to a Stripe payment or an offline payment on the student list page.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>
                      <Select menuPlacement="auto" options={options_pricing}
                        name="enrol_price"
                        value={values.enrol_price['value']}
                        id="enrol_price"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_price",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_price: true });
                        }}
                        className={errors.enrol_price && touched.enrol_price ? "input-error" : "mb-3"} />
                      {errors.enrol_price && touched.enrol_price && <span className="error">{errors.enrol_price}</span>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable ">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_reg_fees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_reg_fees"
                        min={0}
                        className={errors.enrol_reg_fees && touched.enrol_reg_fees ? "input-error" : "mb-0"}
                      />
                      {errors.enrol_reg_fees && touched.enrol_reg_fees && <p className="error">{errors.enrol_reg_fees}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable ">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_deposit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_deposit"
                        min={0}
                        className={errors.enrol_deposit && touched.enrol_deposit ? "input-error" : "mb-0"}
                      />
                      {errors.enrol_deposit && touched.enrol_deposit && <p className="error">{errors.enrol_deposit}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable required-field">Fees Type</h6>
                      <Select menuPlacement="auto" options={options_payment_type}
                        name="enrol_price_type"
                        value={values.enrol_price_type['value']}
                        id="enrol_price_type"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_price_type",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_price_type: true });
                        }}
                        className={errors.enrol_price_type && touched.enrol_price_type ? "input-error" : "mb-0"} />
                      {errors.enrol_price_type && touched.enrol_price_type && <p className="error">{errors.enrol_price_type}</p>}
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>

            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>

        </Modal>

        <Modal
          show={modalShowNotify} onHide={() => setModalShowNotyfy(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                <div className="ten columns col-12">
                  <ul className="">
                    <li> Mon 04-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 11-sep-23 &rArr;	 Lesson Added For Student </li>
                    <li> Mon 18-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 25-sep-23 &rArr;	 Lesson Added For Student</li>
                  </ul>


                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowNotyfy(false)}>Cancel</Button>
                <Button variant="contained" className="primary-filled " onClick={handleClick_student_view}>View Student</Button>
              </div>

            </Modal.Footer>
          </Form>
        </Modal>


        <Modal show={showModal1} onHide={toggleModal1} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'> Unenrolment </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik1.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                            The session option only displays the start date of sessions in which students are enrolled.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))}</h6>
                  <Select menuPlacement="auto" options={options_unenrol_session}
                    name="pk_enrol_key_e"
                    value={formik1.values.pk_enrol_key_e['value']}
                    id="pk_enrol_key_e"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pk_enrol_key_e",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({ ...formik1.touched, pk_enrol_key_e: true });
                    }}
                    className={formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e ? "input-error" : ""} />
                  {formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e && <span className="error">{formik1.errors.pk_enrol_key_e}</span>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Unenrolment Date</h6>
                  {/* <Form.Control
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    max={new Date().toISOString().split("T")[0]}
                    value={formik1.values.enrol_disc_date}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="enrol_disc_date"
                    className={formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date ? "input-error" : ""}
                  /> */}
                  <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                      <DatePicker  views={['year', 'month', 'day']}
                          value={dayjs(formik1.values.enrol_disc_date, 'YYYY-MM-DD')}
                          onChange={(date) =>  {
                            const event = {
                            target: {
                                name: "enrol_disc_date",
                                value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                            },
                            };
                            formik1.handleChange(event);
                        }}
                          onBlur={(event) => {
                            formik1.handleBlur(event);
                            formik1.setTouched({ ...formik1.touched, enrol_disc_date: true });
                          }}
                          name="enrol_disc_date"
                          slotProps={{
                              textField: { readOnly: true, size: 'small',
                                id: 'enrol_disc_date',
                              },
                            }}
                          className={formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date ? "input-error w-100" : "w-100"}
                          minDate={dayjs()}
                          maxDate={dayjs()}
                          format="DD-MM-YYYY"
 />
                  </LocalizationProvider>
                  {formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date && <span className="error">{formik1.errors.enrol_disc_date}</span>}
                </Col>

                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Reason</h6>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    id="enrol_disc_reason"

                    value={formik1.values.enrol_disc_reason}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    className={formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason ? "input-error" : "mb-0"}
                  />
                  {formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason && <p className="error">{formik1.errors.enrol_disc_reason}</p>}
                </Col>
              </Row>


            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingUnenrol}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>






        <Modal show={showForfeit} variant="danger">

          <Modal.Header>Are you sure?</Modal.Header>

          <Modal.Body>
            <p>You want to <b className="bg-warning">{(data_forfeit.type == 1 ? 'forfeit ' : data_forfeit.type == 2 ? 'writeoff' : '')}</b>  this enrolment? </p>

            <Card className='rounded-1 mt-2 card-bdr' >
              <Card.Body>

                <div>
                  <div className="d-flex justify-content-between align-self-center">
                    <div className="me-5">
                      <div className="">

                        <p className="">{data_forfeit.sest_name}<span className=''> ({data_forfeit.tut_fname + ' ' + data_forfeit.tut_surname})</span></p>
                        <h6>Session Date:</h6>
                        <p className="mb-0">
                          <i class="fa-solid fa-calendar me-2"></i>
                          {Moment(data_forfeit.ses_date).format('DD-MM-YYYY')} ({data_forfeit.sest_day})
                        </p>
                        <h6>Session Time:</h6>
                        <p className="mb-0">
                          <i class="fa-solid fa-clock me-2"></i>

                          {data_forfeit.ses_start_time}
                        </p>
                      </div>
                    </div>
                  </div>
                  <span>

                  </span>
                </div>

              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer>

            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setForfeitShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " onClick={() => onSubmitForfeit(data_forfeit.type)} disabled={isSubmittingForfeit} >Yes</Button>
            </div>

          </Modal.Footer>

        </Modal>

        <Modal show={showModalPrice} onHide={toggleModalPrice} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='page-title'>Edit Price </h5>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik3.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>

                {/* --------------Subject--------------- */}


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session Name</h6>
                  <Form.Control
                    type="text"
                    readOnly
                    value={formik3.values.enrol_sest_name}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_sest_name"
                    className={formik3.errors.enrol_sest_name && formik3.touched.enrol_sest_name ? "input-error" : ""}
                  />
                  {formik3.errors.enrol_sest_name && formik3.touched.enrol_sest_name && <p className="error">{formik3.errors.enrol_sest_name}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Fees</h6>
                  <Select menuPlacement="auto" options={options_pricing}
                    name="enrol_price"

                    value={options_pricing.find(option => option.value && formik3.values.enrol_price && option.value.toString() === formik3.values.enrol_price.toString())}
                    // value={formik3.values.enrol_price['value']}
                    id="enrol_price"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_price",
                          value: selectedOption["value"],
                        },
                      };
                      formik3.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik3.handleBlur(event);
                      formik3.setTouched({ ...formik3.touched, enrol_price: true });
                    }}
                    className={formik3.errors.enrol_price && formik3.touched.enrol_price ? "input-error" : "mb-3"} />
                  {formik3.errors.enrol_price && formik3.touched.enrol_price && <p className="error">{formik3.errors.enrol_price}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                  <Form.Control
                    type="number"

                    value={formik3.values.enrol_reg_fees}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_reg_fees"
                    min={0}
                    className={formik3.errors.enrol_reg_fees && formik3.touched.enrol_reg_fees ? "input-error" : "mb-0"}
                  />
                  {formik3.errors.enrol_reg_fees && formik3.touched.enrol_reg_fees && <p className="error">{formik3.errors.enrol_reg_fees}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                  <Form.Control
                    type="number"

                    value={formik3.values.enrol_deposit}
                    onChange={formik3.handleChange}
                    onBlur={formik3.handleBlur}
                    id="enrol_deposit"
                    min={0}
                    className={formik3.errors.enrol_deposit && formik3.touched.enrol_deposit ? "input-error" : "mb-0"}
                  />
                  {formik3.errors.enrol_deposit && formik3.touched.enrol_deposit && <p className="error">{formik3.errors.enrol_deposit}</p>}
                </Col>

              </Row>


            </Modal.Body>
            <Modal.Footer className='pt-0'>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModalPrice}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPrice}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>




        {showPopupGeneratePayment && (
          <Dialog open={showPopupGeneratePayment} onClose={closePopupGeneratePayment}>
            <DialogTitle>Send Mail ({DataGP.st_first_name}   {DataGP.st_surname})</DialogTitle>
            <DialogContent>

              <Form autoComplete="off" onSubmit={formik2.handleSubmit} ref={formRef}>
                <Form.Group >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="payment_emailid"

                    value={formik2.values.payment_emailid}
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    className={formik2.errors.payment_emailid && formik2.touched.payment_emailid ? "input-error mb-0 " : ""}
                  />
                  {formik2.errors.payment_emailid && formik2.touched.payment_emailid && <span className="error clearfix">{formik2.errors.payment_emailid}</span>}




                  <Button variant="contained" className="primary-gray" onClick={() => setShowPopupGeneratePayment(false)}>Cancel</Button>
                  <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingPayment} >Send</Button>
                </Form.Group>
              </Form>
            </DialogContent>
          </Dialog>
        )}
        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Enrolment List </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Enrolment</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            <div className='d-flex justify-content-end gap-2'>
              <div >
                <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                  <Dropdown.Item eventKey="1" onClick={handleClick_student_edit}>Student Profile</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={handleClick_student_view}>Student Particulars</Dropdown.Item>
                  <Dropdown.Item eventKey="3"><NavLink to='/list-students' >Student list</NavLink></Dropdown.Item>

                </DropdownButton>
              </div>
            </div>
          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            <Tab.Container defaultActiveKey="first">
              <Row>
                <Col sm={12} className='border-bottom mb-2'>

                  <div className="d-flex flex-wrap  justify-content-right  float-end">

                    <div className=''>

                      <Button variant='primary' className='me-2 btn-primary-outline' onClick={() => setModalShowAdd(true)} >
                        Add Enrolment
                      </Button>
                      {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                            Edit Enrolment
                          </Button> */}
                      <Button variant='primary' className='me-2 btn-primary-outline' onClick={toggleModal1}>
                        Unenrol
                      </Button>
                    </div>
                    {/* <div className='d-flex gap-2 '>
                       <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div class="form-group has-search my-auto mb-2">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter} />
                          </div>
                        

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_c} />
                          </div>
                          
                        </Tab.Pane>

                        <Tab.Pane eventKey="third">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_l} />
                          </div>
                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_p} />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <div class="form-group has-search my-auto">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control mb-0" onChange={handleFilter_ph} />
                          </div>
                        </Tab.Pane>
                      </Tab.Content>

                    </div> */}

                  </div>

                  <Nav variant="pills" className="mb-3 text-dark ">
                    <div className='d-flex justify-content-between'>
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={handleClick}>Current</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={handleClick}>Completed</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third" className="px-4 py-2 text-dark" onClick={handleClick}>Unenrol</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth" className="px-4 py-2 text-dark" onClick={handleClick} style={{ display: 'none' }}>Payment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth" className="px-4 py-2 text-dark" onClick={handleClick} style={{ display: 'none' }}>Payment History</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="sixth" className="px-4 py-2 text-dark" onClick={handleClick}>Stripe Payment</Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  <span className='me-2 mt-2'>{StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname + ' - ' : '' : ''} <b>{StudentData ? StudentData.pk_student_id != null && StudentData.pk_student_id != '' ? '#' + StudentData.pk_student_id : '' : ''}</b></span>
                </Col>

                <Col sm={12}>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
                        sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CurrentEnrolList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CurrentEnrolList" },
                              records,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleSessionViewClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder= {true}
                          showColumnVerticalBorder= {true}

                        />


                      </Box>

                  
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CompletedEnrolList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CompletedEnrolList" },
                              records_c,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_c.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_c.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_c}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleSessionViewClick(params.row)}
                        disableRowSelectionOnClick
                        showCellVerticalBorder= {true}
                        showColumnVerticalBorder= {true}

                        />


                      </Box>


                    </Tab.Pane>

                    <Tab.Pane eventKey="third">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "UnenrolList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "UnenrolList" },
                              records_l,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_l.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_l.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_l}
                          rowHeight={60} density="compact"
                          onRowClick={(params) => handleUnEnrollSessionViewClick(params.row)}
                          disableRowSelectionOnClick
                          showCellVerticalBorder= {true}
                          showColumnVerticalBorder= {true}

                        />


                      </Box>


                    
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList" },
                              records_p,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_p.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_p.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_p}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder= {true}
                          showColumnVerticalBorder= {true}

                        />


                      </Box>

                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentHistoryList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentHistoryList" },
                              records_ph,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_ph.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_ph.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_ph}
                          rowHeight={60} density="compact"


                        />


                      </Box>

                    </Tab.Pane>



                    <Tab.Pane eventKey="sixth">
                      <div className='mt-3 mb-3 d-flex justify-content-end align-items-end'>

                        {StudentData && StudentData.count_xero_api && parseInt(StudentData.count_xero_api) > 0 ?
                      <Button variant="contained" className="primary-filled rounded-10 p-2" onClick={() => handleClick_student_xero()} >
                        Xero Journal
                      </Button> : null
                      }
                      {StudentData && StudentData.count_qb_api && parseInt(StudentData.count_qb_api) > 0 ?
                      <Button
                          variant="contained"
                          className="primary-filled rounded-10 p-2"
                          onClick={() => handleClick_student_quickbook()}
                      >
                          QB Journal
                      </Button>
                      : null}
                      </div>

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#e3e6ee",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "PaymentList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "PaymentList" },
                              StripePayment_record,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...StripePayment_record.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={StripePayment_record.map((row, index) => ({ ...row, id_row: `${index+1}` }))}
                          columns={columns_stripePayment}
                          rowHeight={60} density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder= {true}
                          showColumnVerticalBorder= {true}

                        />


                      </Box>


                    
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default SessionList;
