/*
Purpose: Edit holidays form
Developer: BY R.SD
Date:25/08/2023 
Project: TCMS
*/


import { useNavigate, useLocation, NavLink } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Select from "react-select";

import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


import axios from "axios";
import {
    security_key
, BASE_URL} from "../global";



const basicSchema = yup.object().shape({

    hol_title: yup.string().min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").required("Title is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    hol_type: yup.mixed().required("Type is required."),
    hol_startdate: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
        const parsedDate = dayjs(value, 'YYYY-MM-DD'); // Update the format here
        return parsedDate.isValid();
    })
    .required("Start date is required."),

    hol_enddate: yup
    .string().test('is-valid-date', 'Please provide a valid date.', function (value) {
        const parsedDate = dayjs(value, 'YYYY-MM-DD'); // Update the format here
        return parsedDate.isValid();
    }).test('is-valid-date', 'End date must be greater than start date.', function (value) {
        const startDate = dayjs(this.parent.hol_startdate, 'YYYY-MM-DD');
        const endDate = dayjs(value, 'YYYY-MM-DD');
    
        return endDate.isValid() && (endDate.isSame(startDate, 'day') || endDate.isAfter(startDate, 'day'));
    }).required("End date is required."),

});

function EditHolidays() {


    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate("/operating-hoursandholidays");
    };

    const [HolidayData, setHolidayData] = useState([]);

    const location = useLocation();

    useEffect(() => {

        const { pk_hol_id } = location.state || {};
        const extractedHolId = pk_hol_id;

        if (extractedHolId !== '' && extractedHolId !== undefined) {
            //setId(extractedId);
            fetchData(extractedHolId);
        }else{
            navigate("/operating-hoursandholidays");
        }
    }, [location]);
    
    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "readHoliday",
                center_key: sessionStorage.getItem('key'),
                pk_hol_id: id
            });
            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setHolidayData(response.data.data[0]);
                }
               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const currentDate = new Date(); // Get the current date and time
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " "); // Format it as "YYYY-MM-DD HH:mm:ss"
   

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_tutor*/
        hol_title: HolidayData ? HolidayData.hol_title : '',
        hol_type: HolidayData ? HolidayData.hol_type : '',
        hol_startdate: HolidayData ? HolidayData.hol_startdate : '',
        hol_enddate: HolidayData ? HolidayData.hol_enddate : '',
   

        /*Common*/
        pk_hol_id: HolidayData ? HolidayData.pk_hol_id : '',

        updated_by: sessionStorage.getItem('key'),
        fk_cent_key: sessionStorage.getItem('key'),

       
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        // const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        // onSubmit,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            
            try {

              

                try {
                    const response = await axios.post(BASE_URL+"API/api_index.php", {
                        security_key: security_key,
                        mode: "updateHoliday",
                        ...formValues,
                    });

                    if (response.data.success === true) {



                        if (response.data.data === 1) {
                            resetFormFields(resetForm, setValues, initialValues);
                            toast.success("Success! Your form was submitted", {
                                autoClose: 3000, // 3 seconds in this example
                            });

                            // After 3 seconds, redirect to another page
                            setTimeout(function () {
                                navigate("/operating-hoursandholidays");
                            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                            setIsSubmitting(false); // Re-enable the submit button
                        } else {
                            toast.error("Failure! Unable to update holidays. Try again later", {
                                autoClose: 3000, // 3 seconds in this example
                            });

                            setIsSubmitting(false); // Re-enable the submit button
                        }

                    } else {
                        toast.error("Failure! Unable to update holidays. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    toast.error("Failure! Unable to update holidays. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                } finally {
                    setIsSubmitting(false); // Re-enable the submit button
                }



                // You can also perform further actions based on the API response
                // For example, show a success message or redirect to another page.
            } catch (error) {
                // Handle any API request errors here
                toast.error("Failure! Unable to update holidays. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button


            }



        },
    });

    
    const options_holidaytype = [
        { value: '', label: '---Select---' },
        { value: 'School Holidays', label: 'School Holidays' },
        { value: 'Public Holidays', label: 'Public Holidays' },
    ];



    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>


                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Edit Holiday </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>

                                    <Typography color="text.primary">Holiday</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>



                    {/* --------------Conditions Details--------------- */}
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Holidays Details</h6>
                            </div>

                            <Row>
                                {/* --------------Holiday Title--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Holiday Title </h6>
                                    <Form.Control
                                        type="text"
                                        
                                        id="hol_title"
                                        value={values.hol_title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.hol_title && touched.hol_title ? "input-error" : ""}
                                    />
                                    {errors.hol_title && touched.hol_title && <p className="error">{errors.hol_title}</p>}
                                </Col>

                                {/* -------------- Holiday Type--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field"> Holiday Type</h6>

                                     <Select menuPlacement="auto" 
                                        options={options_holidaytype}
                                        
                                        name="hol_type"
                                        id="hol_type"
                                        value={options_holidaytype.find(option => option.value === values.hol_type)}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "hol_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, hol_type: true });
                                        }}
                                        className={errors.hol_type && touched.hol_type ? "input-error" : ""}
                                    />
                                    {errors.hol_type && touched.hol_type && <p className="error">{errors.hol_type}</p>}
                                </Col>


                                {/* --------------Holiday Start Date--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Holiday Start Date</h6>
                                    {/* <Form.Control
                                        type="date"
                                        id="hol_startdate"
                                        min={values.hol_startdate}
                                        value={values.hol_startdate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.hol_startdate && touched.hol_startdate ? "input-error" : ""}
                                    />
                                    {errors.hol_startdate && touched.hol_startdate && <p className="error">{errors.hol_startdate}</p>} */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.hol_startdate, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "hol_startdate",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, hol_startdate: true });
                                            }}
                                            name="hol_startdate"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'hol_startdate',
                                                },
                                                }}
                                            className={errors.hol_startdate && touched.hol_startdate ? "input-error w-100" : "w-100"}
                                            minDate={dayjs(values.hol_startdate, 'YYYY-MM-DD')}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.hol_startdate && touched.hol_startdate && <span className="error">{errors.hol_startdate}</span>}
                                </Col>

                                {/* --------------Holiday End Date--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Holiday End Date</h6>
                                    {/* <Form.Control
                                        type="date"
                                        id="hol_enddate"
                                        min={values.hol_startdate!=null && values.hol_startdate!=''? values.hol_startdate : new Date().toISOString().split("T")[0]}
                                        value={values.hol_enddate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.hol_enddate && touched.hol_enddate ? "input-error" : ""}
                                    />
                                    {errors.hol_enddate && touched.hol_enddate && <p className="error">{errors.hol_enddate}</p>} */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.hol_enddate, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "hol_enddate",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, hol_enddate: true });
                                            }}
                                            name="hol_enddate"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'hol_enddate',
                                                },
                                                }}
                                            className={errors.hol_enddate && touched.hol_enddate ? "input-error w-100" : "w-100"}
                                            minDate={values.hol_startdate!=null && values.hol_startdate!=''? dayjs(values.hol_startdate, 'YYYY-MM-DD') : dayjs()}
                                            format="DD-MM-YYYY"
                                             />
                                    </LocalizationProvider>
                                    {errors.hol_enddate && touched.hol_enddate && <span className="error">{errors.hol_enddate}</span>}
                                </Col>



                            </Row>
                        </div>
                    </Stack>
                    {/* --------------Contact Details End--------------- */}




                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={handleCancel}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>


                </div>
            </Form>
        </>);
}

export default EditHolidays;