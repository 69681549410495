import React from "react";

const PageNotFound = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-white">
            <h1 className="display-6 fw-bold text-primary">404 Error</h1>
            <h1 className="display-6 fw-bold text-muted">Page Not Found</h1>
        </div>
    );
};

export default PageNotFound;
