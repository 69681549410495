/*
Purpose: list details of lead with use datatable
Developer: BY R.SD
Date:23/08/2023 
Project: TCMS
*/
import React from 'react';
import { useState, useEffect } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import Moment from 'moment';
import {
  security_key, BASE_URL
} from "../global";

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListActivity = () => {

  const [loading, setLoading] = useState(true);

  // const [Id, setId] = useState(null);

  // const [studentkey, setStudentKey] = useState(null);


  const location = useLocation();
  useEffect(() => {
   
    //const extractedId = urlParams.get("id");
    const { pk_lesson_key, pk_student_key } = location.state;
    

    if (pk_lesson_key !== '' && pk_lesson_key !== '') {
      // setId(pk_lesson_key);
      // setStudentKey(pk_student_key);

      fetchData(pk_lesson_key, pk_student_key);


    }
  }, [location]);
  //Get key value end


  // Sample data source

  //Api call start
  const [data, setItems] = useState([]);
  const [records, setRecords] = useState(data);


  const fetchData = async (pk_lesson_key, pk_student_key) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getLessonActivity",
        pk_lesson_key: pk_lesson_key,
        pk_student_key: pk_student_key
      });
     
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

       

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  // Sample columns configuration
  const columns = [
    {
      headerName: 'Activity Name',
      field: "Activity Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.node_name == null || params.row.node_name == '' ? '-' : params.row.node_name}`;
      },

    },
    {
      headerName: 'Title',
      field: "Title",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.title == null || params.row.title == '' ? '-' : params.row.title}`;
      },
    },
    {
      headerName: 'Description',
      field: "Description",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.description == null || params.row.description == '' ? '-' : params.row.description}`;
      },
    },
    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },
    },
    {
      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
    },
    {
      headerName: 'Start Time',
      field: "Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_time == null || params.row.ses_start_time == '' ? '-' : Moment(params.row.ses_start_time, 'HH:mm:ss').format('HH:mm')}`;
      },

    },
    {
      headerName: 'End Time',
      field: "End Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_end_time == null || params.row.ses_end_time == '' ? '-' : Moment(params.row.ses_end_time, 'HH:mm:ss').format('HH:mm')}`;
      },
    },
    {
      headerName: 'Tutor Name',
      field: "Tutor Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },
    },

  ];



  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />
      <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Lesson Activity List </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Lesson Activity</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
            {/* <NavLink className="primary-filled rounded-10 p-2" to='/AddSubject' >
                <AddIcon className="btn-icon"/> New Subject</NavLink> */}
          </div>
        </div>



        {/* --------------Section Start--------------- */}

        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            <Row>
              <Col xs="12">
                <div className="">

                  <Row className="justify-content-center">


                    {/* --------------Table Content Start--------------- */}
                    <Col sm={12}>


                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "LessonList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "LessonList" },
                              records,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns}
                          rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                        />


                      </Box>

                    </Col>
                    {/* --------------Table Content Start--------------- */}

                  </Row>

                </div>
              </Col>
            </Row>

            {/* --------------Content End--------------- */}


          </Card.Body>


        </Card>




        {/* --------------Page End--------------- */}

      </div>
    </>
  );


};

export default ListActivity;
