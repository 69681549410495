import React from 'react';
import { Form, DatePicker, Button, Space } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from "yup";

const YourFormComponent = () => {
  const formik = useFormik({
    initialValues: {
      selectedDate: null,
    },
    validationSchema: yup.object().shape({
      selectedDate: yup.date().required('Please select a date'),
    }),
    onSubmit: (values) => {

    },
  });

  return (
    <Form onFinish={formik.handleSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Form.Item
        label="Select Date"
        name="selectedDate"
        rules={[{ required: true, message: 'Please select a date' }]}
      >
        <DatePicker
          value={formik.values.selectedDate ? moment(formik.values.selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY')  : null} format={'DD-MM-YYYY'}
          placeholder="DD-MM-YYYY" onChange={(date, dateString) => formik.setFieldValue('selectedDate', dateString)}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={formik.handleReset}>Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default YourFormComponent;
