/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
import PhoneInput from 'react-phone-input-2';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, BASE_URL} from "../global";

const basicSchema = yup.object().shape({
    cw_number: yup.mixed().required("WhatsApp number is required."),
});



function CentreWhatsApp() {
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const [CentreData, setCentreData] = useState([]);

    //const [id, setId] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== '') {
            
            fetchData(extractedId);
        }
    }, [location]);
    
    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getSingleCenterWhatsAppDetails",
                center_key: sessionStorage.getItem('key'),
                fk_ci_id: sessionStorage.getItem('user_id'),
                user_type: sessionStorage.getItem('user_type'),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCentreData(response.data.data[0]);
                   
                }

               
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);

            
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                security_key: security_key,
                mode: "UpdateSingleCenterWhatsAppDetails",
                ...formValues,
            });

            if (response.data.success === true) {
                // Reset the form
                if(response.data.data === 1){
                    toast.success("Success! Your form was submitted", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    // After 3 seconds, redirect to another page
                    setTimeout(function () {
                        navigate("/WhatsApp");
                    }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    setIsSubmitting(false); // Re-enable the submit button
                }else if(response.data.data === 2){

                    toast.error("Student username is not available", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }else if(response.data.data === 3){
                    toast.error("Parents / Guardians username is not available", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }else{
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
                
            } else {

                toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button
                
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
            
        } finally {
            setIsSubmitting(false); // Re-enable the submit button
            
        }
          
    };

   

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched } = useFormik({
        initialValues: {
            /*tbl_certre*/
            cent_incharge: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
            cw_number: CentreData ? CentreData.cw_number : '',
            fk_ci_id: sessionStorage.getItem('user_id'),
            /*Common*/

            updated_by: sessionStorage.getItem('key'),
            fk_cent_key: sessionStorage.getItem('key'),
            user_type: sessionStorage.getItem('user_type')

        },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit
    });


    
    return (
        <>
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> WhatsApp Deatils </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">WhatsApp</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>



                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>WhatsApp Details</h6>
                            </div>
                            <Row>

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Centre Incharge</h6>
                                    <Form.Control type="text" 
                                        value={values.cent_incharge}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly
                                        id="cent_incharge"
                                        className={errors.cent_incharge && touched.cent_incharge ? "input-error" : ""}
                                    />
                                    {errors.cent_incharge && touched.cent_incharge && <p className="error">{errors.cent_incharge}</p>}
                                </Col>

                                
                                
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-label">WhatsApp Number</h6>
                                    <PhoneInput
                                        name="cw_number"
                                         countryCodeEditable={true}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={ sessionStorage.getItem('country_alph_code')!=null &&  sessionStorage.getItem('country_alph_code')!='' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.cw_number}
                                        onChange={(value) =>  {
                                            const event = {
                                            target: {
                                                name: "cw_number",
                                                value: value,
                                            },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cw_number: true });
                                        }}
                                        id="cw_number"
                                        inputClass={errors.cw_number && touched.cw_number ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.cw_number && touched.cw_number && <p className="error">{errors.cw_number}</p>}
                                </Col>
                                


                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default CentreWhatsApp;