/*
Designed By R.Sanjai
Created : 24/08/2023
Screen : Log-in
*/

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { FiEye, FiEyeOff } from "react-icons/fi"; // Assuming you've imported the eye icons

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.css';

import 'react-notifications-component/dist/theme.css'
import { security_key, BASE_URL} from "../global";

//const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const basicSchema = yup.object().shape({
    ca_login_id: yup.string().required("A username is required.").min(2, "Minimum 2 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    ca_password: yup.string().min(5, "Minimum 5 characters is required.").required("A password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
});

function AdminLogin() {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    
   
    const navigate = useNavigate();


  const [isAdminCheck, setIsAdminCheck] = useState(null);
  const location = useLocation();


useEffect(() => {

    document.title = 'TCMS SuperAdmin';
    const checkAdminStatus = async () => {
        const isAdminValue = sessionStorage.getItem('is_admin');
        if (isAdminValue !== null && isAdminValue === '1') {
          navigate("/admin/admin-list");
        } else {
            setIsAdminCheck(false); // Set state to indicate that the condition is checked
        }
      };
  
      checkAdminStatus();
}, [location]);

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_center_auth*/
        ca_login_id: "", //Not in db
        ca_password: "",
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } =
        useFormik({
            // const formik = useFormik({
            initialValues,
            validationSchema: basicSchema,
            // onSubmit,
            onSubmit: async (formValues) => {
                // Disable the submit button and reset alerts
                setIsSubmitting(true);

                    try {
                        const response = await axios.post(BASE_URL+"API/api_index.php", {
                            security_key: security_key,
                            mode: "checkSuperAdminAndCenterSuperAdmin",
                            ...formValues,
                        });
    
                        if (response.data.success === true) {
                            // Reset the form
    
                            if (response.data.data.length > 0) {
    
                                resetFormFields(resetForm, setValues, initialValues);
    
                                if (response.data.data[0]['pk_sa_key'] !== '') {
    
                                    sessionStorage.setItem('key', response.data.data[0]['pk_sa_key']);
                                    sessionStorage.setItem('is_admin', '1');
                                    sessionStorage.setItem('center_name', 'Admin');
                                    sessionStorage.setItem('first_name', response.data.data[0]['sa_first_name']);
                                    sessionStorage.setItem('sur_name', response.data.data[0]['sa_last_name']);
                                    sessionStorage.setItem('user_id', response.data.data[0]['pk_sa_id']);
                                    //0-super admin
                                    //1-Center super admin and admin.

                                    sessionStorage.setItem('user_type', response.data.data[0]['user_type']);
                                    sessionStorage.setItem('isAdminSuccess', '1');
                                    // Store.addNotification({
                                    //     title: "Login Success!",
                                    //     message: "You have successfully logged into your account.",
                                    //     type: "success",
                                    //     insert: "top-center",
                                    //     container: "top-center",
                                    //     animationIn: ["animate__animated", "animate__fadeIn"],
                                    //     animationOut: ["animate__animated", "animate__fadeOut"],
                                    //     dismiss: {
                                    //       autoClose: 1000,
                                    //       onScreen: true
                                    //     }
                                    //   });
                                    
                                    // toast.success("You have successfully logged into your account.", {
                                    //     autoClose: 1000, // 3 seconds in this example
                                    // });
                                    // After 3 seconds, redirect to another page
                                    //setTimeout(function () {
                                        navigate("/admin/admin-list");
                                   // }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds
    
                                } else {
                                    // Store.addNotification({
                                    //     title: "Login Failed!",
                                    //     message: "The entered username or password is wrong.",
                                    //     type: "danger",
                                    //     insert: "top-center",
                                    //     container: "top-center",
                                    //     animationIn: ["animate__animated", "animate__fadeIn"],
                                    //     animationOut: ["animate__animated", "animate__fadeOut"],
                                    //     dismiss: {
                                    //       autoClose: 2000,
                                    //       onScreen: true
                                    //     }
                                    //   });
                                    toast.error("The entered username or password is wrong.", {
                                        autoClose: 2000, // 3 seconds in this example
                                    });
                                    setIsSubmitting(false); // Re-enable the submit button
                                }
    
                                // alert("Success! Your form was submitted.");
    
                            } else {
    
                                // Store.addNotification({
                                //     title: "Login Failed!",
                                //     message: "The entered username or password is wrong.",
                                //     type: "danger",
                                //     insert: "top-center",
                                //     container: "top-center",
                                //     animationIn: ["animate__animated", "animate__fadeIn"],
                                //     animationOut: ["animate__animated", "animate__fadeOut"],
                                //     dismiss: {
                                //       autoClose: 2000,
                                //       onScreen: true
                                //     }
                                //   });
                                toast.error("The entered username or password is wrong.", {
                                    autoClose: 2000, // 3 seconds in this example
                                });
                                setIsSubmitting(false); // Re-enable the submit button
                            }
    
                        } else {
                            // Store.addNotification({
                            //     title: "Login Failed!",
                            //     message: "The entered username or password is wrong.",
                            //     type: "danger",
                            //     insert: "top-center",
                            //     container: "top-center",
                            //     animationIn: ["animate__animated", "animate__fadeIn"],
                            //     animationOut: ["animate__animated", "animate__fadeOut"],
                            //     dismiss: {
                            //       autoClose: 2000,
                            //       onScreen: true
                            //     }
                            //   });
                            toast.error("The entered username or password is wrong.", {
                                autoClose: 2000, // 3 seconds in this example
                            });
                            setIsSubmitting(false); // Re-enable the submit button
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        // Store.addNotification({
                        //     title: "Login Failed!",
                        //     message: "Unable to login. Please check your username and password or Contact TCMS Team.",
                        //     type: "danger",
                        //     insert: "top-center",
                        //     container: "top-center",
                        //     animationIn: ["animate__animated", "animate__fadeIn"],
                        //     animationOut: ["animate__animated", "animate__fadeOut"],
                        //     dismiss: {
                        //       autoClose: 2000,
                        //       onScreen: true
                        //     }
                        //   });
                        toast.error("The entered username or password is wrong.", {
                            autoClose: 2000, // 3 seconds in this example
                        });
                        setIsSubmitting(false); // Re-enable the submit button
                    } finally {
                        setIsSubmitting(false); // Re-enable the submit button
                    }
                
            },
        });


        const [passwordVisible, setPasswordVisible] = useState(false);


        const togglePasswordVisibility = () => {
            setPasswordVisible(!passwordVisible);
        };
        
       

    return (
        <>{isAdminCheck !== null ? (
            <>
            <ToastContainer position="top-center" />
            
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}  className='page-center cus-login mx-auto'>
                <Container className='position-relative cus-form'>

                 
                    <Row className='bg-white justify-content-center rounded-15 p-3'>
                        <Col xs="12" md="12" lg="12">

                            <div className='justify-content-center'>
                                <div className='text-center'>
                                    <img src="mygooroo-tcms.png" width={150} className="page-center mb-2" />
                                    <p className="text-center para"><strong>SuperAdmin</strong></p>
                                    <p className="text-center para">Tutoring Centre Management System (TCMS)</p>
                                </div>

                                <div className='mt-4'>
                                    <div className='position-relative'>
                                        <h6 className="form-lable required-field">Username</h6>
                                        <Form.Control
                                            type="text"
                                            
                                            value={values.ca_login_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="ca_login_id"
                                            className={errors.ca_login_id && touched.ca_login_id ? "input-error" : ""}
                                        />
                                        {errors.ca_login_id && touched.ca_login_id && <p className="lo-error">{errors.ca_login_id}</p>}
                                    </div>
                                    <div className='position-relative'>
                                        <h6 className="form-lable required-field">Password</h6>

                                        <Form.Control
                                              type={passwordVisible ? "text" : "password"}
                                            
                                            value={values.ca_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="ca_password"

                                            className={errors.ca_password && touched.ca_password ? "input-error"
                                                : ""}
                                        />
                                         <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="password-toggle-button pswlgin" title="show / hide"
                                        >
                                            {passwordVisible ? <FiEye /> : <FiEyeOff />}

                                        </button>
                                        {errors.ca_password && touched.ca_password && <p className="lo-error">{errors.ca_password}</p>}

                                    </div>
                                    <div className='text-center'>

                                        <Button type="submit" variant="contained" className='primary-filled w-100 py-2 mt-3'
                                            disabled={isSubmitting}>Sign in</Button>

                                    </div>

                                </div>
                            </div>
                        </Col>
                
                    </Row>
               
                </Container>
            </Form> </>) : (null) };
        </>
    );
}

export default AdminLogin;