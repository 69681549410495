/*
Purpose: Generate a session
Designed: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useNavigate} from 'react-router-dom';




function GenerateSession() {
    const navigate = useNavigate();

    return (
        <Container className="mb-5">

            <Row>
                <Col xs="12">
                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <h3>  Generate Session</h3>
                        <button type="button" onClick={() => navigate('/')} className="btn btn-outline-dark btn-sm"><i class="fa-solid fa-house"></i></button>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={8}>

                    {/* --------------Student --------------- */}


                    <Row className="justify-content-center">
                        <Col xs={12} >
                            <div className="p-4 bg-2">
                                <h5 className="mb-0">Generate Session</h5>
                            </div>
                        </Col>
                        <Col xs={12} >
                            <Stack>
                                <div className="p-4 bg-white">
                                    <Form>


                                        {/* --------------Start Time --------------- */}
                                        <Form.Group as={Row} className="mb-3" controlId="formStartTime">
                                            <Form.Label column sm="3">
                                                <h6>Session Time</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="time"  />

                                            </Col>
                                        </Form.Group>

                                        {/* --------------Session From Date--------------- */}
                                        <Form.Group as={Row} className="mb-3" controlId="formSessionFromDate">
                                            <Form.Label column sm="3">
                                                <h6> From Date</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="date"  />
                                            </Col>
                                        </Form.Group>

                                        {/* --------------Session To Date--------------- */}
                                        <Form.Group as={Row} className="mb-3" controlId="formSessionToDate">
                                            <Form.Label column sm="3">
                                                <h6> To Date</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="date"  />
                                            </Col>
                                        </Form.Group>

                                        {/* -------------- Skip Holidays --------------- */}
                                        {/* --------------Contact Checkbox--------------- */}
                                        <Form.Group className="mb-3 d-dlex" controlId="formcheckbox">
                                            <Form.Label column sm="3">

                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="skippublic"
                                                    label="Skip public holidays"
                                                />

                                                <Form.Check
                                                    type="checkbox"
                                                    id="skipschool"
                                                    label="Skip school holidays"
                                                />
                                            </Col>
                                        </Form.Group>
                                        {/* --------------Notes --------------- */}
                                        <Form.Group as={Row} className="mb-3" controlId="formNotes">
                                            <Form.Label column sm="3">
                                                <h6>Notes</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control as="textarea" rows={3}  />


                                            </Col>
                                        </Form.Group>



                                    </Form>
                                </div>
                            </Stack>
                        </Col>
                    </Row>
                    {/* --------------Additional Details End--------------- */}

                    <Stack gap={2} className="mx-auto mt-4 d-flex">
                        <Container>
                            <Row>
                                <Col sm="8" className="mx-auto text-center">
                                    <Button variant="primary" className="px-5 py-2 me-2 btn1" >Save</Button>
                                    <Button variant="outline-primary" className="px-5 py-2 btn-cancel-outline">Cancel</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Stack>

                </Col>
            </Row>

        </Container>
    );
}

export default GenerateSession;