import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const data = [
    { id: 1, name: 'John Doe', age: 30 },
    { id: 2, name: 'Jane Smith', age: 25 },
    // Add more data...
  ];
  
  const columns = [
    { name: 'ID', 
    selector: 'id', 
    sortable: true },
    {
        accessorKey: 'name',
        header: 'Name',
        filterVariant: 'text', // default


     
    selector: 'name',


     sortable: true },
    { name: 'Age',
     selector: 'age', 
     sortable: true },
    // Define more columns...
  ];
  const MyDataTable = () => {
    const [filteredData, setFilteredData] = useState(data);
  
    const handleColumnFilter = (column, value) => {
      const filteredRows = data.filter(row =>
        row[column].toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filteredRows);
    };
  
    const context = React.useMemo(() => {
      return {
        onColumnSort: (column, direction) => {
          if (direction === 'ASC' || direction === 'DESC') {
            setFilteredData([...filteredData].sort((a, b) => {
              if (direction === 'ASC') {
                return a[column].localeCompare(b[column]);
              } else {
                return b[column].localeCompare(a[column]);
              }
            }));
          }
        },
      };
    }, [filteredData]);
  
    return (
      <DataTable
        title="User Data"
        columns={columns}
        data={filteredData}
        pagination
        context={context}
        customStyles={{
          search: {
            input: {
              fontSize: '16px',
            },
          },
        }}
        className="list-cus-h"

        onSearch={query => handleColumnFilter('name', query)}
        // Additional props and customization options can be added here
      />
    );
  };
  
  export default MyDataTable;
