import React, { useState, useEffect } from 'react';
import { Dropdown, Card } from 'react-bootstrap';
import Box from '@mui/material/Box';
import PaymentIcon from '@mui/icons-material/Payment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Moment from 'moment';
import "moment-timezone";
import { formatDistance } from 'date-fns'

import { toast } from 'react-toastify';

import { security_key, BASE_URL } from "../global";


const NotificationDropdown = () => {
    const [notifications, setNotifications] = useState([]);



    const MINUTE_MS = 10000;

    useEffect(() => {

        const interval = setInterval(() => {
            fetchDRData();
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, []);
    //Defer Request Call start

    const fetchDRData = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAllCSDeferRequest_notifi",
                fk_cent_key: sessionStorage.getItem('key')
            });

            if (response.data.success === true) {
                setNotifications(response.data.data);

                //setItems(response.data.data);
            } else {
                //alert message provide
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const removeNotification = (id) => {
        setNotifications((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
    };


    // Your custom date and time in ISO 8601 format
    const yourCustomDateTime = new Date('2023-01-01T12:30:00');

    // Get the current date and time
    const currentDateTime = new Date();

    // Calculate the distance between your custom date and time and the current date and time
    const distance = formatDistance(yourCustomDateTime, currentDateTime, { addSuffix: true });
    const [isnotifiDelSubmitting, setIsnotifiDelSubmitting] = useState(false);

 

    function onSubmitViewNotification(data) {
        setIsnotifiDelSubmitting(true);

        const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

            security_key: security_key,
            fk_cent_key: sessionStorage.getItem('key'),
            mode: "viewNotification_notifi",
            pkkey: data.pkkey,
            pkkeytype: data.pkkeytype,
            ptype: data.ptype,
            type: data.type,
        }).then((responseforfeit) => {
            // Handle the successful response here
            toast.success("Success! Viewed notification '" + data.type + "'", {
                autoClose: 3000, // 3 seconds in this example
            });

            // handleTutSessClick(data_forfeit);
            // setTimeout(function () {
            //   window.location.reload(false);

            // }, 3000);
            fetchDRData();
            setIsnotifiDelSubmitting(false);

        })
            .catch((error) => {
                // Handle any errors that occurred during the request
                toast.error("Failure! Unable to hidden viewed notification. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });
                setIsnotifiDelSubmitting(false);

            });
    }


    // const targetTimezone = 'Asia/Singapore';

    const timezoneIdentifier = sessionStorage.getItem('timezone_identifier');
    Moment.tz.setDefault(timezoneIdentifier);


    return (<>
        <div className="notification-dropdown">

            {/* <button onClick={addNotification}>Add Notification</button> */}

            <Box sx={{ color: 'action.active', }}>

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className='no-design'>
                        <NotificationsIcon />
                        <div className='notification-batch'>

                            { (notifications.length >= 99) ? '99+' : notifications.length}

                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='p-0  overflow-auto notifiMain' style={{ height: '400px' }}>
                        {notifications.length > 0 ? (<>
                            {notifications.map((notification) => {


                                const currentDateAndTime = Moment().toDate();

                                const createdOnDate = Moment(notification.created_on).toDate();
                             

                                const distanceString = formatDistance(createdOnDate, currentDateAndTime, { addSuffix: true,timeZone: timezoneIdentifier });


                                
                                return (<><Dropdown.Item href="#" className='drop-pad '>
                                    <Card className='notification-card'>
                                        <Card.Body>
                                            <div className='notification-content'>
                                                <div className='notification-icon1'>
                                                    {notification.icon == 2 ? <PaymentIcon /> : <BookmarkAddIcon />

                                                    }
                                                </div>
                                                <div className='notification-details ms-2 w-100'>
                                                    <p className='mb-0 req-h'>
                                                        {notification.title}<VisibilityOffIcon disabled={isnotifiDelSubmitting} onClick={() => onSubmitViewNotification(notification)} className="float-end nc-icon" />
                                                    </p>

                                                    <div className="notification-dt">
                                                        {/* <p className='mb-0 req-c'>
                                                        {notification.subject_name}
                                                        </p> */}
                                                        {/* <p className='mb-0 cnt-div'>
                                                            |
                                                        </p> */}
                                                        <p className='mb-0 req-c'>

                                                      {  (notification.pkkeytype == 1) ? (<><span class="badge text-bg-primary">{notification.type}</span></>) : (notification.pkkeytype == 2) ? (<><span class="badge text-bg-success">{notification.type}</span></>) : (notification.pkkeytype == 3) ? (<><span class="badge text-bg-info ">{notification.type}</span></>) : ''
                                                      
                                                            
                                                        
                                                        }
                                                        </p>
                                                    </div>
                                                    <div className="notification-dt">
                                  
                                                        <p className='mb-0 req-c'>
                                                       {notification.details
                                                      
                                                            
                                                        
                                                    }
                                                        </p>
                                                    </div>
                                                    <div className="bgcredinfo mt-2">

                                                    <span className='mb-0 req-tcred fst-italic float-start '>
                                                       
                                                    { notification.createdontext}

                                                        </span>
                                                      
                                                        <span className='mb-0 req-tcred fst-italic float-end '>
                                                            {
                                                                
                                                                distanceString
                                                            }
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Dropdown.Item></>);




                            })}
                        </>) : (
                            <Dropdown.Item href="#" className='drop-pad '>
                                <Card className='notification-card'>
                                    <Card.Body>
                                        <div className='notification-content'>

                                            <div className='notification-details ms-2'>
                                                <p className='mb-0 '> No notification.
                                                </p>

                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>

            </Box>
        </div>
    </>);
};

export default NotificationDropdown;