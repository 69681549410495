/*
Purpose: Header of AP
Developer: BY P.KS
Date:22/08/2023
Project: TCMS
*/

/*
Purpose: Header of AP
Designer: R.Sanjai
Date:23/08/2023
Project: TCMS
*/

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';

import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Notifications from './Notifications';



const Header = ({ isOpen, toggleSidebar, handleSearchChange }) => {

    

    ///Restrict for Login component
    const location = useLocation();
    const navigate = useNavigate();

    // List of routes where the header should not be displayed
    const headerExcludedRoutes = ['/'];


    //default sidebar
    const url = location.pathname + location.search;

    if (headerExcludedRoutes.includes(location.pathname)) {
        return null; // Don't render the header
    }



    const handleSearchPage = () => {

        // Redirect to the login page
        navigate("/StudentSearch");

    };

    const handleSearchPage1 = (event) => {

        if (event.key == 'Enter') {
            event.preventDefault();
            handleSearchPage();
        }
        // Redirect to the login page


    };


    //const [data, setData] = useState([]); // Unconditionally call useState



    //   if (sessionStorage.getItem('key') == 0) {
    //     const new_data = [
    //       {
    //         user_name: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
    //         account_type: "Super Admin"
    //       }
    //     ];
    //     setData(new_data);
    //   } else {
    //     const new_data = [
    //       {
    //         user_name: sessionStorage.getItem('first_name') + ' ' + sessionStorage.getItem('sur_name'),
    //         account_type: "Center Administrator"
    //       }
    //     ];
    //     setData(new_data);
    //   }
    const keychk = sessionStorage.getItem('is_admin');

    const styles = {
        popup: {
            display: keychk == '1' ? "block" : "none",

        },
        popup1: {
            display: keychk != '1' ? "block" : "none",

        }
    }

    

    const handleGoBack = () => {
        //const location = useLocation();
        
        if (['/calendar'].includes(location.pathname)) {
           
            return null; // Don't render the header
        }

        window.history.back();


    };


    return (
        <div {...(isOpen && { className: "headLMOpen" })} >
            <div id="navbar" className='d-flex align-items-center flex-grow-1 justify-content-between py-2'>
                <div className='d-flex gap-3 align-items-center'>
                    <IconButton className='cus-menu-btn my-button mt-1' variant="contained" aria-label="delete" size="large"
                        sx={{ m: 0, ...(isOpen && { display: 'none' }) }}
                        onClick={toggleSidebar} >
                        <MenuIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton sx={{ m: 0, ...(!isOpen && { display: 'none' }) }} className='cus-menu-btn my-button mt-1' onClick={toggleSidebar} edge="start" color="inherit" aria-label="menu"   >
                        <ArrowBackIosNewIcon fontSize="inherit" />
                    </IconButton>
                    {/* Added here UX perspective*/}


                    {/* { sessionStorage.getItem('is_admin') == 1 ? ( */}
                    <div className='site-identity custom-d mb-0'>
                        <Image src="mygooroo-tcms.png" width={96} className="img-fluid mx-auto d-block" />
                    </div>

                    {/* ) : (
                            <div className='site-identity'>
                            <Image src="logo.png" width={120} className="img-fluid mx-auto d-block" />
                        </div>
                        )
                    } */}
                    <Button className="secondary-filled p-0" variant="contained" onClick={() => handleGoBack()}>
                        <ChevronLeftIcon fontSize="md" />Back
                    </Button>
                </div>
                <div className='d-flex align-items-center justify-content-end gap-2'>
                    <div className='search-section'>
                        <Paper component="form"
                            sx={{ p: '5px 2px 5px 10px', display: 'flex', alignItems: 'center', width: 350, borderRadius: 3 }}>

                            <InputBase sx={{ flex: 1 }}
                                inputProps={{ 'aria-label': 'Search' }} id='global_search' onChange={handleSearchChange} onKeyDown={handleSearchPage1} placeholder='Search here...' />
                            <IconButton type="button" sx={{ p: '0px', m: 0 }} aria-label="search" onClick={handleSearchPage} >
                                <SearchIcon />
                            </IconButton>

                        </Paper>
                    </div>
                    <div className='notification'>
                        <Notifications />

                    </div>
                </div>


            </div>
        </div>
    );

}

export default Header;