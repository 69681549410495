/*
Purpose: list details of lead with use datatable
Developer: BY R.SD
Date:23/08/2023 
Project: TCMS
*/
import React from 'react';
import { useState, useEffect } from "react";
import { useLocation,NavLink,useNavigate } from 'react-router-dom';

import DataTable from "react-data-table-component";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Stack from 'react-bootstrap/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Moment from 'moment';
import { security_key, BASE_URL} from "../global";

  import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListSlot = () => {
  
  
  //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [IsSlotAssigned, setIsSlotAssigned] = useState(false);
  
  const [Id, setId] = useState(null);
  const [cancel_session_key, setCancelSessionKey] = useState(null);
  const [enrol_key, setEnrolKey] = useState(null);
  const [fk_ses_key, setSessionKey] = useState(null);




  const [stu_name, setStudentName] = useState(null);
  const [subject, setSubject] = useState(null);
 
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const { cancel_session_key,fk_student_key,pk_enrol_key,st_first_name,st_surname,subject_name,fk_ses_key } = location.state || {};

    if(st_first_name!='' || st_first_name!=null){
      setStudentName(st_first_name+' '+st_surname);
      setSubject(subject_name);
    }
        
    if (cancel_session_key !== '' && fk_student_key !=='' && cancel_session_key !== undefined && fk_student_key !== undefined && pk_enrol_key !== undefined && fk_ses_key !== undefined) {
      setId(fk_student_key);
      setCancelSessionKey(cancel_session_key);
      setEnrolKey(pk_enrol_key);
      setSessionKey(fk_ses_key);
      fetchData(fk_student_key);

      fetchexstingData(cancel_session_key)
     
    }else{
      navigate("/calendar");
    }
  }, [location]);
  //Get key value end


  const customeStyle = {
    headRow: {
      style: {
        borderRadius: 'var(--br-3xs)',
        backgroundColor: 'var(--color-gainsboro-400)'
      }
    }
  };
  


 
   // Sample data source
    
     //Api call start
     const [data, setItems] = useState([]);
     const [dataExist, setExist] = useState([]);
     const [dataExisRecord, setExstingRecord] = useState([]);
     const [records, setRecords] = useState(data);
     const [selectedRows, setSelectedRows] = useState([]);
    

     const fetchData = async (student_key) => {
         try {
             const response = await axios.post(BASE_URL+'API/api_index.php', {
               security_key: security_key,
               mode:"getAllSlotSession",
               fk_student_key: student_key,
               fk_cent_key: sessionStorage.getItem('key')
             });
            
             if(response.data.success===true){
               setRecords(response.data.data);
               setItems(response.data.data);
               setLoading(false);

             }else{
               //alert message provide
               setLoading(false);

             }
             
             
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };


     const fetchexstingData = async (cancel_key) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
              security_key: security_key,
              mode:"getAllExstingSlotSession",
              fk_cancel_key: cancel_key,
              fk_cent_key: sessionStorage.getItem('key')
            });
            
            if(response.data.success===true){
              setExstingRecord(response.data.data);
              setExist(response.data.data);
              setLoading(false);

            }else{
              //alert message provide
              setLoading(false);

            }
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

     //Api call End
   

     const handeleChangerow = async(e) => { 
       setSelectedRows(e.selectedRows)
       
    }
  

    
// Sample columns configuration
const columns = [
 
{ id: 'date',
name: 'Date',
selector: 'ses_date',
cell: row => Moment(row.ses_date,'YYYY-MM-DD').format('DD-MM-YYYY'),
sortable: true},
{
    name: 'Session Name',
    selector: row => row.sest_name,
    sortable: true,
  },
  {
    name: 'Day',
    selector: row => row.sest_day,
    sortable: true,
  },
  {
    name: 'Start Time',
    selector: row => Moment(row.ses_start_time, "HH:mm:ss").format('HH:mm'),
    sortable: true
  },
  {
    name: 'End Time',
    selector: row => Moment(row.ses_end_time, "HH:mm:ss").format('HH:mm'),
    sortable: true
  },
  {
    name: 'Tutor',
    selector: row => row.tut_fname + ' ' + row.tut_surname,
    sortable: true,
  },
  { name: "pk_ses_key", selector: (row) => row.pk_ses_key, omit: true },
  { name: "pk_sest_key", selector: (row) => row.pk_sest_key, omit: true },
  { name: "pk_tut_key", selector: (row) => row.pk_tut_key, omit: true }
];



const columns_e = [
 
  { id: 'date1',
  name: 'Date',
  selector: 'ses_date',
  cell: row => Moment(row.ses_date,'YYYY-MM-DD').format('DD-MM-YYYY'),
  sortable: true},
  {
      name: 'Session Name',
      selector: row => row.sest_name,
      sortable: true,
    },
    {
      name: 'Day',
      selector: row => row.sest_day,
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => Moment(row.ses_start_time, "HH:mm:ss").format('HH:mm'),
      sortable: true
    },
    {
      name: 'End Time',
      selector: row => Moment(row.ses_end_time, "HH:mm:ss").format('HH:mm'),
      sortable: true
    },
    {
      name: 'Tutor',
      selector: row => row.tut_fname + ' ' + row.tut_surname,
      sortable: true,
    }
  ];



  function handleFilter(event) {
    const inputValue = event.target.value.trim().toLowerCase();
    const newData = data.filter(row => {
      return (
        Moment(row.ses_date,'YYYY-MM-DD').format('DD-MM-YYYY').includes(inputValue) ||
        row.sest_name.toLowerCase().includes(inputValue) ||
        row.sest_day.toLowerCase().includes(inputValue) ||
        Moment(row.ses_start_time, "HH:mm:ss").format('HH:mm').includes(inputValue) ||
        Moment(row.ses_end_time, "HH:mm:ss").format('HH:mm').includes(inputValue) ||
        (row.tut_fname + ' ' + row.tut_surname).toLowerCase().includes(inputValue) 
      );
    });
    
    setRecords(newData);
  }
  


function handleFilter_e(event) {
  const inputValue1 = event.target.value.trim().toLowerCase();
  const newData = dataExist.filter(row => {
    return (
      Moment(row.ses_date,'YYYY-MM-DD').format('DD-MM-YYYY').includes(inputValue1) ||
      row.sest_name.toLowerCase().includes(inputValue1) ||
      row.sest_day.toLowerCase().includes(inputValue1) ||
      Moment(row.ses_start_time, "HH:mm:ss").format('HH:mm').includes(inputValue1) ||
      Moment(row.ses_end_time, "HH:mm:ss").format('HH:mm').includes(inputValue1) ||
      (row.tut_fname + ' ' + row.tut_surname).toLowerCase().includes(inputValue1) 
    );
  });
  
  setExstingRecord(newData);
}




function onSubmitSlotAssigned() {


  setIsSlotAssigned(true);
    
  axios.post(BASE_URL + "API/api_index.php", {

    security_key: security_key,
    fk_cent_key: sessionStorage.getItem('key'),
    mode: "AssignSessionArray",
    data: selectedRows,
    cencel_key: cancel_session_key,
    student_key: Id,
    enrol_key: enrol_key,
    fk_ses_key: fk_ses_key
  }).then((responseSlotAssigned) => {

    if(responseSlotAssigned.data.data==1){
      
    
      // Handle the successful response here
      toast.success("Success! Student slot assigned", {
        autoClose: 3000, // 3 seconds in this example
      });
     
      // handleTutSessClick(data_forfeit);
      setTimeout(function () {
        window.location.reload(false);
       
        setIsSlotAssigned(false);
      }, 1500);
     
    }else{

      toast.error("Oops! Something went wrong.", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsSlotAssigned(false);

    }
    


  })
    .catch((error) => {
      // Handle any errors that occurred during the request
      toast.error("Oops! Something went wrong.", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsSlotAssigned(false);

    });
}



  return (
    <>
            {/* React alert */}
             <ToastContainer position="top-center" />
    <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'> Slot List </h5>
                
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                    <NavLink underline="hover" color="inherit" to='/admin-list'> Home</NavLink>


                        <Typography color="text.primary">Slot</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2'>
                {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                {/* <NavLink className="primary-filled rounded-10 p-2" to='/AddSubject' >
                <AddIcon className="btn-icon"/> New Subject</NavLink> */}
            </div>
          </div>

        

              {/* --------------Section Start--------------- */}  

              <Card className='my-card p-1 bg-white'>

                  <Card.Body>
                          
                      {/* -------------Content Start--------------- */}  

                      <Row>
                        <Col xs="12">
                            <div className="">

                                <Row className="justify-content-center">

                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>
                                      
                                      <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                                       
                                      <div class="form-group ">
                                          <span class="">{stu_name} - {subject}</span>
                                          
                                        </div>

                                        <div class="form-group has-search my-auto">
                                          <span class="fa fa-search form-control-feedback"></span>
                                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                                        </div>
      

                                      </div>

                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 
                                    


                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>
                                      
                                          <DataTable
                                              progressPending={loading} 
                                              columns={columns}
                                              data={records}
                                              fixedHeader
                                              pagination
                                              selectableRows
                                              selectedRows={selectedRows}
                                              onSelectedRowsChange={handeleChangerow}
                                              onRowClicked={handleRowLeadViewClick} 

                                              customStyles={customeStyle}
                                             className="cursor-pointer  list-cus-h"
                                   

                                          />

                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 

                                </Row>
                                
                            </div>
                        </Col>
                      </Row>

                      {/* --------------Content End--------------- */}


                  </Card.Body>
                  <Stack gap={2} className="mb-2 mt-2">
                        <div className="text-end">

                            {/* <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button> */}
                            <Button type="submit" variant="contained" className="primary-filled " disabled={IsSlotAssigned} onClick={onSubmitSlotAssigned} >Assign</Button>
                        </div>
                    </Stack>
                 
              </Card>

              {/* --------------Section End--------------- */}

              <Card className='my-card mt-4  p-1 bg-white'>
                    <Card.Header>  Assigned Slot </Card.Header>          
                <Card.Body>

                    {/* -------------Content Start--------------- */}

                    <Row>
                        <Col xs="12">
                            <div className="">
                                <Stack>

                                    <Row>
                                        <Col xs="12">
                                            <div className="">

                                                <Row className="justify-content-center">

                                                    {/* --------------Table Content Start--------------- */}
                                                    <Col sm={12}>

                                                        <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">






                                                            <div class="form-group has-search my-auto">
                                                                <span class="fa fa-search form-control-feedback"></span>
                                                                <input type="text" class="form-control mb-0" onChange={handleFilter_e} />
                                                            </div>


                                                        </div>

                                                    </Col>
                                                    {/* --------------Table Content Start--------------- */}



                                                    {/* --------------Table Content Start--------------- */}
                                                    <Col sm={12}>

                                                        <DataTable
                                                            progressPending={loading}
                                                            columns={columns_e}
                                                            data={dataExisRecord}
                                                            fixedHeader
                                                            pagination
                                                            //onRowClicked={handleStudentViewClick}
                                                            customStyles={customeStyle}
                                                           className="cursor-pointer  "

                                                        />

                                                    </Col>
                                                    {/* --------------Table Content Start--------------- */}

                                                </Row>

                                            </div>
                                        </Col>
                                    </Row>
                                </Stack>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>




        {/* --------------Page End--------------- */}

    </div>
    </>
);


};

export default ListSlot;
