/*
Purpose: list details of lead with use datatable
Developer: BY R.SD
Date:23/08/2023 
Project: TCMS
*/
import React from 'react';
import { useState, useCallback, useEffect } from "react";
import { NavLink,useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Moment from 'moment';
import { security_key, BASE_URL} from "../global";
  import Box from "@mui/material/Box";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import LinearProgress from "@mui/material/LinearProgress";



const ListLead = () => {
  
  
  //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const handleRowLeadViewClick = async(e) => {
    navigate(`/view-lead`, { state: { pk_lead_key: `${e.pk_lead_key}` }});

 }

   // Sample data source
    
     //Api call start
     const [data, setItems] = useState([]);
    
     useEffect(() => {
         fetchData();
     }, []);

     const fetchData = async () => {
         try {
             const response = await axios.post(BASE_URL+'API/api_index.php', {
               security_key: security_key,
               mode:"getAllLead_new",
               center_key: sessionStorage.getItem('key')
             });
            
             if(response.data.success===true){
               setRecords(response.data.data);
               setItems(response.data.data);
               setLoading(false);

             }else{
               //alert message provide
               setLoading(false);

             }
             
             
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };

     //Api call End


const columns = [
 
{ headerName: 'Contact Name',
  field: "Contact Name",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.st_is_adultlearner == 1 ? 'Adult Learner' : params.row.par_firstname == null || params.row.par_firstname == '' ? '-' : params.row.par_firstname + ' ' + params.row.par_surname}`;
  }
 },
  { headerName: 'Student Name',
  field: "Student Name",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${ params.row.st_first_name == null || params.row.st_first_name == '' ? '-' : params.row.st_first_name+' '+ params.row.st_surname}`;
  }
 },
  { headerName: 'Email',
  field: "Email",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${ params.row.st_email == null || params.row.st_email == '' ? '-' : params.row.st_email}`;
  }
  },
  { headerName: 'Preferred Contact',
  field: "Preferred Contact",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${ params.row.lead_pref_meth_contact == null || params.row.lead_pref_meth_contact == '' ? '-' : params.row.lead_pref_meth_contact}`;
  }
  },
  { headerName: 'Source',
  field: "Source",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${ params.row.lead_ref_source == null || params.row.lead_ref_source == '' ? '-' : params.row.lead_ref_source}`;
  }
 },
  { 
    headerName: 'Created On',
    field: "Created On",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${Moment(params.row.created_on_n,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}`;
    },
    sortComparator: (valueA, valueB, rowA, rowB, direction) => {
      if (valueA === '-' || valueB === '-') {
        // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
        return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
      }

      const dateA = Moment(valueA, 'DD-MM-YYYY');
      const dateB = Moment(valueB, 'DD-MM-YYYY');

      return direction === 'asc' ? dateA - dateB : dateB - dateA;
    },
  },
  { 
  headerName: 'Updated On',
  field: "Updated On",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.cent_name == null ? '-' : Moment(params.row.updated_on_n,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}`;
    },
    sortComparator: (valueA, valueB, rowA, rowB, direction) => {
      if (valueA === '-' || valueB === '-') {
        // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
        return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
      }

      const dateA = Moment(valueA, 'DD-MM-YYYY');
      const dateB = Moment(valueB, 'DD-MM-YYYY');

      return direction === 'asc' ? dateA - dateB : dateB - dateA;
    },
},

  { headerName: 'Updated By',
  field: "Updated By",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.cent_name == null ? '-' : params.row.cent_name}`;
  }
 },
  { headerName: 'Status',
  field: "Status",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.ls_status == null || params.row.ls_status == '' ? '-' : params.row.ls_status}`;
  }
},
 // { name: "id", selector: (row) => row.pk_lead_key, omit: true }
];

const [records, setRecords] = useState(data);

  return (
    <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'> Lead List </h5>
                
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                        <Typography color="text.primary">Lead</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2'>
                {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                <NavLink className="primary-filled rounded-10 p-2" to='/add-lead' >
                <AddIcon className="btn-icon"/> New Lead</NavLink>
            </div>
          </div>

        

              {/* --------------Section Start--------------- */}  

              <Card className='my-card p-1 bg-white'>

                  <Card.Body>
                          
                      {/* -------------Content Start--------------- */}  

                      <Row>
                        <Col xs="12">
                            <div className="">

                                <Row className="justify-content-center">

                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>
                                      
                                      <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">
                                       
                                      

                                        {/*  <div class="form-group has-search my-auto">
                                          <span class="fa fa-search form-control-feedback"></span>
                                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                                        </div>*/}
      

                                      </div>

                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 
                                    


                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>
                                      
                                          {/* <DataTable
                     progressPending={loading} 
                                              columns={columns}
                                              data={records}
                                              fixedHeader
                                              pagination
                                              
                                              onRowClicked={handleRowLeadViewClick} 

                                              customStyles={customeStyle}
                                             className="cursor-pointer  "
                                   

                                          /> */}
                                      <Box
                                      sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#f4f4f4",
                                        },
                                    }}
                                    style={{ height: '100%', width: "100%" }}
                                >
                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        loading={loading}
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: { fileName: "LeadList" },
                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "LeadList" },
                                                records,
                                                showQuickFilter: true
                                            },
                                        }}
                                        initialState={{
                                          ...records.initialState,
                                          pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        onRowClick={(params) => handleRowLeadViewClick(params.row)}
                                        disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                                    />
                                </Box>
                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 

                                </Row>
                                
                            </div>
                        </Col>
                      </Row>

                      {/* --------------Content End--------------- */}


                  </Card.Body>

              </Card>

              {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}

    </div>
);


};

export default ListLead;
