/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useLocation, NavLink } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, BASE_URL} from "../global";





function CentreWhatsApp() {
    const location = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const [CentreData, setCentreData] = useState([]);


    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== '') {
          
            fetchData(extractedId);
        }
    }, [location]);
    
    //Api call start

    const fetchData = async (id) => {
        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getCenterXeroDetails",
                pk_cent_key: sessionStorage.getItem('key'),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setCentreData(response.data.data[0]);
                   
                }

                
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


   const basicSchema = yup.object().shape({
    zo_authorizationRequestUrl: yup.string().required("This field is required"),
    zo_tokenEndPointUrl: yup.string().required("This field is required"),
    zo_client_id: yup.string().required("This field is required"),
    zo_client_secret: yup.string().required("This field is required"),
    zo_oauth_scope: yup.string().required("This field is required"),
    zo_oauth_state: yup.string().required("This field is required"),
    zo_oauth_redirect_uri: yup.string().required("This field is required"),
});
    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched } = useFormik({
        initialValues: {
            /*tbl_certre*/
            zo_authorizationRequestUrl: CentreData ? CentreData.zo_authorizationRequestUrl : '',
            zo_tokenEndPointUrl: CentreData ? CentreData.zo_tokenEndPointUrl : '',
            zo_client_id: CentreData ? CentreData.zo_client_id : '',
            zo_client_secret: CentreData ? CentreData.zo_client_secret : '',
            zo_oauth_scope: CentreData ? CentreData.zo_oauth_scope : '',
            zo_oauth_state: CentreData ? CentreData.zo_oauth_state : '',
            zo_oauth_redirect_uri: CentreData ? CentreData.zo_oauth_redirect_uri : '',
            /*Common*/
            fk_cent_key: sessionStorage.getItem('key')

        },
        enableReinitialize: true,
       validationSchema: basicSchema,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            setIsSubmitting(true);
    
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "updateCenterXero",
                    ...formValues,
                });
    
                if (response.data.success === true) {
                    // Reset the form
                   
                    if(response.data.data === 1){
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });
    
                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/XeroApi");
                        }, 2000); // 3000 milliseconds, which is equivalent to 3 seconds
    
                        setIsSubmitting(false); // Re-enable the submit button
                    }else{
                        toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                            autoClose: 3000, // 3 seconds in this example
                        });
    
                        setIsSubmitting(false); // Re-enable the submit button
                    }
                    
                } else {
    
                    toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                        autoClose: 3000, // 3 seconds in this example
                    });
    
                    setIsSubmitting(false); // Re-enable the submit button
                    
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Sorry! Data Not Updated. Please Contact TCMS Team", {
                    autoClose: 3000, // 3 seconds in this example
                });
    
                setIsSubmitting(false); // Re-enable the submit button
                
            } finally {
                setIsSubmitting(false); // Re-enable the submit button
                
            }
              
        },
    });



    return (
        <>
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Xero API </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Xero API</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                      

                    </div>



                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Xero API Setting</h6>
                            </div>
                            <Row>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Authorization Request Url</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_authorizationRequestUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_authorizationRequestUrl"
                                        className={errors.zo_authorizationRequestUrl && touched.zo_authorizationRequestUrl ? "input-error" : ""}
                                    />
                                    {errors.zo_authorizationRequestUrl && touched.zo_authorizationRequestUrl && <p className="error">{errors.zo_authorizationRequestUrl}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Token End Point Url</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_tokenEndPointUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_tokenEndPointUrl"
                                        className={errors.zo_tokenEndPointUrl && touched.zo_tokenEndPointUrl ? "input-error" : ""}
                                    />
                                    {errors.zo_tokenEndPointUrl && touched.zo_tokenEndPointUrl && <p className="error">{errors.zo_tokenEndPointUrl}</p>}
                                </Col>
                                
                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Client ID</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_client_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_client_id"
                                        className={errors.zo_client_id && touched.zo_client_id ? "input-error" : ""}
                                    />
                                    {errors.zo_client_id && touched.zo_client_id && <p className="error">{errors.zo_client_id}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Client Secret</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_client_secret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_client_secret"
                                        className={errors.zo_client_secret && touched.zo_client_secret ? "input-error" : ""}
                                    />
                                    {errors.zo_client_secret && touched.zo_client_secret && <p className="error">{errors.zo_client_secret}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Oauth Scope</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_oauth_scope}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_oauth_scope"
                                        className={errors.zo_oauth_scope && touched.zo_oauth_scope ? "input-error" : ""}
                                    />
                                    {errors.zo_oauth_scope && touched.zo_oauth_scope && <p className="error">{errors.zo_oauth_scope}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Oauth State</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_oauth_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_oauth_state"
                                        className={errors.zo_oauth_state && touched.zo_oauth_state ? "input-error" : ""}
                                    />
                                    {errors.zo_oauth_state && touched.zo_oauth_state && <p className="error">{errors.zo_oauth_state}</p>}
                                </Col>


                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Oauth Redirect Uri</h6>
                                    <Form.Control type="text" 
                                        value={values.zo_oauth_redirect_uri}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="zo_oauth_redirect_uri"
                                        className={errors.zo_oauth_redirect_uri && touched.zo_oauth_redirect_uri ? "input-error" : ""}
                                    />
                                    {errors.zo_oauth_redirect_uri && touched.zo_oauth_redirect_uri && <p className="error">{errors.zo_oauth_redirect_uri}</p>}
                                </Col>

                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default CentreWhatsApp;