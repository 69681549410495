/*
Purpose: Header of AP
Developer: BY P.KS
Date:22/08/2023
Project: TCMS
*/

/*
Purpose: Header of AP
Designer: R.Sanjai
Date:23/08/2023
Project: TCMS
*/

import React, { useState } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { Dropdown, ButtonGroup, Image } from 'react-bootstrap';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Drawer from '@mui/material/Drawer';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { FaChalkboardTeacher } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { FaUserGraduate } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";


const Header = ({ isOpen, toggleSidebar }) => {

    ///Restrict for Login component
    const location = useLocation();
    const navigate = useNavigate();

    
    // List of routes where the header should not be displayed
    const headerExcludedRoutes = ['/'];

    //const headerAdminRoutes = ['/admin-list'];

    //default sidebar
    const url = location.pathname + location.search;

    if (headerExcludedRoutes.includes(location.pathname)) {
        return null; // Don't render the header
    }

    const handleLogout = () => {
        // Clear the sessionStorage
        sessionStorage.clear();
        // Redirect to the login page
        navigate("/login");
    };




    //const [data, setData] = useState([]); // Unconditionally call useState
    if (!sessionStorage.getItem('key')) {
            navigate("/");
     }



    var profilecentreimageUrl = 'myGooRoo-dark.png';
    if (sessionStorage.getItem('cent_profile') != 'null' && sessionStorage.getItem('cent_profile') != null && sessionStorage.getItem('cent_profile') != '') {

         const fullImageUrlprofimagect = `${sessionStorage.getItem('cent_profile')}`;

         profilecentreimageUrl = fullImageUrlprofimagect;
    }
  

    return (
        <Drawer sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
            },
        }}
            componentsProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
            variant="persistent"
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}  >
            <header>

                <div className=''>
                    <div id="sidebar">
                    <NavLink activeClassName="active" to='/calendar'>
                        <div className='site-identity d-flex align-item-top'>

                        
                            <Image src={profilecentreimageUrl} width={108} className="img-fluid mx-auto d-block" />    
                        </div></NavLink>

                         <Dropdown className='menu-dd mb-3' style={{ cursor: 'none' }}><Dropdown as={ButtonGroup} align="end" size='lg' id="dropdown-menu-align-responsive-1"
                            className="d-flex gap-2 align-items-center">

                            {/* <img src="icons/avatar-6.jpg" alt="Profile" className='profile-img float-start'></img> */}
                            <div className='text-start'>

                                <h6 className="mb-0"> {sessionStorage.getItem('center_name') } </h6>
                                <small className="mb-0"> Administrator </small>
                            </div>

                        </Dropdown> </Dropdown>
                    



                        <ul className='menu-list mb-0 list-unstyled' >
                            <li className='menu-item'>
                                <NavLink activeClassName="active" to='/calendar'>
                                    <CalendarMonthIcon className='menu-icon' /> Calendar</NavLink>
                            </li>
                            <li className='menu-item'>
                                <NavLink className={url == '/student-profile' || url == '/view-student' || url == '/add-student' ? 'active' : ''} to='/list-students'>
                                    <FaUserGraduate className='menu-icon' /> Students </NavLink>
                            </li>
                            <li className='menu-item'>
                                <NavLink to='/ListParent'>
                                    <RiParentFill className='menu-icon' /> Parents</NavLink>
                            </li>
                            <li className='menu-item'>
                                <NavLink to='/list-tutor'>
                                    <FaChalkboardTeacher className='menu-icon' /> Tutors</NavLink>
                            </li>
                            <li className='menu-item' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>
                                <NavLink to='/ListStaff'>
                                    <FaUserTie className='menu-icon' /> Staff</NavLink>
                            </li>
                            <li className='menu-item'>
                                <NavLink className={url == '/add-lead' || url == '/view-lead' ? 'active' : ''} to='/list-lead'>
                                    <ArticleIcon className='menu-icon' /> Leads</NavLink>
                            </li>
                            <li className='menu-item'>
                                <NavLink to='/Diagnostics'>
                                    <BarChartIcon className='menu-icon' /> Initial Diagnostics</NavLink>
                            </li>
                            <li className='menu-item '>
                                <Dropdown>
                                    <Dropdown.Toggle as={ButtonGroup} align="end" size='lg' id="dropdown-menu-align-responsive-1"
                                        className="menu-pd d-flex my-auto gap-2 align-items-center">
                                        <p className="my-auto">
                                            <ApartmentIcon className='menu-icon' /> Centre
                                        </p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="custom-drop">

                                        <NavLink to='/list-session-time'>Session Times</NavLink>

                                        {/* <NavLink to='/list-session'>Sessions</NavLink> */}

                                        <NavLink to='/Fees'>Fees</NavLink>

                                        <NavLink to='/centre-info'>Centre Info</NavLink>

                                        <NavLink to='/operating-hoursandholidays'>Operating Hours & Holidays</NavLink>

                                        <NavLink to='/ListStudentAttendance'>Student Attendance</NavLink>
                                       
                                        <NavLink to='/Subject'>Subjects</NavLink>
                                        
                                        <NavLink to='/ListCentreForm'>Forms</NavLink>
                                        <NavLink to='/StripeApi'>Stripe API</NavLink>
                                        <NavLink to='/QuickBookApi'>QuickBooks API</NavLink>
                                        <NavLink to='/XeroApi'>Xero API</NavLink>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                          
                            <li className='menu-item'>
                                <NavLink exact activeClassName="active" to='/reports'>
                                    <SummarizeIcon className='menu-icon' /> Reports</NavLink>
                            </li>
                        </ul>

                        <div className='logout-section'>

                            <div className=''>
                                <div className='pad-btn w-100 cursor-pointer' variant="contained" aria-label="delete" size="small" onClick={handleLogout}>
                                    <PowerSettingsNewIcon className='me-1 ' /> Logout
                                </div>
                            </div>
                        </div>

                        <div className=''>

                            <div className=''>
                                <div className='pad-btn w-100 d-flex' variant="contained" aria-label="delete" size="small">
                                    <p className='power-cus w-100 '>Powered by</p> 
                                    <Image src='myGooRoo-dark.png'  className="img-fluid d-block powbyCss" />  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
        </Drawer >
    );

}

export default Header;