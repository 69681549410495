
/*
Purpose: add Discount details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL} from "../global";


const basicSchema = yup.object().shape({
   d_title: yup.string().required("Title is required.").min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    d_discount: yup.number().integer().min(0, "Minimum value 0 required").required("Discount is required.")
});

function AddDiscounts() {

    const navigate = useNavigate();

    

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [PriceData, setPriceData] = useState({});



      //Get key value start
      const location = useLocation();
      useEffect(() => {
            
          const { pk_discount_id } = location.state || {};
          const extracteddisId = pk_discount_id;

          if (extracteddisId !=='' && extracteddisId !== undefined) {
          //setId(extractedId);
          fetchData(extracteddisId);
          }else{
            navigate("/Fees");
          }
      }, [location]);
      //Get key value end
  
      //Api call start
  
      const fetchData = async (id) => {
          try {
              const response = await axios.post(BASE_URL+'API/api_index.php', {
                  security_key: security_key,
                  mode: "getSingleDiscount",
                  pk_discount_id: id
              });
  
              if (response.data.success === true) {
                  if(response.data.data.length > 0){
                    setPriceData(response.data.data[0]);
                
                  }
              
              
              } else {
                  // Handle unsuccessful response, e.g., show an alert to the user.
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
    const initialValues = {
        /*tbl_fees*/
        d_title: PriceData ? PriceData.discount_title : '',
        d_discount: PriceData ? PriceData.discount_cost

        : '',

        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
        pk_discount_id: PriceData ? PriceData.pk_discount_id : '',

        visibility: 1
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,

        //  onSubmit,
        onSubmit: async (formValues) => {
             setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "updateDiscount",
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Fees");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                     //   setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to update discount. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update discount. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update discount. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

        // Now, you can set variables as needed:
        const values = formik.values;
        const handleBlur = formik.handleBlur;
        const touched = formik.touched;
        const handleChange = formik.handleChange;
        const errors = formik.errors;
        const handleSubmit = formik.handleSubmit;
        const setTouched = formik.setTouched;
        const resetForm = formik.resetForm;
        const setValues = formik.setValues;
        const handleReset = formik.handleReset;
     

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>Edit Discount</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>

                                    <Typography color="text.primary">Discount</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Discount </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Title</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.d_title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="d_title"
                                        className={errors.d_title && touched.d_title ? "input-error" : ""}
                                    />
                                    {errors.d_title && touched.d_title && <p className="error">{errors.d_title}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Discount</h6>
                                    <Form.Control
                                        type="number"
                                        min={0}
                                        value={values.d_discount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="d_discount"
                                        className={errors.d_discount && touched.d_discount ? "input-error" : ""}
                                    />
                                    {errors.d_discount && touched.d_discount && <p className="error">{errors.d_discount}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Fees')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default AddDiscounts;