
/*
Purpose: add subject details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useCallback, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL} from "../global";


const basicSchema = yup.object().shape({
    subject_name : yup.string().required("Subject name is required.").min(2, "Minimum 2 characters is required.").max(50, "Maximum 50 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    sub_abbreviation : yup.string().required("Subject abbreviation is required.").max(50, "Maximum 50 characters is required.").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    sub_details : yup.string().required("Description is required.").min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),     
});

function AddSubject() {

    const navigate = useNavigate();

    

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const calculateAbbreviation = (subjectName) => {
        // Simple logic to create an abbreviation (e.g., first letter of each word)
        const words = subjectName.split(' ');
        const abbreviation = words.map((word) => word.charAt(0).toUpperCase()).join('');
       
        return abbreviation;
      };

    const initialValues = {
        /*subjects*/
       
        subject_name : '',
        sub_abbreviation : '',
        sub_details : '', 

        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),
        visibility: 1
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        //  onSubmit,
        onSubmit: async (formValues) => {
            // setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "createSubject",
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Your subject was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Subject");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 2) {
                        toast.error("Sorry! Your subject abbreviation already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if (response.data.data == 3) {
                        toast.error("Sorry! Your subject name already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create subject. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create subject. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create subject. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>New Subject</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Subject</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Subject </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Subject Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.subject_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const abbreviation = calculateAbbreviation(e.target.value);
                                            handleChange({ target: { name: 'sub_abbreviation', value: abbreviation } });
                                          }}
                                        onBlur={handleBlur}
                                        id="subject_name"
                                        className={errors.subject_name && touched.subject_name ? "input-error" : ""}
                                    />
                                    {errors.subject_name && touched.subject_name && <p className="error">{errors.subject_name}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Subject Abbreviation</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.sub_abbreviation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sub_abbreviation"
                                        className={errors.sub_abbreviation && touched.sub_abbreviation ? "input-error" : ""}
                                    />
                                    {errors.sub_abbreviation && touched.sub_abbreviation && <p className="error">{errors.sub_abbreviation}</p>}
                                </Col>
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Description</h6>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        
                                        value={values.sub_details}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="sub_details"
                                        className={errors.sub_details && touched.sub_details ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.sub_details && touched.sub_details && <p className="error">{errors.sub_details}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Subject')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default AddSubject;