/*
Purpose: list of Entrolment with use datatable
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { ToastContainer } from 'react-toastify';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, BASE_URL
} from "../global";


const ReceiptList = () => {



  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sest_name, setSestName] = useState(null);
  const [enrol_start_date, setEnrolStartDate] = useState(null);
  const [sest_day, setSestDay] = useState(null);



  //Get key value start
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    //const extractedId = urlParams.get("id");
    const { fk_stu_key, fk_sest_key, pk_enrol_key, sest_name, enrol_start_date, sest_day } = location.state || {};
    const extractedId = fk_stu_key;
    const extractedSestKey = fk_sest_key;
    const extractedEnrolKey = pk_enrol_key;
    const extractedsest_name = sest_name;
    const extractedenrol_start_date = enrol_start_date;
    const extractedsest_day = sest_day;

    if (extractedId !== '' && extractedId !== undefined && extractedSestKey !== undefined && extractedEnrolKey !== undefined && extractedsest_name !== undefined && extractedenrol_start_date !== undefined && extractedsest_day!== undefined) {
     
      setSestName(extractedsest_name);
      setEnrolStartDate(extractedenrol_start_date);
      setSestDay(extractedsest_day);
      //fetchData(extractedId);
      fetchData(extractedId, extractedSestKey, extractedEnrolKey);
    }else{
      navigate("/student-entrolments");
    }
  }, [location]);
  //Get key value end


  //Api call start






  const fetchData = async (Id, session_key, enroll_key) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getReceiptdata",
        fk_student_key: Id,
        fk_sest_key: session_key,
        pk_enrol_key: enroll_key,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords(response.data.data);
       
        setLoading(false);


      } else {
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }


  };


  // Sample columns configuration
  const columns = [

    {

      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.date_of_pay == null || params.row.date_of_pay == '' ? '-' : Moment(params.row.date_of_pay, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },

    },
    {

      headerName: 'Receipt No.',
      field: "Receipt No.",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.receipt_no == null || params.row.receipt_no == '' ? '-' : params.row.receipt_no}`;
      },

    },
    {

      headerName: 'Type',
      field: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${ params.row.payment_type == 1 ? 'Registration and Deposit' : 'Fees'}`;
      },

    },


    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.amount == null || params.row.amount == '' ? '-' : params.row.amount}`;
      },



    },

    {

      field: "View",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "View",
      headerClassName: "super-app-theme--header",
      width: 250,
      headerAlign:"left",

      renderCell: (params) => {
        const row = params.row;
      if(row.link_of_receipt)
      {
        return (
          <div className='d-flex  pad-12'>
            
            <NavLink className="primary-blue btnmr-2 my-2" target="_blank" to={row.link_of_receipt}>PDF</NavLink>
            {/* <Buttonmui variant="outlined-red" onClick={() => generatePDF(row)} className="primary-blue  btnmr-2 my-2" >PDF </Buttonmui> */}


          </div>
        );
      }
    }
    }
  ];





  return (
    <>
      <ToastContainer position="top-center" />


      <div>
      {/* <div ref={reportTemplateRef}>
				<ReportTemplate />
			</div> */}
{/* <table
      id="invoiceTable"
      style={{ display:'none',
        width: '100%',
        borderCollapse: 'collapse',
        margin: '15px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <tr>
        <td colSpan="3" style={{border:'1px solid #DDD', textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>
          Centre Details
        </td>
        <td colSpan="3" style={{ border:'1px solid #DDD',textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>
          Student Details
        </td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="1" style={{fontWeight:'800'}}><b>Centre Name:</b></td>
        <td colSpan="2" style={{ border:'1px solid #DDD' }}>{genratePDFdata.receipt_no}</td>
        <td style={{fontWeight:'800'}}><b>Student Name:</b></td>
        <td colSpan="2">John Doe</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="1" style={{fontWeight:'800'}}><b>Address:</b></td>
        <td colSpan="2" style={{ border:'1px solid #DDD' }}>12, CDS Street, Singapore - 600447</td>
        <td colSpan="1" style={{fontWeight:'800'}}><b>Email:</b></td>
        <td colSpan="2">john.doe@example.com</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="1" style={{fontWeight:'800'}}><b>Subject:</b></td>
        <td colSpan="2" style={{ border:'1px solid #DDD' }}>K</td>
        <td colSpan="1" style={{fontWeight:'800'}}><b>Enrolment Category:</b></td>
        <td colSpan="2">Regular</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}> 
        <td colSpan="1">Class:</td>
        <td colSpan="2" style={{ border:'1px solid #DDD' }}>Grade 1</td>
        <td colSpan="1">Session Date:</td>
        <td colSpan="2">2023-01-01</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="1">Session Time:</td>
        <td colSpan="2" style={{ border:'1px solid #DDD' }}>10:00 AM - 12:00 PM</td>
        <td colSpan="1">Enrolment Date:</td>
        <td colSpan="2">2022-12-01</td>
      </tr>
      <tr>
        <td colSpan="5" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>
          Order Summary
        </td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td style={{ fontWeight: 'bold',border:'1px solid #DDD' }}>SL</td>
        <td style={{ fontWeight: 'bold' ,border:'1px solid #DDD'}}>Date</td>
        <td style={{ fontWeight: 'bold',border:'1px solid #DDD' }}>Receipt No.</td>
        <td style={{ fontWeight: 'bold',border:'1px solid #DDD' }}>Type</td>
        <td style={{ fontWeight: 'bold',border:'1px solid #DDD' }}>Fees (SGD)</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td style={{ border:'1px solid #DDD' }}>1</td>
        <td style={{ border:'1px solid #DDD' }}>2023-01-02</td>
        <td style={{ border:'1px solid #DDD' }}>R123456</td>
        <td style={{ border:'1px solid #DDD' }}>Tuition</td>
        <td style={{ border:'1px solid #DDD' }}>150.00</td>
      </tr>
      <tr className="bold" style={{ border:'1px solid #DDD' }}>
        <td colSpan="4"></td>
        <td style={{ fontWeight: 'bold' }}>Total: 150.00</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="3">AMT IN WORD</td>
        <td></td>
        <td>E. & O.E</td>
      </tr>
      <tr style={{ border:'1px solid #DDD' }}>
        <td colSpan="4"></td>
        <td>COMPANY NAME</td>
      </tr>
    </table>


        <table style={{ display: 'none' }}>
          <thead>
            <tr>
              <th>Item</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Product A</td>
              <td>2</td>
              <td>$20.00</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Product B</td>
              <td>1</td>
              <td>$15.00</td>
            </tr>
          </tbody>
        </table> */}
      </div>

      <div className="page-wrapper">


        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Enrolment Receipt </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Enrolment Receipt </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            <div className='d-flex justify-content-end gap-2'>
              <div >
                {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                  <Dropdown.Item eventKey="1" onClick={handleClick_student_view}>Student Particulars</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={handleClick_student_edit}>Student Profile</Dropdown.Item>
                  <Dropdown.Item eventKey="3"><NavLink to='/list-students' >Student list</NavLink></Dropdown.Item>

                </DropdownButton> */}
              </div>
            </div>
          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            <Row>
              <Col sm={12} className='border-bottom mb-2'>



                <span className='me-2 mt-2'>{'Enrollment Date: ' + Moment(enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY') + ' (Session: ' + sest_name + ' - ' + sest_day + ')'}</span>

              </Col>



              <Col sm={12}>



                <Box
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                      backgroundColor: "#e3e6ee",
                    },
                  }}
                  style={{ height: '100%', width: "100%" }}
                >
                 <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                    //sx={{ border: "none", m: 2 }}
                    autoHeight
                    slots={{
                      loadingOverlay: LinearProgress,
                      //noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      // toolbar: EditToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { fileName: "ReceiptList" },
                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "ReceiptList" },
                        records,
                        showQuickFilter: true
                      },


                    }}
                    initialState={{
                      ...records.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    showQuickFilter={true}

                    pageSizeOptions={[10, 25, 50, 100]}

                    loading={loading}
                    rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                    columns={columns}
                    rowHeight={60} density="compact"
                    disableRowSelectionOnClick
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}


                  />


                </Box>

              </Col>
            </Row>

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default ReceiptList;
