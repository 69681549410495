/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';
import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {security_key, options_countries, BASE_URL} from "../global";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import DoneIcon from '@mui/icons-material/Done';

  import CloseIcon from '@mui/icons-material/Close';

const YearLevelList = () => {



  const [records, setRecords] = useState([]);

  
  const [data, setItems] = useState([]);
  
  const [loading, setLoading] = useState(true);

  const [Optionsubject, setOptions] = useState([]);



  //Api call start


  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  

  

    
  useEffect(() => {
    fetchData();
    fetchMasterData();
}, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllCountrySubjectYearLevel"
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

 
  const fetchMasterData = () => {
    // Make an API request to fetch the options
    axios.post(BASE_URL+'API/api_index.php', {
      security_key: security_key,
      mode: "getAllSubjectGroup", //"getAllSubjectByStudent" 
      fk_cent_key: sessionStorage.getItem('key')
      
    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.subject_id,
          label: option.subject_name
        }));
        formattedOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setOptions(formattedOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });
  };

  // Sample columns configuration
  const columns = [

    {
      headerName: 'Countries',
      field: "Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.country_name!=null && params.row.country_name!='' ?  params.row.country_name : '-'}`;
      },
    },
    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name!=null && params.row.subject_name!='' ?  params.row.subject_name : '-'}`;
      },
     
    },
    {
      headerName: 'Year Level',
      field: "YearLevel",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.level_name!=null && params.row.level_name!='' ?  params.row.level_name : '-'}`;
      },
      
    },
    {
      headerName: 'Abbreviation',
      field: "Abbreviation",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        const row = params.row;
        const words = row.level_name.split(/(\d+)/).filter(Boolean).map((element, index) => index === 0 ? element.charAt(0) : element);
        
        const abbreviation = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() : word)).join('');
       
        //const countryObject = options_countries.find(country => country.value === row.yl_country);
        if (abbreviation) {
          return abbreviation;
        } else {
          return words; // If no matching country is found, display just the country code
        }
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.is_deleted)==0? 'Active' : 'Deactive'}`;
      },
      //flex: 1,
      renderCell: (params) => {
        const row = params.row;
       
        if(parseInt(row.is_deleted)==0){
          return (
            <div className='d-flex justify-content-center'> 
              <span className='cursor-pointer active-btn' onClick={() => handleDeleteClick(row)} >
               <DoneIcon className="icon-blue  btn-icon me-1" />
               Active
               </span>
               
            </div>
          );
        }else{
          return (
            <div className='d-flex justify-content-center'> 
              <span className='cursor-pointer deactive-btn'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className=" btn-icon me-1" />
                Deactive
              </span>
             
            </div>
          );
        }
       
      },
    },
   
  ];




  const [modalShowAdd, setModalShowAdd] = useState(false);



  const basicSchema = yup.object().shape({

    yl_country: yup.mixed().required("Country is required."),
    yl_subject: yup.mixed().required("Subject is required."),
    yl_name: yup.string().min(2, "Minimum 2 characters is required.").required("Year Level is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),    
  });

  const initialValues = {
    /*tbl_year_level*/

    yl_country: "",
    yl_subject: '',
    yl_name: '',

    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "createYearLevel_new",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          if (response.data.data == 1) {
            toast.success("Success! Year Level has been created", {
              autoClose: 2000, // 3 seconds in this example
            });
            fetchData();
            // setShow(false);
            setModalShowAdd(false);
            resetForm();

            setIsSubmitting(false); // Re-enable the submit button
          }else if(response.data.data == 2){
              toast.error("Year level already exists", {
                autoClose: 3000, // 3 seconds in this example
              });
          } else {
            toast.error("Failure! Unable to year level. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to year level. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        //console.error("Error fetching data:", error);
        toast.error("Failure! Unable to year level. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;

  // console.log(formik);
  // console.log(errors.ca_login_id);

  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };




  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


  const handleDeleteClick = (row) => {
   const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };

  const [showVisible, setVisibleShow] = useState(false);
  const [data_visible, setVisibleLabelData] = useState([]);
  const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);


  const handleVisibleClick = (row) => {
   
    const updatedData = { ...row };
    setVisibleLabelData(updatedData);

    setVisibleShow(true);
  };





  function onSubmitDelete() {
    setIsUserDeleteSubmitting(true);

    const responsedelete = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "deleteYearLevel",
      activity_level_id: data_delete.activity_level_id
    }).then((responsedelete) => {

      if (responsedelete.data.data == 1) {

        setDeleteShow(false)
        // Handle the successful response here
        toast.success("Success! Year level has been deactivated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsUserDeleteSubmitting(false);

      } else {
        toast.error("Failure! Unable to Year level has been deactivate. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to Year level has been deactivate. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }




  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "VisibleYearLevel",
      activity_level_id: data_visible.activity_level_id
    }).then((responseVisible) => {

      if (responseVisible.data.data == 1) {

        setVisibleShow(false)
        // Handle the successful response here
        toast.success("Success! Year level has been activated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsVisibleSubmitting(false);

      } else {
        toast.error("Failure! Unable to Year level has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to Year level has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }


  

  return (
    <>
       <ToastContainer position="top-center" />

       <Modal show={showDelete} variant="danger">

          <Modal.Header>Year Level</Modal.Header>

          <Modal.Body>
            <p>Are you sure? You want change to deactivate this year level? </p>

          </Modal.Body>
          <Modal.Footer className='pt-0'>
            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
            </div>

          </Modal.Footer>

          </Modal>

          <Modal show={showVisible} variant="danger">

          <Modal.Header>Year Level</Modal.Header>

          <Modal.Body>
            <p>Are you sure? You want change to activate this year level? </p>

          </Modal.Body>
          <Modal.Footer className='pt-0'>
            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
            </div>

          </Modal.Footer>

          </Modal>

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Year Level </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>

              <Col lg="12" md="12" sm="12" className='position-relative mb-3'>
                <h6 className="form-label required-field">Countries</h6>
                 <Select menuPlacement="auto" 
                    options={options_countries}
                    name="yl_country"
                    value={values.yl_country['value']}
                    id="yl_country"
                    onChange={async (selectedOption) => {
                    const event = {
                        target: {
                        name: "yl_country",
                        value: selectedOption["value"],
                        },
                    };
                    handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, yl_country: true });
                    }}
                    className={errors.yl_country && touched.yl_country ? "input-error" : ""}
                />
                {errors.yl_country && touched.yl_country && <span className="error">{errors.yl_country}</span>}
                </Col>

              <Col lg="12" md="12" sm="12" className='position-relative mb-3'>
                <h6 className="form-label required-field">Subject</h6>
                 <Select menuPlacement="auto" 
                    options={Optionsubject}
                    name="yl_subject"
                    value={values.yl_subject['value']}
                    id="yl_subject"
                    onChange={async (selectedOption) => {
                    const event = {
                        target: {
                        name: "yl_subject",
                        value: selectedOption["value"],
                        },
                    };
                    handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, yl_subject: true });
                    }}
                    className={errors.yl_subject && touched.yl_subject ? "input-error" : ""}
                />
                {errors.yl_subject && touched.yl_subject && <span className="error">{errors.yl_subject}</span>}
              </Col>

                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Year Level</h6>
                  <Form.Control
                    type='yl_name'
                    value={values.yl_name}
                    id="yl_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.yl_name && touched.yl_name ? "input-error mb-0" : "mb-0"}
                />
                {errors.yl_name && touched.yl_name && <span className="error">{errors.yl_name}</span>}
                </Col>


               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>




        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Year Level </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                <Typography color="text.primary">Year Level</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

                <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                <AddIcon className="btn-icon me-2"/> 
                Add Year Level
                </Button>
                {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
            
            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap  justify-content-end">
                    
                    <div className='d-flex gap-2 '>
                     
                        {/* <div class="form-group has-search my-auto mb-2">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                        </div> */}
                     

                      
                    </div>

                  </div>
                  
                </Col>

                <Col sm={12}>

              

                        {/* <DataTable
                     progressPending={loading} 
                          columns={columns}
                          data={records}
                          Header
                          pagination
                          highlightOnHover

                          className='overflow-auto list-cus-h'
                        /> */}



                          <Box
                            sx={{
                              height: "fit-content",
                              width: "100%",
                              "& .actions": {
                                  color: "text.secondary",
                              },
                              "& .textPrimary": {
                                  color: "text.primary",
                              },
                              "& .super-app-theme--header": {
                                  backgroundColor: "#f4f4f4",
                              },
                          }}
                          style={{ height: '100%', width: "100%" }}
                      >
                         <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                              //sx={{ border: "none", m: 2 }}
                              autoHeight
                              loading={loading}
                              slots={{
                                  loadingOverlay: LinearProgress,
                                  //noRowsOverlay: CustomNoRowsOverlay,
                                  toolbar: GridToolbar,
                                  // toolbar: EditToolbar,
                              }}
                              slotProps={{
                                  toolbar: {
                                      csvOptions: { fileName: "YearLevelList" },
                                      printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "YearLevelList" },
                                      records,
                                      showQuickFilter: true
                                  },
                              }}
                              initialState={{
                                ...records.initialState,
                                pagination: { paginationModel: { pageSize: 10 } },
                              }}
                              pageSizeOptions={[10, 25, 50, 100]}
                              rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                              columns={columns}
                              rowHeight={60} density="compact"
                              //onRowClick={(params) => handleRowClick(params.row)}
                              disableRowSelectionOnClick
                              showCellVerticalBorder= {true}
                              showColumnVerticalBorder= {true}
                          />
                      </Box>
                   
                </Col>
              </Row>
            

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default YearLevelList;
