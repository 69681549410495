
/*
Purpose: add pricing details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Select from 'react-select'

import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL, options_days, options_months} from "../global";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function EditFees() {

    const navigate = useNavigate();

    
    //Api call start
    const [clickedValue, setClickedValue] = useState(1);


const basicSchema = yup.object().shape({
    stp_product_name: yup.string().required("Product Name is required.").min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    stp_price: yup.number().min(0, "Minimum value 0 required").required("Fees is required."),
    stp_price_id: yup.string().required("Stripe Price ID is required.").min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required."),
    stp_price_meter: yup.string().required("Stripe Price Meter Name is required.").min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required."),
    sp_start_ofDay: yup
    .string()
    .nullable()
    .test('is-required', 'At least one select dropdown is required', function (value) {
      const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;

      return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
    }),

  sp_start_ofDay_mon: yup
    .string()
    .nullable()
    .test('is-required', 'At least one select dropdown is required', function (value) {
      const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;

      return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
    }),
});

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const [PriceData, setPriceData] = useState({});


    //Api call start
//    const [clickedValue, setClickedValue] = useState(1);

      //Get key value start
      const location = useLocation();
      useEffect(() => {
        const { pk_stp_id } = location.state || {};
        const extractedFeeId = pk_stp_id;

          if (extractedFeeId !=='' && extractedFeeId !== undefined) {
          //setId(extractedId);
          fetchData(extractedFeeId);
          }else{
            navigate("/Fees");
          }
      }, [location]);
      //Get key value end
  
      //Api call start
  
      const fetchData = async (id) => {
          try {
              const response = await axios.post(BASE_URL+'API/api_index.php', {
                  security_key: security_key,
                  mode: "getSinglePrice",
                  pk_stp_id: id
              });
  
              if (response.data.success === true) {
                  if(response.data.data.length > 0){
                    setPriceData(response.data.data[0]);
                    setClickedValue(response.data.data[0]['sp_pay_freq'] || 1);
                  
                  }
              
              
              } else {
                  // Handle unsuccessful response, e.g., show an alert to the user.
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };



    const initialValues = {
        /*tbl_fees*/
        stp_product_name: PriceData ? PriceData.stp_product_name : '',
        stp_price: PriceData ? PriceData.stp_price : '',
        stp_price_id: PriceData ? PriceData.stp_price_id : '',
        stp_price_meter: PriceData ? PriceData.stp_price_meter : '',
        fk_cent_key: sessionStorage.getItem('key'),
        updated_by: sessionStorage.getItem('key'),
        pk_stp_id: PriceData ? PriceData.pk_stp_id : '',

        sp_start_ofDay: PriceData ? PriceData.sp_pay_freq != '' && PriceData.sp_pay_freq != null && PriceData.sp_pay_freq==1 ? PriceData.sp_start_ofDay : '' : '',
        sp_start_ofDay_mon: PriceData ? PriceData.sp_pay_freq != '' && PriceData.sp_pay_freq != null && PriceData.sp_pay_freq==2 ? PriceData.sp_start_ofDay : '' : '',
        visibility: PriceData ? PriceData.visibility : '1',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,

        //  onSubmit,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "updatePrice",
                    pk_stp_id: PriceData ? PriceData.pk_stp_id : '',
                    payment_type: clickedValue,
                    
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                   
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Fees");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        // setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to update price. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to update price. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to update price. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } 

        },
    });

        // Now, you can set variables as needed:
        const values = formik.values;
        const handleBlur = formik.handleBlur;
        const touched = formik.touched;
        const handleChange = formik.handleChange;
        const errors = formik.errors;
        const handleSubmit = formik.handleSubmit;
        const setTouched = formik.setTouched;
        const resetForm = formik.resetForm;
        const setValues = formik.setValues;
        const handleReset = formik.handleReset;
     
       

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };

   

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>Edit Fees</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Fees</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Fees </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Product Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_product_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="stp_product_name"
                                        className={errors.stp_product_name && touched.stp_product_name ? "input-error" : ""}
                                    />
                                    {errors.stp_product_name && touched.stp_product_name && <p className="error">{errors.stp_product_name}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Fees</h6>
                                    <Form.Control
                                        type="number"
                                        step="0.001"
                                        min={0}
                                        value={values.stp_price}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="stp_price"
                                        className={errors.stp_price && touched.stp_price ? "input-error" : ""}
                                    />
                                    {errors.stp_price && touched.stp_price && <p className="error">{errors.stp_price}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Stripe Price ID</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="stp_price_id"
                                        className={errors.stp_price_id && touched.stp_price_id ? "input-error" : ""}
                                    />
                                    {errors.stp_price_id && touched.stp_price_id && <p className="error">{errors.stp_price_id}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Stripe Price Meter Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_meter}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="stp_price_meter"
                                        className={errors.stp_price_meter && touched.stp_price_meter ? "input-error" : ""}
                                    />
                                    {errors.stp_price_meter && touched.stp_price_meter && <p className="error">{errors.stp_price_meter}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>
                     {/* --------------Payment Details--------------- */}
                     <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Payment Frequency {['top'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                             Note: Please select the price based on whether it is weekly, monthly, or others. This is because Stripe verifies the logic for the 'Billing Period' in your Stripe account. If it is incorrect, the payment will not be verified.
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))} </h6>
                            </div>
                            {/* <Tab.Container defaultActiveKey={StudentData.sp_pay_type === '2' ? 'second' : 'first'}> */}
                            <Tab.Container defaultActiveKey={"first"} activeKey={clickedValue}  onSelect={(k) => setClickedValue(k)} >
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row justify-content-center mb-3 text-dark outside mx-auto">
                                            <Nav.Item>
                                                <Nav.Link eventKey="1" className="px-4 py-2 text-dark me-2" >Weekly</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="2" className="px-4 py-2 text-dark"  >Monthly</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="3" className="px-4 py-2 text-dark"  > Others</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="1">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className='required-field'>Select day of Week</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_days}
                                                                name="sp_start_ofDay"
                                                                value={options_days.find(option => option.value && values.sp_start_ofDay && option.value.toString() === values.sp_start_ofDay.toString())}
                                                                id="sp_start_ofDay"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay: true });
                                                                }}
                                                                className={errors.sp_start_ofDay && touched.sp_start_ofDay ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay && touched.sp_start_ofDay && <span className="error">{errors.sp_start_ofDay}</span>}

                                                            <Col xs={12} md={4}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="2">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="3">
                                                            <h6 className='required-field'>Select day of Month</h6>
                                                        </Form.Label>
                                                        <Col sm="9">

                                                            <Select menuPlacement="auto" options={options_months}
                                                                name="sp_start_ofDay_mon"
                                                                value={options_months.find(option => option.value && values.sp_start_ofDay_mon && option.value.toString() === values.sp_start_ofDay_mon.toString())}
                                                                id="sp_start_ofDay_mon"
                                                                onChange={(selectedOption) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "sp_start_ofDay_mon",
                                                                            value: selectedOption["value"],
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, sp_start_ofDay_mon: true });
                                                                }}
                                                                className={errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon ? "input-error" : ""}
                                                            />
                                                            {errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon && <span className="error">{errors.sp_start_ofDay_mon}</span>}

                                                            <Col xs={12} md={8}>
                                                                {/* <Button variant="primary" className="mt-2 px-4 py2 btn1">Add Payment</Button> */}
                                                            </Col>
                                                        </Col>
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane>

                                            {/* <Tab.Pane eventKey="3">
                                                <Form>


                                                    <Form.Group as={Row} className="mb-3">
                                                        <Form.Label column sm="12">
                                                            <h6 > <span className='required-field'></span>The payment frequency is based on the subscription's creation date.</h6>
                                                        </Form.Label>
                                                        
                                                    </Form.Group>

                                                </Form>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>

                        </div>
                    </Stack>

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Fees')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Update</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default EditFees;