/*
Purpose: list of Entrolment with use datatable
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import { Row, Col, Card, Form, Button, Stack } from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import { ToastContainer } from 'react-toastify';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";


import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, BASE_URL
} from "../global";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';



const ReceiptList = () => {



  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [data, setItems] = useState([]);
  const [records, setRecords] = useState(data);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  const [sest_name, setSestName] = useState(null);
  const [enrol_start_date, setEnrolStartDate] = useState(null);
  const [sest_day, setSestDay] = useState(null);

  //Get key value start
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    //const extractedId = urlParams.get("id");
    const { fk_stu_key, fk_sest_key, pk_enrol_key, sest_name, enrol_start_date, sest_day } = location.state || {};
    const extractedId = fk_stu_key;
    const extractedSestKey = fk_sest_key;
    const extractedEnrolKey = pk_enrol_key;
    const extractedsest_name = sest_name;
    const extractedenrol_start_date = enrol_start_date;
    const extractedsest_day = sest_day;

    if (extractedId !== '' && extractedId !== undefined && extractedSestKey !== undefined && extractedEnrolKey !== undefined && extractedsest_name !== undefined && extractedenrol_start_date !== undefined && extractedsest_day!== undefined) {
      
      setSestName(extractedsest_name);
      setEnrolStartDate(extractedenrol_start_date);
      setSestDay(extractedsest_day);
      fetchData(extractedId, extractedSestKey, extractedEnrolKey);
    }else{
      navigate("/student-entrolments");
    }
  }, [location]);
  //Get key value end


  //Api call start
  const formRef = useRef(null);
  const fetchData = async (Id, session_key, enroll_key) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getviewFeesdata",
        fk_student_key: Id,
        fk_sest_key: session_key,
        pk_enrol_key: enroll_key,
        fk_cent_key: sessionStorage.getItem('key')
      });
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Sample columns configuration
  const columns = [
    {
      headerName: 'Payment Date',
      field: "Payment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pay_dot == null || params.row.pay_dot == '' ? '-' : Moment(params.row.pay_dot, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },

    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      },
    },

    {

      field: "Status",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 250,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${params.row.pay_gateway_response_code == 'Authorised'? 'Paid' : 'Unpaid'}`;
      },
      renderCell: (params) => {
        const row = params.row;

        if (row.pay_gateway_response_code == 'Authorised') { // Replace this condition with your actual condition
          return (
            <div className='d-flex  pad-12'>Paid
              {/* <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 1)} className="primary-blue  btnmr-2 my-2" >Paid </Buttonmui> */}

            </div>
          );
        }
        else {
          return (
            <div className='d-flex  pad-12'>Unpaid

              {/* <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 1)} className="primary-blue  btnmr-2 my-2" >Unpaid</Buttonmui> */}

            </div>
          );
        }

      }
    }
  ];



  const handleStartDateChange = (event) => {
    //const newStartDate = event.target.value;
    const newStartDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    setStartDate(newStartDate);
    handleDateFilter(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
   // const newEndDate = event.target.value;
    const newEndDate = dayjs(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    setEndDate(newEndDate);
    handleDateFilter(startDate, newEndDate);
  };

  const handleDateFilter = (start, end) => {
    const filterStartDate = start ? new Date(start).getTime() : null;
    const filterEndDate = end ? new Date(end).getTime() : null;

    const filteredData = data.filter((record) => {
      const lessonStartDate = new Date(record.pay_dot).getTime();
      const lessonEndDate = new Date(record.pay_dot).getTime();

      return (
        (filterStartDate === null || lessonStartDate >= filterStartDate) &&
        (filterEndDate === null || lessonEndDate <= filterEndDate)
      );
    });

    setRecords(filteredData);
  };




  const handleDateReset = () => {
    const today = new Date().toISOString().split('T')[0];
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const endDateValue = nextMonth.toISOString().split('T')[0];

    setStartDate(today);
    setEndDate(endDateValue);
    handleDateFilter(today, endDateValue);
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="page-wrapper">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Fees View </h5>
            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>
                <Typography color="text.primary">Fees View </Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <Card className='my-card p-1 bg-white'>
          <Card.Body>
            <Row>
              <Col sm={12} className='border-bottom mb-2'>
                <span className='me-2 mt-2'>{'Enrollment Date: ' + Moment(enrol_start_date, "YYYY-MM-DD").format('DD-MM-YYYY') + ' ( ' + sest_name + ' - ' + sest_day + ')'}</span>
              </Col>
              <Col sm={12}>
                <Stack gap={3} >
                  <div className="mb-3">

                    <div className="cus-les-sec">

                      <div className='cus-les-left'>
                        <div className="cus-les-sd">
                          <Form>

                            {/* --------------Start Date--------------- */}
                            <Form.Group className="form-group d-flex align-items-center" controlId="formsdate">
                              <Form.Label className="pe-2 mb-0">
                                <p className="mb-0">Start Date</p>
                              </Form.Label>
                              <Col className="">
                                {/* <input type="date" className="form-control mb-0" onChange={handleStartDateChange} value={startDate} /> */}
                                <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                    <DatePicker  views={['year', 'month', 'day']}
                                        value={dayjs(startDate, 'YYYY-MM-DD')}
                                        onChange={handleStartDateChange}
                                        maxDate={dayjs(endDate, 'YYYY-MM-DD')}
                                        format="DD-MM-YYYY"
 />
                                </LocalizationProvider>
                              </Col>
                            </Form.Group>

                          </Form>
                        </div>

                        <div className="cus-les-ed">
                          <Form>

                            {/* --------------End Date--------------- */}
                            <Form.Group className="form-group d-flex align-items-center" controlId="formedate">
                              <Form.Label className="pe-2 mb-0">
                                <p className="mb-0">End Date</p>
                              </Form.Label>
                              <Col className="">
                                {/* <input type="date" className="form-control mb-0" onChange={handleEndDateChange} value={endDate} /> */}
                                <LocalizationProvider className={'form-control mb-0'} dateAdapter={AdapterDayjs}>
                                    <DatePicker  views={['year', 'month', 'day']}
                                        value={dayjs(endDate, 'YYYY-MM-DD')}
                                        onChange={handleEndDateChange}
                                        minDate={dayjs(startDate, 'YYYY-MM-DD')}
                                        format="DD-MM-YYYY"
 />
                                </LocalizationProvider>
                              </Col>
                            </Form.Group>

                          </Form>
                        </div>
                        <Button variant="secondary" className="btn-sec-outline" onClick={handleDateReset}>
                            Reset
                          </Button>
                      </div>

                      <div className='cus-les-right'>


                        <div className="cus-les-rst">
                          {/* <Button variant="secondary" className="btn-sec-outline" onClick={handleDateReset}>
                            Reset
                          </Button> */}
                        </div>
                      </div>

                    </div>

                  </div>
                </Stack>

                <Box
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                      backgroundColor: "#e3e6ee",
                    },
                  }}
                  style={{ height: '100%', width: "100%" }}
                >
                 <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                    //sx={{ border: "none", m: 2 }}
                    autoHeight
                    slots={{
                      loadingOverlay: LinearProgress,
                      //noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      // toolbar: EditToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { fileName: "FeesList" },
                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "FeesList" },
                        records,
                        showQuickFilter: true
                      },


                    }}
                    initialState={{
                      ...records.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    showQuickFilter={true}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={loading}
                    rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                    columns={columns}
                    rowHeight={60} density="compact"
                    disableRowSelectionOnClick
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}
                  />
                </Box>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ReceiptList;
