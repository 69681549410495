/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, useHistory, NavLink } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { security_key, BASE_URL} from "../global";
import Select from 'react-select';


const options_default = [
    { value: '', label: '---Select---' },
    { value: 'Online', label: 'Online' },
    { value: 'In centre', label: 'In centre' },
];


const options_duration = [
    { value: '', label: '---Select---' },
    { value: '15', label: '15 minutes' },
    { value: '30', label: '30 minutes' },
    { value: '45', label: '45 minutes' },
    { value: '1', label: '1 hour' },
    // Add more duration options as needed
];


const basicSchema = yup.object().shape({
    sest_name: yup.string().required("Session name is required."),
    sest_start_time: yup.string().required("Start time is required."),
    sest_duration: yup.string().required("Duration is required."),
    sest_from_date:  yup.date().required("From date is required."),
    sest_to_date:  yup.date().required("To date is required."),
    sest_type: yup.mixed().required("Session type is required."),
    fk_tut_key: yup.mixed().required("Tutor is required."),
    //sest_skip_holidays: yup.boolean().oneOf([true], "Please check the checkbox").required("Please check the checkbox"),
});



function AddHolidaySessionTime() {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const handleSave = () => {
        navigate("/list-session-time");
    };
    const [options_tutor, setOptions] = useState([]);

    useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL+'API/api_index.php', {
            security_key: security_key,
            mode: "getAllTutorByCenter",
            center_key: sessionStorage.getItem('key')
        })
          .then(response => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const formattedOptions = response.data.data.map(option => ({
              value: option.pk_tut_key,
              label: option.tut_fname+' '+option.tut_surname
            }));
            setOptions(formattedOptions);
          })
          .catch(error => {
            console.error('Error fetching options from API: ', error);
          });
      }, []);

      const initialValues = {
        sest_name: '',
        sest_start_time: '',
        sest_duration: '',
        sest_from_date:  '',
        sest_to_date:  '',
        sest_type: '',
        fk_tut_key:'',
        sest_skip_holidays: false,
        sest_day:[],
    
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),
        
    };


    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {

            
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                security_key: security_key,
                mode: "createSessionTime",
                ...formValues,
            });

            if (response.data.success === true) {
                // Reset the form
                //formRef.current.reset();
                if(response.data.data === 1){
                    toast.success("Success! Your form was submitted", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    // After 3 seconds, redirect to another page
                    setTimeout(function () {
                        navigate("/list-session-time");
                    }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    setIsSubmitting(false); // Re-enable the submit button
                }else{
                    toast.error("Oops! Something went wrong.", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
                
            } else {

                toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button
                
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
            
        } finally {
            setIsSubmitting(false); // Re-enable the submit button
            
        }
           
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };


    return (
        <>
        {/* React alert */}
         <ToastContainer position="top-center" />
         {/* center form */}
         <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

            <div className="">

                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className='page-title'> Create Holiday Session Time  </h5>
                        <div role="presentation" >
                            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                <Typography color="text.primary">Session</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                    </div>*/}
                </div>


                <Stack>
                    <div className="my-card p-4 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Holiday Session Time </h6>
                        </div>
                            <Row>

                            {/* --------------Holiday Session Name--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Name</h6>
                                    <Form.Control 
                                    type="text" 
                                    
                                    value={values.sest_name}
                                    id="sest_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.sest_name && touched.sest_name ? "input-error" : ""}
                                     />
                                    {errors.sest_name && touched.sest_name && <p className="error">{errors.sest_name}</p>}
                                </Col>
                                        

                            {/* --------------Holiday Session Name--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Tutor</h6>
                                     <Select menuPlacement="auto"  options={options_tutor}
                                     name="fk_tut_key"
                                     id="fk_tut_key"
                                     value={values.fk_tut_key["value"]}
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "fk_tut_key",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, fk_tut_key: true });
                                     }}
                                     className={errors.fk_tut_key && touched.fk_tut_key ? "input-error" : ""}
                                    />
                                    {errors.fk_tut_key && touched.fk_tut_key && <p className="error">{errors.fk_tut_key}</p>}
                                </Col>

                                 {/* --------------Holiday Session Name--------------- */}
                                        
                                 <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Type</h6>
                                     <Select menuPlacement="auto"  options={options_default}
                                    name="sest_type"
                                    id="sest_type"
                                    value={values.sest_type["value"]}
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "sest_type",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, sest_type: true });
                                    }}
                                    className={errors.sest_type && touched.sest_type ? "input-error" : ""}
                                     />
                                     {errors.sest_type && touched.sest_type && <p className="error">{errors.sest_type}</p>}
                                </Col>
                                       
                                        {/* --------------Holiday Session Name--------------- */}
                                       
                                            <Col sm="9" as={Row}  className='mb-3 position-relative'>
                                                <h6 className="form-lable">Days</h6>


                                                <div className="d-flex justify-content-around align-items-center">
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">MON</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur}  value={'Monday'} />
                                                        {/* <Form.Check type="checkbox" id="mon-checkbox"  className="mb-3 my_checkbox" /> */}
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">TUE</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Tuesday'} />
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">WED</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Wednesday'} />
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">THU</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Thursday'} />
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">FRI</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Friday'}/>
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">SAT</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Saturday'} />
                                                    </div>
                                                    <div className="text-center  bg-white border rounded py-1 px-2">
                                                        <label htmlFor="mon-checkbox">SUN</label><br />
                                                        <input type="checkbox" className="my_checkbox" name="sest_day"  onChange={handleChange} onBlur={handleBlur} value={'Sunday'}/>
                                                    </div>

                                                </div>

                                            </Col>
                                        

                                        {/* --------------Holiday Session Name--------------- */}
                                       
                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                                <h6 className="form-lable">Start Time</h6>
                                                <Form.Control type="time"   value={values.sest_start_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="sest_start_time"
                                                className={errors.sest_start_time && touched.sest_start_time ? "input-error" : ""}
                                                />
                                                {errors.sest_start_time && touched.sest_start_time && <p className="error">{errors.sest_start_time}</p>}
                                            </Col>
                                       


                                        {/* --------------Holiday Session Name--------------- */}
                                     
                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                                <h6 className="form-lable">Duration</h6>
                                                 <Select menuPlacement="auto"  options={options_duration}  
                                                    name="sest_duration"
                                                    id="sest_duration"
                                                    value={values.sest_duration["value"]}
                                                    onChange={(selectedOption) => {
                                                        const event = {
                                                            target: {
                                                                name: "sest_duration",
                                                                value: selectedOption["value"],
                                                            },
                                                        };
                                                        handleChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        handleBlur(event);
                                                        setTouched({ ...touched, sest_duration: true });
                                                    }}
                                                    className={errors.sest_duration && touched.sest_duration ? "input-error" : ""} />
                                                    {errors.sest_duration && touched.sest_duration && <p className="error">{errors.sest_duration}</p>}
                                            </Col>
                                        
                                       
                                        

                                        {/* --------------Holiday Session Name--------------- */}
                                       
                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                                <h6 className="form-lable">From Date</h6>
                                                <Form.Control type="date" 
                                                value={values.sest_from_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="sest_from_date"
                                                className={errors.sest_from_date && touched.sest_from_date ? "input-error" : ""} />
                                                {errors.sest_from_date && touched.sest_from_date && <p className="error">{errors.sest_from_date}</p>}
                                            </Col>
                                       

                                        {/* --------------Holiday Session Name--------------- */}
                                      
                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                                <h6 className="form-lable">To Date</h6>
                                                <Form.Control type="date" value={values.sest_to_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                id="sest_to_date"
                                                className={errors.sest_to_date && touched.sest_to_date ? "input-error" : ""}  />
                                                {errors.sest_to_date && touched.sest_to_date && <p className="error">{errors.sest_to_date}</p>}
                                                
                                            </Col>
                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                            <Form.Check
                                                type="checkbox"
                                                label="Skip Holidays"
                                                name="sest_skip_holidays"
                                                id="sest_skip_holidays"
                                                checked={values.sest_skip_holidays}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            </Col>

                                    </Row>
                                </div>
                            </Stack>
                        
                    {/* --------------Holiday Session Time End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            
                            <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>

                  

            </div>

        </Form>
        </>
    );
}

export default AddHolidaySessionTime;