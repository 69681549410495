
/*
Purpose: Update Centre details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
Updated on : 24/08/2023
updated by : KTH
*/
import React from 'react';
import { Row, Col, Form, Card, Button,Modal } from 'react-bootstrap';
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Select from 'react-select'
import axios from 'axios';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { security_key, options_countries, BASE_URL} from "../global";

import { Button as Buttonmui } from '@mui/material';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";



const ListUser = () => {

 
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  
  const [extractedcentKey, setextractedcentKey] = useState('');

  useEffect(() => {
    const { pk_cent_key } = location.state || {};
  
    if (pk_cent_key !== undefined) {
      setextractedcentKey(pk_cent_key);
    } else {
      navigate("/admin/admin-list");
    }
  }, [location]);
  


  useEffect(() => {
    if (extractedcentKey !='') {
      fetchData();
    }else{
    
    }
  }, [extractedcentKey]);
  
 
const [records, setRecords] = useState([]);
const [data, setItems] = useState([]);
const [optionCentre, setCenterOptions] = useState([]);






//Api call start

    const fetchData = async () => {
      

        try {
            const response = await axios.post(BASE_URL+'API/api_index.php', {
                security_key: security_key,
                mode: "getAllCenterUsers",
                pk_cent_key: extractedcentKey,

                
            });

            if (response.data.success === true) {
                if(response.data.data.length > 0){
                    setRecords(response.data.data);
                    setItems(response.data.data);
                    setLoading(false);
                    
                }
                else {
                 
                  setRecords([]);
                  setItems([]);
                  setLoading(false);
                    // Handle unsuccessful response, e.g., show an alert to the user.
                }
         
            } else {
             
              setRecords([]);
              setItems([]);
              setLoading(false);
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setRecords([]);
            setItems([]);
            setLoading(false);
        }


    };
//Api call End

    const formRef = useRef(null);

    

//User Managment

const [isUserSubmitting, setIsUserSubmitting] = useState(false);

//   const fetchUserData = async () => {
//     try {
//       const response = await axios.post(BASE_URL+'API/api_index.php', {
//         security_key: security_key,
//         mode: "getAllCountryYearLevel"
//       });
//       
//       if (response.data.success === true) {
//         setRecords(response.data.data);
//         setItems(response.data.data);


//       } else {
//         //alert message provide
//       }

//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };



   // Sample columns configuration
   const columns = [

    {
      headerName: 'Name',
      field: "Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ci_first_name || ''} ${params.row.ci_surname || ''}`;
      }
      
    },
    {
      headerName: 'Centre Name',
      field: "cent_name",
      headerClassName: "super-app-theme--header",
      flex: 1
      },
    {
      headerName: 'Type',
      field: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ci_type==0 ? 'Super Admin' : 'Admin'}`;
      }
      
    }, {
      headerName: 'Country',
      field: "Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.country_name !=null && params.row.country_name !='' ? params.row.country_name : '-'}`;
      }
      
      },
    {
      headerName: 'UserName',
      field: "UserName",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ca_login_id !=null && params.row.ca_login_id !='' ? params.row.ca_login_id : '-'}`;
      }
      
    },
    {
      headerName: 'Password',
      field: "Password",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ca_login_id !=null && params.row.ca_login_id !='' ? '*******' : '-'}`;
      }
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      width: 200,
      //align:'left',
      headerAlign:"left",
      renderCell: (params) => {
            const row = params.row;

            if (row.ci_type!=0) { // Replace this condition with your actual condition
              return (
                <div className='d-flex flex-wrap pad-12'>
                  <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="filled-danger my-2" >Delete </Buttonmui>
                </div>
              );
            }else{
                return('-');
            }
        }
      },
    //{ name: "id", selector: (row) => row.pk_ci_id, omit: true }
   
  ];


  const [modalShowAdd, setModalShowAdd] = useState(false);


   //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
   const validationSchema2 = yup.object().shape({

    pk_cent_key: yup.mixed().required("Centre is required."),
    ci_first_name_user: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    ci_surname_user: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    cent_country_user: yup.mixed().required("Country is required."),
    ca_login_id_user: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    ca_password_user: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    ca_con_password_user: yup
        .string()
        .oneOf([yup.ref("ca_password_user"), null], "Password is not matching")
        .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    
  });
    
    const initialValues2 = {
       
        pk_cent_key: '',//AdminData ? AdminData.pk_cent_key : '',
        pk_cent_id: '',//AdminData ? AdminData.pk_cent_id : '',



        /*tbl_center_auth*/
        ca_login_id_user: '',
        ca_password_user: '',
        ca_con_password_user: '',

        ci_first_name_user: '',
        ci_surname_user: '',
        cent_country_user: '',

        created_by: sessionStorage.getItem('key'),
  };


 


  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: validationSchema2,
    enableReinitialize: true,
    onSubmit: async (values) => {
        setIsUserSubmitting(true);


      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "createCenterUsers",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! User account added.", {
              autoClose: 3000, // 3 seconds in this example
            });

            fetchData();
            formik2.resetForm();
            setModalShowAdd(false)


            setIsUserSubmitting(false); // Re-enable the submit button
          }else if(response.data.data === 2){

            toast.error("Username is not available", {
                autoClose: 3000, // 3 seconds in this example
            });

            setIsUserSubmitting(false); // Re-enable the submit button

        } else {
            toast.error("Failure! Unable to create user. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsUserSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create user. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsUserSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create user. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsUserSubmitting(false); // Re-enable the submit button

      } finally {
        setIsUserSubmitting(false); // Re-enable the submit button

      }

    },
  });

  const handleUsernameBlur3 = useCallback(async () => {
    const username = formik2.values.ca_login_id_user;
    if (username) {
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                userID: username,
                security_key: security_key,
                mode: "checkCenterUserId",
            });

            if (response.data.success === true) {
                formik2.setFieldTouched("ca_login_id_user", true, false);
                formik2.setFieldError("ca_login_id_user", "Username is not available");
            } else {
                formik2.setFieldTouched("ca_login_id_user", true, false);
                formik2.setFieldError("ca_login_id_user", null);
            }
        } catch (error) {
            console.error("Error checking username:", error);
            formik2.setFieldError("ca_login_id_user", null);
        }
    }
}, [formik2.values.ca_login_id_user]);


const [showDelete, setDeleteShow] = useState(false);
const [data_delete, setDeleteLabelData] = useState([]);
const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


const handleDeleteClick = (row) => {
   
    const updatedData = { ...row};
    setDeleteLabelData(updatedData);
 
    setDeleteShow(true);
 };

 function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteCenterUsers",
     pk_ca_id: data_delete.pk_ca_id,
     pk_ci_id: data_delete.pk_ci_id,
    }).then((responseuserdelete) => {

        if(responseuserdelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! User has been deleted.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to delete user. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete user. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }



  return (
    <>
    {/* React alert */}
     <ToastContainer position="top-center" />
    


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={formik2.handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add User Account</h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>

                 {/* -------------- First Name--------------- */}

                 <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">First Name</h6>
                    <Form.Control type="text"
                        name="ci_first_name_user"
                        
                        value={formik2.values.ci_first_name_user}
                        onChange={formik2.handleChange}
                        // onBlur={handleBlur}
                        id="ci_first_name_user"
                        onBlur={formik2.handleBlur}
                        className={formik2.errors.ci_first_name_user && formik2.touched.ci_first_name_user ? "input-error" : ""}
                        />
                        {formik2.errors.ci_first_name_user && formik2.touched.ci_first_name_user && <p className="error">{formik2.errors.ci_first_name_user}</p>}
                </Col>

                <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Last Name</h6>
                    <Form.Control type="text"
                        name="ci_surname_user"
                        
                        value={formik2.values.ci_surname_user}
                        onChange={formik2.handleChange}
                        // onBlur={handleBlur}
                        id="ci_surname_user"
                        onBlur={formik2.handleBlur}
                        className={formik2.errors.ci_surname_user && formik2.touched.ci_surname_user ? "input-error" : ""}
                        />
                        {formik2.errors.ci_surname_user && formik2.touched.ci_surname_user && <p className="error">{formik2.errors.ci_surname_user}</p>}
                </Col>


                
                <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Country</h6>
                     <Select menuPlacement="auto"  options={options_countries}
                        name="cent_country_user"
                        value={options_countries.find(option => option.value && formik2.values.cent_country_user && option.value.toString() === formik2.values.cent_country_user.toString())}
                        id="cent_country_user"

                        onChange={(selectedOption) => {
                          //setTouched({ ...formik2.touched, cent_country_user: true });
                          // if (selectedOption["value"] > 0) {
                              const country_id = selectedOption["value"];
                              async function handleCountryCenters(country_id) {
                                 try {
                                   
                                     // Make an API request here with the FormData object
                                     const response_data = await axios.post(BASE_URL+'API/api_index.php', {
                                      security_key: security_key,
                                      country_id: country_id,
                                      mode: "getCountryCenters"
                                  })
                             
                                     // Handle the API response here
                                     if (response_data.data.success === true) {
                                        // console.log(response_data.data.data);

                                          const formattedCentreOptions = response_data.data.data.map(option => ({
                                            value: option.pk_cent_key,
                                            label: option.cent_name
                                        }));
                                        formattedCentreOptions.unshift({
                                          value: '',
                                          label: '--Select--'
                                        });
                                        setCenterOptions(formattedCentreOptions);

                                       
                                         
                                         //handleChange({ target: { name: "cent_country_user", value: selectedOption["value"] } });
                                     } else {
                                         //handleChange({ target: { name: "cent_country_user", value: selectedOption["value"] } }); // Clear the input value
                                     }
                             
                                     // You can also perform further actions based on the API response
                                     // For example, show a success message or redirect to another page.
                                 } catch (error) {
                                    // console.log('null');
                                     //handleChange({ target: { name: "cent_country_user", value: selectedOption["value"] } }); // Clear the input value
                                 }
                             }
                             
                             // Call the async function when needed, passing the fileName as an argument
                             handleCountryCenters(country_id);

                             const event = {
                                  target: {
                                      name: "cent_country_user",
                                      value: selectedOption['value'],
                                  },
                              };
                              formik2.handleChange(event);

                              const event1 = {
                                target: {
                                    name: "pk_cent_key",
                                    value: '',
                                },
                            };
                            formik2.handleChange(event1);
                            formik2.setFieldTouched("pk_cent_key", false);
                           
                      }}
                        // onChange={(selectedOption) => {
                        //     const event = {
                        //         target: {
                        //             name: "cent_country_user",
                        //             value: selectedOption["value"],
                        //         },
                        //     };
                        //     formik2.handleChange(event);
                        // }}
                        onBlur={(event) => {
                            formik2.handleBlur(event);
                            formik2.setTouched({ ...formik2.touched, cent_country_user: true });
                        }}
                        className={formik2.errors.cent_country_user && formik2.touched.cent_country_user ? "input-error mb-0" : "mb-3"}
                    />
                    {formik2.errors.cent_country_user && formik2.touched.cent_country_user && <span className="error">{formik2.errors.cent_country_user}</span>}
                </Col>

                
                <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Centre Name</h6>
                     <Select menuPlacement="auto"  options={optionCentre}
                        name="pk_cent_key"
                        value={optionCentre.find(option => option.value.toString() == formik2.values.pk_cent_key.toString())}
                        id="pk_cent_key"
                        
                        onChange={(selectedOption) => {

                          //console.log(selectedOption);
                            const event = {
                                target: {
                                    name: "pk_cent_key",
                                    value: selectedOption['value'],
                                },
                            };
                            formik2.handleChange(event);
                        }}
                        onBlur={(event) => {
                            formik2.handleBlur(event);
                            formik2.setTouched({ ...formik2.touched, pk_cent_key: true });
                        }}
                        className={formik2.errors.pk_cent_key && formik2.touched.pk_cent_key ? "input-error mb-0" : "mb-3"}
                    />
                    {formik2.errors.pk_cent_key && formik2.touched.pk_cent_key && <span className="error">{formik2.errors.pk_cent_key}</span>}
                </Col>
                            

                
              <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Username</h6>
                    <Form.Control type="text"
                        name="ca_login_id_user"
                        
                        value={formik2.values.ca_login_id_user}
                        onChange={formik2.handleChange}
                        // onBlur={handleBlur}
                        id="ca_login_id_user"
                        onBlur={formik2.handleBlur}
                       // onBlur={handleUsernameBlur3}
                        className={formik2.errors.ca_login_id_user && formik2.touched.ca_login_id_user ? "input-error" : ""}
                        />
                        {formik2.errors.ca_login_id_user && formik2.touched.ca_login_id_user && <p className="error">{formik2.errors.ca_login_id_user}</p>}
                </Col>
                                            
            {/* --------------Student Password--------------- */}
            
                <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Password</h6>
                    <Form.Control type="password"
                        
                        value={formik2.values.ca_password_user}
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        id="ca_password_user"
                        className={formik2.errors.ca_password_user && formik2.touched.ca_password_user ? "input-error" : ""}
                        />
                    {formik2.errors.ca_password_user && formik2.touched.ca_password_user && <p className="error">{formik2.errors.ca_password_user}</p>}
                </Col>
                            

            {/* --------------Student Confirm Password--------------- */}
            
                <Col lg="6" md="6" sm="12" className='position-relative'>
                    <h6 className="form-lable required-field">Confirm Password</h6>
                    <Form.Control type="password"  
                        value={formik2.values.ca_con_password_user}
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        id="ca_con_password_user"
                        className={formik2.errors.ca_con_password_user && formik2.touched.ca_con_password_user ? "input-error" : " mb-0"}
                        />
                        {formik2.errors.ca_con_password_user && formik2.touched.ca_con_password_user && <p className="error">{formik2.errors.ca_con_password_user}</p>}
                </Col>


                
               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray bdr-cus-1" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isUserSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>

        <Modal show={showDelete} variant="danger">

          <Modal.Header>User Account</Modal.Header>

          <Modal.Body>
            <p>Are you sure? You want to this <b className="bg-warning">User Account</b>? </p>

            <Card className='rounded-1 mt-2 card-bdr' >
              <Card.Body>

                <div>
                  <div className="d-flex justify-content-between align-self-center">
                    <div className="me-5">
                      <div className="">

                       
                        <h6 className=''>User Name:</h6>
                        <p className="ml-3 mb-2">
                            {data_delete.ci_first_name +' '+data_delete.ci_surname}
                        </p>
                        <h6>Country:</h6>
                        <p className="ml-3 mb-2">
                        {data_delete.country_name}
                        </p>
                        <h6>User login ID:</h6>
                        <p className=" ml-3mb-2">
                        {data_delete.ca_login_id}
                        </p>
                        <h6>PassWord:</h6>
                        <p className=" ml-3 mb-2">
                        *********
                        </p>
                      </div>
                    </div>
                  </div>
                  <span>

                  </span>
                </div>

              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer className='pt-0'>
            <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
            </div>

            </Modal.Footer>

            </Modal>

        <div className="">

        <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className='page-title'> Centre Users </h5>
                        <div role="presentation" >
                            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                                <Typography color="text.primary">User</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className='d-flex gap-2'>
                    {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                        <div className='d-flex justify-content-end gap-2'>
                            {/* <div >
                                <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                                    <Dropdown.Item onClick={() =>  toggleModal1()}>  Reset Password
                                    </Dropdown.Item>
                                   
                                    
                                </DropdownButton>
                            </div> */}
                             <Button  variant="contained" className="primary-filled rounded-10 p-2 me-0" onClick={() => setModalShowAdd(true)} >
                                New User
                                </Button>
                        </div>
                    </div>
                </div>


                {/* </Tab.Pane>

                    <Tab.Pane eventKey="second"> */}

                                
                    {/* <div className='d-flex justify-content-end mb-3 gap-2'>

                            <div className=''>

                                <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                                New User
                                </Button>
                                <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                                    Edit Enrolment
                                    </Button>
                            
                            </div>

                            </div> */}

                                                        
                            <Card className='my-card p-1 bg-white'>

                                <Card.Body>

                                {/* -------------Content Start--------------- */}

                                
                                    <Row>
                                    <Col sm={12}>
                                        <div className="d-flex flex-wrap  justify-content-end">
                                        
                                        <div className='d-flex gap-2 '>
                                        
                                            {/* <div class="form-group has-search my-auto mb-2">
                                                <span class="fa fa-search form-control-feedback"></span>
                                                <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                                            </div> */}
                                        

                                            
                                        </div>

                                        </div>
                                        
                                    </Col>

                                    <Col sm={12}>

                                    
                                        
                                        <Box
                                            sx={{
                                              height: "fit-content",
                                              width: "100%",
                                              "& .actions": {
                                                  color: "text.secondary",
                                              },
                                              "& .textPrimary": {
                                                  color: "text.primary",
                                              },
                                              "& .super-app-theme--header": {
                                                  backgroundColor: "#f4f4f4",
                                              },
                                          }}
                                          style={{ height: '100%', width: "100%" }}
                                      >
                                         <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                              //sx={{ border: "none", m: 2 }}
                                              autoHeight
                                              loading={loading}
                                              slots={{
                                                  loadingOverlay: LinearProgress,
                                                  //noRowsOverlay: CustomNoRowsOverlay,
                                                  toolbar: GridToolbar,
                                                  // toolbar: EditToolbar,
                                              }}
                                              slotProps={{
                                                  toolbar: {
                                                      csvOptions: { fileName: "CentreUserList" },
                                                      printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CentreUserList" },
                                                      records,
                                                      showQuickFilter: true
                                                  },
                                              }}
                                              initialState={{
                                                ...records.initialState,
                                                pagination: { paginationModel: { pageSize: 10 } },
                                              }}
                                              pageSizeOptions={[10, 25, 50, 100]}
                                              rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                              columns={columns}
                                              rowHeight={60} density="compact"
                                              //onRowClick={(params) => handleRowClick(params.row)}
                                              disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                                          />
                                      </Box>
                                    </Col>
                                    </Row>
                                

                                </Card.Body>

                            </Card>

{/* 
                    </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container> */}

    </div>
        
    </>
  );
}

export default ListUser;