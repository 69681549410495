/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import { Row, Col, Card, Form, Modal, Button } from "react-bootstrap";

import axios from "axios";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";


import Box from "@mui/material/Box";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { security_key, BASE_URL } from "../global";

const ListForm = () => {
    const [records, setRecords] = useState([]);
    const [UserData, setUserData] = useState([]);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [Id, setId] = useState(null);
    //Get key value start
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.pk_student_key) {
            const { pk_student_key } = location.state || {};

            const extractedStafId = pk_student_key;

            if (extractedStafId !== "" && extractedStafId !== undefined) {
                setId(extractedStafId);
            } else {
                navigate("/list-students");
            }
        }
    }, [location]);

    //Api call start

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        if (Id && Id != "") {
            fetchQuickBookJournalData();
            fetchUserData();
        }
    }, [Id]);

    const fetchQuickBookJournalData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "fetchQuickBookJournalData",
                fk_student_id: Id,
                fk_cent_key: sessionStorage.getItem("key"),
            });

            const output = response.data;

            if (output.length > 0) {
                setRecords(output);
              
                setLoading(false);
            } else {
                //alert message provide
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "getStudentAndQuickBookToken",
                fk_student_id: Id,
                fk_cent_key: sessionStorage.getItem("key"),
            });

            const output = response.data;

            if (output) {
                setUserData(output);
            } else {
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [optionsAccount, setOptionsAccount] = useState([]);

    const fetchAccountData = async () => {
        toast.dismiss();
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "fetchAccountData",
                fk_cent_key: sessionStorage.getItem("key"),
            });

            const output = response.data;

            if (output.status && output.data.length > 0) {
                setOptionsAccount(output.data);
            } else {
                toast.error(output.data, {
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Sample columns configuration
    const columns = [
        {
            headerName: "Debit Amount",
            field: "Debit Amount",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                console.log(params.row);
                return `${params.row.qe_debit_amount == null || params.row.qe_debit_amount == "" ? "-" : params.row.qe_debit_amount}`;
            },
        },
        {
            headerName: "Debit Description",
            field: "Debit Description",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.qe_debit_discrip == null || params.row.qe_debit_discrip == "" ? "-" : params.row.qe_debit_discrip}`;
            },
        },
        {
            headerName: "Credit Amount",
            field: "Credit Amount",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.qe_credit_amount == null || params.row.qe_credit_amount == "" ? "-" : params.row.qe_credit_amount}`;
            },
        },
        {
            headerName: "Credit Description",
            field: "Credit Description",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.qe_credit_discrip == null || params.row.qe_credit_discrip == "" ? "-" : params.row.qe_credit_discrip}`;
            },
        },
        // {
        //     id: "Date",
        //     headerName: "Date",
        //     field: "Date",
        //     headerClassName: "super-app-theme--header",
        //     flex: 1,
        //     valueGetter: (params) => {
        //         return `${params.row.xe_date == null || params.row.xe_date == "" ? "-" : Moment(params.row.xe_date, "YYYY-MM-DD").format("DD-MM-YYYY")}`;
        //     },
        //     sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        //         if (valueA === "-" || valueB === "-") {
        //             // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
        //             return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        //         }

        //         const dateA = Moment(valueA, "DD-MM-YYYY");
        //         const dateB = Moment(valueB, "DD-MM-YYYY");

        //         return direction === "asc" ? dateA - dateB : dateB - dateA;
        //     },
        // },
    ];

    const [modalShowAdd, setModalShowAdd] = useState(false);

    const basicSchema = yup.object().shape({
        qb_account_data: yup.string().required("Account is required."),
        qb_debit_amount: yup.number().min(0, "Minimum value 0 required").required("Amount is required."),
        qb_debit_descrip: yup
            .string()
            .required("Description is required.")
            .min(2, "Minimum 2 characters is required.")
            .max(250, "Maximum 250 characters is required.")
            .matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma")
            .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
        qb_credit_amount: yup
            .number()
            .min(0, "Minimum value 0 required")
            .required("Amount is required.")
            .oneOf([yup.ref("qb_debit_amount")], "Credit and Debit amounts must be the same."),
        qb_credit_descrip: yup
            .string()
            .required("Description is required.")
            .min(2, "Minimum 2 characters is required.")
            .max(250, "Maximum 250 characters is required.")
            .matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma")
            .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
    });

    const initialValues = {
        /*tbl_year_level*/
        pk_qt_key: UserData && UserData.pk_qt_key ? UserData.pk_qt_key : "",
        qb_account_data: "",
        qb_debit_amount: 0,
        qb_debit_descrip: "",
        qb_credit_amount: 0,
        qb_credit_descrip: "",
        fk_cent_key: sessionStorage.getItem("key"),
        pk_student_key: UserData && UserData.pk_student_key ? UserData.pk_student_key : "",
        pk_student_name: UserData && UserData.st_first_name ? UserData.st_first_name + " " + UserData.st_surname : "",
        created_by: sessionStorage.getItem("key"),
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        // onSubmit,
        onSubmit: async (formValues) => {
            toast.dismiss();

            setIsSubmitting(true);

            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "sendJournalData",
                    ...formValues,
                });

                const output = response.data;

                if (output.status === true) {
                    if (output.data === 1) {
                        toast.success("Success! Your journal added.", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        fetchQuickBookJournalData();
                        fetchUserData();
                        fetchAccountData();
                        resetForm();
                        setModalShowAdd(false);
                        setIsSubmitting(false);
                    } else {
                        toast.error("Failure! Unable to create journal. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                        setIsSubmitting(false); // Re-enable the submit button
                    }
                } else {
                    toast.error("Failure! Unable to create forms. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create forms. Try again later", {
                    autoClose: 3000,
                });

                setIsSubmitting(false);
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const setFieldValue = formik.setFieldValue;
    const handleReset = formik.handleReset;
    const fileInputRef = useRef(null);
    // console.log(formik);

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <ToastContainer position="top-center" />

            <div className="page-wrapper">
                <Modal show={modalShowAdd} onHide={() => setModalShowAdd(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <h5 className="page-title"> Add journal </h5>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="mt-3 mb-3 d-flex justify-content-end align-items-end">
                                {/* {UserData && UserData.pk_xt_key ? null : (
                                    <Button
                                        variant="contained"
                                        className="primary-filled rounded-10 p-2"
                                        onClick={() => {
                                            openInNewTab("https://developer.intuit.com/v2/OAuth2Playground/RedirectUrl?key=" + sessionStorage.getItem("key"));
                                            setModalShowAdd(false);
                                        }}
                                    >
                                        Get Token
                                    </Button>
                                )} */}
                                {UserData && !UserData.pk_qt_key && (
                                    <Button
                                        variant="contained"
                                        className="primary-filled rounded-10 p-2"
                                        onClick={() => {
                                            const client_id = "ABTxWlksP1Kfuy6cMEqIZOU8gBcxhGSLqqON7rUJUg2TnfJMjB";
                                            const scope = "com.intuit.quickbooks.accounting";
                                            const response_type = "code";
                                            const redirect_uri = "https://tcms.mygooroo.io/webpages/quic_kbook/qb_redirect_url.php";
                                            openInNewTab(
                                                `https://appcenter.intuit.com/app/connect/oauth2?client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=PlaygroundAuth`
                                            );
                                            setModalShowAdd(false);
                                        }}
                                    >
                                        Get Token
                                    </Button>
                                )}
                            </div>
                            {/* --------------Date--------------- */}
                            <Row>
                                <Col lg="12" md="12" sm="12" className="position-relative mb-4">
                                    <h6 className="form-lable required-field">Select Account</h6>
                                    <Select
                                        // menuPlacement="auto"
                                        options={optionsAccount}
                                        id="qb_account_data"
                                        name="qb_account_data"
                                        value={
                                            values &&
                                            values.qb_account_data &&
                                            optionsAccount.find((option) => {
                                                return option.value && option.value.toString() === values.qb_account_data.toString();
                                            })
                                        }
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "qb_account_data",
                                                    value: selectedOption.value,
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, qb_account_data: true });
                                        }}
                                        filterOption={(option, inputValue) => {
                                            return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                        }}
                                    />
                                    <div className="mt-3">
                                        {errors.qb_account_data && touched.qb_account_data && <p className="error">{errors.qb_account_data}</p>}
                                    </div>
                                </Col>
                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Debit Amount</h6>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min={0}
                                        value={values.qb_debit_amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_debit_amount"
                                        className={errors.qb_debit_amount && touched.qb_debit_amount ? "input-error" : ""}
                                    />
                                    {errors.qb_debit_amount && touched.qb_debit_amount && <p className="error">{errors.qb_debit_amount}</p>}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="position-relative">
                                    <h6 className="form-lable required-field">Credit Amount</h6>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        min={0}
                                        value={values.qb_credit_amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_credit_amount"
                                        className={errors.qb_credit_amount && touched.qb_credit_amount ? "input-error" : ""}
                                    />
                                    {errors.qb_credit_amount && touched.qb_credit_amount && <p className="error">{errors.qb_credit_amount}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className="position-relative mt-3">
                                    <h6 className="form-lable">Debit Description</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={values.qb_debit_descrip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_debit_descrip"
                                        className={errors.qb_debit_descrip && touched.qb_debit_descrip ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.qb_debit_descrip && touched.qb_debit_descrip && <p className="error">{errors.qb_debit_descrip}</p>}
                                </Col>
                                <Col lg="6" md="6" sm="12" className="position-relative mt-3">
                                    <h6 className="form-lable">Credit Description</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={values.qb_credit_descrip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="qb_credit_descrip"
                                        className={errors.qb_credit_descrip && touched.qb_credit_descrip ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.qb_credit_descrip && touched.qb_credit_descrip && <p className="error">{errors.qb_credit_descrip}</p>}
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="mx-auto d-flex gap-2">
                                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>
                                    Cancel
                                </Button>
                                <Button variant="contained" type="submit" className="primary-filled " disabled={isSubmitting}>
                                    Save
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* --------------Page Start--------------- */}

                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="page-title">Quick Book</h5>

                        <div role="presentation">
                            <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                                <NavLink underline="hover" color="inherit" to="/calendar">
                                    {" "}
                                    Home
                                </NavLink>

                                <Typography color="text.primary">Quick Book</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className="d-flex gap-2">
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                        <div className="">
                            <Button
                                variant="contained"
                                className="primary-filled rounded-10 p-2"
                                onClick={() => {
                                    fetchUserData();
                                    fetchAccountData();
                                    setModalShowAdd(true);
                                }}
                            >
                                Add journal
                            </Button>
                            {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
                        </div>
                    </div>
                </div>

                <Card className="my-card p-1 bg-white">
                    <Card.Body>
                        {/* -------------Content Start--------------- */}

                        <Row>
                            <Col sm={12}>
                                <div className="mt-2 mb-3">
                                    <p>
                                        <b>{UserData && UserData.st_first_name ? "#" + UserData.st_first_name + " " + UserData.st_surname : ""}</b>
                                    </p>
                                </div>

                                <Box
                                    sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#e3e6ee",
                                        },
                                    }}
                                    style={{ height: "100%", width: "100%" }}
                                >
                                    <DataGrid
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: { fileName: "FormList" },
                                                printOptions: {
                                                    pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                                    disableToolbarButton: true,
                                                    hideFooter: true,
                                                    hideToolbar: true,
                                                    fileName: "FormList",
                                                },
                                                records,
                                                showQuickFilter: true,
                                            },
                                        }}
                                        initialState={{
                                            ...records.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        showQuickFilter={true}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                        loading={loading}
                                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                        columns={columns}
                                        rowHeight={60}
                                        density="compact"
                                        disableRowSelectionOnClick
                                        showCellVerticalBorder={true}
                                        showColumnVerticalBorder={true}
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                {/* --------------Section End--------------- */}

                {/* --------------Page End--------------- */}

                {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
                {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}
            </div>
        </>
    );
};

export default ListForm;
