/*
Purpose: list details of student with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/

import React from 'react';
import { useState, useCallback, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';
import { security_key , BASE_URL} from "../global";
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

const Student = ({ message }) => {


  //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const handleStudentViewClick = async (e) => {
    
    if(parseInt(e.position)==1){
        if(parseInt(e.visibility)==1){
          navigate(`/view-student`, { state: { pk_student_key: `${e.pk_key}` }});
        }else{
        
          toast.error("Sorry! Allow Only active student.", {
            autoClose: 3000, // 3 seconds in this example
          });
          
        }

      }else if(parseInt(e.position)==2){
        navigate(`/edit-tutor`, { state: { pk_tut_key: `${e.pk_key}` } });
      }else if(parseInt(e.position)==3){
        navigate(`/ListParentStudent`, { state: { pk_par_key: `${e.pk_key}` } });
      }else{
        
      }

  }


  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);
  
  
  const handleDeleteClick = (row) => {
   
      const updatedData = { ...row};
      setDeleteLabelData(updatedData);
   
      setDeleteShow(true);
   };
  
   const [showVisible, setVisibleShow] = useState(false);
   const [data_visible, setVisibleLabelData] = useState([]);
   const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
   
   
   const handleVisibleClick = (row) => {
    
       const updatedData = { ...row};
       setVisibleLabelData(updatedData);
    
       setVisibleShow(true);
    };
   

    
function onSubmitDelete() {
  setIsUserDeleteSubmitting(true);

  const responsedelete = axios.post(BASE_URL+"API/api_index.php", {

    security_key: security_key,
    updated_by: sessionStorage.getItem('key'),
    mode: "deleteSearchAccount",
    pk_key: data_delete.pk_key,
    position: data_delete.position
  }).then((responsedelete) => {

      if(responsedelete.data.data == 1){

          setDeleteShow(false)
          // Handle the successful response here
          toast.success("Success! Account has been inactivated.", {
            autoClose: 3000, // 3 seconds in this example
          });
        
         fetchData();

          setIsUserDeleteSubmitting(false);

      }else{
          toast.error("Failure! Unable to Account has been inactive. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsUserDeleteSubmitting(false);
      }
     

  })
    .catch((error) => {
      // Handle any errors that occurred during the request
      toast.error("Failure! Unable to Account has been inactive. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsUserDeleteSubmitting(false);
    });
}




function onSubmitVisible() {
  setIsVisibleSubmitting(true);

  const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

    security_key: security_key,
    updated_by: sessionStorage.getItem('key'),
    mode: "VisibleSearchAccount",
    pk_key: data_visible.pk_key,
    position: data_visible.position
  }).then((responseVisible) => {

      if(responseVisible.data.data == 1){

        setVisibleShow(false)
          // Handle the successful response here
          toast.success("Success! Account has been activated.", {
            autoClose: 3000, // 3 seconds in this example
          });
        
         fetchData();

         setIsVisibleSubmitting(false);

      }else{
          toast.error("Failure! Unable to Account has been active. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });
            setIsVisibleSubmitting(false);
      }
     

  })
    .catch((error) => {
      // Handle any errors that occurred during the request
      toast.error("Failure! Unable to Account has been active. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsVisibleSubmitting(false);
    });
}
   //Api call start
   
 
   const [data, setItems] = useState([]);
   const [records, setRecords] = useState(data);
   useEffect(() => {
       fetchData(); 
       
   }, []);

   useEffect(() => {
    handleFilterSearch(message!='' || message!=null ? message : '')
    //setfilterData(message);
  }, [message]);

   const fetchData = async () => {
       try {
           const response = await axios.post(BASE_URL+'API/api_index.php', {
             security_key: security_key,
             mode:"getAllSearch",
             fk_cent_key: sessionStorage.getItem('key')
           });
         
           if(response.data.success===true){
            setItems(response.data.data);
            
          
           
            if (message != '') {
               
              const inputValue2 = message.toString().trim().toLowerCase();
             
              const newData2 = response.data.data.filter(row => {
                return (
                  (row.first_name!=null && row.first_name!=''?   row.first_name+" "+row.last_name : '-').toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  (row.user_name == null ||  row.user_name == '' ? '-' : row.user_name).toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  (row.position == 1 ? 'Student' : row.position== 2 ? 'Tutor' : row.position== 3 ? 'Parent' : '-').toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  (row.dob == null ||  row.dob == '' ? '-' : Moment(row.dob,'YYYY-MM-DD').format('DD-MM-YYYY')).includes(message.toString().trim().toLowerCase()) ||
                  (row.phone == null ||  row.phone == '' ? '-' : row.phone).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  (parseInt(row.visibility)==1 ? 'Active' : 'Inactive').toString().toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  (row.email == null ||  row.email == '' ? '-' : row.email).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) 


                  // row.pk_student_id.toString().includes(message.toString().trim().toLowerCase()) ||
                  // (row.st_first_name+' '+ row.st_surname).toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.sa_login_id == null ||  row.sa_login_id == '' ? '-' : row.sa_login_id).toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.st_is_adultlearner==1 ? 'Adult Learner' : (row.par_firstname == null || row.par_firstname == '') ? '-' : row.par_firstname+' '+ row.par_surname).toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.st_dob == null ||  row.st_dob == '' ? '-' : Moment(row.st_dob,'YYYY-MM-DD').format('DD-MM-YYYY')).includes(message.toString().trim().toLowerCase()) ||
                  // (row.st_gender == null ||  row.st_gender == '' ? '-' : row.st_gender).toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.level_name == null ||  row.level_name == '' ? '-' : row.level_name).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.st_illnesses == null ||  row.st_illnesses == '' ? '-' : row.st_illnesses).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (row.subject_name == null ||  row.subject_name == '' ? '-' : row.subject_name).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) ||
                  // (parseInt(row.visibility)==1  ? 'Active' : 'Inactive').toString().toLowerCase().includes(message.toString().trim().toLowerCase()) 
                 // (row.st_city == null ||  row.st_city == '' ? '-' : row.st_city).toString().toLowerCase().includes(message.toString().trim().toLowerCase()) 
                );
              });
              setRecords(newData2);
             
            }else{
                setRecords(response.data.data);
                
                          }
            
                          setLoading(false);
           }else{
             //alert message provide
             setLoading(false);
           }
           
           
       } catch (error) {
           console.error('Error fetching data:', error);
       }
   };

   //Api call End


  // Sample columns configuration
  const columns = [
    
    {
      headerName: 'Name',
      field: "Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.first_name!=null && params.row.first_name!=''?   params.row.first_name+" "+params.row.last_name : '-'}`;
      }
    },
    {
      headerName: 'User Name',
      field: "User Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.user_name == null ||  params.row.user_name == '' ? '-' : params.row.user_name}`;
      }
    },
    {
      headerName: 'Type',
      field: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.position == 1 ? 'Student' : params.row.position== 2 ? 'Tutor' : params.row.position== 3 ? 'Parent' : '-'}`;
      }
     
    },
    {
      
      headerName: 'Date Of Birth',
      field: "Date Of Birth",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.dob == null ||  params.row.dob == '' ? '-' : Moment(params.row.dob,'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: 'Phone',
      field: "Phone",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.phone == null ||  params.row.phone == '' ? '-' : params.row.phone}`;
      }
    },
    {
      headerName: 'Email',
      field: "Email",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.email == null ||  params.row.email == '' ? '-' : params.row.email}`;
      }
    },
    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 200,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility)==1? 'Active' : 'Inactive'}`;
      },
      renderCell: (params) => {
        const row = params.row;
       
          return (
            <div className='d-flex pad-12'>

              {parseInt(row.visibility)==1 ? 
              <span className='active-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)}>
               <DoneIcon className="icon-blue  btn-icon me-1" />
               Active
               </span> :  
               <span className='deactive-btn my-auto cursor-pointer' onClick={() => handleVisibleClick(row)} >
                <CloseIcon className=" btn-icon me-1"  />
                Inactive
              </span> }

              {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue  btnmr-2 my-auto">Send Enrolment </Buttonmui> */}

            </div>
          );
        }

    },
    
  ];




  function handleFilterSearch(event) {
    
    const inputValue1 = event.trim().toLowerCase();
   
    const newData1 = data.filter(row => {
      return (
        
        (row.first_name!=null && row.first_name!=''?   row.first_name+" "+row.last_name : '-').toLowerCase().includes(inputValue1) ||
        (row.user_name == null ||  row.user_name == '' ? '-' : row.user_name).toLowerCase().includes(inputValue1) ||
        (row.position == 1 ? 'Student' : row.position== 2 ? 'Tutor' : row.position== 3 ? 'Parent' : '-').toLowerCase().includes(inputValue1) ||
        (row.dob == null ||  row.dob == '' ? '-' : Moment(row.dob,'YYYY-MM-DD').format('DD-MM-YYYY')).includes(inputValue1) ||
        (row.phone == null ||  row.phone == '' ? '-' : row.phone).toString().toLowerCase().includes(inputValue1) ||
        (parseInt(row.visibility)==1 ? 'Active' : 'Inactive').toString().toLowerCase().includes(inputValue1) ||
        (row.email == null ||  row.email == '' ? '-' : row.email).toString().toLowerCase().includes(inputValue1) 
      );
    });
    setRecords(newData1);
   
  }
  
  

  return (

    <>
     <ToastContainer position="top-center" />

    <Modal show={showDelete} variant="danger">

<Modal.Header>Account</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to inactivate this account? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

  <Modal show={showVisible} variant="danger">

<Modal.Header>Account</Modal.Header>

<Modal.Body>
<p>Are you sure? You want change to activate this account? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
<div className="mx-auto d-flex gap-2" >

  <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
  <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
</div>

</Modal.Footer>

</Modal>
    <div className="page-wrapper">

      {/* --------------Page Start--------------- */}


      {/* --------------Section Start--------------- */}
      
      <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'>Search List </h5>
                    
                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                        <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>
                        <Typography color="text.primary">Search</Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    {/* <NavLink className="primary-filled rounded-10 p-2" to='/add-student' >
                      <AddIcon className="btn-icon"/>  New Student</NavLink> */}
                </div>

            </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">
                    
                      

                       
                        
                    
                      <div class="form-group has-search my-auto">
                        {/* <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control mb-0"    onChange={handleFilter}/> */}
                      </div>


                    </div>

                  </Col>
                  {/* --------------Table Content Start--------------- */}



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>
{/* 
                    <DataTable
                     progressPending={loading} 
                      columns={columns}
                      data={records}
                      fixedHeader
                      pagination
                      onRowClicked={handleStudentViewClick}
                      customStyles={customeStyle}
                     className="cursor-pointer  list-cus-h"
                    /> */}


                  <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#e3e6ee",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "SearchList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "SearchList" },
                            records
                            // showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        // showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
                        onRowClick={(params) => handleStudentViewClick(params.row)}
                        disableRowSelectionOnClick
                      showCellVerticalBorder= {true}
                      showColumnVerticalBorder= {true}

                      />
                      </Box>

                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>
    </>
  );

};

export default Student;
