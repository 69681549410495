/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import { useNavigate, NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import Select from "react-select";

import { useState, useCallback, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import 'react-phone-input-2/lib/style.css'; // Import CSS styles for the phone input
import PhoneInput from 'react-phone-input-2';

import axios from "axios";
import { security_key, options_emp_type, options_time_zone, 
    options_countries, options_gender, BASE_URL} from "../global";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const basicSchema = yup.object().shape({
    // salutation: yup.mixed().required("Salutation is required."),
    staff_fname: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    staff_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    staff_dob: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required("Date of birth is required."),
    staff_gender: yup.mixed().required("Gender is required."),
   // staff_profile_pic: yup.mixed().required("File is required."),
    staff_notes: yup.string().notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    staff_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Address is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    staff_zip: yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").required("Postal code is required.").matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
    // staff_address2: yup.mixed().required("Address2 is required."),
    // tut_suburb: yup.mixed().required("Suburb is required."),
    staff_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("State is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    staff_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required(" City/Town is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    // staff_zip: yup.mixed().required("Postcode is required."),
    //staff_country: yup.mixed().required("Country is required."),
    staff_mobile_countryCode: yup.string().notRequired(),
    staff_other_countryCode: yup.string().notRequired(),
    staff_mobile_phone: yup
    .string()
    .test(
      'minDigits',
      'Minimum 5 digits required',
      function (value) {
        const { staff_mobile_countryCode } = this.parent;
        const countryCodeLength = staff_mobile_countryCode ? staff_mobile_countryCode.length : 0;
        const phoneLength = value ? value.length : 0;
        const difference = phoneLength - countryCodeLength;

        // Check if the difference is less than 5
        return difference >= 5 || difference === 0;
      }
    )
    .required("Mobile phone is required."),
    staff_other_phone: yup
    .string()
    .test(
      'minDigits',
      'Minimum 5 digits required',
      function (value) {
        const { staff_other_countryCode } = this.parent;
        const countryCodeLength = staff_other_countryCode ? staff_other_countryCode.length : 0;
        const phoneLength = value ? value.length : 0;
        const difference = phoneLength - countryCodeLength;

        // Check if the difference is less than 5
        return difference >= 5 || difference === 0;
      }
    )
    .notRequired(),
    //staff_other_phone: yup.string().min(7, "Minimum 5 digit required").required("Other Phone is required."),
    // tut_mobile: yup.mixed().required("Mobile is required."),
    staff_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
        return value && value.includes('.');
      }),
    sa_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    sa_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    sa_confirm_password: yup
        .string()
        .oneOf([yup.ref("sa_password"), null], "Password is not matching")
        .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    //staff_timezone: yup.mixed().required("Time zone is required."),
    is_active: yup.boolean().oneOf([true], "Please check the Is Active checkbox").required("Please check the Is Active checkbox"),
    staff_type: yup.mixed().required("Staff type is required."),
    // effective_date: yup.mixed().required("Effective date is required."),
    staff_st_date: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    })
    .required('Start date is required.'),
    //staff_en_date: yup.mixed().required("End date is required."),
    //te_wage_typ: yup.mixed().required("Wage type is required."),
    //te_wages:  yup.number().positive().integer().required("Wage rate is required."),
    staff_monthly_wage: yup.number().integer().min(0, "Minimum value 0 required").required("Monthly Wage is required."),
    staff_weekly_wage: yup.number().integer().min(0, "Minimum value 0 required").required("Weekly Wage is required."),
    
});



function AddStaff() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

    const handleSave = () => {
         navigate("/ListStaff");
    };

    

    const currentDate = new Date(); // Get the current date and time
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " "); // Format it as "YYYY-MM-DD HH:mm:ss"
    

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_tutor*/
        salutation: "", //Not in db
        staff_dob: "",
        staff_fname: "",
        staff_surname: "",
        staff_profile_pic: "",
        staff_gender: "",
        staff_notes: "",
        staff_address: "",
        staff_mobile_countryCode: "",
        staff_other_countryCode: "",
        staff_city: "", //Not in form
        staff_state: "",
        staff_zip: "",
        staff_country: sessionStorage.getItem('country_code')!=null &&  sessionStorage.getItem('country_code')!='' ? sessionStorage.getItem('country_code') : 'SG',
       
        staff_zip: "",
        staff_mobile_phone: "",
        staff_other_phone: "",
        staff_emailid: "",

        /*tbl_tutor_auth*/
        sa_login_id: "",
        sa_password: "",
        sa_confirm_password: "", //Not in db

        /*tbl_tut_employee*/
        staff_timezone: sessionStorage.getItem('time_zone'),
        is_active: true, //Not in db
        staff_type: "",
        staff_st_date: "", //Not in form
        staff_en_date: "", //Not in form
       

        staff_monthly_wage: "0",
        staff_weekly_wage: "0",
       

        /*Common*/
        created_by: sessionStorage.getItem('key'),
        fk_cent_key: sessionStorage.getItem('key'),
    };

    const formik = useFormik({
        // const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            

                    try {
                        const response = await axios.post(BASE_URL+"API/api_index.php", {
                            security_key: security_key,
                            mode: "createStaff",
                            file_name: formValues.staff_profile_pic,
                            ...formValues,
                        });
        
                        if (response.data.success === true) {
                           
                            
        
                            if(response.data.data === 1){
                                resetFormFields(resetForm, setValues, initialValues);
                                toast.success("Success! Your form was submitted", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
            
                                // After 3 seconds, redirect to another page
                                setTimeout(function () {
                                    navigate("/ListStaff");
                                    setIsSubmitting(false); // Re-enable the submit button
                                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
            
                               
                            }else if(response.data.data === 2){

                                toast.error("Username is not available", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
                                handleScrollAndFocus('sa_login_id');
                                setIsSubmitting(false); // Re-enable the submit button

                                
                            }else{
                                toast.error("Failure! Unable to create staff. Try again later", {
                                    autoClose: 3000, // 3 seconds in this example
                                });
            
                                setIsSubmitting(false); // Re-enable the submit button
                            }
        
                        } else {
                            toast.error("Failure! Unable to create staff. Try again later", {
                                autoClose: 3000, // 3 seconds in this example
                            });
            
                            setIsSubmitting(false); // Re-enable the submit button
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        toast.error("Failure! Unable to create staff. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });
        
                        setIsSubmitting(false); // Re-enable the submit button
                    } finally {
                        setIsSubmitting(false); // Re-enable the submit button
                    }
            
            
        },
    });


    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    //const handleDateChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const fileInputRef = useRef(null);

    
    const SaLoginIdRef = useRef(null);

    const scrollToField = (fieldRef) => {
        if (fieldRef && fieldRef.current) {
            fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            fieldRef.current.focus();
        }
    };

    const handleScrollAndFocus = (fieldId) => {
        switch (fieldId) {
            case 'sa_login_id':
                scrollToField(SaLoginIdRef);
                break;
            default:
                break;
        }
    };
   
  
  const handleUsernameBlur = useCallback(async () => {
    const username = formik.values.sa_login_id;
    if (username) {
        try {
            const response = await axios.post(BASE_URL+"API/api_index.php", {
                userID: username,
                security_key: security_key,
                mode: "checkStaffUserId",
            });

            if (response.data.success === true) {
                formik.setFieldTouched("sa_login_id", true, false);
                formik.setFieldError("sa_login_id", "Username is not available");
            } else {
                formik.setFieldTouched("sa_login_id", true, false);
                formik.setFieldError("sa_login_id", null);
            }
        } catch (error) {
            console.error("Error checking username:", error);
            formik.setFieldError("sa_login_id", null);
        }
    }
}, [formik.values.sa_login_id]);
useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);
    return (
        <>
        {/* React alert */}
         <ToastContainer position="top-center" />
         {/* center form */}
         <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>


         <div className="">

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'> New Staff </h5>
                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                     
                            <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>

                            <Typography color="text.primary">Staff</Typography>
                        </Breadcrumbs>
                    </div>
                </div>
              
            </div>

            <Stack>
                    <div className="my-card p-3 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>Personal </h6>
                        </div>
                            <Row>
   
                                {/* Inside your form */}

                                {/* --------------Tutor Salutation--------------- */}
                                {/* <Form.Group as={Row} className="mb-3" controlId="salutation">
                                    <Form.Label column sm="3">
                                        <h6>Salutation</h6>
                                    </Form.Label>
                                    <Col sm="9">
                                         <Select menuPlacement="auto" 
                                            options={options_salutation}
                                            
                                            name="salutation"
                                            value={values.salutation["value"]}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: "salutation",
                                                        value: selectedOption["value"],
                                                    },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, salutation: true });
                                            }}
                                            className={errors.salutation && touched.salutation ? "input-error" : ""}
                                        />

                                        {errors.salutation && touched.salutation && <p className="error">{errors.salutation}</p>}
                                    </Col>
                                </Form.Group> */}

                            {/* --------------Tutor First Name--------------- */}
                        
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.staff_fname}
                                        id="staff_fname"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_fname && touched.staff_fname ? "input-error" : ""}
                                    />
                                    {errors.staff_fname && touched.staff_fname && <p className="error">{errors.staff_fname}</p>}
                                </Col>
                                

                            {/* --------------Tutor Last Name--------------- */}
                        
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                        <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        id="staff_surname"
                                        value={values.staff_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_surname && touched.staff_surname ? "input-error" : ""}
                                    />
                                    {errors.staff_surname && touched.staff_surname && <p className="error">{errors.staff_surname}</p>}
                                </Col>

                                 {/* --------------Student Gender--------------- */}
                            
                                 <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Gender</h6>
                                     <Select menuPlacement="auto"  options={options_gender}
                                     name="staff_gender"
                                     value={values.staff_gender["value"]}
                                     id="staff_gender"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "staff_gender",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, staff_gender: true });
                                     }}
                                     className={errors.staff_gender && touched.staff_gender ? "input-error" : ""}
                                      />
                                      {errors.staff_gender && touched.staff_gender && <p className="error">{errors.staff_gender}</p>}
                                </Col>
                            

                            {/* --------------Tutor Date of Birth--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Date of Birth</h6>
                                    {/* <Form.Control
                                        type="date"
                                        id="staff_dob"
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.staff_dob}
                                        
                                        className={errors.staff_dob && touched.staff_dob ? "input-error" : ""}
                                    /> */}

                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.staff_dob, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "staff_dob",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_dob: true });
                                            }}
                                            name="staff_dob"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'staff_dob',
                                                },
                                                }}
                                            className={errors.staff_dob && touched.staff_dob ? "input-error w-100" : "w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.staff_dob && touched.staff_dob && <span className="error">{errors.staff_dob}</span>}
                                </Col>
                                        
                            {/* --------------Tutor Photo--------------- */}

                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable">Photo</h6>
                                <Form.Control type="file" 
                                 name="staff_profile_pic"
                                 id="staff_profile_pic"
                                 accept=".png, .jpeg, .jpg, .jfif"
                                 onChange={(event) => {
                                     setTouched({ ...touched, staff_profile_pic: true });
                                     if (event.target.files.length > 0) {
                                         const fileName = event.target.files[0];
                                         async function handleFileUpload(fileName) {
                                            try {
                                                // Create a FormData object to send the form data, including the image file
                                                const formData = new FormData();
                                                formData.append("mode", "file_s3_upload_image");
                                                formData.append("user", "staff");
                                                formData.append("image", fileName); // Append the image file
                                        
                                                // Make an API request here with the FormData object
                                                const response_file = await axios.post(BASE_URL+'API/upload/index.php', formData);
                                        
                                                // Handle the API response here
                                                if (response_file.data.success === true) {
                                                    handleChange({ target: { name: "staff_profile_pic", value: response_file.data.data } });
                                                } else {
                                                    handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                                    fileInputRef.current.value = null;
                                                }
                                        
                                                // You can also perform further actions based on the API response
                                                // For example, show a success message or redirect to another page.
                                            } catch (error) {
                                                handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                                fileInputRef.current.value = null;
                                            }
                                        }
                                        
                                        // Call the async function when needed, passing the fileName as an argument
                                        handleFileUpload(fileName);
                                        
                                         
                                     } else {
                                         // handleChange(event);
                                         handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                         fileInputRef.current.value = null;
                                        }
                                 }}
                                 ref={fileInputRef}
                                 className={errors.staff_profile_pic && touched.staff_profile_pic ? "input-error" : ""}
                                  />
                                  {errors.staff_profile_pic && touched.staff_profile_pic && <p className="error">{errors.staff_profile_pic}</p>}
                            </Col>
                            
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Photo</h6>
                                    <Form.Control
                                        type="file"
                                        name="staff_profile_pic"
                                        id="staff_profile_pic"
                                        
                                        onChange={(event) => {
                                            setTouched({ ...touched, staff_profile_pic: true });
                                            if (event.target.files.length > 0) {
                                                const fileName = event.target.files[0];
                                                handleChange({ target: { name: "staff_profile_pic", value: fileName } });
                                            } else {
                                                // handleChange(event);
                                                handleChange({ target: { name: "staff_profile_pic", value: null } }); // Clear the input value
                                            }
                                        }}
                                        
                                        className={errors.staff_profile_pic && touched.staff_profile_pic ? "input-error" : ""}
                                    />
                                    {errors.staff_profile_pic && touched.staff_profile_pic && <p className="error">{errors.staff_profile_pic}</p>}
                                </Col> */}
                                        

                            {/* --------------Tutor Notes--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Notes</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        id="staff_notes"
                                        
                                        value={values.staff_notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_notes && touched.staff_notes ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.staff_notes && touched.staff_notes && <p className="error">{errors.staff_notes}</p>}
                                </Col>
                            </Row>
                                       
                        </div>
                    </Stack>
                           
                        {/* --------------Tutor Personal End--------------- */}

                        {/* --------------Contact Details--------------- */}

                        <Stack className='mt-3'>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details </h6>
                            </div>
                
                                <Row>
                       
                            {/* --------------Contact Address1--------------- */}
                            
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        id="staff_address"
                                        
                                        value={values.staff_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_address && touched.staff_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.staff_address && touched.staff_address && <p className="error">{errors.staff_address}</p>}
                                </Col>
                                        

                                        {/* --------------Contact Address2--------------- */}
                                        {/* <Form.Group as={Row} className="mb-3" controlId="staff_address2">
                                            <Form.Label column sm="3">
                                                <h6>Address 2</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    
                                                    value={values.staff_address2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.staff_address2 && touched.staff_address2 ? "input-error control_textarea" : "control_textarea"}
                                                />
                                                {errors.staff_address2 && touched.staff_address2 && <p className="error">{errors.staff_address2}</p>}
                                            </Col>
                                        </Form.Group> */}

                                        {/* --------------Contact Details Title--------------- */}
                                        {/* <Form.Group as={Row} className="mb-3" controlId="tut_suburb">
                                            <Form.Label column sm="3">
                                                <h6>Suburb</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    type="text"
                                                    
                                                    value={values.tut_suburb}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.tut_suburb && touched.tut_suburb ? "input-error" : ""}
                                                />
                                                {errors.tut_suburb && touched.tut_suburb && <p className="error">{errors.tut_suburb}</p>}
                                            </Col>
                                        </Form.Group> */}

                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control
                                        type="text"
                                        id="staff_city"
                                        
                                        value={values.staff_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_city && touched.staff_city ? "input-error" : ""}
                                    />
                                    {errors.staff_city && touched.staff_city && <p className="error">{errors.staff_city}</p>}
                                </Col>
                                        

                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control
                                        type="text"
                                        id="staff_state"
                                        
                                        value={values.staff_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_state && touched.staff_state ? "input-error" : ""}
                                    />
                                    {errors.staff_state && touched.staff_state && <p className="error">{errors.staff_state}</p>}
                                </Col>
                                        

                                        {/* --------------Contact Details Title--------------- */}
                                        {/* <Form.Group as={Row} className="mb-3" controlId="staff_zip">
                                            <Form.Label column sm="3">
                                                <h6>Postcode</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    type="text"
                                                    
                                                    value={values.staff_zip}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.staff_zip && touched.staff_zip ? "input-error" : ""}
                                                />
                                                {errors.staff_zip && touched.staff_zip && <p className="error">{errors.staff_zip}</p>}
                                            </Col>
                                        </Form.Group> */}

                        {/* --------------Student Country--------------- */}
                        
                            <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                <h6 className="form-lable required-field">Country</h6>
                                 <Select menuPlacement="auto" 
                                    options={options_countries}
                                    
                                    name="staff_country"
                                    id="staff_country"
                                    isDisabled={true}
                                    value={options_countries.find(option => option.value && values.staff_country && option.value.toString() == values.staff_country.toString())}
                                    //value={values.staff_country["value"]}
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "staff_country",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, staff_country: true });
                                    }}
                                    className={errors.staff_country && touched.staff_country ? "input-error" : ""}
                                />
                                {errors.staff_country && touched.staff_country && <p className="error">{errors.staff_country}</p>}
                            </Col>
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text"  
                                        value={values.staff_zip}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       id="staff_zip"
                                       className={errors.staff_zip && touched.staff_zip ? "input-error" : "mb-0"} 
                                       />
                                    {errors.staff_zip && touched.staff_zip && <p className="error">{errors.staff_zip}</p>}
                                </Col>
                        
                        </Row>
                    </div>
                </Stack>
            
            {/* --------------Contact Details End--------------- */}

            {/* --------------Contact Details--------------- */}
            <Stack className='mt-3'>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact </h6>
                            </div>
                
                            <Row>
                       
                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Mobile Phone</h6>
                                    {/* <Form.Control
                                        type="text"
                                        id="staff_mobile_phone"
                                        
                                        value={values.staff_mobile_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_mobile_phone && touched.staff_mobile_phone ? "input-error" : ""}
                                    />
                                    {errors.staff_mobile_phone && touched.staff_mobile_phone && <p className="error">{errors.staff_mobile_phone}</p>} */}
                                    <PhoneInput
                                        name="staff_mobile_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code')!=null &&  sessionStorage.getItem('country_alph_code')!='' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.staff_mobile_phone}
                                        onChange={(phone, country) =>  {
                                            const event = {
                                            target: {
                                                name: "staff_mobile_phone",
                                                value: phone,
                                            },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "staff_mobile_countryCode",
                                                    value: country.dialCode,
                                                },
                                                };
                                            handleChange(event1);
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, staff_mobile_phone: true });
                                        }}
                                        inputProps={{id: 'staff_mobile_phone'}}
                                        id="staff_mobile_phone"
                                        inputClass={errors.staff_mobile_phone && touched.staff_mobile_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.staff_mobile_phone && touched.staff_mobile_phone && <span className="error">{errors.staff_mobile_phone}</span>}
                                </Col>
                                        
                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Other Phone</h6>
                                    {/* <Form.Control
                                        type="text"
                                        id="staff_other_phone"
                                        
                                        value={values.staff_other_phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.staff_other_phone && touched.staff_other_phone ? "input-error" : ""}
                                    />
                                    {errors.staff_other_phone && touched.staff_other_phone && <p className="error">{errors.staff_other_phone}</p>} */}
                                    <PhoneInput
                                        name="staff_other_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code')!=null &&  sessionStorage.getItem('country_alph_code')!='' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.staff_other_phone}
                                        onChange={(phone, country) =>  {
                                            const event = {
                                            target: {
                                                name: "staff_other_phone",
                                                value: phone,
                                            },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "staff_other_countryCode",
                                                    value: country.dialCode,
                                                },
                                                };
                                            handleChange(event1);
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, staff_other_phone: true });
                                        }}
                                        inputProps={{id: 'staff_other_phone'}}
                                        id="staff_other_phone"
                                        inputClass={errors.staff_other_phone && touched.staff_other_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.staff_other_phone && touched.staff_other_phone && <span className="error">{errors.staff_other_phone}</span>}
                                </Col>
                                        
                                        {/* --------------Contact Details Title--------------- */}
                                        {/* <Form.Group as={Row} className="mb-3" controlId="tut_mobile">
                                            <Form.Label column sm="3">
                                                <h6>Mobile</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    type="text"
                                                    
                                                    value={values.tut_mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.tut_mobile && touched.tut_mobile ? "input-error" : ""}
                                                />
                                                {errors.tut_mobile && touched.tut_mobile && <p className="error">{errors.tut_mobile}</p>}
                                            </Col>
                                        </Form.Group> */}
                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Email</h6>
                                    <Form.Control
                                        type="staff_emailid"
                                        id="staff_emailid"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.staff_emailid}
                                        
                                        className={errors.staff_emailid && touched.staff_emailid ? "input-error" : "mb-0"}
                                    />
                                    {errors.staff_emailid && touched.staff_emailid && <p className="error">{errors.staff_emailid}</p>}
                                </Col>
                            
                            </Row>
                        </div>
                    </Stack>
                
            {/* --------------Contact Details End--------------- */}

            {/* --------------Login Details--------------- */}
                    <Stack className='mt-3'>
                    <div className="my-card p-3 bg-white">
                        <div className='section-heading border-bottom pb-3 mb-3'>
                            <h6 className='mb-0'>TCMS Login </h6>
                        </div>
            
                            <Row>
                       
                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Username</h6>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        id="sa_login_id"
                                        value={values.sa_login_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        //onBlur={handleUsernameBlur}
                                        className={errors.sa_login_id && touched.sa_login_id ? "input-error" : ""}
                                        ref={SaLoginIdRef}
                                        
                                    />
                                    {errors.sa_login_id && touched.sa_login_id && <p className="error">{errors.sa_login_id}</p>}
                                </Col>
                                        

                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control
                                        type="password"
                                        autoComplete="new-password"
                                        id="sa_password"
                                        value={values.sa_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.sa_password && touched.sa_password ? "input-error" : ""}
                                    />
                                    {errors.sa_password && touched.sa_password && <p className="error">{errors.sa_password}</p>}
                                </Col>
                                        

                            {/* --------------Contact Details Title--------------- */}
                            
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control
                                        type="password"
                                        autoComplete="new-password"
                                        id="sa_confirm_password"
                                        value={values.sa_confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.sa_confirm_password && touched.sa_confirm_password ? "input-error" : ""}
                                    />
                                    {errors.sa_confirm_password && touched.sa_confirm_password && (
                                        <p className="error">{errors.sa_confirm_password}</p>
                                    )}
                                </Col>
                                        
                            {/* --------------Student Parents / Guardians --------------- */}
                        
                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none'}}>
                                    <h6 className="form-lable required-field">Time zone</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_time_zone}
                                        
                                        name="staff_timezone"
                                        id="staff_timezone"
                                        value={options_time_zone.find(option => option.value && values.staff_timezone && option.value.toString() === values.staff_timezone.toString())}
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "staff_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, staff_timezone: true });
                                        }}
                                        className={errors.staff_timezone && touched.staff_timezone ? "input-error" : ""}
                                    />
                                    {errors.staff_timezone && touched.staff_timezone && <p className="error">{errors.staff_timezone}</p>}
                                </Col>
                                        

                        {/* --------------Contact Details Title--------------- */}

                            <Col lg="12" md="12" sm="12" className='position-relative'>
                                {/* <h6 className="form-lable required-field"></h6>    */}
                                <Form.Check
                                    type="checkbox"
                                    label="Is Active"
                                    name="is_active"
                                    id="is_active"
                                    checked={values.is_active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="mb-0"
                                />

                                {errors.is_active && touched.is_active && <span className="error">{errors.is_active}</span>}
                            </Col>
                        
                        </Row>
                    </div>
                </Stack>
                            
                {/* --------------Login Details End--------------- */}

                {/* --------------Conditions Details--------------- */}
                <Stack className='mt-3'>
                <div className="my-card p-3 bg-white">
                    <div className='section-heading border-bottom pb-3 mb-3 d-flex'>
                        <h6 className='mb-0 mr-3'>Employment Conditions</h6>&nbsp;
                       
                        {/* {['right'].map((placement) => (
                        <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                            If both flat rate fee and per student fee are input, the wage calculated for the tutor will use the following formula: Flat Rate Fee + (Number of Students * Per Student Fee) 
                            </Tooltip>
                        }
                        >
                            <Button variant="secondary" className="no-bdr">
                                <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    ))} */}
                    </div>
        
                        <Row>
                        {/* --------------Student Parents / Guardians --------------- */}
                        
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Staff Type</h6>
                                 <Select menuPlacement="auto" 
                                    options={options_emp_type}
                                    
                                    name="staff_type"
                                    id="staff_type"
                                    value={values.staff_type["value"]}
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "staff_type",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, staff_type: true });
                                    }}
                                    className={errors.staff_type && touched.staff_type ? "input-error" : ""}
                                />
                                {errors.staff_type && touched.staff_type && <p className="error">{errors.staff_type}</p>}
                            </Col>
                                       

                                        {/* --------------Student Parents / Guardians --------------- */}
                                        {/* <Form.Group as={Row} className="mb-3" controlId="effective_date">
                                            <Form.Label column sm="3">
                                                <h6>Effective Date</h6>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    type="date"
                                                    value={values.effective_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.effective_date && touched.effective_date ? "input-error" : ""}
                                                />
                                                {errors.effective_date && touched.effective_date && <p className="error">{errors.effective_date}</p>}
                                            </Col>
                                        </Form.Group> */}

                        {/* --------------Student Parents / Guardians --------------- */}
                    
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Start Date</h6>
                                {/* <Form.Control
                                    type="date"
                                    id="staff_st_date"
                                    min={new Date().toISOString().split("T")[0]}
                                    value={values.staff_st_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_st_date && touched.staff_st_date ? "input-error" : ""}
                                /> */}
                                 <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.staff_st_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "staff_st_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, staff_st_date: true });
                                            }}
                                            name="staff_st_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'staff_st_date',
                                                   
                                                },
                                                }}
                                            className={errors.staff_st_date && touched.staff_st_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                {errors.staff_st_date && touched.staff_st_date && <span className="error">{errors.staff_st_date}</span>}
                            </Col>
                                        
                        {/* --------------Student Parents / Guardians --------------- */}
                        
                            <Col lg="4" md="6" sm="12" className='position-relative'  style={{ display:'none'}}>
                                <h6 className="form-lable required-field">End Date</h6>
                                <Form.Control
                                    type="date"
                                    id="staff_en_date"
                                    min={values.staff_st_date ? values.staff_st_date : undefined} // Set min to staff_st_date if it's defined
                                    value={values.staff_en_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_en_date && touched.staff_en_date ? "input-error" : ""}
                                />
                                {errors.staff_en_date && touched.staff_en_date && <p className="error">{errors.staff_en_date}</p>}
                            </Col>
                                        
                        {/* --------------Student Parents / Guardians --------------- */}
                        
                           
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Monthly Wage</h6>
                                <Form.Control
                                    type="number"
                                    id="staff_monthly_wage"
                                    
                                    value={values.staff_monthly_wage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_monthly_wage && touched.staff_monthly_wage ? "input-error" : ""}
                                />
                                {errors.staff_monthly_wage && touched.staff_monthly_wage && <p className="error">{errors.staff_monthly_wage}</p>}
                            </Col>
                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                <h6 className="form-lable required-field">Weekly Wage</h6>
                                <Form.Control
                                    type="number"
                                    id="staff_weekly_wage"
                                    
                                    value={values.staff_weekly_wage}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.staff_weekly_wage && touched.staff_weekly_wage ? "input-error" : "mb-0"}
                                />
                                {errors.staff_weekly_wage && touched.staff_weekly_wage && <p className="error">{errors.staff_weekly_wage}</p>}
                            </Col>
                            
                        
                        </Row>
                    </div>
                </Stack>
            
            {/* --------------Conditions Details End--------------- */}

            <Stack gap={2} className="mt-3">
                <div className="text-end">
                    
                    <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                    <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                </div>
            </Stack>

                            
                    
        </div>

    </Form>
    </>
    );
}

export default AddStaff;
