/*
Purpose: Add session form
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import { useNavigate,  NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import Select from "react-select";

import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';



import axios from "axios";
import {
    security_key, options_days 
, BASE_URL} from "../global";



const basicSchema = yup.object().shape({
    ses_name: yup.string().required("Title is required."),
    ses_day: yup.mixed().required("Day is required."),
    ses_tutor: yup.mixed().required("Tutor is required."),
    ses_notes: yup.mixed().required("Notes is required."),
    ses_type: yup.mixed().required("Type is required."),
    ses_start_time: yup.mixed().required("Time is required."),
    ses_end_time: yup.mixed().required("Time is required."),
    //.when("ses_start_time", (ses_start_time, schema) => ses_start_time && schema.min(ses_start_time)),
    ses_duration: yup.mixed().required("Duration is required."),

   
});

function AddSession() {

    const [options_tutor, setOptions] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();


    const currentDate = new Date(); // Get the current date and time
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " "); // Format it as "YYYY-MM-DD HH:mm:ss"



    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        setValues({
            ...initialValues, // Reset select fields
        });
    };

    const initialValues = {
        /*tbl_tutor*/
        ses_name: "",
        ses_day: "",
        ses_tutor: "",
        ses_notes: "",
        ses_type: "",
        ses_start_time: "",
        ses_end_time: "",
        ses_duration: "",

        /*Common*/
        created_by: "",
        created_on: formattedDate,
        updated_by: "0",
        updated_on: formattedDate,
        visibility: "1",
        fk_cent_key: sessionStorage.getItem('key'),
    };

    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
        // const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            // Disable the submit button and reset alerts
            //setIsSubmitting(true);
    
                    try {
                        const response = await axios.post(BASE_URL+"API/api_index.php", {
                            security_key: security_key,
                            mode: "createSession",
                            ...formValues,
                        });

                        if (response.data.success === true) {



                            if (response.data.data === 1) {
                                resetFormFields(resetForm, setValues, initialValues);
                                toast.success("Success! Your form was submitted", {
                                    autoClose: 3000, // 3 seconds in this example
                                });

                                // After 3 seconds, redirect to another page
                                setTimeout(function () {
                                    navigate("/list-tutor");
                                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                                setIsSubmitting(false); // Re-enable the submit button
                            } else {
                                toast.error("Oops! Something went wrong.", {
                                    autoClose: 3000, // 3 seconds in this example
                                });

                                setIsSubmitting(false); // Re-enable the submit button
                            }

                        } else {
                            toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                                autoClose: 3000, // 3 seconds in this example
                            });

                            setIsSubmitting(false); // Re-enable the submit button
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } finally {
                        setIsSubmitting(false); // Re-enable the submit button
                    }

               

                    setIsSubmitting(false); // Re-enable the submit button
                }

                // You can also perform further actions based on the API response
                // For example, show a success message or redirect to another page.
           



 
    });

    
    const options_type = [
        { value: "1", label: "Online" },
        { value: "2", label: "In-centre" },

      ];


      useEffect(() => {
        // Make an API request to fetch the options
        axios.post(BASE_URL+'API/api_index.php', {
            security_key: security_key,
            mode: "getAllTutorByCenter",
            center_key: sessionStorage.getItem('key')

            
        })
          .then(response => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
            const formattedOptions = response.data.data.map(option => ({
              value: option.pk_tut_id ,
              label: option.tut_fname+' '+option.tut_surname
            }));
            setOptions(formattedOptions);
          })
          .catch(error => {
            console.error('Error fetching options from API: ', error);
          });
      }, []);


    return (

        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>


                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Create Session </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Session</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>

                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Personal </h6>
                            </div>
                            <Row>
                                {/* -------------- Session Title--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Title</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.ses_name}
                                        id="ses_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ses_name && touched.ses_name ? "input-error" : ""}
                                    />
                                    {errors.ses_name && touched.ses_name && <p className="error">{errors.ses_name}</p>}
                                </Col>

                                {/* --------------Session Date--------------- */}

                              <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Day</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_days
                                        }
                                        
                                        name="ses_day"
                                        id="ses_day"
                                       // value={values.te_wage_typ["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ses_day",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ses_day: true });
                                        }}
                                        className={errors.ses_day && touched.ses_day ? "input-error" : ""}
                                    />
                                   
                                    {errors.ses_day && touched.ses_day && <p className="error">{errors.ses_day}</p>}
                                </Col>

                                                      
                                

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Tutor</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_tutor}
                                        
                                        name="ses_tutor"
                                        id="ses_tutor"
                                       // value={values.te_wage_typ["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ses_tutor",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ses_tutor: true });
                                        }}
                                        className={errors.ses_tutor && touched.ses_tutor ? "input-error" : ""}
                                    />
                                    {errors.ses_tutor && touched.ses_tutor && <span className="error">{errors.ses_tutor}</span>}
                                </Col>

                                




                                
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Type</h6>
                                     <Select menuPlacement="auto" 
                                        options={options_type}
                                        
                                        name="ses_type"
                                        id="ses_type"
                                        //value={values.te_wage_typ["value"]}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ses_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ses_type: true });
                                        }}
                                        className={errors.ses_type && touched.ses_type ? "input-error" : ""}
                                    />
                                    {errors.ses_type && touched.ses_type && <span className="error">{errors.ses_type}</span>}
                                </Col>


                                {/* --------------Session Start Time--------------- */}

                               <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session Start Time</h6>
                                    <Form.Control type="time" 
                                    
                                    value={values.ses_start_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="ses_start_time"
                                    className={errors.ses_start_time && touched.ses_start_time ? "input-error" : ""} 
                                    />
                                    {errors.ses_start_time && touched.ses_start_time && <span className="error">{errors.ses_start_time}</span>}

                                  
                                </Col>

                                {/* --------------Session End Time--------------- */}
                              <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Session End Time</h6>
                                    <Form.Control type="time" 
                                    
                                    value={values.ses_end_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="ses_end_time"
                                    minTime={values.ses_start_time}

                                    className={errors.ses_end_time && touched.ses_end_time ? "input-error" : ""} 
                                    />
                                    {errors.ses_end_time && touched.ses_end_time && <span className="error">{errors.ses_end_time}</span>}
                                </Col>


                                {/* --------------Duration--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Duration</h6>
                                    <Form.Control
                                     type="text" 
                                     
                                     value={values.ses_duration}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="ses_duration"

                                     className={errors.ses_duration && touched.ses_duration ? "input-error" : ""}
                                      />
                                      {errors.ses_duration && touched.ses_duration && <span className="error">{errors.ses_duration}</span>}
                                    
                                </Col>


                                {/* --------------Tutor Notes--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Notes</h6>
                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        style={{height: "132px"}}
                                        id="ses_notes"
                                        
                                        value={values.ses_notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.ses_notes && touched.ses_notes ? "input-error" : ""}
                                    />
                                    {errors.ses_notes && touched.ses_notes && <p className="error">{errors.ses_notes}</p>}
                                </Col>
                            </Row>

                        </div>
                    </Stack>

                    {/* --------------Tutor Personal End--------------- */}


                    {/* --------------Conditions Details End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={handleSave}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>



                </div>

            </Form>
        </>)

        
}

export default AddSession;