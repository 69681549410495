/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS


UpdateBy:KTH
updateDate:30/08/2023 
Purpose: include API
*/

import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { Row, Col, Stack, Dropdown, DropdownButton, ButtonGroup, Modal } from 'react-bootstrap';

import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import Moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import { security_key, BASE_URL } from "../global";



function ListTutorPayment() {

  //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  const [showPaid, setPaidShow] = useState(false);
  const [data_paid, setPaidLabelData] = useState([]);
  const [isUserPaidSubmitting, setIsUserPaidSubmitting] = useState(false);


  const [showPaidDetail, setPaidDetailShow] = useState(false);

  const handlePaidClick = (row) => {
   
    const updatedData = { ...row };
    setPaidLabelData(updatedData);

    setPaidShow(true);
  };

  const handlePaidDetailClick = (row) => {
  
    const updatedData = { ...row };
    setPaidLabelData(updatedData);

    setPaidDetailShow(true);
  };
  


  // Sample columns configuration
  const columns = [
    {
      headerName: 'S.No',
      field: "id",
      headerClassName: "super-app-theme--header",
      flex: 1
    


      // valueGetter: (params) => {
      //   return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname+' '+params.row.tut_surname}`;
      // },

    },
    {
      headerName: 'Payment Schedule',
      field: "Payment Schedule",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_payment_frequency == null || params.row.tut_payment_frequency == '' ? '-' : params.row.tut_payment_frequency}`;
      },
    },
    {
      headerName: 'Period',
      field: "Period",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.start == null || params.row.start == '' ? '-' : Moment(params.row.start, 'YYYY-MM-DD').format('DD-MM-YYYY')+' - '+Moment(params.row.end, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
    },

    {
      headerName: 'Details',
      field: "Details",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.tut_weekly_wage == null || params.row.tut_weekly_wage == ""
            ? " Weekly wage = 0,"
            : ` Weekly wage = ${params.row.tut_weekly_wage},`
        }${
          params.row.tut_month_wage == null || params.row.tut_month_wage == ""
            ? " Monthly wage = 0,"
            : ` Monthly wage = ${params.row.tut_month_wage},`
        }${
          params.row.tut_flat_rate_fees == null || params.row.tut_flat_rate_fees == ""
            ? " Flat rate = 0,"
            : ` Flat Rate = ${params.row.tut_flat_rate_fees},`
        }${
          params.row.tut_per_stu_fees == null || params.row.tut_per_stu_fees ==""
            ? " Per student fees = 0,"
            : ` Per student fees = ${params.row.tut_per_stu_fees},`
        }${
          params.row.student_count == null || params.row.student_count == ""
            ? " Session student count = 0,"
            : ` Session student count = ${params.row.student_count},`
        }${
          params.row.session_completed_count == null || params.row.session_completed_count == ""
            ? " Completed Session = 0,"
            : ` Completed Session = ${params.row.session_completed_count},`
        }${
          params.row.tut_min_student == null || params.row.tut_min_student == ""
            ? " Tutor Min Student = 0"
            : ` Tutor Min Student = ${params.row.tut_min_student}`
        }`;
      },
    },
    {

      headerName: 'Amount ('+ sessionStorage.getItem('currency_code')+')',
      field: "Amount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.amount == null || params.row.amount == '' ? '0' : params.row.amount}`;
      },
    },
    {
      field: "Status",
      type: "actions",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 250,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.status)==1? 'Paid' : 'Not Paid'}`;
      },
      renderCell: (params) => {
        const row = params.row;

        if(row.amount == null || row.amount == '' || parseInt(row.amount)==0){
          return (<p>-</p>);
        }else{
          if(parseInt(row.status)==1){
            return (<Button variant="outlined-red" className="primary-blue my-2"  onClick={() => handlePaidDetailClick(row)} >Paid</Button>);
          }else{
            return (<Button variant="outlined-red" className="filled-danger my-2" onClick={() => handlePaidClick(row)}>Not Paid</Button>);
          }
        }
       
        
      }

    },
    
  ];

  // Sample data source

  //Api call start

  // const data1 = [
  //   {
  //     pay_schedule:'Weekly',
  //     pay_start_date:'2023-12-06',
  //     pay_to_date:'2023-12-13',
  //     pay_amount:'100.00'
  //   },
    

  // ];
  const [data1, setItems] = useState([]);
  const [tutorData, setTutorData] = useState([]);
  const [Id, setId] = useState(null);

  const location = useLocation();
  useEffect(() => {

  const { pk_tut_key } = location.state || {};
  const extractedTId = pk_tut_key;
      if (extractedTId !== '' && extractedTId !== undefined) {
          setId(extractedTId);
          fetchData(extractedTId);
          
      }else{
        navigate("/list-tutor");
      }
  }, [location]);



  useEffect(() => {
       
      fetchPayData(Id);
       
  }, [Id]); 
  //Api call End
  

  const [records, setRecords] = useState(data1);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  
    const handleTutorClick = () => {
      navigate(`/edit-tutor`, { state: { pk_tut_key: `${Id}` } });
    }
  
  const fetchData = async (id) => {


    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          mode: "getSingleTutor_new",
          center_key: sessionStorage.getItem('key'),
          pk_tut_key: id
      });

      if (response.data.success === true) {
          if (response.data.data.length > 0) {
              setTutorData(response.data.data[0]);

          }

          
      } else {
          // Handle unsuccessful response, e.g., show an alert to the user.
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
   
  };


  const fetchPayData = async (id) => {
  try {
    const responsetut = await axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getTutorViewPayment",
      center_key: sessionStorage.getItem('key'),
      pk_tut_key: id
    });
   
    if (responsetut.data.success === true) {
      setRecords(responsetut.data.data);
      setItems(responsetut.data.data);
      setLoading(false);

    } else {
      //alert message provide
      setLoading(false);

    }


  } catch (error) {
    console.error('Error fetching data:', error);
  }

}

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;

    if (endDate && new Date(endDate).getTime() < new Date(newStartDate).getTime()) {
      // You can handle this validation error, for example, by not updating the state
     // console.error('End date cannot be earlier than start date');
     setStartDate('');
      return;
  }
    setStartDate(newStartDate);
    handleDateFilter(newStartDate, endDate);
};

const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;

      // Check if the new end date is not earlier than the start date
      if (startDate && new Date(newEndDate).getTime() < new Date(startDate).getTime()) {
        // You can handle this validation error, for example, by not updating the state
       // console.error('End date cannot be earlier than start date');
        setEndDate('');
        return;
    }
    setEndDate(newEndDate);
    handleDateFilter(startDate, newEndDate);
};

const handleDateFilter = (start, end) => {
    const filterStartDate = start ? new Date(start).getTime() : null;
    const filterEndDate = end ? new Date(end).getTime() : null;

    const filteredData = data1.filter((record) => {
        const lessonStartDate = new Date(record.ses_date).getTime();
        const lessonEndDate = new Date(record.ses_date).getTime();

        return (
            (filterStartDate === null || lessonStartDate >= filterStartDate) &&
            (filterEndDate === null || lessonEndDate <= filterEndDate)
        );
    });

    setRecords(filteredData);
   
};

const handleDateReset = () => {
    
    setStartDate('');
    setEndDate('');
    setRecords(data1);

    //setLessionRecords(lesson_data);
};


function onSubmitPaid() {
  setIsUserPaidSubmitting(true);

  const responsepaid = axios.post(BASE_URL + "API/api_index.php", {

    security_key: security_key,
    updated_by: sessionStorage.getItem('key'),
    mode: "PaidTutor",
    pk_tp_key: data_paid.pk_tp_key,
    // tut_payment_frequency: data_paid.tut_payment_frequency,
    // start: data_paid.start,
    // end: data_paid.end,
    // amount: data_paid.amount,
    fk_cent_key: sessionStorage.getItem('key')
  }).then((responsepaid) => {

    if (responsepaid.data.data == 1) {

      setPaidShow(false)
      // Handle the successful response here
      toast.success("Success! Amount has been paid.", {
        autoClose: 3000, // 3 seconds in this example
      });

      fetchPayData(Id);

      setIsUserPaidSubmitting(false);

    } else {
      toast.error("Failure! Unable to Amount has been paid. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsUserPaidSubmitting(false);
    }


  })
    .catch((error) => {
      // Handle any errors that occurred during the request
      toast.error("Failure! Unable to Amount has been paid. Try again later", {
        autoClose: 3000, // 3 seconds in this example
      });
      setIsUserPaidSubmitting(false);
    });
}


  

  return (
    <>
      <ToastContainer position="top-center" />
    <div className="page-wrapper">


        <Modal show={showPaid} variant="danger">

          <Modal.Header>Paid</Modal.Header>

          <Modal.Body>
            <p>Are you sure? You want to mark this tutor as paid? </p>

          </Modal.Body>
          <Modal.Footer className='pt-0'>
            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setPaidShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " disabled={isUserPaidSubmitting} onClick={() => onSubmitPaid()} >Yes</Button>
            </div>

          </Modal.Footer>

        </Modal>



        <Modal show={showPaidDetail} variant="danger">

<Modal.Header>Payment details</Modal.Header>

<Modal.Body className="bdr-btm">
  

  <Card className='rounded-1 mt-2 card-bdr' >
    <Card.Body>

      <div className="me-5">
        <div className="">

          <div className="cus-pyt-dtls">
            <h6 className='mb-0'>Tutor Name:</h6>
            <p className="mb-0">
            {tutorData ? tutorData.tut_fname != null && tutorData.tut_fname != '' ? tutorData.tut_fname + ' ' + tutorData.tut_surname  : '' : ''}
            </p>
          </div>

          <div className="cus-pyt-dtls">
            <h6 className='mb-0'>Payment Schedule:</h6>
            <p className="mb-0">
              {data_paid.tut_payment_frequency}
            </p>
          </div>

          <div className="cus-pyt-dtls">
            <h6>From Date:</h6>
              <p className="mb-0">
                {Moment(data_paid.start, 'YYYY-MM-DD').format('DD-MM-YYYY')}
              </p>
          </div>

          <div className="cus-pyt-dtls">
              <h6>End Date:</h6>
              <p className="mb-0">
                {Moment(data_paid.end, 'YYYY-MM-DD').format('DD-MM-YYYY')}
              </p>
          </div>

          <div className="cus-pyt-dtls">
              <h6>Amount:</h6>
              <p className="mb-0">
                {data_paid.amount} - {sessionStorage.getItem('currency_code')}
              </p>
          </div>

          <div className="cus-pyt-dtls mb-0">
              <h6 className="mb-0">Status:</h6>
              <p className="mb-0 text-success"> <strong>Paid</strong>
                {/* {parseInt(data_paid.status)==0? '' } */}
              </p>
          </div>
             
              

        </div>
      </div>
    </Card.Body>
  </Card>
</Modal.Body>
<Modal.Footer className=''>
  <div className="mx-auto d-flex gap-2" >

    <Button variant="contained" className="primary-gray" onClick={() => setPaidDetailShow(false)}>Cancel</Button>
   
  </div>

</Modal.Footer>

</Modal>
      {/* --------------Page Start--------------- */}


      {/* --------------Section Start--------------- */}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Tutor Payment</h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>



              <Typography color="text.primary">Tutor Payment</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                        

              <div >
                  <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                      <Dropdown.Item onClick={() => handleTutorClick()}>  Edit Tutor
                      </Dropdown.Item>


                  </DropdownButton>
              </div>
            {/* <NavLink className="primary-filled rounded-10 p-2" to=''>
              <AddIcon className="btn-icon" />View Payments</NavLink> */}

          </div>
        </div>
      </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>
          <Stack gap={3} >
                <div className="mb-3">

                </div>
          </Stack>
          <Stack gap={3} >
                <div className="mb-3">

                    <div className="cus-les-sec">
                        {/* 
                        <div className='cus-les-left'>
                            <div className="cus-les-sd">
                                <Form>

                                    
                                    <Form.Group className="form-group d-flex align-items-center" controlId="formsdate">
                                        <Form.Label className="pe-2 mb-0">
                                            <p className="mb-0">Start Date</p>
                                        </Form.Label>
                                        <Col className="">
                                            <input type="date" max={endDate} className="form-control mb-0" onChange={handleStartDateChange} value={startDate} />
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </div>

                            <div className="cus-les-ed">
                                <Form>

                                    
                                    <Form.Group className="form-group d-flex align-items-center" controlId="formedate">
                                        <Form.Label className="pe-2 mb-0">
                                            <p className="mb-0">End Date</p>
                                        </Form.Label>
                                        <Col className="">
                                            <input type="date" min={startDate} className="form-control mb-0" onChange={handleEndDateChange} value={endDate} />
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </div>
                            <div className="cus-les-rst">
                                <Button variant="secondary" className="btn-sec-outline" onClick={handleDateReset}>
                                    Reset
                                </Button>
                            </div>
                        </div> */}
                        <div className='cus-les-right'>
                          

                            <div className="cus-les-rst">
                            <span className='ml-5 mt-4'><h5>{tutorData ? tutorData.tut_fname != null && tutorData.tut_fname != '' ? tutorData.tut_fname + ' ' + tutorData.tut_surname  : '' : ''}</h5></span>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack>
          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">

                <Row className="justify-content-center">

                



                  {/* --------------Table Content Start--------------- */}
                  <Col sm={12}>

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#f4f4f4",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "TutorPayment" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "TutorPayment" },
                            records,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `${index+1}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
                        disableRowSelectionOnClick
                        showCellVerticalBorder= {true}
                        showColumnVerticalBorder= {true}
                      //  onRowClick={(params) => handleRowClick(params.row)}


                      />
                    </Box>



                  </Col>
                  {/* --------------Table Content Start--------------- */}

                </Row>

              </div>
            </Col>
          </Row>

          {/* --------------Content End--------------- */}


        </Card.Body>

      </Card>

      {/* --------------Section End--------------- */}




      {/* --------------Page End--------------- */}

    </div>

    </>
  );
}

export default ListTutorPayment;