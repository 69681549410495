/*
Purpose: list details of session with use datatable
Designed: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Tab, Card, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Button as Buttonmui } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from "react-bootstrap/Stack";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';

import {
  security_key, BASE_URL
} from "../global";






const SessionList = () => {


  //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  
  const [extractedsessionKey, setextractedsessionKey] = useState('');
  useEffect(() => {

     const { pk_sest_key } = location.state || {};

     const extractedsessionKey = pk_sest_key;
 
 
     if (extractedsessionKey !== '' && extractedsessionKey !== undefined) {
         setextractedsessionKey(extractedsessionKey);
 
     }else{
       navigate("/list-session-time");
     }
   }, [location]);

  
  const handleStudentViewClick = async (e) => {
    navigate(`/student-profile`, { state: { pk_student_key: `${e.fk_stu_key}` } });

  }

   //Edit Date/time start
  const [showEditDT, setEditDTShow] = useState(false);
  const [EventData, setEditData] = useState([]);
  const [iSEditDTSubmitting, setIsEditDTSubmitting] = useState(false);
  //Edit Date/time End

//Tutor Start
// const [options_tutor, setOptions] = useState([]);
//Tutor End
  // Sample data source
  /*
  
    pk_sest_id	
    pk_sest_key Ascending 1	
    sest_day	
    sest_start_time	
    sest_type	
    tut_fname	
    tut_surname	
    Dates*/



  const columns = [
    {
      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    }, {
      headerName: 'Start Time',
      field: "Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_time == null || params.row.ses_start_time == '' ? '-' : Moment(params.row.ses_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    }, {
      headerName: 'End Time',
      field: "End Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_end_time == null || params.row.ses_end_time == '' ? '-' : Moment(params.row.ses_end_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    // {

    //   headerName: 'Day',
    //   field: "Day",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
    //   },

    // },
    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.tut_fname == null || params.row.tut_fname == '') && (params.row.tut_surname == null || params.row.tut_surname == '') ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },

    },

    // {
    //   name: 'Student',
    //   selector: row => row.enrolment_count,
    //   sortable: true,
    // },
    {
      headerName: 'Type',
      field: "Type",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_type == null || params.row.sest_type == '' ? '-' : params.row.sest_type}`;
      },

    },
    {
      headerName: 'Classes',
      field: "Classes",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 120,
      headerAlign:"left",
      renderCell: (params) => {
        const row = params.row;
      
        return (
          
          <div className='d-flex  pad-12'>
           {parseInt(row.ses_is_completed) == 1 ? (
              <span className='text-success'> Completed</span>
            ) : (
          <Buttonmui
            variant="outlined-red"
            onClick={() => {
              setEditData(row);
              setEditDTShow(true);
            }}
            className="primary-blue btnmr-2 my-2"
          >
            Edit
          </Buttonmui>
        )}
          </div>
        
        );
       
      }
    },
  ];




  const [options_tutor, setOptions] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the options
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getAllTutorbycenterSess",
      center_key: sessionStorage.getItem('key'),
    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.pk_tut_key,
          label: option.tut_fname + ' ' + option.tut_surname
        }));
        setOptions(formattedOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });
  }, []);


  //Api call start
  const [data, setItems] = useState([]);

  const [records, setRecords] = useState(data);


  useEffect(() => {
    fetchData();
  }, [extractedsessionKey]);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllSessionFromTime",
        pk_sest_key: extractedsessionKey,
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  //const handleFilterSessionClick = () => {
  // Disable the submit button and reset alerts
  //setIsSubmitting(true);



  //  useEffect(() => {

  //async () => {

  // try {
  //   const response =  axios.post(BASE_URL+'API/api_index.php', {
  //     security_key: security_key,
  //     mode: "getAllSessionFromTime",
  //     fk_cent_key: sessionStorage.getItem('key')
  //   });
  //   
  //   if (response.data.success === true) {
  //     setRecords(response.data.data);
  //     setItems(response.data.data);
  //   } else {
  //     //alert message provide
  //   }
  //   // You can also perform further actions based on the API response
  //   // For example, show a success message or redirect to another page.
  // } catch (error) {
  //   console.error('Error fetching data:', error);



  // }
  //}, []);

  //}





  //Api call End


  //Api call start

  const [loadingst, setLoadingst] = useState(true);

  const [datastudent, setItemsstudent] = useState([]);

  useEffect(() => {


    fetchDatastudent(extractedsessionKey);


  }, [extractedsessionKey]);

  const fetchDatastudent = async (id) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllStudentEditSessionTime",
        pk_sest_key: id,
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
       
        setItemsstudent(response.data.data);
        setLoadingst(false);

      } else {
        //alert message provide
        setLoadingst(false);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End



  // Sample columns configuration
  const columnsstudent = [
    {

      headerName: 'ID#',
      field: "ID#",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.pk_student_id == null || params.row.pk_student_id == '' ? '-' : params.row.pk_student_id}`;
      },

    },
    {
      headerName: 'Student Name',
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.st_first_name == null || params.row.st_first_name == '') && (params.row.st_surname == null || params.row.st_surname == '') ? '-' : params.row.st_first_name + " " + params.row.st_surname}`;
      },


    },
    {

      headerName: 'Date Of Birth',
      field: "Date Of Birth",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_dob == null || params.row.st_dob == '' ? '-' : Moment(params.row.st_dob, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },
    {
      headerName: 'Gender',
      field: "Gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_gender == null || params.row.st_gender == '' ? '-' : params.row.st_gender}`;
      },

    },
    {
      headerName: 'Year Level',
      field: "Year Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.st_year_level == null || params.row.st_year_level == '' ? '-' : params.row.st_year_level}`;
      },

    },
    {

      headerName: 'Enrol Start Date',
      field: "Enrol Start Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_start_date == null || params.row.enrol_start_date == '' ? '-' : Moment(params.row.enrol_start_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },

    },

    {

      headerName: 'Subjects',
      field: "Subjects",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },

    },

  ];


  

  const validationSchema1 = yup.object().shape({
    fk_tut_key: yup.mixed().required("Tutor  is required."),
    ses_date: yup
    .string()
    .test('is-valid-date', 'Please provide a valid date.', function (value) {
      const parsedDate = dayjs(value, 'YYYY-MM-DD');
      return parsedDate.isValid();
    }).required("Session date is required."),
    ses_start_time: yup.string().test('is-valid-time', 'Please provide a valid time.', function (value) {
      // Check if the value is a valid time
      return dayjs(value, 'HH:mm:ss').isValid();
    }).required('Start Time is required.'),
    ses_end_time: yup.string()
      .required('End Time  is required.')
      .test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const ses_start_time = Moment(this.parent.ses_start_time, 'HH:mm');
        const ses_end_time = Moment(value, 'HH:mm');
        return ses_end_time.isAfter(ses_start_time);
      }).test('is-valid-time', 'Please provide a valid time.', function (value) {
        // Check if the value is a valid time
        return dayjs(value, 'HH:mm:ss').isValid();
      }),
  });

  const initialValues1 = {
    pk_ses_key: EventData && EventData.pk_ses_key && EventData.pk_ses_key != '' ? EventData.pk_ses_key : '',
    fk_tut_key: EventData && EventData.fk_tut_key && EventData.fk_tut_key != '' ? EventData.fk_tut_key : '',
    ses_date: EventData && EventData.ses_date && EventData.ses_date != '' ? EventData.ses_date : '',
    ses_start_time: EventData && EventData.ses_start_time && EventData.ses_start_time != '' ? EventData.ses_start_time : '',
    ses_end_time: EventData && EventData.ses_end_time && EventData.ses_end_time != '' ? EventData.ses_end_time : '',
    ses_duration: '',
    updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsEditDTSubmitting(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateSessionDateTime_new",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            setTimeout(function () {
              window.location.reload(false);


            }, 2000);
            

            setIsEditDTSubmitting(false); // Re-enable the submit button
          }else if(response.data.data === 2){
            toast.error("This tutor already has session on these date and time.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsEditDTSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update session. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsEditDTSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to update session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsEditDTSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update session. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsEditDTSubmitting(false); // Re-enable the submit button

      } finally {
        setIsEditDTSubmitting(false); // Re-enable the submit button

      }

    },
  });

 
  return (

    <>
    {/* React alert */}
     <ToastContainer position="top-center" />
    <Modal show={showEditDT} variant="danger" size="lg" style={{ zIndex: '1111' }}>

    <Modal.Header>Edit Session</Modal.Header>
    <Form onSubmit={formik1.handleSubmit}>
      <Modal.Body>
        <Row>
          
        <Col lg="6" md="6" sm="12" className='position-relative mb-3'>
              <h6 className="form-lable required-field">Tutor</h6>
                <Select menuPlacement="auto"  options={options_tutor}
                  name="fk_tut_key"
                  id="fk_tut_key"
                  value={options_tutor.find(option => option.value && formik1.values.fk_tut_key && option.value.toString() == formik1.values.fk_tut_key.toString())}

                  onChange={(selectedOption) => {
                      const event = {
                          target: {
                              name: "fk_tut_key",
                              value: selectedOption["value"],
                          },
                      };
                      formik1.handleChange(event);
                  }}
                  onBlur={(event) => {
                    formik1.handleBlur(event);
                    formik1.setTouched({ ...formik1.touched, fk_tut_key: true });
                  }}
                  className={formik1.errors.fk_tut_key && formik1.touched.fk_tut_key ? "input-error" : ""}
              />
              {formik1.errors.fk_tut_key && formik1.touched.fk_tut_key && <p className="error">{formik1.errors.fk_tut_key}</p>}
          </Col>
          <Col lg="6" md="6" sm="12" className='position-relative mb-3'>
            <h6 className="form-lable required-field">Session Date</h6>
            {/* <Form.Control type="date"
              value={formik1.values.ses_date}
              onBlur={formik1.handleBlur}
              min={formik1.values.ses_date}
              onChange={(value) => {
                const event = {
                  target: {
                    name: "ses_date",
                    value: value.target["value"],
                  },
                };
                console.log(event);
                formik1.handleChange(event);


              }}
              id="ses_date"
              name="ses_date"
              className={formik1.errors.ses_date && formik1.touched.ses_date ? "input-error" : ""} /> */}

                <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                  <DatePicker  views={['year', 'month', 'day']}
                      value={dayjs(formik1.values.ses_date, 'YYYY-MM-DD')}
                      onChange={(date) =>  {
                        const event = {
                        target: {
                            name: "ses_date",
                            value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                        },
                        };
                        formik1.handleChange(event);
                    }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                          formik1.setTouched({ ...formik1.touched, ses_date: true });
                      }}
                      name="ses_date"
                      slotProps={{
                          textField: { readOnly: true, size: 'small',
                            id: 'ses_date',
                          },
                        }}
                       // shouldDisableDate={(date) => !isMonday(dayjs(date))}
                      className={formik1.errors.ses_date && formik1.touched.ses_date ? "input-error w-100" : "w-100"}
                      minDate={dayjs(formik1.values.ses_date, 'YYYY-MM-DD')}
                      format="DD-MM-YYYY"
 />
              </LocalizationProvider>
            {formik1.errors.ses_date && formik1.touched.ses_date && <span className="error">{formik1.errors.ses_date}</span>}
          </Col>

          <Col lg="6" md="6" sm="12" className='position-relative mb-3'>
            <h6 className="form-lable required-field">Start Time</h6>
            {/* <Form.Control type="time" value={formik1.values.ses_start_time}
              onChange={formik1.handleChange}
              onBlur={formik1.handleBlur}
              id="ses_start_time"
              className={formik1.errors.ses_start_time && formik1.touched.ses_start_time ? "input-error" : ""}
            /> */}
                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                  <TimePicker 
                      value={dayjs(formik1.values.ses_start_time, 'HH:mm:ss')}
                      onChange={(time) =>  {
                        const event = {
                        target: {
                            name: "ses_start_time",
                            value: dayjs(time, 'HH:mm:ss').format('HH:mm:ss'),
                        },
                        };
                      
                        formik1.handleChange(event);
                        
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                          formik1.setTouched({ ...formik1.touched, ses_start_time: true });
                      }}
                      slotProps={{
                          textField: { readOnly: true, size: 'small',
                            id: 'ses_start_time',
                          },
                        }}
                      className={formik1.errors.ses_start_time && formik1.touched.ses_start_time ? "input-error w-100 h-auto" : "w-100 h-auto"}
                  />
            </LocalizationProvider>
            {formik1.errors.ses_start_time && formik1.touched.ses_start_time && <span className="error ">{formik1.errors.ses_start_time}</span>}
          </Col>
          {/* --------------End Time--------------- */}

          <Col lg="6" md="6" sm="12" className='position-relative mb-3'>
            <h6 className="form-lable required-field">End Time</h6>
            {/* <Form.Control type="time" value={formik1.values.ses_end_time}
              onChange={formik1.handleChange}
              onBlur={formik1.handleBlur}
              id="ses_end_time"
              className={formik1.errors.ses_end_time && formik1.touched.ses_end_time ? "input-error" : ""}
            /> */}
                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                  <TimePicker 
                      value={dayjs(formik1.values.ses_end_time, 'HH:mm:ss')}
                      onChange={(time) =>  {
                        const event = {
                        target: {
                            name: "ses_end_time",
                            value: dayjs(time, 'HH:mm:ss').format('HH:mm:ss'),
                        },
                        };
                      
                        formik1.handleChange(event);
                        
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                          formik1.setTouched({ ...formik1.touched, ses_end_time: true });
                      }}
                      slotProps={{
                          textField: { readOnly: true, size: 'small',
                            id: 'ses_end_time',
                          },
                        }}
                      className={formik1.errors.ses_end_time && formik1.touched.ses_end_time ? "input-error w-100 h-auto" : "w-100 h-auto"}
                  />
            </LocalizationProvider>
            {formik1.errors.ses_end_time && formik1.touched.ses_end_time && <span className="error ">{formik1.errors.ses_end_time}</span>}
          </Col>
          <Col lg="6" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
            <h6 className="form-lable required-field">Duration</h6>
            <Form.Control
              type="text" // Use "text" type for displaying duration
              id="ses_duration"
              value={formik1.values.ses_end_time && formik1.values.ses_start_time ?
                Moment
                  .utc(
                    Moment(formik1.values.ses_end_time, 'HH:mm').diff(Moment(formik1.values.ses_start_time, 'HH:mm')
                    )
                  )
                  .format('HH:mm') // Format the duration as HH:mm
                : '00.00'
              }
              readOnly // Make the input field read-only
              className={formik1.errors.ses_duration && formik1.touched.ses_duration ? "input-error" : ""}
            />
            {formik1.errors.ses_duration && formik1.touched.ses_duration && <p className="error">{formik1.errors.ses_duration}</p>}
          </Col>


        </Row>

      </Modal.Body>
      <Modal.Footer>

        <div className="mx-auto d-flex gap-2" >

          <Button variant="contained" className="primary-gray" onClick={() => setEditDTShow(false)}>Cancel</Button>
          <Button variant="contained" type='submit' className="primary-filled " disabled={iSEditDTSubmitting} >Save</Button>
        </div>

      </Modal.Footer>
    </Form>
  </Modal>
    <div className="page-wrapper">
      {/* --------------Page Start--------------- */}
      {/* --------------Section Start--------------- */}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'>  Generated Session </h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


              <Typography color="text.primary"> Generated Session</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            {/* {isActive && (

            <NavLink className="primary-filled rounded-10 p-2" to='/generate-session'>
              <AddIcon className="btn-icon" /> Generate Session</NavLink>
          )} */}
          </div>
        </div>

      </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Tab.Container defaultActiveKey="second">
            <Row>
              <Col sm={12}>
                <div className=" justify-content-right  float-end">
                  <div className='d-flex gap-2 '>
                    {/* {isActive && (
                    <div class="form-group has-search my-auto mb-2">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control mb-0" onChange={handleFilter}  />
                    </div>
                  )} */}

                    {/* {!isActive && (
                    <div class="form-group has-search my-auto mb-2">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input type="text" class="form-control mb-0" onChange={handleFilter}  />
                    </div>
                  )} */}
                  </div>
                </div>

              </Col>

              <Col sm={12}>


                {/* <Stack>
                      <div className="my-card p-4 bg-white">

                        <Row> */}
                {/* -------------- Session Title--------------- */}
                {/* <Col lg="3" md="6" sm="12" className='position-relative'>
                             <Select menuPlacement="auto" 
                              options={options_year}
                              
                              name="sess_day"
                              id="sess_day"

                         
                            />

                          </Col> */}

                {/* --------------Session Date--------------- */}

                {/* <Col lg="3" md="6" sm="12" className='position-relative'>
                             <Select menuPlacement="auto" 
                              options={options_month}

                              
                              name="sess_day"
                              id="sess_day"
                            
                            />

                          </Col>




                          <Col lg="3" md="6" sm="12" className='position-relative'>
                             <Select menuPlacement="auto" 
                              options={options_tutor}
                              
                              name="sess_day"
                              id="sess_day"
                           
                            />

                          </Col>
                          <Col lg="3" md="6" sm="12" className='position-relative'>
                            <Button type="submit" variant="contained" className="primary-filled w-100 "  >Filter</Button>

                          </Col>

                        </Row>

                      </div>
                    </Stack> */}

                <Box
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                      backgroundColor: "#e3e6ee",
                    },
                  }}
                  style={{ height: '100%', width: "100%" }}
                >
                 <DataGrid
  
                    //sx={{ border: "none", m: 2 }}
                    autoHeight
                    slots={{
                      loadingOverlay: LinearProgress,
                      //noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      // toolbar: EditToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { fileName: "SessionList" },
                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "SessionList" },
                        records,
                        showQuickFilter: true
                      },


                    }}
                    initialState={{
                      ...records.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    showQuickFilter={true}

                    pageSizeOptions={[10, 25, 50, 100]}

                    loading={loading}
                    rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                    columns={columns}
                    rowHeight={60} density="compact"
                    disableRowSelectionOnClick
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}
                    // onRowClick={(params) =>{ console.log(params.row); setEditData(params.row); setEditDTShow(true);}}
                  />


                </Box>




              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>

      <Card className='my-card mt-4  p-1 bg-white'>
        <Card.Header>  Assigned Students </Card.Header>
        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Row>
            <Col xs="12">
              <div className="">
                <Stack>

                  <Row>
                    <Col xs="12">
                      <div className="">

                        <Row className="justify-content-center">

                  

                          {/* --------------Table Content Start--------------- */}
                          <Col sm={12}>

                            <Box
                              sx={{
                                height: "fit-content",
                                width: "100%",
                                "& .actions": {
                                  color: "text.secondary",
                                },
                                "& .textPrimary": {
                                  color: "text.primary",
                                },
                                "& .super-app-theme--header": {
                                  backgroundColor: "#e3e6ee",
                                },
                              }}
                              style={{ height: '100%', width: "100%" }}
                            >
                             <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                //sx={{ border: "none", m: 2 }}
                                autoHeight
                                slots={{
                                  loadingOverlay: LinearProgress,
                                  //noRowsOverlay: CustomNoRowsOverlay,
                                  toolbar: GridToolbar,
                                  // toolbar: EditToolbar,
                                }}
                                slotProps={{
                                  toolbar: {
                                    csvOptions: { fileName: "StudentList" },
                                    printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "StudentList" },
                                    datastudent,
                                    showQuickFilter: true
                                  },


                                }}
                                initialState={{
                                  ...datastudent.initialState,
                                  pagination: { paginationModel: { pageSize: 10 } },
                                }}
                                showQuickFilter={true}

                                pageSizeOptions={[10, 25, 50, 100]}

                                loading={loading}
                                rows={datastudent.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                columns={columnsstudent}
                                rowHeight={60} density="compact"
                                onRowClick={(params) => handleStudentViewClick(params.row)}

                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}

                              />


                            </Box>


                          </Col>
                          {/* --------------Table Content Start--------------- */}

                        </Row>

                      </div>
                    </Col>
                  </Row>
                </Stack>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* --------------Section End--------------- */}
      {/* --------------Page End--------------- */}
    </div>

    </> 

  );


};

export default SessionList;
