/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';

import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, options_countries, BASE_URL} from "../global";
  import DoneIcon from '@mui/icons-material/Done';
  import CloseIcon from '@mui/icons-material/Close';

  import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

const ListDiagnosticTest = () => {

  const [records, setRecords] = useState([]);

  
  const [data, setItems] = useState([]);
  
  const [loading, setLoading] = useState(true);


  //Api call start


  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
const [data_delete, setDeleteLabelData] = useState([]);
const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


const handleDeleteClick = (row) => {
   
    const updatedData = { ...row};
    setDeleteLabelData(updatedData);
 
    setDeleteShow(true);
 };


 const [showVisible, setVisibleShow] = useState(false);
 const [data_visible, setVisibleLabelData] = useState([]);
 const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
 
 
 const handleVisibleClick = (row) => {
    
     const updatedData = { ...row};
     setVisibleLabelData(updatedData);
  
     setVisibleShow(true);
  };

  useEffect(() => {
    fetchData();
}, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllDiagnostictype"
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }

   
  };

 

  // Sample columns configuration
  const columns = [

    {
      headerName: 'Diagnostic Name',
      field: "Diagnostic Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.dtt_name == null ||  params.row.dtt_name == '' ? '-' : params.row.dtt_name}`;
      }
    },
    {
      headerName: 'Countries',
      field: "Countries",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.country_name == null ||  params.row.country_name == '' ? '-' : params.row.country_name}`;
      }
      
    },
    // {
    //   name: 'Content Details',
    //   selector: row =>row.content_details == null ||  row.content_details == '' ? '-' : row.content_details,
    //   sortable: true
    // },
  //   {
  //     name: 'Content Details',cell: (row) => {
  //     if(row.content_details!=''){
  //       return (
  //         <div className='d-flex justify-content-center'>
  //          <div dangerouslySetInnerHTML={{ __html: row.content_details }} />
  //           {/* <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="primary-blue  btnmr-2 my-2" >Visible</Buttonmui> */}
  //         </div>
  //       );
  //     }else{
  //       return (
  //         <div className='d-flex justify-content-center'>
  //          -
  //           {/* <Buttonmui variant="outlined-red" onClick={() => handleVisibleClick(row)}  className="filled-danger" >Not Visible</Buttonmui> */}
  //         </div>
  //       );
  //     }
        
        
  //   }
  // },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      width: 200,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility)==1? 'Active' : 'Deactive'}`;
      },
      renderCell: (params) => {
            const row = params.row;
        if(parseInt(row.visibility)==1){
          return (
            <div className='d-flex justify-content-center'> 
              <span className='cursor-pointer active-btn' onClick={() => handleDeleteClick(row)} >
               <DoneIcon className="icon-blue  btn-icon me-1" />
               Active
               </span>
               
            </div>
          );
        }else{
          return (
            <div className='d-flex justify-content-center'> 
              <span className='cursor-pointer deactive-btn'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className=" btn-icon me-1" />
                Deactive
              </span>
             
            </div>
          );
        }
          
           
      }
    },
   // { name: "id", selector: (row) => row.pk_content_key, omit: true }
   
  ];


  const [modalShowAdd, setModalShowAdd] = useState(false);



  const basicSchema = yup.object().shape({

    dtt_name: yup.string().required("Diagnostic Name is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    fk_country_id: yup.mixed().required("Countries is required."), 
    // dtt_type: yup.string().required("Diagnostic Type is required.").min(2, "Minimum 2 characters is required.").max(150, "Maximum 150 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),  
    
    //f_link: yup.mixed().required("Form link is required."),  


  //   ban_image: yup.mixed().test('at-least-one', 'Form image or Banner link is required..', function (value) {
  //     const { ban_link } = this.parent;
  //     if (!value && !ban_link) {
  //         return this.createError({
  //             path: 'ban_image',
  //             message: 'Banner image or Banner link is required..',
  //         });
  //     }
  //     return true;
  // }),

  // ban_link: yup.mixed().test('at-least-one', 'Banner image or Banner link is required..', function (value) {
  //     const { ban_image } = this.parent;
  //     if (!value && !ban_image) {
  //         return this.createError({
  //             path: 'ban_link',
  //             message: 'Banner image or Banner link  is required..',
  //         });
  //     }
  //     return true;
  // }),

   

  });

  const initialValues = {
    /*tbl_year_level*/

    dtt_name: '',
    fk_country_id: '',
  //  dtt_type: '',
    //f_link: '',

    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      try {
        const response = await axios.post(BASE_URL+"API/api_index.php", {
          security_key: security_key,
          mode: "CreateDiagnosticType",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your diagnostic test was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData();
            setModalShowAdd(false);

            resetForm();
           

            setIsSubmitting(false); // Re-enable the submit button
          } else if(response.data.data === 2) {
            toast.error("The diagnostic name already exists in this countries", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }else {
            toast.error("Failure! Unable to create diagnostic test. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create diagnostic test. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create diagnostic test. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;


  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };


  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteDiagnostic",
      pk_dtt_key: data_delete.pk_dtt_key
    }).then((responseuserdelete) => {

        if(responseuserdelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! Diagnostic test has been deactivated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to delete diagnostic test. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete diagnostic test. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }



  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "VisibleDiagnostic",
      pk_dtt_key: data_visible.pk_dtt_key
    }).then((responseVisible) => {

        if(responseVisible.data.data == 1){

          setVisibleShow(false)
            // Handle the successful response here
            toast.success("Success! diagnostic test has been activated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

           setIsVisibleSubmitting(false);

        }else{
            toast.error("Failure! Unable to change diagnostic test status. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsVisibleSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to change diagnostic test status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }

  
  




  return (
    <>
       <ToastContainer position="top-center" />

      
      <Modal show={showDelete} variant="danger">

<Modal.Header>Diagnostic</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to deactivate this Diagnostic? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>


  <Modal show={showVisible} variant="danger">

<Modal.Header>Diagnostic</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to activate this Diagnostic? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> 
                
                Add Diagnostic Test </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Diagnostic Name</h6>
                        <Form.Control
                            type="text"
                            value={values.dtt_name}
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            id="dtt_name"
                            className={errors.dtt_name && touched.dtt_name ? "input-error" : ""}
                        />
                        {errors.dtt_name && touched.dtt_name && <p className="error">{errors.dtt_name}</p>}
                    </Col>
                     {/* --------------Student phone--------------- */}
                     {/* <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Diagnostic Type</h6>
                        <Form.Control
                            type="text"
                            value={values.dtt_type}
                            onChange={handleChange}
                            
                            onBlur={handleBlur}
                            id="dtt_type"
                            className={errors.dtt_type && touched.dtt_type ? "input-error" : ""}
                        />
                        {errors.dtt_type && touched.dtt_type && <p className="error">{errors.dtt_type}</p>}
                    </Col> */}
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Countries</h6>
                 <Select menuPlacement="auto" 
                    options={options_countries}
                    name="fk_country_id"
                    value={values.fk_country_id['value']}
                    id="fk_country_id"
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "fk_country_id",
                                value: selectedOption["value"],
                            },
                        };
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, fk_country_id: true });
                    }}
                    className={errors.fk_country_id && touched.fk_country_id ? "input-error" : ""}
                />
                {errors.fk_country_id && touched.fk_country_id && <span className="error">{errors.fk_country_id}</span>}
                </Col>
               
               
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>
        
        </Modal>

       
        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Diagnostic Test</h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                <Typography color="text.primary">Diagnostic Test</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

                <Button  variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                  <AddIcon className="btn-icon me-2"/> 
                Add Diagnostic Test
                </Button>
                {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}
            
            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap  justify-content-end">
                    
                    <div className='d-flex gap-2 '>
                     
                        {/* <div class="form-group has-search my-auto mb-2">
                          <span class="fa fa-search form-control-feedback"></span>
                          <input type="text" class="form-control mb-0"  onChange={handleFilter} />
                        </div>
                      */}

                      
                    </div>

                  </div>
                  
                </Col>

                <Col sm={12}>

               
                     <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                              color: "text.secondary",
                          },
                          "& .textPrimary": {
                              color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                              backgroundColor: "#f4f4f4",
                          },
                      }}
                      style={{ height: '100%', width: "100%" }}
                      >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          loading={loading}
                          slots={{
                              loadingOverlay: LinearProgress,
                              //noRowsOverlay: CustomNoRowsOverlay,
                              toolbar: GridToolbar,
                              // toolbar: EditToolbar,
                          }}
                          slotProps={{
                              toolbar: {
                                  csvOptions: { fileName: "DiagnosticList" },
                                  printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "DiagnosticList" },
                                  records,
                                  showQuickFilter: true
                              },
                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns}
                          rowHeight={60} density="compact"
                         // onRowClick={(params) => handleRowClick(params.row)}
                         disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}
                      />
                  </Box>

                   
                </Col>
              </Row>
            

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default ListDiagnosticTest;
